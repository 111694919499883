const SELECT_PROJECT_CRUD = 'SELECT_PROJECT_CRUD';
const SET_SELECTED_PROJECT_FIELD = 'SET_SELECTED_PROJECT_FIELD';
const SET_SELECTED_PROJECT_TYPE = 'SET_SELECTED_PROJECT_TYPE';
const SET_PROJECT_VALIDATION_ERRORS = 'SET_PROJECT_VALIDATION_ERRORS';
const CLEAR_PROJECT_VALIDATION_ERRORS = 'CLEAR_PROJECT_VALIDATION_ERRORS';
const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
const PROJECT_BULK_UPLOAD_MESSAGES = 'PROJECT_BULK_UPLOAD_MESSAGES';

export {
  SELECT_PROJECT_CRUD,
  SET_SELECTED_PROJECT_FIELD,
  SET_SELECTED_PROJECT_TYPE,
  SET_PROJECT_VALIDATION_ERRORS,
  CLEAR_PROJECT_VALIDATION_ERRORS,
  CLEAR_PROJECTS,
  PROJECT_BULK_UPLOAD_MESSAGES,
}
