import React from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Grid from '@material-ui/core/Grid';
import ApproveDirectiveSection from '../../../../commonComponents/CreditDetails/ApproveDirectiveSection';
import DetailFrame from '../../../../commonComponents/CreditDetails/DetailFrame'
import QuestionAndAnswers from './QuestionAndAnswers';
import { changeSectionCompletionStatus } from '../../../../actions/creditDetail';
import { getCurrentCreditDetailQuestionAnswers } from '../../../../selectors/creditDetail';

const DirectiveQAndA = ({ section, creditId }) => {
  const dispatch = useDispatch();
  const { creditDetailSectionRefId, isCompleted } = section;
  const questions = useSelector(getCurrentCreditDetailQuestionAnswers, shallowEqual);
  const questionEntries = Object.entries(questions);

  const handleCompleteSectionCheck = (statusComplete) => {
    const payload = {
      creditId: creditId,
      creditDetailSectionRefId: creditDetailSectionRefId,
      isCompleted: statusComplete
    };
    dispatch(changeSectionCompletionStatus(payload));
  }

  const checkAreAllQuestionsAnswered = () => {
    const checkUnansweredQuestions = (question) => question[1].answer.value === false || question[1].answer.value === '';
    return !questionEntries.some(checkUnansweredQuestions)
  }

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item>
         <ApproveDirectiveSection
           title={'Directive Q&A'}
           body={'These answers will form part of the report and relate to the amounts included in the Expense Details and general company controls over financial reporting.'}
           onToggleComplete={handleCompleteSectionCheck}
           isCompleted={isCompleted}
           disabled={!checkAreAllQuestionsAnswered()}
          />
        </Grid>

        {questionEntries.map((questionEntry, index) => {
          const questionData = questionEntry[1];

          return (
            <Grid key={index} item>
              <DetailFrame
                title={questionData.question}
                questionCounter={{ counter: index, quantity: questionEntries.length }}
                iconText={'Q&A'}
                divider>
                <QuestionAndAnswers
                  question={questionData}
                  item={index}
                  isCompleted={isCompleted}
                  creditId={creditId}
                />
              </DetailFrame>
            </Grid>
          )
        })}
      </Grid>
    </>
  );
};

export default DirectiveQAndA;


