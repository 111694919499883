import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  dialsWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dialContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '65px',
    height: '85px',
    alignSelf: 'flex-end',
    margin: `0 ${theme.spacing(1.5)}px`,
  },
  dial: {
    borderRadius: '100%',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  default: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[800],
    border: `1px solid ${theme.palette.grey[800]}`,
  },
  pending: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
  },
  completed: {
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  dialValue: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: 700,
    fontSize: '1.8rem',
  },
  dialTitle: {
    alignSelf: 'center',
    color: theme.palette.primary.main,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
}));
