const headConfig = [
  { name: 'Id', sortable: true, id: 'id', width: 100 },
  { name: 'Jurisdiction', sortable: true, id: 'jurisdictionName', width: 250 },
  { name: 'Tax Payer Type', sortable: true, id: 'taxPayerType', width: 150 },
  { name: 'Credit Details', sortable: false, id: 'creditDetailStatus', width: 250 },
  { name: 'Credit Status', sortable: true, id: 'creditStatus', width: 150 },
  { name: 'Published Status', sortable: true, id: 'publishedStatus', width: 150 },
  { name: 'Entity Credit Amount', sortable: true, id: 'entityCreditAmount', width: 200 },
  { name: 'Group Credit Amount', sortable: true, id: 'groupCreditAmount', width: 200 },
  { name: 'Credit Method', sortable: true, id: 'creditMethod', width: 300 },
  { name: 'Due Date', sortable: true, id: 'dueDateFormatted', width: 150 },
];


const rowsConfig = ['legalEntityName', 'id', 'jurisdictionName', 'taxPayerType', 'creditDetailStatus', 'creditStatus', 'publishedStatus', 'entityCreditAmount', 'groupCreditAmount', 'creditMethod', 'dueDateFormatted'];


export {
  headConfig,
  rowsConfig,
};
