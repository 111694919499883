import React from 'react';
import { InputLabel, Input as InputMui } from '@material-ui/core';

const File = ({ name, onChange, label, muiProps, isInvalid }) => {
  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <InputMui
        id={name}
        onChange={onChange}
        error={isInvalid}
        type='file'
        {...muiProps}
      />
    </>
  );
};

export default File;
