import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Search, ArrowUpward, ArrowDownward } from '@material-ui/icons';
import InputBase from '@material-ui/core/InputBase';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  columnSearchContainer: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 5,
    marginRight: theme.spacing(.5),
    width: 290,
  },
  iconButton: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  input: {
    '& input': {
      '&::placeholder': {
        color: theme.palette.grey[900],
        opacity: 1,
      }
    }
  }
}));

const ColumnSearch = ({ value, onChange, onSortChange, sortMode }) => {
  const styles = useStyles();
  
  const SortArrow = () => {
    const componentsBySortMode = {
      'ASC': <ArrowUpward />,
      'DESC': <ArrowDownward />,
    }
    
    return componentsBySortMode[sortMode];
  }
  
  return (
    <div className={styles.columnSearchContainer}>
      <IconButton disableRipple className={styles.iconButton}>
        <Search />
      </IconButton>
      <InputBase
        className={styles.input}
        placeholder="Employee"
        value={value}
        onChange={onChange}
      />
        <IconButton className={styles.iconButton} disableRipple onClick={onSortChange} >
        <SortArrow />
      </IconButton>
    </div>
  );
};

export default ColumnSearch;
