import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  topControlsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  topControlsLeftSection: {
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  selector: {
    width: '200px',
    marginRight: theme.spacing(1)
  },
  noData: {
    width: '80%',
    background: theme.palette.grey[100],
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(2)
  },
  ButtonContainer: {
    textAlign: 'center'
  },
  IconMargin: {
    marginRight: '5px'
  },
  ButtonContainerChild: {
    display: 'inline-block',
    margin: '10px'
  },
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  entityDropdown: {
    width: '230px',
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end'
  },
  totalCountryContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(.5),
    whiteSpace: 'nowrap'
  },
  countryTotalExpensesSubtitle: {
    color: theme.palette.grey[800],
    marginRight: theme.spacing(.5)
  },
  countryTotalExpensesValue: {
    color: theme.palette.primary.main,
  },
  circularProgress: {
    color: theme.palette.primary.main,
    position: 'relative',
    left: '50%',
  },
}));
