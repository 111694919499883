export function setupWalkme(setupData) {
  const WALKME_SCRIPT_STAG = `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/ea99c6174fa949ed89103c49ab1d2ee0/test/walkme_ea99c6174fa949ed89103c49ab1d2ee0_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`;
  const WALKME_SCRIPT_PROD = `(function() {var walkme = document.createElement('script'); walkme.type = 'text/javascript'; walkme.async = true; walkme.src = 'https://cdn.walkme.com/users/ea99c6174fa949ed89103c49ab1d2ee0/walkme_ea99c6174fa949ed89103c49ab1d2ee0_https.js'; var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(walkme, s); window._walkmeConfig = {smartLoad:true}; })();`;

  const walkmeNode = document.getElementById("walkme-script-node")

  if(!walkmeNode){
    const node = document.createElement('script');
    node.setAttribute("id", "walkme-script-node")
    node.type = 'text/javascript';
    node.innerHTML = ['develop', 'staging'].includes(setupData.config?.XbsEnvironment) ? WALKME_SCRIPT_STAG : WALKME_SCRIPT_PROD;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  window.userId = setupData.auth.id;
  window.role = setupData.auth.roles;
  window.tenantId = setupData.selectedTenantId;
}
