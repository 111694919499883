export const buildEmployeeInterviewInputFields = (employeeInterview) => [
  {
    name: 'name',
    label: 'Name',
    type: 'Input',
    value: employeeInterview.name,
    required: true,
  },
  {
    name: 'title',
    label: 'Title',
    type: 'Input',
    value: employeeInterview.title,
    required: true,
  },
];
