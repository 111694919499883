const TABS =
  [
    {name: 'Preliminary', id: 1},
    {name: 'Internal Draft', id: 2},
    {name: 'Published Draft', id: 3},
    {name: 'Final Report', id: 4}
  ];

const SECTION_TITLE = 'Reports';

const CRUD_TYPES = {
  edit: 'edit',
  create: 'create'
}

const ERRORS = {
  reportId: 'Select a report or enter a report name',
  reportName: 'Enter a report name or select a report',
  template: 'Select a report type'
}

const REPORT_VERSION_ACTIONS = {
  promote: 'promote',
  demote: 'demote'
}

const REPORT_VERSION_STATUSES = {
  Pending: 1,
  Generated: 2,
  Failed: 3,
  Uploaded: 4
}

const PUBLISH_REPORT_ACTIONS = {
  publish: 'publish',
  unpublish: 'unpublish'
}

const STAGES_PUBLISH_ALLOWED = [4, 3];

const MAX_TABLE_HEIGHT = '90%'

  export {
    TABS,
    SECTION_TITLE,
    CRUD_TYPES,
    ERRORS,
    REPORT_VERSION_ACTIONS,
    REPORT_VERSION_STATUSES,
    PUBLISH_REPORT_ACTIONS,
    STAGES_PUBLISH_ALLOWED,
    MAX_TABLE_HEIGHT
  }
