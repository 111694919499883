import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import numberYearToDate from '../../../utils/numberYearToDate';

const Year = ({ value, label, onChange, name, muiProps, isInvalid }) => {
  const handleChange = (date) => {
    const value = (date && date.getFullYear) ? date.getFullYear() : date;
    onChange({ target: { name, value } });
  };
  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
    >
      <KeyboardDatePicker
        views={["year"]}
        disableToolbar
        autoOk
        variant="inline"
        format="yyyy"
        margin="normal"
        label={label}
        value={value ? numberYearToDate(value) : null}
        onChange={handleChange}
        error={isInvalid}
        {...muiProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default Year;
