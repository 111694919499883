import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";


const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: theme.spacing(1),
  },
  card: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: theme.spacing(17),
  },
  avatar: {
    width: theme.spacing(2.8),
    height: theme.spacing(2.8),
    fontSize: theme.spacing(0.9),
    color: theme.palette.black[800],
  },
  title: {
    color: theme.palette.grey[800],
  },
}));

const DetailFrame = ({ title, questionCounter, iconText, divider, className, children }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={`${classes.card} ${className}`}>
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {iconText}
          </Avatar>
        }
        title={
          <Typography className={questionCounter ? classes.title : null}
                      variant={questionCounter ? 'caption' : 'subtitle2'} component="h2">
            {questionCounter ? `Question ${questionCounter.counter + 1} of ${questionCounter.quantity}` : title}
          </Typography>
        }

        subheader={
          questionCounter ?
            <Typography variant={'subtitle2'} component="h2">
              {title}
            </Typography> : null
        }
      />

      {divider ? <Divider variant="middle"/> : null}

      <CardContent className={classes.cardContent}>
        {children}
      </CardContent>
    </Card>
  )
}

export default DetailFrame;