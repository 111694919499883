const TABS =
  [
    'Entity Overview',
    'Employee Interviews',
    'Entity Detail'
  ];

const TEXTBOX_PLACEHOLDER = 'Please enter an answer for this question';
const ENTITY_OVERVIEW_NO_DATA = 'There are no questions available for the selected entiy';
const EMPLOYEE_INTERVIEW_NO_DATA = 'There is no data for the selected entity';
const SELECT_ENTITY = 'Please select an entity'
const MAX_TABLE_HEIGHT = '90%'

export {
  TABS,
  TEXTBOX_PLACEHOLDER,
  ENTITY_OVERVIEW_NO_DATA,
  EMPLOYEE_INTERVIEW_NO_DATA,
  SELECT_ENTITY,
  MAX_TABLE_HEIGHT,
}
