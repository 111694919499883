import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, ButtonGroup } from '@material-ui/core';
import ClassNames from 'classnames';
import TextEditor from '../../components/TextEditor';

const usePromptStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    border: `1px solid ${theme.palette.grey[700]}`,
    color: theme.palette.grey[700],
    '&$selected': {
      color: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.secondary.dark}`,
      backgroundColor: theme.palette.primary.veryLight
    }
  },
  selected: {
    // this empty class makes the above class work for the selected button
    // tested in Firefox, Safari, and Chrome with the same result.
    // Strange but true. @todo: figure out a better solution.
  }
}));

const YesNoPrompt = ({ booleanValue, onChange }) => {
  const styles = usePromptStyles();
  const yesClasses = ClassNames({
    [styles.button]: true,
    [styles.selected]: !!booleanValue
  });
  const noClasses = ClassNames({
    [styles.button]: true,
    [styles.selected]: !booleanValue
  })
  return (
    <ButtonGroup className={styles.buttonGroup} color="primary" aria-label="outlined primary button group">
      <Button onClick={() => onChange(true)} className={yesClasses}>Yes</Button>
      <Button onClick={() => onChange(false)} className={noClasses}>No</Button>
    </ButtonGroup>
  )
}

const usePromptPageStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem'
  },
  caption: {
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(2)
  },
  textEditor: {
    marginTop: theme.spacing(2),
  },
  questionContainer: {
    marginTop: theme.spacing(2),
  }
}));

const SimplePromptResponsePage = ({
  title,
  caption,
  placeholder,
  boolValue,
  textValue,
  onChange,
  onBlur,
  onToggle,
  onUnmount,
  editorKey, // always pass a key so that we don't reuse editors
  hideToolbar,
}) => {
  const styles = usePromptPageStyles();
  const [initialEditorContent, setInitialEditorContent] = useState(null);
  const [needsToLoad, setNeedsToLoad] = useState(true);
  if (textValue && needsToLoad) {
    setNeedsToLoad(false);
  }
  useEffect(() => {
    setInitialEditorContent(textValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsToLoad, editorKey]);

  useEffect(() => onUnmount, []); //eslint-disable-line

  return (
    <div className={styles.container}>
      <div className={styles.questionContainer}>
        <Typography className={styles.title}>{ title }</Typography>
        <Typography className={styles.caption}>{ caption }</Typography>
      </div>
      <YesNoPrompt booleanValue={boolValue} onChange={onToggle} />
      <div className={styles.textEditor}>
        <TextEditor
          key={editorKey}
          onBlur={onBlur}
          onChange={onChange}
          data-testid="questionTextEditor"
          initialEditorContent={initialEditorContent}
          placeholder={placeholder}
          hideToolbar={hideToolbar}
          height={160}
        />
      </div>
    </div>
  )
};

export default SimplePromptResponsePage;
