import React from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { formatResultByType } from '../../../../utils/customFormatter';
const useStyles = makeStyles((theme) => ({
  tableContainer: {
    width: '60%',
    maxHeight: '80%',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    marginTop: theme.spacing(2)
  },
  tableHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  tableHeaderContent: {
    color: theme.palette.common.white,
    fontWeight: 700,
  },
  tableRow: {
    '&:nth-child(even)': {
      backgroundColor: theme.palette.grey[200],
    }
  },
  tableTitle: {
    fontWeight: 700,
  },
  closeRow: {
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
  },
  closeRowContent: {
    fontWeight: 700,
    color: theme.palette.common.white,
  }
}));



const CreditCalculationResultsModal = ({ credit, toggle, open }) => {
  const styles = useStyles();
  const filteredCalculations = credit.creditCalculations.filter(creditObj => {
    if ('displayOption' in creditObj) {
      return (creditObj.displayOption === 1 || creditObj.displayOption === 2);
    } else {
      return true;
    }
  });

  return (
    <Modal
      open={open}
      onClose={() => toggle(false)}
    >
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow className={styles.tableHeader}>
              <TableCell
                className={styles.tableHeaderContent}
                component="th"
                align="center"
                colSpan={2}
              >
                Credit Calculation Results
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={styles.tableTitle}>Title</TableCell>
              <TableCell className={styles.tableTitle} align="center">Result</TableCell>
            </TableRow>
          </TableHead>
          <TableBody >
            {filteredCalculations.map(creditCalculation => {
              const creditCalculationType = creditCalculation.type === 'percentage' ? 'decimalPercentage' : creditCalculation.type;

              return (
                <TableRow className={styles.tableRow} key={creditCalculation.title}>
                  <TableCell>{creditCalculation.title}</TableCell>
                  <TableCell align="center">{formatResultByType({ value: creditCalculation.result, type: creditCalculationType, currencyIsoCode: credit.currencyIsoCode })}</TableCell>
                </TableRow>
              )
            })}
            <TableRow onClick={() => toggle(false)} className={styles.closeRow}>
              <TableCell className={styles.closeRowContent} align="center" colSpan={2}>Close</TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>
    </Modal>
  );
};

export default CreditCalculationResultsModal;
