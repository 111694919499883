const headConfig = [
  { name: 'Jurisdiction', sortable: true, id: 'jurisdictionName' },
  { name: 'Active Projects', sortable: true, id: 'activeProjects' },
];

const rowsConfig = ['name', 'jurisdictionName', 'activeProjects']

export {
  headConfig,
  rowsConfig
}
