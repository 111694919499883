import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { ArrowBack, AttachMoney, HelpOutline, PlaylistAddCheck } from '@material-ui/icons'
import { CREDIT_DETAIL_INITIAL_STATE, CREDIT_DETAIL_SECTIONS_REF_ID } from './constants'

import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import { NavCard } from '../../../commonComponents/NavCard/NavCard';
import DirectiveExpenseDetails from './DirectiveExpenseDetails';
import DirectiveQAndA from './DirectiveQAndA';
import { DocumentationReview } from './DocumentationReview/DocumentationReview';
import { selectContainer } from '../../../actions/container';
import { getCreditById } from '../../../selectors/credits';
import { fetchSections, getCreditDetailQuestionsAndAnswers } from '../../../actions/creditDetail';
import { getSections } from '../../../selectors/creditDetail';
import { useStyles } from './styles';
import { usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../constants/promiseTracker';
import LoadingElement from '../../../commonComponents/LoadingElement';

const CreditDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { creditId } = useParams();
  const credit = useSelector(getCreditById(Number(creditId)));
  const sectionsData = useSelector(getSections);
  const [activeSectionId, setActiveSectionId] = useState(sectionsData[0]?.creditDetailSectionRefId || 1);
  const styles = useStyles();
  const { promiseInProgress: isFetchingQuestions } = usePromiseTracker({ area: tags.FETCH_CREDIT_DETAIL_QUESTIONS, delay: 0 });
  const { promiseInProgress: isFetchingSections } = usePromiseTracker({ area: tags.FETCH_CREDIT_DETAIL_SECTIONS, delay: 0 });

  useEffect(() => {
    dispatch(fetchSections(creditId));
  }, [dispatch,creditId]);

  useEffect(() => {
    dispatch(getCreditDetailQuestionsAndAnswers(creditId, activeSectionId));
  }, [dispatch,activeSectionId,creditId]);

  const getSectionDataById = (sectionRefId) => {
    return sectionsData.find((sectionData) => sectionData.creditDetailSectionRefId === sectionRefId) || {};
  };

  function handleContainerSelect(value) {
    const containerId = Number(value);

    dispatch(selectContainer(containerId));
  }

  function handleGoBackToStudy() {
    history.push('/service-manager/study-central');
  }

  function handleSetCurrentTab(sectionId) {
    return () => { setActiveSectionId(sectionId); }
  }

  function isActive(sectionId) {
    return activeSectionId === sectionId;
  }

  function showCurrentTabData() {
    switch (activeSectionId) {
      case CREDIT_DETAIL_SECTIONS_REF_ID.directiveExpenseDetails: return <DirectiveExpenseDetails section={getSectionDataById(activeSectionId)} creditId={creditId}/>;
      case CREDIT_DETAIL_SECTIONS_REF_ID.directiveQAndA: return <DirectiveQAndA section={getSectionDataById(activeSectionId)} creditId={creditId}/>;
      case CREDIT_DETAIL_SECTIONS_REF_ID.documentationReview: return <DocumentationReview section={getSectionDataById(activeSectionId)} creditId={creditId}/>;
      default: return null;
    }
  }

  const setSectionIcon = (sectionRefId) => {
    const iconsPerSection = {
      [CREDIT_DETAIL_SECTIONS_REF_ID.directiveExpenseDetails]: AttachMoney,
      [CREDIT_DETAIL_SECTIONS_REF_ID.directiveQAndA]: HelpOutline,
      [CREDIT_DETAIL_SECTIONS_REF_ID.documentationReview]: PlaylistAddCheck,
    };

    return iconsPerSection[sectionRefId];
  };

  function renderMainContent() {
    const isLoading = isFetchingSections || sectionsData === CREDIT_DETAIL_INITIAL_STATE.sections;
    if (isLoading) {
      return <LoadingElement />
    } else if (!sectionsData.length) {
      return <div data-testid="not-found-message">No credit details found for the specified credit</div>
    }

    return (
      <main className={styles.main}>
        <nav className={styles.nav}>
          {sectionsData.map((section) => {
            const { creditDetailSectionRefId: sectionId, name, isCompleted } = section;

            return (
              <NavCard
                key={sectionId}
                cardTitle={name}
                isActive={isActive(sectionId)}
                CardIcon={setSectionIcon(sectionId)}
                handleSetCurrentTab={handleSetCurrentTab(sectionId)}
                isCompleted={isCompleted}
              />
            )
          })}
        </nav>

        <section className={styles.info}>
          {isFetchingQuestions ? <LoadingElement /> : showCurrentTabData()}
        </section>
      </main>
    )
  }

  return (
    <>
      <ContainersDropdown onChange={handleContainerSelect} />

      <div className={styles.container}>
        <header className={styles.header}>
          <ArrowBack className={styles.backButton} onClick={handleGoBackToStudy} />

          <h1>Credit Details</h1>
          <h2 className={styles.creditName}>{credit?.legalEntityName}</h2>
        </header>

        {renderMainContent()}
      </div>
    </>
  );

};

export default CreditDetails;
