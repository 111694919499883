const downloadBuffer = (buffer, fileName) => {
  const { ContentType, Body } = buffer;
  const bytes = new Uint8Array(Body.data);
  const blob = new Blob([bytes], { type: ContentType });
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  link.remove();
};

export default downloadBuffer;
