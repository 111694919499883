import React from 'react';
import TabNavigation from '../../../commonComponents/TabNavigation/';
import { makeStyles } from '@material-ui/core/styles';
import FilteredSurveyList from './FilteredSurveyList';

const sectionWidth = '75%';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100]
  },
  surveysListWrapper: {
    width: '100%',
  },
  surveysListHeader: {
    backgroundColor: theme.palette.common.white,
    padding: `${theme.spacing(1.5)}px 0`,
    fontSize: '2rem',
    display: 'flex',
    justifyContent: 'center'
  },
  tabNavigationAppBar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white
  },
  tabNavigationTabs: {
    width: sectionWidth
  },
  tabNavigationPanel: {
    width: sectionWidth,
    margin: 'auto',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  title: {
    display: 'block',
    width: sectionWidth
  }
}));

const SurveyLists = () => {
  const styles = useStyles();
  const tabs = [
    'Open Surveys',
    'Submitted Surveys'
  ];
  return (
    <div className={styles.mainContainer}>
      <div className={styles.surveysListWrapper}>
        <div className={styles.surveysListHeader}>
          <span className={styles.title}>
            Your Surveys 
          </span>
        </div>
  
        <TabNavigation
          tabs={tabs} 
          type="flat" 
          AppBarProps={{ className: styles.tabNavigationAppBar }}
          TabsProps={{ className: styles.tabNavigationTabs }}
          TabPanelProps={{ className: styles.tabNavigationPanel, PanelBoxProps: { p: 0 } }}
        >
          <FilteredSurveyList type="open" />
          <FilteredSurveyList type="closed" />
        </TabNavigation>
      </div>
    </div>
  );
};

export default SurveyLists;
