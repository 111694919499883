import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.common.black,
    height: '100vh',
    color: theme.palette.common.white,
  },
  title: {
    fontSize: '2em'
  },
  titleSpan: {
    color: theme.palette.pink
  },
  formGroup: {
    position: 'relative',
    overflowY: 'scroll',
    height: '520px',
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(2),
    background: theme.palette.pink,
    width: '70%',
    height: '50px',
    borderRadius: 10,
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.shades.pink,
    }
  },
  inputError: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(0.5)
  },
  eulaContent: {
    width: '70%'
  },
  eulaError: {
    color: theme.palette.error.main,
    paddingTop: theme.spacing(1)
  }
}))

export default useStyles;