export const getSelectedTenant = (state) => {
  const { selectedTenant, tenants } = state.common;
  if (!selectedTenant || !tenants) {
    return {};
  }

  const selectedTenantObject = tenants.find(({ tenant_id }) => tenant_id === selectedTenant);
  const dropdownSelectedTenant = typeof selectedTenantObject === 'undefined' ? {} : selectedTenantObject;
  return dropdownSelectedTenant;
};

export const getSelectedTenantId = state => state?.common?.selectedTenant;
