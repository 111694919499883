import { 
  GET_EXPENSES_TYPES_SUCCESS,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_TAX_YEARS_SUCCESS,
  GET_EMPLOYEES_FOR_EXPENSE_SUCCESS,
  GET_ALLOCATED_PROJECTS,
  EDIT_ALLOCATED_PROJECT,
  ADD_PROJECT_ALLOCATION,
  DELETE_PROJECT_ALLOCATION,
  SET_EXPENSE_VALIDATION_ERRORS,
  CLEAR_EXPENSE_VALIDATION_ERRORS,
  GET_HOURS_BY_SOURCE,
  SET_DIRECTIVE_CLASSIFICATIONS,
  SET_TOTAL_EXPENSES
} from '../constants/actionTypes/expense'
import { TOTAL_EXPENSE } from '../components/ServiceManager/Expenses/constants';

const initialState = {
  totalExpenses: {
    [TOTAL_EXPENSE.country]: 0,
  }
};

const expense = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPENSES_TYPES_SUCCESS:
      return {
        ...state,
        expensesTypes: action.expensesTypes
      }
    case GET_EXPENSES_SUCCESS:
      return {
        ...state,
        expenses: {
          [action.entityId]: {
            [action.expenseTypeRefId] : action.expenses
          }
        }
      }
    case GET_EXPENSES_TAX_YEARS_SUCCESS:
      return {
        ...state,
        taxYears: action.taxYears
      }
    
    case GET_EMPLOYEES_FOR_EXPENSE_SUCCESS: 
      return {
        ...state,
        employees: action.employees
      }
    case GET_ALLOCATED_PROJECTS: 
        return {
          ...state,
         allocatedProjects: action.projects
        }
    case EDIT_ALLOCATED_PROJECT: {
      const { allocatedProjects } = state;
      allocatedProjects[action.index] = {...allocatedProjects[action.index], ...action.payload};
      return {
        ...state,
        allocatedProjects
      }
    }
    case ADD_PROJECT_ALLOCATION: {
      const { allocatedProjects } = state
      return {
        ...state,
        allocatedProjects: [...allocatedProjects, {projectId: null, projectPercent: 0, expenseId: action.expenseId}]
    }}
    case DELETE_PROJECT_ALLOCATION:{
      const allocatedProjects = state.allocatedProjects.filter((_, i) => i !== action.index)
      return {
        ...state,
        allocatedProjects
      }
    }
    case SET_EXPENSE_VALIDATION_ERRORS:
      return {
        ...state,
        uploadValidationErrors: action.errors
      }
    case CLEAR_EXPENSE_VALIDATION_ERRORS:
      return {
        ...state,
        uploadValidationErrors: []
      }
    case SET_TOTAL_EXPENSES:
      return {
        ...state,
        totalExpenses: {
          ...state.totalExpenses,
          [action.totalExpenseType]: action.totalExpenses
        }
      }
    case GET_HOURS_BY_SOURCE: 
      return {
        ...state,
        hoursBySource: action.hoursBySource
      }
    case SET_DIRECTIVE_CLASSIFICATIONS: 
      return {
        ...state,
        directiveClassifications: action.directiveClassifications
      }
    default: return state;
    }
  };

export default expense;
