export const TENANT_STATUSES = {
  1: 'Prospect',
  2: 'Setup',
  3: 'Active',
  4: 'Archived',
  'Prospect': 1,
  'Setup': 2,
  'Active': 3,
  'Archived': 4,
};

export const TENANT_INITIAL_STATE = {
  list: [],
  selected: {
    name: '',
    status: '',
    enabled: '',
  },
};

export const MAX_TABLE_HEIGHT = '90%'
