import {
  FETCH_SURVEY_PROJECTS_SUCCESS,
  SELECT_SURVEY_PROJECT_QUALIFICATION,
  FETCH_SURVEY_PROJECT_QUALIFICATIONS_SUCCESS,
  PREVIOUS_PROJECT_QUESTION,
  NEXT_PROJECT_QUESTION,
  UPDATE_PROJECT_QUESTION_ANSWER,
  RESET_STATE_SURVEY_WIZARD_PROJECTS,
  SET_IS_SAVE_IN_PROGRESS,
} from '../../constants/actionTypes/surveyWizard';
import { PROJECTS_INITIAL_STATE } from '../../components/SurveyCentral/SurveyWizard/constants';

const surveyWizard = (state = PROJECTS_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SURVEY_PROJECTS_SUCCESS:
      return {
        ...state,
        projects: action.projects,
        selectedProjectId: (Object.values(action.projects)[0] || {}).id
      }
    case SELECT_SURVEY_PROJECT_QUALIFICATION:
      return {
        ...state,
        selectedProjectId: action.selectedProjectId,
        selectedQuestionIndex: action.selectedProjectId === state.selectedProjectId ? state.selectedQuestionIndex : 0
      }
    case FETCH_SURVEY_PROJECT_QUALIFICATIONS_SUCCESS:
      return {
        ...state,
        qualifications: {
          ...state.qualifications,
          [action.project]: action.qualifications
        }
      }
    case PREVIOUS_PROJECT_QUESTION:
      return {
        ...state,
        selectedQuestionIndex: state.selectedQuestionIndex - 1
      }
    case NEXT_PROJECT_QUESTION:
      return {
        ...state,
        selectedQuestionIndex: state.selectedQuestionIndex + 1
      }
    case UPDATE_PROJECT_QUESTION_ANSWER:
      const stateQualificationsCopy = [...state.qualifications[action.projectId]];
      const currentQuestion = stateQualificationsCopy.find(question => question.id === action.questionId);

      if (!currentQuestion) {
        return {
          ...state
        }
      }

      currentQuestion.answer = {
        ...currentQuestion.answer,
        description: action.textValue
      };

      if (action.boolValue !== undefined) {
        currentQuestion.answer.value = action.boolValue ? 1 : 0;
      }

      return {
        ...state,
        qualifications: {
          ...state.qualifications,
          [action.projectId]: stateQualificationsCopy
        }
      };
    case SET_IS_SAVE_IN_PROGRESS:
      return {
        ...state,
        isSaveInProgress: action.isSaveInProgress
      }
    case RESET_STATE_SURVEY_WIZARD_PROJECTS:
      return PROJECTS_INITIAL_STATE
    default: return state;
  }
};

export default surveyWizard;
