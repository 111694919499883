import React from 'react';

const SubmitSvg = ({ className }) => {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <title>Icon/Upload</title>
        <g id="Icon/Upload" stroke="none" strokeWidth="1" fillRule="evenodd">
            <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
            <path d="M19,12 L19,19 L5,19 L5,12 L3,12 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,12 L19,12 Z M11,6.83 L8.41,9.41 L7,8 L12,3 L17,8 L15.59,9.41 L13,6.83 L13,17.0067139 L11,17.0067139 L11,6.83 Z" id="Upload-24px" fill="#42195A"></path>
        </g>
    </svg>
  );
};

export default SubmitSvg;
