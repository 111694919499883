import { trackPromise } from 'react-promise-tracker';
import { apiClient } from '../../api/apiClient';
import { handleError } from '../error';

import {
  FETCH_EXPENSE_QUESTIONS_SUCCESS,
  FETCH_EXPENSE_TYPES_FOR_WIZARD_SUCCESS,
  SET_SELECTED_EXPENSE_SUB_SECTION,
  NEXT_EXPENSE_QUESTION,
  PREVIOUS_EXPENSE_QUESTION,
  UPDATE_EXPENSE_QUESTION_ANSWER
} from '../../constants/actionTypes/surveyWizard';

import { tags } from '../../constants/promiseTracker';

export const fetchExpenseQuestionsSuccess = (expenseTypeId, questions) => ({
  type: FETCH_EXPENSE_QUESTIONS_SUCCESS,
  questions,
  expenseTypeId
});

export const fetchExpenseTypesForWizardSuccess = types => ({
  type: FETCH_EXPENSE_TYPES_FOR_WIZARD_SUCCESS,
  types
});

export const setSelectedExpenseType = expenseTypeId => ({
  type: SET_SELECTED_EXPENSE_SUB_SECTION,
  expenseTypeId
});

export const nextExpenseQuestion = () => ({
  type: NEXT_EXPENSE_QUESTION
})

export const previousExpenseQuestion = () => ({
  type: PREVIOUS_EXPENSE_QUESTION
})

export const updateExpenseQuestionAnswer = ({ answer, answerDescription, questionId, expenseTypeId }) => ({
  type: UPDATE_EXPENSE_QUESTION_ANSWER,
  answer,
  answerDescription,
  questionId,
  expenseTypeId
})

export const fetchExpenseQuestions = (expenseTypeId, jurisdictionId, taxYear, surveyId) => async dispatch => {
  try {
    const questions = await  trackPromise(
      apiClient({
        method: 'get',
        url: `expense-type/expense-type-questions?taxYear=${taxYear}&jurisdictionId=${jurisdictionId}&expenseTypeId=${expenseTypeId}&surveyId=${surveyId}`
      }),
      tags.FETCH_EXPENSE_QUESTIONS
    );
  
    dispatch(fetchExpenseQuestionsSuccess(expenseTypeId, questions));
    return questions;
  } catch (err) {
    return dispatch(handleError(err));
  }
}

export const fetchExpenseTypes = (jurisdictionId, taxYear) => async (dispatch) => {
  if (!jurisdictionId || !taxYear) {
    return;
  }
  try {
    let expensesTypes = await trackPromise(
      apiClient({
        method: 'get',
        url: `/expense-type?jurisdictionId=${jurisdictionId}&taxYear=${taxYear}`,
      }),
      tags.FETCH_EXPENSE_TYPES_FOR_SURVEYS
    );
    // cut out extraneous data
    expensesTypes = expensesTypes.map(({ name, id, title }) => ({ name, id, title }));
    dispatch(fetchExpenseTypesForWizardSuccess(expensesTypes));
    return expensesTypes;
  } catch (err) {
    return dispatch(handleError(err));
  }
};

export const createUpdateExpenseQuestionAnswer = ({ answer, answerDescription, surveyId, questionId, expenseTypeId }) => async (dispatch, getState) => {
  // we want to allow for partials on dispatches here, so we get the missing values from the store
  const state = getState();
  if (!Object.keys(state.surveyWizard.expenses.expenseQuestions).length) {
    return;
  }
  const currentQuestion = state.surveyWizard.expenses.expenseQuestions[expenseTypeId].find(question => question.id === questionId);
  if (!currentQuestion.answer && answer === undefined && !answerDescription) {
    return;
  }
  answer = (answer === undefined ? (currentQuestion.answer || {}).value : answer);
  answerDescription = (answerDescription === undefined ? (currentQuestion.answer || {}).description : answerDescription);

  const parsedAnswer = parseInt(answer, 10);
  try {
    await apiClient({
      method: 'post',
      url: `expense-type/expense-type-questions/answer`,
      data: {
        answer: parsedAnswer ? 1 : 0,
        answerDescription,
        surveyId,
        questionId
      },
    });
  } catch (err) {
    return dispatch(handleError(err));
  }
};
