import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEE_FILTER_SUCCESS,
  GET_EMPLOYEES_WITH_EXPENSES_FOR_TAX_YEAR_SUCCESS,
  SELECT_EMPLOYEE,
  SET_SELECTED_EMPLOYEE_FIELD,
} from '../constants/actionTypes/employee';
import { EMPLOYEE_INITIAL_STATE } from '../components/ServiceManager/Employees/constants';

const employee = (state = EMPLOYEE_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        list: action.employee,
      };
    case GET_EMPLOYEES_WITH_EXPENSES_FOR_TAX_YEAR_SUCCESS:
      return {
        ...state,
        listByTaxYear: action.employee,
      };
    case GET_EMPLOYEE_FILTER_SUCCESS:
      return {
        ...state,
        filterData: action.filterData,
      };
    case SELECT_EMPLOYEE:
      return {
        ...state,
        selected: action.selectedEmployee,
      };
    case SET_SELECTED_EMPLOYEE_FIELD:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
};

export default employee;
