const GET_EXPENSES_TYPES_SUCCESS = 'GET_EXPENSES_TYPES_SUCCESS';
const GET_EXPENSES_SUCCESS = 'GET_EXPENSES_SUCCESS';
const GET_EXPENSES_TAX_YEARS_SUCCESS = 'GET_EXPENSES_TAX_YEARS_SUCCESS';
const GET_EMPLOYEES_FOR_EXPENSE_SUCCESS = 'GET_EMPLOYEES_FOR_EXPENSE_SUCCESS';
const GET_ALLOCATED_PROJECTS = 'GET_ALLOCATED_PROJECTS';
const EDIT_ALLOCATED_PROJECT = 'EDIT_ALLOCATED_PROJECT';
const ADD_PROJECT_ALLOCATION = 'ADD_PROJECT_ALLOCATION';
const DELETE_PROJECT_ALLOCATION = 'DELETE_PROJECT_ALLOCATION';
const SET_EXPENSE_VALIDATION_ERRORS = 'SET_EXPENSE_VALIDATION_ERRORS';
const CLEAR_EXPENSE_VALIDATION_ERRORS = 'CLEAR_EXPENSE_VALIDATION_ERRORS';
const GET_HOURS_BY_SOURCE = 'GET_HOURS_BY_SOURCE';
const SET_TOTAL_EXPENSES = 'SET_TOTAL_EXPENSES';
const SET_DIRECTIVE_CLASSIFICATIONS = 'SET_DIRECTIVE_CLASSIFICATIONS';

export {
  GET_EXPENSES_TYPES_SUCCESS,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_TAX_YEARS_SUCCESS,
  GET_EMPLOYEES_FOR_EXPENSE_SUCCESS,
  GET_ALLOCATED_PROJECTS,
  EDIT_ALLOCATED_PROJECT,
  ADD_PROJECT_ALLOCATION,
  DELETE_PROJECT_ALLOCATION,
  SET_EXPENSE_VALIDATION_ERRORS,
  CLEAR_EXPENSE_VALIDATION_ERRORS,
  GET_HOURS_BY_SOURCE,
  SET_TOTAL_EXPENSES,
  SET_DIRECTIVE_CLASSIFICATIONS,
}
