export const SUB_SECTION_ROUTES = {
  'Expenses': 'expenses',
  'Projects': 'projects',
  'Documents': 'documents',
  'Submit': 'submit'
}

export const SUB_SECTION_IDS = {
  'Expenses': 2,
  'Projects': 1,
  'Documents': 3,
  'Submit': 4
}

export const SURVEY_INITIAL_STATE = {
  survey: {
    documentsDescription: ''
  },
  surveyTabs: [],
  surveySections: {
    [SUB_SECTION_IDS.Projects]: {},
    [SUB_SECTION_IDS.Expenses]: {},
    [SUB_SECTION_IDS.Documents]: {},
    [SUB_SECTION_IDS.Submit]: {}
  }
};

export const EXPENSES_INITIAL_STATE = {
  expenseTypes: [],
  expenseQuestions: {},
  selectedExpenseType: null,
  selectedQuestion: 0
};

export const PROJECTS_INITIAL_STATE = {
  projects: {},
  qualifications: {},
  selectedProjectId: null,
  selectedQuestionIndex: 0,
  isSaveInProgress: false
}

export const EXPENSE_TYPES_WHICH_REQUIRE_EMPLOYEE_RESOURCES = [
  1,
  5,
  6,
  14,
  21
];

export const TITLE_EXPANSION_PANEL = {
  ShowActivities: 'Show Activities',
  HideActivities: 'Hide Activities'
};
