import { FETCH_TIMESHEETS_SUCCESS } from '../constants/actionTypes/timesheets';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';

export const fetchTimesheets = payload => async dispatch => {
  try {
    let rowConfig = [];
    let headConfig = [];
    const data = await apiClient({
      method: 'post',
      url: '/user/timesheets',
      data: payload
    });
    Object.keys(data[0]).forEach(key => {
      if (key === 'projects') {
        rowConfig = [
          ...rowConfig,
          ...data[0][key].map(proj => proj.projectName),
        ];
        headConfig = [
          ...headConfig,
          ...data[0][key].map(proj => ({
            name: proj.projectName,
            sortable: true,
            id: proj.projectName,
          })),
        ];
      }
      if (key === 'name') rowConfig = [...rowConfig, key];
    });

    const timesheets = data.map(resp => {
      let additionalProps = {};
      resp.projects.forEach(proj => {
        additionalProps = { ...additionalProps, [proj.projectName]: proj.value || '-' };
      });
      return { ...resp, ...additionalProps };
    });
    dispatch(fetchTimesheetsSuccess({ timesheets, tableConfig: { rowConfig, headConfig } }));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const fetchTimesheetsSuccess = (data) => ({
  type: FETCH_TIMESHEETS_SUCCESS,
  data,
});
