import Typography from "@material-ui/core/Typography";
import React, {useCallback, useState, useEffect} from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles} from "@material-ui/core";
import useDebounce from "../../../../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  animationModalContainer: {
    width: '550px',
    height: '550px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(41,3,58,1) 100%)',
    borderRadius: '5px',
  },
  closeButtonContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#FFFFFF',
    zIndex: 10
  },
  animationContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  calculationAnimation: {
    width: '80%',
    height: '80%',
    position: 'relative',
  },
  fionaTitle: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: '#FFFFFF',
    paddingLeft: '20px',
    '&::before': {
      content: "''",
      width: '10px',
      height: '10px',
      position: 'absolute',
      borderRadius: '50%',
      boxSizing: 'border-box',
      display: 'block',
      backgroundColor: 'green',
      left: '0',
      top: '10px',
    }
  },
  spin: {
    width: '50%',
    height: '50%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    margin: '-25% 0 0 -25%',
  },
  '@keyframes spinnerBefore': {
    'from': {
      transform: 'scale(0)',
      opacity: '0',
    },
    'to': {
      transform: 'scale(1, 1)',
      opacity: '0.8',
    },
  },
  '@keyframes spinnerAfter': {
    'from': {
      transform: 'scale(1)',
      opacity: '0.8',
    },
    'to': {
      transform: 'scale(2, 2)',
      opacity: '0',
    }
  },
  spinOne: {
    '&::before': {
      content: "''",
      width: '98%',
      height: '98%',
      border: '6px solid #C320E2',
      top: '1%',
      left: '1%',
      position: 'absolute',
      borderRadius: '50%',
      boxSizing: 'border-box',
      display: 'block',
      transform: 'scale(0)',
      opacity: '0',
      animation: '$spinnerBefore 1.5s linear infinite',
    },
    '&::after': {
      content: "''",
      width: '100%',
      height: '100%',
      border: '12px solid #C320E2',
      top: '0',
      left: '0',
      position: 'absolute',
      borderRadius: '50%',
      boxSizing: 'border-box',
      display: 'block',
      transform: 'scale(0)',
      opacity: '0',
      animation: '$spinnerBefore 1.5s linear infinite',
    }
  },
  spinTwo: {
    '&::before': {
      content: "''",
      width: '98%',
      height: '98%',
      display: 'block',
      border: '6px solid #E43FF2',
      position: 'absolute',
      top: '1%',
      left: '1%',
      borderRadius: '50%',
      boxSizing: 'border-box',
      animation: '$spinnerAfter 1.5s linear infinite',
      animationDeplay: '1.5s',
      transform: 'scale(2)',
      opacity: '0.8',
    },
    '&::after': {
      content: "''",
      width: '100%',
      height: '100%',
      display: 'block',
      border: '12px solid #E43FF2',
      position: 'absolute',
      top: '0',
      left: '0',
      borderRadius: '50%',
      boxSizing: 'border-box',
      animation: '$spinnerAfter 1.5s linear infinite',
      animationDeplay: '1.5s',
      transform: 'scale(1)',
      opacity: '0.8',
    }
  },
}));

const LoadingModal = ({ handleClose, handleComplete, minimumAnimationTime = 5000 }) => {
  const styles = useStyles();
  const [showLoading, setShowLoading] = useState(false);
  const [debounceLoading, clean] = useDebounce(() => {
    handleComplete();
  }, minimumAnimationTime);

  useEffect(() => {
    if(!showLoading) {
      setShowLoading(true);
      debounceLoading();
    }
  }, [showLoading,debounceLoading])

  const onModalClose = useCallback(() => {
    setShowLoading(false);
    clean();
    handleClose();
  }, [handleClose, clean]);

  return (
    <div className={styles.animationModalContainer}>
      <div className={styles.closeButtonContainer}>
        <IconButton edge="start" color="inherit" onClick={onModalClose} aria-label="close" >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.fionaTitle}>
        <Typography variant="subtitle1">Calculating...</Typography>
      </div>
      <div className={styles.animationContainer}>
        <div className={styles.calculationAnimation}>
          <div className={styles.spin + ' ' + styles.spinOne}></div>
          <div className={styles.spin + ' ' + styles.spinTwo}></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;