import React from 'react';
import GlobalDropdown from '../../commonComponents/GlobalDropdown';
import { useSelector } from 'react-redux';

const EntitiesDropdown = ({ onChange }) => {
  const entities = useSelector(state => state.common.entities);
  const selectedEntity = useSelector(state => state.common.selectedEntity);

  return (
    <GlobalDropdown
      options={entities}
      onChange={onChange}
      value={selectedEntity}
      optionAccessors={{ name: 'name', value: 'entity_id' }}
      label="Entities"
    />
  );
};

export default EntitiesDropdown;
