import React from 'react';
import SurveyResponseWidget from '../wizardComponents/SurveyResponseWidget';

import { currentWizardSurvey, currentSurveySections } from '../../../../selectors/surveyWizard/surveyWizard';
import { toggleAndSaveSurveySectionCompletion } from '../../../../actions/surveyWizard/surveyWizard';
import { SUB_SECTION_IDS } from '../constants';
import { useSelector, useDispatch } from 'react-redux';
import DocumentsChecklist from './DocumentsChecklist';
import SurveyDocumentsSvg from '../../../../svgs/SurveyDocumentsSvg';

const DocumentsSection = () => {
  const dispatch = useDispatch();
  const survey = useSelector(currentWizardSurvey);
  const surveySections = useSelector(currentSurveySections);
  const surveySectionRefId = SUB_SECTION_IDS['Documents']
  const sectionCompleted = !!surveySections[surveySectionRefId].isCompleted;

  const sideNavProps = {
    tabs: [{ title: 'Document Checklist', selected: true, icon: SurveyDocumentsSvg }],
    title: 'Documents',
    completed: sectionCompleted,
    toggleCallback: () => dispatch(toggleAndSaveSurveySectionCompletion(survey.surveyId, surveySectionRefId, !sectionCompleted))
  };
  
  const contentPaneProps = {
    onNext: null,
    onPrevious: null,
    totalSubSections: 1,
    currentSubsection: 0,
    children: <DocumentsChecklist survey={survey} />,
    title: 'Document Checklist',
    questionTitle: 'Document Checklist',
    questionHelp: 'Please select all document types that apply. Items ranging from calendar invites to meeting notes to design drawings and photographs can be used to support the research activities performed. The regulations do not specify types of documents as the tax authority understands that each business is unique and may retain/create documentation that aligns with their business processes.',
    Icon: SurveyDocumentsSvg
  };
  
  return (
    <SurveyResponseWidget sideNavProps={sideNavProps} contentPaneProps={contentPaneProps} />
  );
};

export default DocumentsSection;
