import React, {useCallback, useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import { Dialog, DialogContent, DialogActions, IconButton, CircularProgress } from '@material-ui/core';
import { Close, CheckCircle, AddCircleRounded, RadioButtonUnchecked } from '@material-ui/icons';

import Button from "../../Button";
import SearchBar from "./SearchBar";

import { useDataModal, useLoadFetchData } from './AssignEmployeesProjects.registry';
import { UseStyles } from './AssignEmployeesProjects.styles';
import { hideModal } from "../../../actions/modal";
import { clearEmployeeFilter } from "../../../actions/employee";

import Filters from "./Filters";

const AssignEmployeesProjects = (props) => {
  const { kind, onSelect, keys, info } = props;
  const styles = UseStyles();
  const dispatch = useDispatch();
  const isLoading = useLoadFetchData();

  const [selected, setSelected] = useState(0);
  const [modalInfo, setModalInfo] = useState();

  const modalData = useDataModal({ kind });

  const orderInfo = (data) => {
    return data.sort((a,b) => a.name.localeCompare(b.name));
  };

  useEffect(() => {
    if (!modalInfo) {
      setModalInfo(orderInfo(info));
    }
  }, [info, modalInfo]);

  useEffect(() => {
    if (modalInfo) {
      const selectedInfo = modalInfo.filter((info) => info.selected);
      setSelected(selectedInfo.length);
    }
  }, [modalInfo]);

  const onHandleSelectItem = useCallback((value) => {
    const selectedInfo = modalInfo.map((info) => {
      if (info[keys.value] === value) {
        info.selected = !info.selected;
      }
      return info;
    });

    setModalInfo(selectedInfo);
  }, [modalInfo, keys]);

  const onHandleSearchAction = useCallback((value) => {
    const selectedInfo = modalInfo.map((info) => {
      info.visible = info[keys.name].toUpperCase().includes(value.toUpperCase());
      return info;
    });

    setModalInfo(selectedInfo);
  }, [modalInfo, keys]);

  const onHandleClose = useCallback(() => {
    dispatch(clearEmployeeFilter());
    dispatch(hideModal());
  }, [dispatch]);

  const onHandleSave = useCallback(() => {
    const selectedInfo = modalInfo.filter((info) => info.selected);
    onSelect && onSelect(selectedInfo);
    dispatch(hideModal());
  }, [dispatch, onSelect, modalInfo]);

  const onApplyFilter = (info, key, value) => {
    info.forEach((data) => {
      let applyFilter = false;
      if (data[key] && data.visible) {
        applyFilter = data[key].toString().toUpperCase().includes(value.toString().toUpperCase());
      }
      data.visible = applyFilter;
    });
  };

  const onHandleChangeFilters = useCallback((values) => {
    const filteredInfo = info.map((data) => {
      data.visible = true;
      return data;
    });

    const filterOptions = Object.keys(values);
    filterOptions.forEach((option) => {
      onApplyFilter(filteredInfo, option, values[option]);
    });

    setModalInfo(filteredInfo);
  }, [info]);

  const onHandleSetOrder = useCallback(() => {
    setModalInfo([...modalInfo].reverse());
  }, [modalInfo]);

  const onHandleSelectAll = useCallback((value) => {
    const selectedInfo = modalInfo.map((info) => {
      if (info.visible) {
        info.selected = value;
      }
      return info;
    });

    setModalInfo(selectedInfo);
  }, [modalInfo]);

  if (isLoading) {
    return (
      <Dialog fullWidth={true} maxWidth='sm' open={true}>
        <DialogContent className={styles.ContentLoading}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog fullWidth={true} maxWidth='sm' open={true}>
      <div className={styles.HeaderModal}>
        <div className={styles.HeaderModalTitle}>
          <AddCircleRounded />
          <h3>{modalData.title}</h3>
        </div>
        <IconButton size='small' color='inherit' aria-label='close' onClick={onHandleClose}>
          <Close />
        </IconButton>
      </div>
      <Filters values={modalData.filters} onChange={onHandleChangeFilters} />
      <DialogContent className={styles.ContentModal}>
        <SearchBar
          placeholder={modalData.searchPlaceholder}
          onSearch={onHandleSearchAction}
          onChange={onHandleSearchAction}
          onSelect={onHandleSelectAll}
          onOrder={onHandleSetOrder}
        />
        <div className={styles.ContentListWrapper}>
          {modalInfo.map((info) => {
            if(info.visible){
              const itemClassNames = classNames({
                [styles.ContentListItem]: true,
                [styles.ContentListItemSelected]: info.selected
              });

              return (
                <div key={`item-${info[keys.value]}`} className={itemClassNames} onClick={() => onHandleSelectItem(info[keys.value])}>
                  <h4>{info[keys.name]}</h4>
                  {info.selected ? <CheckCircle /> : <RadioButtonUnchecked />}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      </DialogContent>
      <DialogActions className={styles.FooterModal}>
        <div>{selected} Selected</div>
        <div>
          <Button className={styles.FooterModalButton} data-testid={'assign-employees-modal-cancel-button-id'} onClick={onHandleClose} color='secondary'>Cancel</Button>
          <Button className={styles.FooterModalButton} data-testid={'assign-employees-modal-button-id'} onClick={onHandleSave}>Save Changes</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

AssignEmployeesProjects.propTypes = {
  kind: PropTypes.oneOf(['employees', 'projects']),
  onSelect: PropTypes.func
}

export default AssignEmployeesProjects;