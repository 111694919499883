import { apiClient } from '../../../api/apiClient';

export const fetchCredits = (containerId) => {
  return apiClient({
      method: 'get',
      url: `/credit?containerId=${containerId}`,
    });
};

export const createCredit = (payload) => {
  return apiClient({
    method: 'post',
    url: '/credit',
    data: payload,
  });
};

export const updateCredit = (creditId, body) => {
  return apiClient({
    method: 'patch',
    url: `/credit/${creditId}`,
    data: body,
  });
};

export const deleteCredit = (creditId,) => {
  return apiClient({
    method: 'delete',
    url: `/credit/${creditId}`,
  });
};

export const fetchCreditById = (creditId) => {
  return apiClient({
    method: 'get',
    url: `/credit/by-id?creditId=${creditId}`,
  });
};

export const fetchCreditsForDropdown = async (entityId) => {
  return apiClient({
    method: 'get',
    url: `/credit/entity?entityId=${entityId}`,
  });
};

export const fetchCreditMethods = (jurisdictionId, taxYear) => {
  return apiClient({
    method: 'get',
    url: `/credit-method?jurisdictionId=${jurisdictionId}&taxYear=${taxYear}`,
  });
};

export const fetchCreditUserOptions = (methodRefId, taxYear) => {
  return apiClient({
    method: 'get',
    url: `/credit-user-options?creditMethodRefId=${methodRefId}&taxYear=${taxYear}`
  });
};
