import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapperToggle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
    '& button:first-child': {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8
    },
    '& button:last-child': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8
    },
    '& button': {
      width: 50,
      background: theme.palette.grey[100],
      color: theme.palette.grey[800],
      border: 0,
      padding: theme.spacing(1),
      textAlign: 'center',
      fontSize: '1rem',
      '&.active': {
        fontWeight: 'Bold',
        background: '#F1EAF8',
        color: theme.palette.primary.main,
      }
    }
  }
}));

const Toggle = ({ onSelect, options, defaultValue = '', ...rest }) => {
  const classes = useStyles();
  const [optionSelected, setOptionSelected] = useState(defaultValue);
  const onToggleSelected = useCallback((value) => {
    setOptionSelected(value);
    if (onSelect) onSelect(value);
  }, [onSelect]);

  return (
    <div className={classes.wrapperToggle}>
      {options.map((option) => {
        const isSelected = optionSelected === option.value;
        return (
          <button
            key={"toggle-option-" + option.value}
            className={isSelected ? 'active' : ''}
            onClick={() => onToggleSelected(option.value)}>{option.label}
          </button>
        );
      })}
    </div>
  );
}

Toggle.propTypes = {
  onSelect: PropTypes.func,
  options: PropTypes.array,
  defaultValue: PropTypes.string
}

export default Toggle;
