import React from 'react';
import Button from '@material-ui/core/Button';
import useStyles from './ErrorMessage.styles';
import { useHistory } from "react-router-dom";

const ErrorMessage = (props) => {
  const styles = useStyles();
  const history = useHistory();

  const resetErrorBoundary = () => {
    props.clearErrorBoundary();
    history.goBack();
  };

  const redirectHome = () => {
    props.clearErrorBoundary();
    history.push("/");
  };

  return (
    <div className={styles.area}>
      <div className={styles.content}>
        <div className={`${styles.errorCenter} ${styles.container}`}>
          <img className={styles.image} src={`/imgs/error_500.svg`} alt={'error_500'} />
          <div className={styles.errorText}>INTERNAL ERROR</div>
        </div>
        <h3 className={styles.title}>SOMETHING WENT WRONG HERE</h3>
        <p className={styles.text}>The link you followed is probably broken or the page has been removed</p>
        <div className={styles.errorCenter}>
          <Button className={styles.button} onClick={resetErrorBoundary} variant="contained" color="primary">
            BACK
          </Button>
          <Button className={styles.button} onClick={redirectHome} variant="contained" color="primary">
            BACK TO HOME
          </Button>
        </div>
      </div>
    </div>
  );

};

export default ErrorMessage;
