import { ERRORS, CRUD_TYPES } from '../constants';

const buildReportInputFields = (report, inputData = {}) => [
  {
    name: 'reportId',
    label: 'Report',
    type: 'Selector',
    options: report.reports || [],
    value: report.reportId || "",
    optionKeys: {
      name: 'name',
      value: 'id',
    },
    helperText: report.crudType === CRUD_TYPES.create && report.error.report ? ERRORS.reportId : '',
    shouldDisplay: report.crudType === CRUD_TYPES.create,
  },
  {
    name: 'reportName',
    label: 'Report Name',
    type: 'Input',
    value: report.reportName || "",
    shouldDisplay: (report.crudType === CRUD_TYPES.create && !report.reportId) || report.crudType === CRUD_TYPES.edit,
    muiProps: {
      disabled: report.crudType === CRUD_TYPES.edit
    },
    helperText: report.crudType === CRUD_TYPES.create && report.error.report ? ERRORS.reportName : ''
  },
  {
    name: 'templateId',
    label: 'Report Type',
    type: 'Selector',
    // TODO: For next line, delete filter for "Summary" report type once it's needed
    options: (report.reportTypes || []).filter((reportType) => reportType.displayName !== 'Summary'),
    value: report.templateId || "",
    optionKeys: {
      name: 'displayName',
      value: 'id',
    },
    helperText: report.crudType === CRUD_TYPES.create && report.error.templateId ? ERRORS.template: '',
    shouldDisplay: report.crudType === CRUD_TYPES.create
  },
  {
    name: 'reportType',
    label: 'Report Type',
    type: 'Input',
    value: report.templateDisplayName,
    shouldDisplay: report.crudType === CRUD_TYPES.edit,
    muiProps: {
      disabled: true
    },
  },
  {
    name: 'reportProjects',
    label: 'Projects',
    type: 'AutocompleteMultipleSelect',
    shouldDisplay: true,
    value: report.reportProjects || [],
    options: inputData.projects || [],
    optionKeys: {
      name: 'name',
      value: 'id',
    },
    muiProps: {
      disabled: report.crudType === CRUD_TYPES.edit
    },
    onChange: inputData.handleProjectsChange,
    maxChips: 1
  }
];

export default buildReportInputFields
