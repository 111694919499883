import React, { useState, Fragment } from 'react';
import Input from './fieldTypes/Input';
import File from './fieldTypes/File';
import Date from './fieldTypes/Date';
import Year from './fieldTypes/Year';
import Selector from './fieldTypes/Selector';
import Bool from './fieldTypes/Bool';
import Modal from './fieldTypes/Modal';
import ReadOnly from './fieldTypes/ReadOnly';
import MultipleSelect from './fieldTypes/MultipleSelect';
import AutocompleteSelect from './fieldTypes/AutocompleteSelect';
import AutocompleteMultipleSelect from './fieldTypes/AutocompleteMultipleSelect';
import AutocompleteSelectVirtualized from './fieldTypes/AutocompleteSelectVirtualized';
import AutocompleteMultipleSelectVirtualized from './fieldTypes/AutocompleteMultipleSelectVirtualized';
import FormControl from '@material-ui/core/FormControl';
import { useStyles } from './styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import Button from '../Button';
import validateForm from './validateForm';
import classNames from 'classnames';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useDispatch } from "react-redux";
import { showModal } from "../../actions/modal";

const FormBuilder = ({ fields, onChange, formActions, children }) => {
  const formFieldTypes = { File, Input, Date, Selector, Bool, MultipleSelect, AutocompleteMultipleSelect, ReadOnly, AutocompleteSelect, Year, AutocompleteSelectVirtualized, AutocompleteMultipleSelectVirtualized, Modal };
  const [shouldDisplayValidationMessages, setShouldDisplayValidationMessages] = useState(false);
  const styles = useStyles();
  const dispatch = useDispatch();
  const displayableFields = fields.filter((field) => field.shouldDisplay !== false);
  const { inputFields: validatedFields } = validateForm(displayableFields, shouldDisplayValidationMessages);

  const handleConfirm = (actionToExecuteOnConfirm, actionName) => {
    dispatch(showModal('confirmation', {
      title: 'We need your approval',
      textBody: `Are you sure you want to ${actionName.toLowerCase()}?`,
      buttonText: actionName,
      onConfirm: actionToExecuteOnConfirm
    }));
  };

  const renderInputFields = () => {
    return validatedFields.map((field) => {
      const { name, label, type, options, optionKeys, value, helperText, muiProps, selectedOptions, required, maxChips, isInvalid, allowEmptyOption, onBlur, singleSelection, modalOptions } = field;

      if (options && options.length && optionKeys && optionKeys.name) {
        const { name: sortValue } = optionKeys;
        options.sort((a, b) => a[sortValue] < b[sortValue] ? -1 : 1);
      }

      const handlerToExecute = field.onChange || onChange;
      const handleChange = (...params) => {
        setShouldDisplayValidationMessages(false);
        handlerToExecute(...params);
      };

      const FormField = formFieldTypes[type];
      return (
        <FormControl key={name || label} className={styles.input}>
          <FormField
            name={name}
            label={required ? `${label}*` : label}
            options={options}
            optionKeys={optionKeys}
            value={typeof value === 'undefined' || value === null ? '' : value}
            muiProps={muiProps}
            onChange={handleChange}
            selectedOptions={selectedOptions}
            maxChips={maxChips}
            isInvalid={isInvalid}
            allowEmptyOption={allowEmptyOption}
            onBlur={onBlur}
            singleSelection={singleSelection}
            modalOptions={modalOptions}
          />
          {helperText && <FormHelperText error >{helperText}</FormHelperText>}
        </FormControl>
      );
    })
  };

  const renderFormActions = () => {
    const displayableFormActions = formActions.filter((formAction) => formAction.shouldDisplay !== false);
    return displayableFormActions.map((formAction) => {
      const { color, onClick, name, shouldCheckInputsValidity, disabled, size, helperText, isLoading, shouldAskConfirmation } = formAction;

      const handleFormActionClick = () => {
        const isValid = validateForm(displayableFields, true).valid;
        if (shouldCheckInputsValidity && !isValid) {
          setShouldDisplayValidationMessages(true);
          return
        }

        if (shouldAskConfirmation) {
          handleConfirm(onClick, name);
          return
        }

        onClick();
      };

      const buttonClassNames = classNames({
        [styles.fullSizeButton]: size === 'full',
        [styles.button]: true
      });
      return (
        <Fragment key={name}>
          <Button data-testid={`${name}-test-id`} className={buttonClassNames} onClick={handleFormActionClick} disabled={disabled} color={color}>
            {name} {isLoading && <CircularProgress size={25} className={styles.loadingDownload} />}
          </Button>
          {helperText && (
            <Typography align={'center'} variant={'caption'}>
              {helperText}
            </Typography>
          )}
        </Fragment>
      )
    });
  };

  return (
    <>
      <form className={styles.form}>
        {renderInputFields()}
        {children}
        <FormControl className={styles.buttonsContainer}>
          {renderFormActions()}
        </FormControl>
      </form>
    </>
  );
};

FormBuilder.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    type: PropTypes.oneOf([
      'File', 'Bool', 'Selector', 'Input', 'Date', 'MultipleSelect', 'ReadOnly', 'AutocompleteSelect',
      'AutocompleteMultipleSelect', 'Year', 'AutocompleteSelectVirtualized', 'AutocompleteMultipleSelectVirtualized',
      'Modal'
    ]).isRequired,
    value: PropTypes.any,
    shouldDisplay: PropTypes.bool,
    helperText: PropTypes.string,
    muiProps: PropTypes.object,
    required: PropTypes.bool,
    modalOptions: PropTypes.shape({
      name: PropTypes.string,
      kind: PropTypes.string
    }),
    onChange: PropTypes.func, // If specified this event handler will take precedence over form level onChange
    validationOptions: PropTypes.shape({
      customValidate: PropTypes.func, // This custom validation function has to return a string or undefined (if valid). The string is the validation error message
      regex: PropTypes.instanceOf(RegExp),
      regexErrorMessage: PropTypes.string
    }),
    // * Selector props *
    optionKeys: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.object),
    allowEmptyOption: PropTypes.bool,
    // * Multiple selector props *
    selectedOptions: PropTypes.arrayOf(PropTypes.object),
    maxChips: PropTypes.number,
    isInvalid: PropTypes.bool,
    isLoading: PropTypes.bool
  }).isRequired),
  formActions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.oneOf(['grape', 'rhubarb', 'secondary']),
    size: PropTypes.oneOf(['full', 'small']),
    shouldDisplay: PropTypes.bool,
    disable: PropTypes.bool,
    shouldCheckInputsValidity: PropTypes.bool,
    helperText: PropTypes.string,
    shouldAskConfirmation: PropTypes.bool,
  })),
  onChange: PropTypes.func.isRequired // This handler will be executed in all fields
};

export default FormBuilder;
