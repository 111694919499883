const validMimeTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
const preValidateExpenseXLSX = async (file) => {
  if (!file || !validMimeTypes.includes(file.type)) {
    return { isXLSX: false, message: 'Please upload an excel (.xlsx) worksheet only.' }
  } else {
    return { isXLSX: true }
  };
}

export default preValidateExpenseXLSX;
