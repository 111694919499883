const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAIL = 'GET_USERS_FAIL';
const EDIT_USER_FAIL = 'EDIT_USERS_FAIL';
const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
const SET_SELECTED_USER = 'SET_SELECTED_USER';
const SET_SELECTED_USER_FIELD = 'SET_SELECTED_USER_FIELD';
const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
const CLEAN_USER = 'CLEAN_USER';
const TOGGLE_USER_SURVEY_WELCOME_SUCCESS = 'TOGGLE_USER_SURVEY_WELCOME_SUCCESS';
const TOGGLE_USER_SURVEY_WELCOME_FAIL = 'TOGGLE_USER_SURVEY_WELCOME_FAIL';
const GET_LIST_SURVEY_LEADERS = 'GET_LIST_SURVEY_LEADERS';

export {
    GET_USERS_SUCCESS,
    GET_USERS_FAIL,
    EDIT_USER_FAIL,
    CREATE_USER_FAIL,
    SET_SELECTED_USER,
    SET_SELECTED_USER_FIELD,
    DELETE_USER_FAIL,
    CLEAN_USER,
    TOGGLE_USER_SURVEY_WELCOME_SUCCESS,
    TOGGLE_USER_SURVEY_WELCOME_FAIL,
    GET_LIST_SURVEY_LEADERS
}