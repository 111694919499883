import React, { useLayoutEffect, useRef } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Add, Search } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import useStyles from './styles';

const AddEmployeeDropdown = ({ employees, timesheets, onEmployeeOptionClick, onClose }) => {
  const styles = useStyles();
  const inputRef = useRef(null);
  
  useLayoutEffect(() => {
    inputRef.current.focus();
  }, []);
  
  const filterEmployeesAlreadyInTimesheet = () => {
    let filteredEmployees = [];

    if (employees) {
      filteredEmployees = employees.filter((employee) => {
        const employeeIdsAlreadyInTimesheet = timesheets.map((timesheet) => timesheet.employeeId);
        const isAlreadyInTimesheet = employeeIdsAlreadyInTimesheet.includes(employee.id);
        return !isAlreadyInTimesheet;
      });
    }

    return filteredEmployees;
  };
  
  const employeesNotInTimesheet = filterEmployeesAlreadyInTimesheet();
  return (
    <Autocomplete
      className={styles.dropdown}
      disableClearable
      options={employeesNotInTimesheet}
      getOptionLabel={(option) => option.name ||''}
      onChange={onEmployeeOptionClick}
      openOnFocus
      forcePopupIcon={false}
      noOptionsText="No more employees found"
      onClose={onClose}
      closeIcon={<Search/>}
      renderOption={(option) => (
        <div className={styles.dropdownOption}>
          <Add />
          {option.name}
        </div>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Employee List"
          margin="normal"
          variant="outlined"
          inputRef={inputRef}
          InputProps={{
            ...params.InputProps, startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )}}
        />
      )}
    />
  );
};


export default AddEmployeeDropdown;
