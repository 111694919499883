import React from 'react';
import { InputLabel, MenuItem, Select } from '@material-ui/core';
import sortDropdownOptions from '../../../utils/sortDropdownOptions';

const Selector = ({ sortOptions, name, label, value, options, optionKeys, onChange, isInvalid, muiProps, allowEmptyOption }) => {
  const sortedOptions = sortDropdownOptions(options, sortOptions);
  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <Select
        id={name}
        value={value}
        name={name}
        onChange={onChange}
        error={isInvalid}
        {...muiProps}
      >
        {allowEmptyOption && (
          <MenuItem value={''}>-</MenuItem>
        )}
        {sortedOptions.map((option) => (
          <MenuItem
            key={option[optionKeys.value]}
            value={option[optionKeys.value]}
          >
            {option[optionKeys.name]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default Selector;
