import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useStyles } from './styles';
import ExpansionPanelHint from '../../../../commonComponents/ExpansionPanelHint';
import useDebounce from '../../../../hooks/useDebounce';
import { updateOrCreateAnswers } from '../../../../actions/creditDetail';
import { useDispatch } from 'react-redux';

const QuestionRow = ({ question, hint, answer, onAnswerChange, questionId, creditId, isSectionCompleted }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [saveAnswer] = useDebounce(async (detailQuestionId, answerDetailQuestion ) => {
    const dataAnswerDetail = {
      creditId: creditId,
      creditDetailQuestionId: detailQuestionId,
      answer: answerDetailQuestion
    };

    dispatch(updateOrCreateAnswers(dataAnswerDetail))
  }, 3000);

  return (
    <div className={styles.questionWrapper}>
      <Grid alignItems="flex-start" container direction="row" spacing={2}>
        <Grid item sm={3}>
          <span>{question}</span>
        </Grid>

        <Grid item sm={3} className={styles.inputWrapper}>
            <span className={styles.currency}>(USD)$</span>
            <TextField
              value={answer.value}
              type="number"
              size="small"
              label="Add amount"
              variant="outlined"
              disabled={Boolean(isSectionCompleted)}
              onChange={(event) => {
                const { value } = event.target;
                onAnswerChange(value, questionId);
                saveAnswer(questionId, value);
              }}
            />
        </Grid>

        <Grid item sm={6}>
          <ExpansionPanelHint>
            {hint}
          </ExpansionPanelHint>
        </Grid>
      </Grid>
    </div>
  );
};

export default QuestionRow;