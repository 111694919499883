import { roles } from "./roles";
import {creditCentralRouter, serviceManagerRoutes} from '../constants/routes';
import { APP_SECTIONS } from "./appSections";

export const NECESSARY_ROLES_PER_COMPONENT = {
  rollForwardButton: [roles.FULL_ACCESS_ADMIN, roles.LIMITED_ACCESS_ADMIN, roles.PS_MANAGER]
};

export const  EXCLUDED_PATHS_FOR_CAN_CONTAINER_ROLL = [
  APP_SECTIONS.service_manager.baseUrl,
  APP_SECTIONS.credit_central.baseUrl,
  APP_SECTIONS.survey_central.baseUrl,
  creditCentralRouter[0].path,
  serviceManagerRoutes[0].path,
  `${APP_SECTIONS.service_manager.baseUrl}/${serviceManagerRoutes[0].name}`,
  serviceManagerRoutes[2].path];



