import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.primary.main,
    textTransform: 'capitalize',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    '&.MuiFab-sizeSmall': {
      borderRadius: 4,
      padding: theme.spacing(1.5)
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    }
  },
  squareButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.primary.main}`,
    '&.MuiFab-sizeSmall': {
      borderRadius: 4,
      padding: theme.spacing(1.5)
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)'
    }
  },
  squareFab:{
    borderRadius: '5px',
    width: '97%',
    height: '38px',
    margin: theme.spacing(.5),
    padding: `0 ${theme.spacing(2)}px`,
    textTransform: 'capitalize'
  },
  loading: {
    marginRight: theme.spacing(1)
  }
}));
