import React, {useCallback, useEffect, useState} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Typography from '@material-ui/core/Typography';
import { apiClient } from '../../../../api/apiClient';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../../constants/promiseTracker';
import LoadingElement from '../../../../commonComponents/LoadingElement';
import classnames from 'classnames';
import ColumnSearch from './ColumnSearch';
import InputBase from '@material-ui/core/InputBase';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { fetchEmployeesWithExpensesForTaxYear } from '../../../../actions/employee';
import AddEmployeeButton from './AddEmployeeButton';
import useDebounce from '../../../../hooks/useDebounce';
import ExpansionPanelActivities from './ExpansionPanelActivities';
import { surveyWizardExpenseSelectedExpenseType } from '../../../../selectors/surveyWizard/expenses';
import Toggle from "../../../../commonComponents/Toggle";
import DebounceTextField from "../../../../commonComponents/DebounceTextField";

const timesheetsInitialState = [];

const HOURS_NUMBER = "HRS";
const HOURS_PERCENTAGE = "PRC";

const ToggleOptions = [
  { label: '%', value: HOURS_PERCENTAGE },
  { label: 'Hrs', value: HOURS_NUMBER }
]

const EmployeeResourcesTimesheet = ({ question }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [timesheets, setTimesheets] = useState(timesheetsInitialState);
  const [activities, setActivities] = useState([]);
  const [modifedTotalHours, setModifiedTotalHours] = useState({});
  const [employeeSearch, setEmployeeSearch] = useState('');
  const [employeeSort, setEmployeeSort] = useState('ASC');
  const [kind, setKind] = useState(HOURS_PERCENTAGE);
  const survey = useSelector((state) => state.surveyWizard.survey.survey);
  const [saveTotalHoursOn2sInactivity] = useDebounce((totalHoursData) => {
    saveTotalHours(totalHoursData);
  }, 2000);
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_SURVEY_RETROACTIVE_TIMESHEETS, delay: 200 });
  const { questionTitle, questionCaption } = question;

  const currentExpenseTabId = useSelector(surveyWizardExpenseSelectedExpenseType);

  useEffect(() => {
    fetchAndSetTimesheets();
    const { taxYear } = survey;
    dispatch(fetchEmployeesWithExpensesForTaxYear(survey.entityId, taxYear));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentExpenseTabId]);

  useEffect(() => {
    fetchActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTimesheets = async () => {
    const { taxYear, surveyId } = survey;

    if (!taxYear || !surveyId) {
      return;
    }

    const timesheetResults = await apiClient({
      method: 'get',
      url: `/survey-timesheet-retroactive/get-survey-timesheets?taxYear=${taxYear}&surveyId=${surveyId}&expenseTypeRefId=${currentExpenseTabId}`
    });

    return timesheetResults;
  };

  const fetchAndSetTimesheets = async () => {
    const timesheetsResult = await trackPromise(
      getTimesheets(),
      tags.FETCH_SURVEY_RETROACTIVE_TIMESHEETS
    );

    setTimesheets(timesheetsResult || []);
  };

  const fetchActivities = async () => {
    const { taxYear, jurisdictionId } = survey;

    if (!taxYear || !jurisdictionId) {
      return;
    }

    const activitiesResult = await apiClient({
        method: 'get',
        url: `/activity?taxYear=${taxYear}&jurisdictionId=${jurisdictionId}`
      });

    setActivities(activitiesResult);
  };

  const handleTimesheetChange = (value, projectTimesheet, timesheet) => {
    let valueCalculated = value;

    if (kind === HOURS_PERCENTAGE) {
      valueCalculated = timesheet.totalHours * (valueCalculated / 100);
    }

    const timesheetData = {
      ...projectTimesheet,
      timePeriodRefId: timesheet.timePeriodRefId,
      employeeId: timesheet.employeeId,
      timesheetValue: valueCalculated
    };

    saveTimesheet(timesheetData);
  };

  const handleTotalHoursChange = async (event, timesheet) => {
    const { value } = event.target;
    const { employeeId } = timesheet;

    timesheet.totalHours = value

    const totalHoursData = { employeeId, totalHours: value };

    saveTotalHoursOn2sInactivity(totalHoursData);
    setModifiedTotalHours(totalHoursData);
  };

  const handleTotalHoursBlur = async () => {
    saveTotalHours(modifedTotalHours);
  };

  const handleEmployeeSearch = (event) => {
    const { value } = event.target;
    setEmployeeSearch(value);
  }

  const handleSortChange = () => {
    const sortToggled = employeeSort === 'ASC' ? 'DESC' : 'ASC';
    setEmployeeSort(sortToggled);
  };

  const handleChangeToggle = useCallback((value) => {
    setKind(value)
  }, [])

  const saveTimesheet = async (timesheetData) => {
    const { timesheetValue } = timesheetData;
    const isTimesheetUnmodified = timesheetValue === null || typeof timesheetValue === 'undefined';
    if (isTimesheetUnmodified) {
      return null;
    }
    await createOrUpdateTimesheet(timesheetData);

    const updatedTimesheets = await getTimesheets();
    setTimesheets(updatedTimesheets);
  };

  const saveTotalHours = async (totalHoursData) => {
    const { employeeId, totalHours } = totalHoursData;
    if (totalHours !== null && typeof totalHours !== 'undefined') {
      await apiClient({
        method: 'patch',
        url: `/employee/employee-total-hours/${employeeId}`,
        data: {
          totalHours
        }
      });
    }

    setModifiedTotalHours({});
  };

  const createOrUpdateTimesheet = async (timesheetData) => {
    const { taxYear, timePeriodRefId } = survey;
    const { employeeId, projectId, timesheetId, timesheetValue } = timesheetData;

    await apiClient({
      url: `/survey-timesheet-retroactive/create-update`,
      method: 'post',
      data: {
        employeeId,
        projectId,
        timePeriodRefId,
        year: taxYear,
        timesheetId,
        value: Number(timesheetValue)
      }
    });
  };

  const getFilteredTimesheets = () => {
    return timesheets.filter((timesheet) => (
      (timesheet.employeeName || '').toLowerCase().includes(employeeSearch.toLowerCase())
    ));
  };

  const getSortedTimesheets = (filteredTimesheets) => {
    let timesheetsSorted = filteredTimesheets.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1);

    if (employeeSort === 'DESC') {
      timesheetsSorted.reverse();
    }

    return timesheetsSorted;
  };

  const renderProjectsInHeader = () => {
    // In order to get the timesheet projects it's enough just accessing the first timesheet
    // since all timesheets have the same projects
    const projects = timesheets[0].timesheetsPerProject;

    return projects.map((projectTimesheet) => {
      return (
        <TableCell className={styles.headerCells} align="center" key={projectTimesheet.projectId}>
          {projectTimesheet.projectName} <br />
        </TableCell>
      )
    });
  };

  const renderProjectsInRow = (timesheet) => {
    return timesheet.timesheetsPerProject.map((projectTimesheet) => {

      let hoursValue = projectTimesheet.timesheetValue;

      if (hoursValue) {
        if (kind === HOURS_PERCENTAGE) {
          hoursValue = ((projectTimesheet.timesheetValue / timesheet.totalHours) * 100);

          if((hoursValue % 1) > 0){
            hoursValue = Number(hoursValue).toFixed(2);

            if((hoursValue % 1) === 0){
              hoursValue = Number(hoursValue).toFixed(0);
            }
          }
        }
      } else {
        hoursValue = '';
      }

      return (
        <TableCell align="center" key={projectTimesheet.projectId}>
          <DebounceTextField
            label="Add"
            value={hoursValue.toString()}
            style={styles.timesheetInput}
            onChange={(value) => handleTimesheetChange(value, projectTimesheet, timesheet)}
            type="number"
            indicator="%"
            showIndicator={kind === HOURS_PERCENTAGE}
          />
        </TableCell>
      )
    })
  };

  const renderTableRows = () => {
    const filteredTimesheets = getFilteredTimesheets();
    const sortedTimehseets = getSortedTimesheets(filteredTimesheets);

    return sortedTimehseets.map((timesheet) => {
      return (
        <TableRow key={timesheet.employeeId}>
          <TableCell className={styles.firstColumn}>
            {timesheet.employeeName}
          </TableCell>
          {renderProjectsInRow(timesheet)}
          <TableCell className={styles.lastColumn}>
            <InputBase
              placeholder="Total Hours"
              defaultValue={timesheet.totalHours}
              className={`${styles.timesheetInput} ${styles.totalHoursTimesheetInput}`}
              onChange={(event) => handleTotalHoursChange(event, timesheet)}
              onBlur={handleTotalHoursBlur}
              type="number"
            />
          </TableCell>
        </TableRow>
      )
    });
  };

  const renderTable = () => {
    if (!timesheets.length) {
      return <div className={styles.notFound}>No employees added yet</div>;
    }

    const firstColumnHeaderClassNames = classnames([styles.firstColumn, styles.headerCells, styles.searchableHeader]);
    return (
      <Table className={styles.table} size="medium" stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={firstColumnHeaderClassNames}>
              <ColumnSearch onSortChange={handleSortChange} sortMode={employeeSort} onChange={handleEmployeeSearch} value={employeeSearch} />
            </TableCell>
            {renderProjectsInHeader()}
            <TableCell className={`${styles.lastColumn} ${styles.headerCells}`}>
              Total hours worked
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {renderTableRows()}
        </TableBody>
      </Table>
    );
  };

  if (isDataFetchInProgress || timesheets === timesheetsInitialState) {
    return <LoadingElement />
  }

  return (
    <>
      <div className={styles.timesheetContainer}>
        <div className={styles.questionContainer}>
          <Typography className={styles.title}>{questionTitle}</Typography>
          <Typography className={styles.caption}>{questionCaption}</Typography>
        </div>
        <ExpansionPanelActivities activities={activities}/>
        <div className={styles.tabToggleHours}>
          <Toggle options={ToggleOptions} defaultValue={HOURS_PERCENTAGE} onSelect={handleChangeToggle} />
        </div>
        <TableContainer className={styles.tableContainer}>
          {renderTable()}
          <AddEmployeeButton survey={survey} timesheets={timesheets} onEmployeeAdd={fetchAndSetTimesheets} />
        </TableContainer>
      </div>
  </>
  );
};

export default EmployeeResourcesTimesheet;
