import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  startUpMenuButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '19rem',
    height: '19rem',
    marginRight: theme.spacing(3),
    border: 0,
    backgroundColor: theme.palette.black[900],
    color: theme.palette.common.white,
    cursor: 'pointer',
    textDecoration: 'none',
    transition: theme.transitions.create(
      ['background-color'],
      { duration: theme.transitions.duration.complex }
    ),
    '&:hover': {
      backgroundColor: theme.palette.black[800],
    }
  },
  imgLaunchpad: {
    width: '11.375rem',
    marginBottom: theme.spacing(1),
  },
  h2Launchpad: {
    fontSize: '1.25rem',
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(0.5)
  },
  h3Launchpad: {
    marginBottom: 0,
    fontSize: '0.9rem',
    color: theme.palette.grey[900],
    fontWeight: theme.typography.fontWeightLight,
    marginTop: 0
  }

}));

const NavItem = ({ img, imgAlt, link, title, subtitle }) => {
  const classes = useStyles();
  return(
  <Link to={link} className={classes.startUpMenuButton}>
    <img className={classes.imgLaunchpad}
      src={img}
      alt={imgAlt}
    />
    <h2 className={classes.h2Launchpad}>{title}</h2>
    <h3 className={classes.h3Launchpad}>{subtitle}</h3>
  </ Link>
)};

NavItem.propTypes = {
  img: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  imgAlt: PropTypes.string
};

export default NavItem;
