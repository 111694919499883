import { DEFAULT_STATUSES } from '../constants';
import { checkIsDateValid } from '../../../../customValidations/checkIsDateValid';

export const buildCreditInputFields = (credit, inputData) => [
  {
    name: 'entityId',
    label: 'Entity',
    type: 'Selector',
    options: inputData.entities,
    value: credit.entityId,
    muiProps: {
      disabled: inputData.isEdit
    },
    optionKeys: {
      name: 'name',
      value: 'entity_id',
    },
    required: true,
  },
  {
    name: 'jurisdictionId',
    label: 'Jurisdiction',
    type: 'AutocompleteSelect',
    options: inputData.jurisdictions,
    value: credit.jurisdictionId,
    muiProps: {
      disabled: inputData.isEdit
    },
    optionKeys: {
      name: 'name',
      value: 'jurisdiction_id',
    },
    required: true,
  },
  {
    name: 'taxPayerTypeRefId',
    label: 'Tax Payer Type',
    type: 'Selector',
    options: inputData.taxPayerTypes,
    value: credit.taxPayerTypeRefId,
    muiProps: {
      disabled: inputData.isEdit
    },
    optionKeys: {
      name: 'name',
      value: 'id',
    },
    required: true,
  },
  {
    name: 'publishedStatusRefId',
    label: 'Published Status',
    type: 'Selector',
    options: inputData.publishedStatuses,
    value: credit.publishedStatusRefId || DEFAULT_STATUSES.publishedStatusRefId,
    optionKeys: {
      name: 'name',
      value: 'id',
    },
    required: true,
  },
  {
    name: 'creditMethodRefId',
    label: 'Credit Method',
    type: 'Selector',
    options: inputData.creditMethods,
    value: credit.creditMethodRefId,
    helperText: !inputData.jurisdictionId && inputData.isCreate ? 'Select a jurisdiction in order to display the methods' : '',
    muiProps: {
      disabled: inputData.isEdit || !inputData.jurisdictionId,
    },
    optionKeys: {
      name: 'name',
      value: 'id',
    },
    required: true,
  },
  {
    name: 'dueDate',
    label: 'Due Date',
    type: 'Date',
    required: true,
    value: credit.dueDate,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
  {
    name: 'creditStatusRefId',
    label: 'Credit Status',
    type: 'Selector',
    options: inputData.creditStatuses,
    value: credit.creditStatusRefId,
    optionKeys: {
      name: 'name',
      value: 'id'
    },
    required: true,
  },
  {
    name: 'notes',
    label: 'Notes',
    type: 'Input',
    value: credit.notes,
    muiProps: {
      multiline: true,
      rows: 3
    }
  },
];
