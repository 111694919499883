import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  title: {
    width: '100%',
    fontSize: 'x-large',
    padding: theme.spacing(2),
  },
  heading: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    padding: theme.spacing(1),
  },
  dials: {
    margin: theme.spacing(4)
  }
}));
