import React from 'react';
import useStyles from './Dials.styles';

const Dials = ({ dialConfig }) => {
  const styles = useStyles();

  const getClassNameForCounter = (type) => {
    const classNames = {
      default   : styles.default,
      completed : styles.completed,
      pending   : styles.pending
    };

    return classNames[type];
  };

  return (
    <div className={styles.dialsWrapper} data-testid="dials-test">
      {dialConfig.map(({ type, title, value }) =>
        <div className={styles.dialContainer} key={title} >
        <div className={`${styles.dial} ${getClassNameForCounter(type)}`}>
          <span className={styles.dialValue}>{value}</span>
        </div>
        <div className={styles.dialTitle}>{title}</div>
        </div>
      )}
     </div>
  );
};

export default Dials;
