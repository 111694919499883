import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  openModalButton: {
    position: 'absolute',
    top: '5px',
    right: '12px',
    width: '20px',
    height: '20px',
    zIndex: '10'
  },
  chipsTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '1',
    margin: '10px 0 5px 0'
  },
  chipsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    paddingBottom: '5px',
    boxSizing: 'content-box',
    '& > *': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      margin: theme.spacing(0.1),
      '& svg': {
        fill: theme.palette.primary.contrastText
      }
    }
  }
}));
