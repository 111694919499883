import React from 'react';
import { VariableSizeList } from 'react-window';

import useVirtualDropdownResetCache from '../../hooks/useVirtualDropdownResetCache';

const ItemSize = 44;

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);

  // List height calculation is always overshooting by one item for some reason. 
  // In order to fix this we're manually calculating the height based on ItemSize and current size.
  const calculatedListHeight = props.children.props.style.height;
  props.children.props.style.height = calculatedListHeight - ItemSize;

  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow({ data, index, style }) {
  return React.cloneElement(data[index], { style });
}

const VirtualizedDropdownListbox = React.forwardRef(({ children, ...other }, ref) => {
  const itemData = React.Children.toArray(children);

  const gridRef = useVirtualDropdownResetCache(itemData.length);

  function getListboxHeight() {
    if (itemData.length > 8) {
      return 8 * ItemSize;
    }

    return ItemSize * itemData.length;
  }

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          ref={gridRef}
          width="100%"
          height={getListboxHeight()}
          itemData={itemData}
          itemCount={itemData.length}
          itemSize={() => ItemSize}
          overscanCount={5}
          outerElementType={OuterElementType}
          innerElementType="ul"
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

export default VirtualizedDropdownListbox;
