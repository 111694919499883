import {
  FETCH_SURVEY_BY_ID_SUCCESS,
  FETCH_SURVEY_TABS_SUCCESS,
  RESET_STATE_SURVEY_WIZARD_SURVEY,
  FETCH_SURVEY_SECTION_INFO_SUCCESS,
  TOGGLE_SURVEY_SECTION_COMPLETE,
  CHANGE_SURVEY_DOCUMENTS_DESCRIPTION,
} from '../../constants/actionTypes/surveyWizard';
import { SURVEY_INITIAL_STATE } from '../../components/SurveyCentral/SurveyWizard/constants';

const surveyWizard = (state = SURVEY_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SURVEY_BY_ID_SUCCESS:
      return {
        ...state,
        survey: action.survey
      }
    case FETCH_SURVEY_TABS_SUCCESS:
      return {
        ...state,
        surveyTabs: action.surveyTabs
      }
    case FETCH_SURVEY_SECTION_INFO_SUCCESS:
      return {
        ...state,
        surveySections: action.sections.reduce((a,section) => {
          a[section.surveySectionRefId] = section;
          return a;
        }, {})
      }
    case TOGGLE_SURVEY_SECTION_COMPLETE:
      return {
        ...state,
        surveySections: {
          ...state.surveySections,
          [action.surveySectionRefId]: {
            ...state.surveySections[action.surveySectionRefId],
            isCompleted: action.isCompleted ? 1 : 0
          }
        }
      };
    case CHANGE_SURVEY_DOCUMENTS_DESCRIPTION: {
      return {
        ...state,
        survey: {
          ...state.survey,
          documentsDescription: action.documentsDescription
        }
      }
    }
    case RESET_STATE_SURVEY_WIZARD_SURVEY:
      return SURVEY_INITIAL_STATE;
    default: return state;
  }
};

export default surveyWizard;
