import { makeStyles } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  image: {
    width: '60%'
  },
  container: {
    position: 'relative',
    display: 'inline-block',
    textAlign: 'center'
  },
  errorText: {
    position: 'absolute',
    top: '72%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 40,
    color: '#a9afb5',
    fontWeight: 'fontWeightBold',
  },
  area: {
    backgroundColor: '#252525',
    display:'block',
    float: 'top',
    textAlign: 'center'
  },
  content: {
    display: 'table-cell',
    verticalAlign: 'middle',
    height: '100vh',
  },
  title: {
    color: '#884b92',
    fontWeight: 'fontWeightBold',
    fontSize: 18,
    textAlign: 'center',
    paddingTop: 30
  },
  text: {
    fontSize: 16,
    color: '#a9afb5',
    textAlign: 'center'
  },
  errorCenter: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 16,
  },
  button: {
    display: 'flex',
    margin: 8,
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: purple[500],
    '&:hover': {
      backgroundColor: purple[700],
    },
  },

}));
