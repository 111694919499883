import React from 'react';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Slider from 'rc-slider';
import { useStyles, createRcSliderStyles } from './styles';
import { useTheme } from '@material-ui/core/styles';
import RDPlannerModalShared from './RDPlannerModalShared';

const TotalQreExpenseTypeRows = ({ totalQrePerExpenseType, totalQrePerExpenseTypeState }) => {
  const styles = useStyles();
  const muiTheme = useTheme();
  const rcSliderStyles = createRcSliderStyles(muiTheme);
  const { setTotalQrePerExpenseType } = totalQrePerExpenseTypeState;
  
  const calculateAdditionalPercentageIncrease = (percentageIncrease) => {
    const additionalPercentageIncrease = (percentageIncrease - 1) * 100;
    return additionalPercentageIncrease.toFixed(0);
  };
  
  const formatAdditionalPercentage = (additionalPercentage) => {
    let additionalPercentageFormatted = `${additionalPercentage}%`;
    if (additionalPercentage > 0) {
      additionalPercentageFormatted = `+${additionalPercentageFormatted}`;
    }
    
    return additionalPercentageFormatted;
  }
  
  const handleSliderChangePercentageIncrease = (value, modifiedExpenseTypeTotalQre) => {
    const modifiedExpenseTypeTotalQreIndex = totalQrePerExpenseType.findIndex((expenseTypeTotalQre) => {
      return expenseTypeTotalQre.expenseTypeRefId === modifiedExpenseTypeTotalQre.expenseTypeRefId;
    });
    
    const newTotalQrePerExpenseType = [...totalQrePerExpenseType];
    newTotalQrePerExpenseType[modifiedExpenseTypeTotalQreIndex] = {
      ...modifiedExpenseTypeTotalQre,
      totalQreNextYear: modifiedExpenseTypeTotalQre.totalQre * value,
      percentageIncrease: value
    };
    setTotalQrePerExpenseType(newTotalQrePerExpenseType);
  };
  
  const handleSliderChangeMoneyIncrease = (value, modifiedExpenseTypeTotalQre) => {
    const modifiedExpenseTypeTotalQreIndex = totalQrePerExpenseType.findIndex((expenseTypeTotalQre) => {
      return expenseTypeTotalQre.expenseTypeRefId === modifiedExpenseTypeTotalQre.expenseTypeRefId;
    });
    
    const newTotalQrePerExpenseType = [...totalQrePerExpenseType];
    newTotalQrePerExpenseType[modifiedExpenseTypeTotalQreIndex] = { ...modifiedExpenseTypeTotalQre, totalQreNextYear: value };
    setTotalQrePerExpenseType(newTotalQrePerExpenseType);
  };
  
  const totalQrePerExpenseTypeRows = totalQrePerExpenseType.map((expenseTypeTotalQre) => {
    const { totalQre: expenseTypeTotalQreValue, totalQreNextYear: expenseTypeTotalQreValueNextYear, expenseTypeName, percentageIncrease } = expenseTypeTotalQre;
    const expenseTypeTotalQreValueNextYearFormatted = RDPlannerModalShared.formatCurrency(expenseTypeTotalQreValueNextYear);
    const additionalPercentageIncrease = calculateAdditionalPercentageIncrease(percentageIncrease);
    
    const totalQreCellClassNames = classNames({
      [styles.modifiedExpenseTypeTotalQreCell]: expenseTypeTotalQreValue !== expenseTypeTotalQreValueNextYear
    });
    
    const sliderOptionsForMoneyIncrease = {
      defaultValue: 0,
      step: 1000,
      min: 0,
      max: 1000000,
      onChange: (value) => handleSliderChangeMoneyIncrease(value, expenseTypeTotalQre)
    };
    
    const sliderOptionsForPercentageIncrease = {
      defaultValue: 1,
      step: 0.05,
      min: 0,
      max: 2,
      onChange: (value) => handleSliderChangePercentageIncrease(value, expenseTypeTotalQre)
    }
    
    const additionalPercentageIncreaseFormatted = formatAdditionalPercentage(additionalPercentageIncrease)
    const sliderOptions = expenseTypeTotalQreValue === 0 ? sliderOptionsForMoneyIncrease : sliderOptionsForPercentageIncrease;
    const increaseRate = expenseTypeTotalQreValue === 0 ? expenseTypeTotalQreValueNextYearFormatted : additionalPercentageIncreaseFormatted;
    return (
      <TableRow key={expenseTypeName}>
        <TableCell className={styles.columnTitle}>{expenseTypeName}</TableCell>
        <TableCell align="right">{RDPlannerModalShared.formatCurrency(expenseTypeTotalQreValue)}</TableCell>
        <TableCell align="right">
          <div className={styles.sliderCell}>
            <Slider
              className={styles.slider}
              startPoint={1}
              handleStyle={rcSliderStyles.handle}
              railStyle={rcSliderStyles.rail}
              trackStyle={rcSliderStyles.track}
              {...sliderOptions}
            />
          </div>
        </TableCell>
        <TableCell className={totalQreCellClassNames} align="right">
          <div className={styles.totalQreNextYear}>
              <span className={styles.additionalPercentageIncrease}>
                {percentageIncrease !== 1 && increaseRate}
              </span>
            <span>
                {RDPlannerModalShared.formatCurrency(expenseTypeTotalQreValueNextYear)}
              </span>
          </div>
        </TableCell>
      </TableRow>
    )
  });
  
  return totalQrePerExpenseTypeRows;
};

export default TotalQreExpenseTypeRows;
