const chooseColorBasedOnDepth = (node) => {
  const depthColors = {
    0: '#673AB7',
    1: '#7E57C2',
    2: '#9575CD',
    3: '#B39DDB'
  };

  return depthColors[node.depth] || '#673AB7';
};

export default chooseColorBasedOnDepth;
