export const currentSurveyProjects = state => state.surveyWizard.projects.projects;
export const surveyWizardSelectedProjectId = state => state.surveyWizard.projects.selectedProjectId;
export const getCurrentProjectQuestionIndex = state => state.surveyWizard.projects.selectedQuestionIndex;
export const getProjectQualificationTabs = state => Object.values(state.surveyWizard.projects.projects)
  .map(project => ({
    title: project.name,
    id: project.id,
    selected: surveyWizardSelectedProjectId(state) === project.id
  }));
export const getCurrentProjectQuestions = state => {
  return (state.surveyWizard.projects.qualifications[surveyWizardSelectedProjectId(state)] || [])
}
export const getCurrentProjectQuestionDetails = state => getCurrentProjectQuestions(state)[getCurrentProjectQuestionIndex(state)] || {}
