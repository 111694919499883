import { FETCH_TENANTS_SUCCESS, SELECT_TENANT_CRUD, SET_SELECTED_TENANT_FIELD } from '../constants/actionTypes/tenant';
import { TENANT_INITIAL_STATE } from '../components/ServiceManager/Tenants/constants';

const tenants = (state = TENANT_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TENANTS_SUCCESS:
      return {
        ...state,
        list: action.tenants
      };
    case SELECT_TENANT_CRUD:
      return {
        ...state,
        selected: action.selectedTenant
      };
    case SET_SELECTED_TENANT_FIELD:
      return {
        ...state,
        selected: { ...state.selected, [action.key]: action.value }
      }
    default: return state;
  }
};

export default tenants;
