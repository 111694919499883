import React from 'react';
import { useDispatch } from 'react-redux';
import { toggleUserSurveyWelcome } from '../../../actions/users'
import { makeStyles } from '@material-ui/core/styles';
import Radio from '../../../commonComponents/Radio';
import Button from '../../../commonComponents/Button';
import { welcomePageSections } from './config';
import SurveySVG1 from '../../../assets/svg/ic-surveys.png';
import { WithRoleRestriction } from '../../../hocs';
import { roles } from '../../../constants/roles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  welcomeSurveyContainer: {
    textAlign: 'left',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  textContainer: {
    display: 'inline-block',
    width: '440px'
  },
  graphic: {
    margin: '2em 10em 2em 10em',
    height: '380px',
    width: '380px'
  },
  header: {
    fontWeight: "bold",
    fontSize: '2.4rem',
    color: theme.palette.secondary.dark
  },
  body: {
    fontWeight: 200,
    fontSize: '1.3rem',
    color: theme.palette.grey[900]
  },
  spacer: {
    margin: '2em 10em 2em 0em',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  buttonsAndRadio: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  nextButton: {
    color: theme.palette.secondary.dark,
    border: `1px solid ${theme.palette.primary.main}`
  },
  prevButton: {
    color: theme.palette.grey[900]
  },
  hiddenButton: {
    visibility: 'hidden'
  },
  button: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none'
    }
  },
  radioButton: {
    padding: theme.spacing(.2),
    margin: theme.spacing(.2),
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  radio: {
    display: 'flex',
  },
  icon: {
    borderRadius: '50%',
    width: 12,
    height: 12,
    backgroundColor: theme.palette.grey[400]
  },
  checkedIcon: {
    borderRadius: '50%',
    width: 12,
    height: 12,
    backgroundColor: theme.palette.primary.main
  }
}));


const SurveyWelcomePage = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [selectedRadioIndex, setSelectedRadioIndex] = React.useState(0);

  const prevButtonClasses = classNames({
    [styles.button]: true,
    [styles.prevButton]: true,
  });
  const nextButtonClasses = classNames({
    [styles.button]: true,
    [styles.nextButton]: true
  });
  const getSVG = () => {
    return <img alt="graphic" className={styles.graphic} src={SurveySVG1} />
  };

  const ContentContainer = ({ header, body }) => {
    return (
      <div data-testid="welcome-survey-content-container" className={styles.contentContainer}>
        <div className={styles.textContainer}>
          <h1 className={styles.header}>{header}</h1>
          <p className={styles.body}>{body}</p>
        </div>
      </div>
    );
  }

  const onStartButtonClick = () => {
    dispatch(toggleUserSurveyWelcome());
  }
  const onNextButtonClick = () => {
    setSelectedRadioIndex(selectedRadioIndex + 1);
  }
  const onPrevButtonClick = () => {
    setSelectedRadioIndex(selectedRadioIndex - 1);
  }

  const makeButtons = (selectedIndex) => {
    const previousButtonProps = {
      children: 'Previous',
      className: prevButtonClasses,
      'data-testid': 'welcome-page-prev-button',
      onClick: onPrevButtonClick
    };
    const nextButtonProps = {
      children: 'Next',
      className: nextButtonClasses,
      'data-testid': 'welcome-page-next-button',
      onClick: onNextButtonClick
    }
    const onFirstWelcomeSection = selectedIndex === 0
    const onLastWelcomeSection = selectedIndex === welcomePageSections.length - 1

    if (onFirstWelcomeSection) {
      previousButtonProps.className = styles.hiddenButton
    } else if (onLastWelcomeSection) {
      nextButtonProps.children = 'Start'
      nextButtonProps.onClick = onStartButtonClick
      nextButtonProps['data-testid'] = 'welcome-page-start-button'
    }

    return (
      <div>
        <Button {...previousButtonProps} />
        <Button {...nextButtonProps} />
      </div>
    )
  }

  const { header, body } = welcomePageSections[selectedRadioIndex];

  return (
    <div className={styles.welcomeSurveyContainer}>
      {getSVG()}
      <div className={styles.spacer}>
        <ContentContainer header={header} body={body}/>
        <div className={styles.buttonsAndRadio}>
          <div className={styles.radio}>
            {welcomePageSections.map((text, i) => (
              <Radio disableRipple className={styles.radioButton}
                icon={<span className={styles.icon} />}
                checkedIcon={<span className={styles.checkedIcon} />}
                data-testid={`text-content-radio-${i}`}
                key={i} checked={selectedRadioIndex === i} value={i} />
            ))}
          </div>
          {makeButtons(selectedRadioIndex)}
        </div>
      </div>
    </div>
  );
}

const restrictWithRoles = WithRoleRestriction({
  necessaryRoles: [roles.PS_MANAGER, roles.SURVEY_RESPONDENT, roles.FULL_ACCESS_ADMIN, roles.LIMITED_ACCESS_ADMIN],
});

export default restrictWithRoles(SurveyWelcomePage);
