import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modalHead: {
    color: theme.palette.common.black
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: `0 ${theme.spacing(1)}px`,
    '& tr': {
      height: 40
    },
    '& td': {
      backgroundColor: theme.palette.grey[50],
      whiteSpace: 'nowrap',
      border: 'none',

      '&:first-child': {
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5
      },
      '&:last-child': {
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5
      }
    }
  },
  modalTitle: {
    fontWeight: 700,
  },
  modalSubtitle: {
    fontSize: '0.8rem',
    color: theme.palette.grey[900]
  },
  mainWrapper: {
    width: 800,
    padding: theme.spacing(2)
  },
  columnTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  headRow: {
    '& th': {
      border: 'none',
      fontSize: '0.9rem',
      color: theme.palette.grey[800],
      fontWeight: 700,
      paddingBottom: 0,
      '&:last-child': {
        paddingRight: theme.spacing(2.5)
      }
    }
  },
  totalQualifiedExpensesRow: {
    '& td': {
      backgroundColor: 'unset',
      '&:last-child': {
        paddingRight: theme.spacing(2.2)
      }
    }
  },
  creditAmount: {
    fontSize: '1.3rem',
    paddingRight: theme.spacing(.7)
  },
  totalQreNextYear: {
    width: 130,
    display: 'flex',
    justifyContent: 'space-between'
  },
  slider: {
    width: 200,
    display: 'flex',
    alignItems: 'center',
  },
  sliderCell: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  modifiedExpenseTypeTotalQreCell: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    'td&': {
      backgroundColor: theme.palette.primary.veryLight
    }
  },
  modifiedTotals: {
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  additionalPercentageIncrease: {
    width: 45,
    marginRight: theme.spacing(1),
    textAlign: 'center'
  },
  creditAmountRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div': {
      padding: theme.spacing(1.5),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '50%',
      backgroundColor: theme.palette.grey[50],
      borderRadius: 5,
      '&:first-child': {
        marginRight: theme.spacing(7.5)
      }
    }
  }
}));

export const createRcSliderStyles = (theme) => ({
  handle: {
    height: 16,
    width: 16,
    backgroundColor: theme.palette.common.white,
    border: 'none',
    marginTop: -2,
    boxShadow: `0px 0px 3px 1px ${theme.palette.grey[400]}`
  },
  rail: {
    backgroundColor: theme.palette.grey[200],
    height: 12,
    borderRadius: 5,
  },
  track: {
    height: 12,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 5
  }
});
