import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import getValueFromEvent from '../../../../utils/getValueFromEvent';
import {
  createEmployee,
  deleteEmployee,
  updateEmployee,
  setSelectedEmployeeField,
  selectEmployee,
} from '../../../../actions/employee';
import { EMPLOYEE_INITIAL_STATE } from '../constants';
import { getSelectedEntity } from '../../../../selectors/entities';
import { getSelectedEmployee } from '../../../../selectors/employees';
import dateToMysqlFormat from '../../../../utils/dateToMysqlFormat';
import dateToDisplayableFormat from '../../../../utils/dateToDisplayableFormat';
import { buildEmployeeInputFields } from './buildEmployeeInputFields';
import FormBuilder from '../../../../commonComponents/FormBuilder';

const EmployeesCrud = (props) => {
  const dispatch = useDispatch();
  const entityId = useSelector(getSelectedEntity, shallowEqual);
  const employee = useSelector(getSelectedEmployee, shallowEqual);
  const isCreateCrud = props.crudType === 'create';

  useEffect(() => {
    if (!isCreateCrud) {
      dispatch(selectEmployee(props));
    } else {
      dispatch(selectEmployee(EMPLOYEE_INITIAL_STATE.selected));
    }
  }, [dispatch, props, isCreateCrud]);

  useEffect(() => {
    if (isCreateCrud) {
      return;
    }

    if (employee.hireDate) {
      const hireDate = dateToDisplayableFormat(employee.hireDate);
      dispatch(setSelectedEmployeeField('hireDate', hireDate));
    }
    if (employee.termDate) {
      const termDate = dateToDisplayableFormat(employee.termDate);
      dispatch(setSelectedEmployeeField('termDate', termDate));
    }
  }, [dispatch, employee.termDate, employee.hireDate, isCreateCrud]);

  const handleChange = (event) => {
    const { name } = event.target;
    const value = getValueFromEvent(event);
    dispatch(setSelectedEmployeeField(name, value));
  };

  const handleEmployeeUpdate = () => {
    const { id } = employee;
    const body = makeEmployeeBody();
    delete body.email;
    delete body.entityId;
    dispatch(updateEmployee(id, entityId, body));
    props.closeCrudPanel();
  };

  const handleEmployeeDelete = () => {
    const { id } = employee;

    dispatch(deleteEmployee(id, entityId));
    props.closeCrudPanel();
  };

  const handleEmployeeCreate = () => {
    const body = makeEmployeeBody();

    dispatch(createEmployee(body));
    props.closeCrudPanel();
  };

  const makeEmployeeBody = () => {
    return {
      name: employee.name,
      department: employee.department,
      jobTitle: employee.jobTitle,
      hireDate: dateToMysqlFormat(employee.hireDate),
      termDate: dateToMysqlFormat(employee.termDate),
      rdHours: employee.rdHours,
      totalHours: employee.totalHours || 0,
      email: employee.email || null,
      entityId,
    };
  };
  
  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleEmployeeCreate,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleEmployeeUpdate,
      color: 'grape',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleEmployeeDelete,
      color: 'rhubarb',
      shouldAskConfirmation: true
    },
  ];
  const inputFields = buildEmployeeInputFields(employee, {
    isEditCrud: props.crudType === 'edit'
  });
  return (
    <div>
      <FormBuilder onChange={handleChange} fields={inputFields} formActions={formActions}>
        {!isCreateCrud && (
          <p>
            R&D Hours from Resource Tracker: {employee.rdHours}
          </p>
        )}
      </FormBuilder>
    </div>
  );
};

export default EmployeesCrud;
