import { makeStyles } from '@material-ui/core/styles';

const stickyColumnzIndex = 10;

const useStyles = makeStyles((theme) => ({
  table: {
    '& td, th': {
      padding: theme.spacing(.75)
    },
  },
  tableContainer: {
    overflow: 'auto',
    height: 400
  },
  searchableHeader: {
    padding: 0,
  },
  firstColumn: {
    borderRight: `${theme.palette.grey[400]} 1px solid`,
    position: 'sticky',
    left: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: stickyColumnzIndex,
    'th&': {
      zIndex: stickyColumnzIndex + 1
    }
  },
  lastColumn: {
    borderLeft: `${theme.palette.grey[400]} 1px solid`,
    position: 'sticky',
    right: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: stickyColumnzIndex,
    'th&': {
      zIndex: stickyColumnzIndex + 1
    }
  },
  headerCells: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[900],
    whiteSpace: 'nowrap'
  },
  timesheetInput: {
    '& .MuiInput-root': {
      '&::before': {
        border: 0
      },
      '&:hover': {
        '&::before': {
          border: 0
        }
      }
    },
    '& input': {
      width: '100%',
      padding: theme.spacing(.25),
      color: theme.palette.grey[900],
      borderRadius: 5,
      textAlign: 'center',
      border: `2px solid transparent`,
      cursor: 'pointer',
      '&::placeholder': {
        color: theme.palette.grey[900],
        opacity: 1,
      },
      '&:hover': {
        border: `2px solid ${theme.palette.grey[400]}`,
      },
      '&:focus': {
        border: `2px solid ${theme.palette.primary.main}`,
        cursor: 'text',
      },
    },
  },
  totalHoursTimesheetInput: {
    '& input': {
      textAlign: 'left'
    }
  },
  notFound: {
    padding: theme.spacing(3),
  },
  addEmployeeButton: {
    display: 'flex',
    padding: theme.spacing(.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    cursor: 'pointer',
    alignItems: 'center',
    width: 150,
    '& span': {
      marginRight: theme.spacing(1)
    }
  },
  dropdown: {
    width: '300px',
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5),
  },
  dropdownOption: {
    display: 'flex',
    color: theme.palette.grey[900],
  },
  questionContainer: {
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem'
  },
  caption: {
    color: theme.palette.grey[700],
    marginBottom: theme.spacing(1)
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    marginLeft: theme.spacing(1),
  },
  accordionHideBorder: {
     '-webkit-box-shadow':'none',
     '-moz-box-shadow':'none',
     'box-shadow':'none',
    padding: theme.spacing(0),
    backgroundColor: theme.palette.grey[50],
  },
  accordionBody: {
    padding: theme.spacing(0),
    color: theme.palette.grey[700],
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  accordionSummary: {
    display: 'inline-flex',
    padding: theme.spacing(0),
    marginBottom: theme.spacing(0),
    justifyContent: 'flex-start',
    '& .MuiExpansionPanelSummary-content': {
      flexGrow: 0
    } 
  },
  tabToggleHours: {
    marginBottom: theme.spacing(1),
  }
}));

export default useStyles;
