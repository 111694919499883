import React from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import usePortal from '../../hooks/usePortal';

const useStyles = makeStyles(theme => ({
  crudContainer: {
    padding: theme.spacing(1.5),
    overflow: 'auto',
    position: 'relative',
    minHeight: '120px',
  },
}));

const CrudPanel = ({ children }) => {
  const styles = useStyles();
  const target = usePortal('crud-panel-container');

  const areCrudPropsEmpty = (!children[1].props.crudType);
  if (areCrudPropsEmpty) {
    return null;
  }

  const crudPanel = (
    <div className={styles.crudContainer}>
      {children}
    </div>
  );

  return ReactDOM.createPortal(crudPanel, target);
};

export default CrudPanel;
