import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { surveyStatusByRefId } from '../constants';

const useStyles = makeStyles((theme) => ({
  surveyCard: {
    backgroundColor: theme.palette.common.white,
    width: '30%',
    margin: '1.5%',
    height: 350,
    borderRadius: 5,
    padding: 36,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    cursor: 'pointer',
    boxShadow: `4px 4px 4px 0px ${theme.palette.grey[200]}`,
    [theme.breakpoints.down('md')]: {
      width: '47%',
    }
  },
  cardTitle: {
    fontSize: '1.2rem',
  },
  periodCovered: {
    fontSize: '.9rem',
    color: theme.palette.grey[800],
  },
  cardSecondaryText: {
    fontSize: '.9rem',
    color: theme.palette.grey[800],
    '& > span': {
      color: theme.palette.primary.main
    }
  },
  status: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '.9rem',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(.5),
  },
  dueDate: {
    backgroundColor: theme.palette.primary.veryLight,
    padding: theme.spacing(0.7),
    borderRadius: 5
  }
}));

const SurveyCard = ({ survey, onClick, shouldDisplayEntityName }) => {
  const styles = useStyles();

  const formatDate = (date) => {
    if (!date) {
      return '-';
    }
    return moment(new Date(date)).utc().format('DD MMM YYYY');
  };

  const getProgressPercentage = () => {
    const { completedSections, totalSections } = survey;
    return (completedSections / totalSections) * 100
  }

  return (
    <div className={styles.surveyCard} onClick={() => onClick(survey)}>
      <div>
        <div data-testid="title" className={styles.cardTitle}>Your R&D Project Survey {survey.taxYear}</div>
      </div>

      <div  className={styles.periodCovered}>
        <div><b>Period Covered</b></div>
        <span data-testid="subtitle">
          {formatDate(survey.startDate)} to {formatDate(survey.endDate)}
        </span>
      </div>

      {shouldDisplayEntityName && (
        <div>
          <div className={styles.cardSecondaryText}><span>Entity:</span> {survey.entityName}</div>
          <div className={styles.cardSecondaryText}><span>Tax Year:</span> {survey.taxYear}</div>
          <div className={styles.cardSecondaryText}><span>Company:</span> {survey.companyName}</div>
        </div>
      )}

      <div>
        <div data-testid="status" className={styles.status}>
          {surveyStatusByRefId[survey.surveyStatusRefId]}
          <div data-testid="dueDate" className={styles.dueDate}>Due {formatDate(survey.dueDate)}</div>
        </div>
        <LinearProgress variant="determinate" value={getProgressPercentage()} />
      </div>
    </div>
  );
};

export default SurveyCard;
