import React from 'react';
import PropTypes from 'prop-types';
import Fab from "@material-ui/core/Fab";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles as expenseButtonUseStyles } from '../../../../commonComponents/FileUpload/styles'

const ExpensesDownload = ({ onClick,isLoading, title, icon = null }) => {
  const expenseButtonStyles = expenseButtonUseStyles();
  
  return (
    <div>
        <Fab data-testid="file-download"
          color="secondary"
          size="small"
          component="span"
          aria-label="add"
          variant="extended"
          className={expenseButtonStyles.button}
          disabled={isLoading}
          onClick={onClick}
        >
          {isLoading && <CircularProgress data-testid="downloading-expense" className={expenseButtonStyles.loading} size={24} />}
          {icon} {title}
        </Fab>
    </div>
  );
};

ExpensesDownload.propTypes = {
  onClick: PropTypes.func,
};

export default ExpensesDownload;
