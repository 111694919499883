import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getValueFromEvent from '../../../../utils/getValueFromEvent';

// COMPONENTS
import FormBuilder from '../../../../commonComponents/FormBuilder'

// UTILS
import { buildSurveyInputFields } from './buildSurveyInputFields'
import useRoleRestriction from '../../../../hooks/useRoleRestriction';
import { NECESSARY_ROLES_PER_COMPONENT, SURVEY_STATUS_REF_ID_BY_NAME } from '../constants';
import {
  createSurvey,
  deleteSurvey,
  editSurvey,
  selectSurvey,
  setSelectedSurveyField,
  setSelectedProjectSurvey,
  setSelectedEmployeeSurvey,
  reopenSurvey,
} from '../../../../actions/survey';
import { SURVEY_INITIAL_STATE } from '../constants';
import { getSelectedEntity } from '../../../../selectors/entities';
import { listByTaxYear } from '../../../../selectors/employees';
import { getSurveyLeaders } from '../../../../selectors/users';
import { getSelectedSurvey, getTimePeriods, getTrackingTypes, getUserProxyIds } from '../../../../selectors/surveys';
import dateToMysqlFormat from '../../../../utils/dateToMysqlFormat';

const SurveysCrud = (props) => {
  const dispatch = useDispatch();
  const survey = useSelector(getSelectedSurvey);
  const entityId = useSelector(getSelectedEntity);
  const leaders = useSelector(getSurveyLeaders);
  const employeesByTaxYear = useSelector(listByTaxYear);
  const timePeriods = useSelector(getTimePeriods);
  const trackingTypes = useSelector(getTrackingTypes);
  const userProxyIds = useSelector(getUserProxyIds);
  const { id: surveyTypeId } = props.surveyType;
  const { allProjectSurveys } = props;
  const hasRoleAccessTo = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);

  useEffect(() => {
    if (props.crudType !== 'create') {
      dispatch(selectSurvey(props));
    } else {
      dispatch(selectSurvey(SURVEY_INITIAL_STATE.selected));
    }
  }, [dispatch, props]);

  const handleChange = (event, name) => {
    let value = getValueFromEvent(event);
    value = Number(value) || value;

    if (typeof name !== 'string') {
      name = event.target.name;
    }
    dispatch(setSelectedSurveyField(name, value));
  };

  const handleSurveyUpdate = () => {
    const { id } = survey;
    const body = makeSurveyBody();
    const surveyData = { surveyTypeId, entityId, surveyId: id };
    dispatch(editSurvey(surveyData, body));
    props.closeCrudPanel();
  };

  const handleSurveyReopen = () => {
    const { id } = survey;
    const surveyData = { surveyTypeId, entityId, surveyId: id };
    dispatch(reopenSurvey(surveyData));
    props.closeCrudPanel();
  };

  const handleSurveyDelete = () => {
    const { id } = survey;

    const surveyData = { surveyTypeId, entityId, surveyId: id };
    dispatch(deleteSurvey(surveyData));
    props.closeCrudPanel();
  };

  const handleSurveyCreate = () => {
    const body = makeSurveyBody();
    const surveyData = { surveyTypeId, entityId };
    dispatch(createSurvey(surveyData, body));
    props.closeCrudPanel();
  };

  const makeSurveyBody = () => {
    const { userId, dueDate, projectSurveys, employeeSurveys, userProxyId, timePeriodId, trackingTypeId, surveyStartDate, surveyEndDate } = survey;
    const body = {
      typeId: surveyTypeId,
      dueDate: dateToMysqlFormat(dueDate),
      userId,
      entityId,
      userProxyId: userProxyId || null,
      projectSurveys: projectSurveys.map(projectSurvey => projectSurvey.project_id),
      employeeSurveys: employeeSurveys.map(employeeSurvey => employeeSurvey.id),
      timePeriodId: timePeriodId,
      trackingTypeId: trackingTypeId,
      startDate: dateToMysqlFormat(surveyStartDate),
      endDate: dateToMysqlFormat(surveyEndDate)
    };
    return body;
  };

  const getSelectedProjectSurvey = () => {
    return allProjectSurveys.filter((projectSurvey) => (
      survey.projectSurveys.find((projectSurveyOfProject) => projectSurvey.project_id === projectSurveyOfProject.project_id)
    ));
  };

  const handleProjectSurveyChange = (selectedProjectSurveys) => {
    dispatch(setSelectedProjectSurvey('projectSurveys', selectedProjectSurveys))
  };

  const getSelectedEmployeeSurvey = () => {
    return employeesByTaxYear.filter((employeeSurvey) => (
      survey.employeeSurveys.find((employeeSurveyOfEmployee) => employeeSurvey.id === employeeSurveyOfEmployee.id)
    ));
  };

  const handleEmployeeSurveyChange = (selectedEmployeeSurveys) => {
    dispatch(setSelectedEmployeeSurvey('employeeSurveys', selectedEmployeeSurveys))
  };


  const handleViewSurveyClick = () => {
    const { id } = survey
    const win = window.open(`/survey-central/survey/${id}`, "_blank");
    win.focus();
  };

  const surveyIsCompleted = survey.surveyStatusRefId === SURVEY_STATUS_REF_ID_BY_NAME.completed.id;

  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleSurveyCreate,
      shouldCheckInputsValidity: true
    },
    {
      name:  surveyIsCompleted ? 'Reopen' : 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: surveyIsCompleted ? handleSurveyReopen : handleSurveyUpdate,
      color: 'secondary',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleSurveyDelete,
      color: 'secondary',
      shouldAskConfirmation: true
    },
    {
      name: 'View Survey',
      shouldDisplay: hasRoleAccessTo.viewSurveyButton && props.crudType === 'edit',
      onClick: handleViewSurveyClick,
    },
  ];

  let inputFields = buildSurveyInputFields(survey, {
    allProjectSurveys,
    allEmployeeSurveys: employeesByTaxYear,
    timePeriods,
    trackingTypes,
    surveyTypeId,
    userProxyIds,
    isEdit: props.crudType === 'edit',
    leaders,
    handleProjectSurveyChange,
    handleEmployeeSurveyChange,
    selectedProjectSurvey: getSelectedProjectSurvey(),
    selectedEmployeeSurvey: getSelectedEmployeeSurvey()
  });

  if (survey.surveyStatus === 'Completed') {
    inputFields = inputFields.map(field => ({
      ...field,
      muiProps: {
        ...(field.muiProps || {}),
        disabled: true
      }
    }))
  }

  return (
    <div>
      <FormBuilder
        fields={inputFields}
        onChange={handleChange}
        formActions={formActions}
      />
    </div>
  );
};

export default SurveysCrud;
