import React from 'react';
import PropTypes from 'prop-types';
import Fab from "@material-ui/core/Fab";
import classNames from 'classnames';
import { useStyles } from '../FileUpload/styles'
import CircularProgress from '@material-ui/core/CircularProgress';

const FileUpload = ({ onChange, buttonText, type, onClick, isLoading }) => {

  const styles = useStyles();
  const fabClassNames = classNames({
    [styles.squareFab]: true,
    [styles.squareButton]: true
  });
  const renderButtonByType = (type) => {

    const square = (
      <Fab
        className={fabClassNames}
        color="secondary"
        size="small"
        component="span"
        onClick={onClick}
        data-testid="reportUploadSquareButton">
        {buttonText}
      </Fab>
    )
 
    const round = (
      <Fab
        color="secondary"
        size="small"
        component="span"
        aria-label="add"
        variant="extended"
        onClick={onClick}
        className={styles.button}
        disabled={isLoading}
        data-testid="reportUploadRoundButton">
        {isLoading && <CircularProgress size={24} className={styles.loading} />}
        {buttonText}
      </Fab>
    )

    const uploadButtonTypes = {
      square,
      round
    }

    return uploadButtonTypes[type] || round
    

  }
  
  const resetFilePathOnChange = (event) => {
    event.target.value = ''
  };
  
  return (
    <div>
      <label htmlFor="uploadFile">
        <input
          style={{ display: "none" }}
          id="uploadFile"
          name="uploadFile"
          type="file"
          onChange={onChange}
          onClick={resetFilePathOnChange}
          disabled={isLoading}
          data-testid="reportUploadInput"
        />
        {renderButtonByType(type)}
      </label>
    </div>
  );
};

FileUpload.propTypes = {
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  buttonText: PropTypes.string
};

export default FileUpload;
