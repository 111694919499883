import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { apiClient } from '../../../../api/apiClient';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { handleError } from '../../../../actions/error';
import { tags } from '../../../../constants/promiseTracker';
import LoadingElement from '../../../../commonComponents/LoadingElement';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch } from 'react-redux';
import TextEditor from '../../../TextEditor';
import { editorConstants } from '../../../../constants/editor';

import useDebounce from '../../../../hooks/useDebounce';
import { changeSurveyDocumentsDescription } from '../../../../actions/surveyWizard/surveyWizard';

const useStyles = makeStyles((theme) => ({
  documentsSectionContainer: {
    height: '100%',
  },
  tableContainer: {
    height: '80%',
    overflow: 'auto'
  },
  title: {
    fontWeight: 600,
    fontSize: '1rem'
  },
  caption: {
    color: theme.palette.grey[700],
  },
  questionContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  cell: {
    whiteSpace: 'nowrap',
  },
  headerCell: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[900]
  },
  checkbox: {
    color: theme.palette.grey[600],
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    '&.Mui-checked:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main
    }
  },
  textArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& > .ql-container': {
      border: `0px`,
      minHeight: '6ch',
      '& .ql-editor': {
        overflow: 'auto',
        height: '20ch',
        border: `1px solid ${theme.palette.grey[300]}`,
        '&:focus': {
          border:`1px solid ${theme.palette.primary.light}`
        },
      }
    },
  },
}));

const initialDocumentsPerProjectState = [];

const DocumentsChecklist = ({ survey }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [documentsPerProject, setDocumentsPerProject] = React.useState(initialDocumentsPerProjectState);
  const [initialEditorContent, setInitialEditorContent] = React.useState(null);
  const { jurisdictionId, taxYear, surveyId } = survey;
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_DOCUMENTS_PER_PROJECT, delay: 0 });
  const [saveDocumentsDescriptionOn2sInactivity] = useDebounce((surveyId, documentsDescription) => {
    saveDocumentsDescription(surveyId, documentsDescription);
  }, 2000);

  useEffect(() => {
    setInitialEditorContent(survey.documentsDescription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataFetchInProgress]);
  useEffect(() => {
    const fetchDocumentsPerProject = async () => {
      try {
        const documentsPerProjectResult = await trackPromise(
          apiClient({
            method: 'get',
            url: `/survey/get-survey-documentation-per-project?surveyId=${surveyId}&taxYear=${taxYear}&jurisdictionId=${jurisdictionId}`,
          }),
          tags.FETCH_DOCUMENTS_PER_PROJECT
        );
        setDocumentsPerProject(documentsPerProjectResult);
      } catch (err) {
        setDocumentsPerProject([]);
        dispatch(handleError(err))
      }
    };

    if (jurisdictionId && taxYear && surveyId) {
      fetchDocumentsPerProject();
    } else {
      setDocumentsPerProject([]);
    }
  }, [jurisdictionId, surveyId, taxYear, dispatch]);

  const saveProjectDocumentation = async (projectDocument, project) => {
    try {
      await apiClient({
        method: 'post',
        url: `/survey/update-survey-documentation`,
        data: {
          surveyId,
          surveyDocumentation: {
            projectId: project.projectId,
            documentationRefId: projectDocument.documentationRefId
          }
        }
      });
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  const saveDocumentsDescription = async (surveyId, documentsDescription) => {
    try {
      await apiClient({
        method: 'patch',
        url: `/survey/${surveyId}/update-documents-description`,
        data: {
          documentsDescription,
        }
      });
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  const handleDocumentCheck = async (projectDocument, project) => {
    await saveProjectDocumentation(projectDocument, project);
  };

  const handleDocumentsDescriptionChange = (value) => {
    dispatch(changeSurveyDocumentsDescription(value));
    saveDocumentsDescriptionOn2sInactivity(surveyId, value);
  };

  const renderProjectsInHeaders = () => {
    const { projects } = documentsPerProject[0];

    return projects.map((project) => (
      <TableCell data-testid="header" key={project.projectId} className={styles.headerCell} align="center">{project.projectName}</TableCell>
    ));
  };

  const renderDocumentationCheckboxPerProject = (projectDocument) => {
    return projectDocument.projects.map((project) => (
      <TableCell key={project.projectId} className={styles.headerCell} align="center">
        <Checkbox
          data-testid="checkbox"
          defaultChecked={project.isChecked}
          onChange={() => handleDocumentCheck(projectDocument, project)}
          disableRipple
          className={styles.checkbox}
        />
      </TableCell>
    ))
  };

  const renderRows = () => {
    return documentsPerProject.map((projectDocument) => (
      <TableRow data-testid="row" key={projectDocument.documentationRefId}>
        <TableCell className={styles.cell}>{projectDocument.documentationName}</TableCell>
        {renderDocumentationCheckboxPerProject(projectDocument)}
      </TableRow>
    ));
  };

  if (isDataFetchInProgress || documentsPerProject === initialDocumentsPerProjectState) {
    return <LoadingElement />
  }

  if (!documentsPerProject.length) {
    return (
      <div>
        Documents not found for this survey
      </div>
    )
  }

  return (
    <div className={styles.documentsSectionContainer}>
      <div className={styles.questionContainer}>
        <Typography className={styles.title}>Select available documents</Typography>
        <Typography className={styles.caption}>Please select at least three types of documentation for each project. In the space below, please provide examples of how these documents display R&amp;D.</Typography>
      </div>

      { isDataFetchInProgress === false &&
        <TextEditor
          onChange={handleDocumentsDescriptionChange}
          data-testid="documentsDescription"
          initialEditorContent={initialEditorContent}
          maxAllowedCharacters={editorConstants.MAX_CHAR_LIMIT}
          height={260}
        />
      }

      <TableContainer className={styles.tableContainer} >
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell className={styles.headerCell}>Document</TableCell>
              {renderProjectsInHeaders()}
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRows()}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DocumentsChecklist;
