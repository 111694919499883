import React, { useState, cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import VirtualizedTable from '../../../commonComponents/VirtualizedTable';
import Button from '../../../commonComponents/Button';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCrudPanel } from '../../../actions/common';
import CrudPanel from '../../../commonComponents/CrudPanel';
import { Close } from '@material-ui/icons';
import { getIsCrudPanelOpen } from '../../../selectors/common';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingElement from '../../../commonComponents/LoadingElement';

const useStyles = makeStyles(theme => ({
  dataContainer: {
    display: 'flex'
  },
  tableContainer: {
    flex: 1,
    maxHeight: '67vh',
    overflowY: 'auto',
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: 'pointer',
  },
  preTableComponents: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  leftPreTableComponents: {
    flexGrow: 1,
  },
  rightPreTableComponents: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center'
  },
  createNewButton: {
    marginRight: theme.spacing(1)
  },
  selectMultiple: {
    color: theme.palette.grey[800],
    padding: theme.spacing(0.3)
  },
  tableButtons: {
    display: 'flex',
    alignItems: 'flex-end'
  }
}));

const TableAndCrud = ({
  tableHead,
  tableData,
  children,
  statusOptions,
  searchBy,
  searchByPlaceholder,
  initialOrderBy,
  tableRows,
  handleButtonClickEffect,
  formatConfig,
  maxTableHeight,
  hideCreateButton,
  renderComponentBeforeTable,
  idKey,
  createNewButtonTitle = 'Create New',
  shouldShowCheckBoxesOnClick = false,
  isDataFetchInProgress = false,
  bulkActions = {},
  cellWrappers = {}
}) => {
  const styles = useStyles();
  const isCrudPanelOpen = useSelector(getIsCrudPanelOpen);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const dispatch = useDispatch();


  useEffect(() => () => (
    dispatch(toggleCrudPanel(false))
  ), [dispatch]);

  const closeCrudPanel = () => {
    dispatch(toggleCrudPanel(false));
    setSelectedRow()
  }
  const openCrudPanel = (selectedRow) => {
    dispatch(toggleCrudPanel(true));
    setSelectedRow(selectedRow)
  }

  const handleRowClick = (row, crudType, selectOnlyOne) => {
    const isRowEditable = !('editable' in row) || row.editable;

    if (shouldShowCheckBoxesOnClick) {

      const isOneSelected = selectedRowsData.length === 1;
      const areTwoSelected = selectedRowsData.length === 2;
      const isAlreadyInSelectedData = selectedRowsData.find(selected => selected[idKey] === row[idKey]);
      if ((!selectedRowsData.length || selectOnlyOne) && isRowEditable) {
        openCrudPanel({ ...row, crudType })
      } else if (isOneSelected && isAlreadyInSelectedData) {
        closeCrudPanel()
      } else if ((areTwoSelected && isAlreadyInSelectedData) && isRowEditable) {
        openCrudPanel({ ...selectedRowsData.find(selected => selected[idKey] !== row[idKey]), crudType })
      } else {
        closeCrudPanel()
      }
    } else {
      if (isRowEditable) {
        openCrudPanel({ ...row, crudType })
      }
    }
  }

  const handleCreateRow = () => {
    dispatch(toggleCrudPanel(true));
    setSelectedRow({ crudType: 'create' })
    if (handleButtonClickEffect) {
      handleButtonClickEffect()
    }
  }
  const CreateNewButton = (buttonProps) => (
    <div className={styles.tableButtons}>
      {!hideCreateButton && <Button className={styles.createNewButton} onClick={handleCreateRow} {...buttonProps}>
        {createNewButtonTitle}
      </Button>}
    </div>
  )

  return (
    <div>
      <div className={styles.preTableComponents}>
        {shouldShowCheckBoxesOnClick && selectedRowsData.length > 0 && (
          <div className={styles.leftPreTableComponents}>
            <IconButton aria-label="delete" className={styles.selectMultiple} onClick={() => bulkActions.delete(selectedRowsData, setSelectedRowsData)}>
              <DeleteIcon fontSize="large" />
            </IconButton>
          </div>
        )}
        <div className={styles.rightPreTableComponents}>
          {
            renderComponentBeforeTable ?
              renderComponentBeforeTable(CreateNewButton)
              :
              <CreateNewButton />
          }
        </div>
      </div>
      <div className={styles.dataContainer}>
        <div className={styles.tableContainer}>
          {
            isDataFetchInProgress ? <LoadingElement />
              :
              <VirtualizedTable
                head={tableHead}
                data={tableData || []}
                handleRowClick={handleRowClick}
                statusOptions={statusOptions}
                searchBy={searchBy}
                searchByPlaceholder={searchByPlaceholder}
                initialOrderBy={initialOrderBy}
                tableRows={tableRows}
                formatConfig={formatConfig}
                maxTableHeight={maxTableHeight}
                shouldShowCheckBoxesOnClick={shouldShowCheckBoxesOnClick}
                selectedRowsData={selectedRowsData}
                setSelectedRowsData={setSelectedRowsData}
                setSelectedRow={setSelectedRow}
                idKey={idKey}
                cellWrappers={cellWrappers}
              />
          }
        </div>
        {isCrudPanelOpen && (
          <CrudPanel data-testid="crud-panel">
            <Close onClick={closeCrudPanel} className={styles.closeIcon} />
            {cloneElement(children, { ...selectedRow, closeCrudPanel })}
          </CrudPanel>
        )}
      </div>
    </div>
  )
};

TableAndCrud.propTypes = {
  tableHead: PropTypes.arrayOf(PropTypes.shape({})),
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node,
  statusOptions: PropTypes.arrayOf(PropTypes.string),
  searchBy: PropTypes.string,
  initialOrderBy: PropTypes.string,
  tableRows: PropTypes.arrayOf(PropTypes.string),
  handleButtonClickEffect: PropTypes.func,
  formatTypes: PropTypes.arrayOf(PropTypes.string),
  formatConfig: PropTypes.shape({}),
  maxTableHeight: PropTypes.string,
  createNewButtonTitle: PropTypes.string
}

export default TableAndCrud
