const validatePdfMimeType = async (file) => {
  const blob = file.slice(0, 4);
  const hex = await readBlobHexAsync(blob)
  if (!file || hex !== '25504446') {
    return { isPdf: false, message: 'Please upload a PDF file.' }
  } else {
    return { isPdf: true }
  };
}

function readBlobHexAsync(blob) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      const uint = new Uint8Array(reader.result)
      let bytes = []
      uint.forEach((byte) => {
        bytes.push(byte.toString(16))
      })
      const hex = bytes.join('').toUpperCase()

      resolve(hex);
    };

    reader.onerror = reject;
    reader.readAsArrayBuffer(blob);
  })
}
export default validatePdfMimeType;
