import React from 'react';

const PortalContainer = ({ ...props }) => {
  return (
    <div {...props}>

    </div>
  );
};

export default PortalContainer;