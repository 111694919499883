import {
  FETCH_SECTIONS_SUCCESS,
  FETCH_QUESTION_ANSWERS_SUCCESS,
  UPDATE_ANSWER,
  UPDATE_SECTION_COMPLETION_STATUS
} from '../constants/actionTypes/creditDetail';
import { CREDIT_DETAIL_INITIAL_STATE } from '../components/ServiceManager/CreditDetails/constants';

const mapQuestionDataWithQuestionId = (questions) => {
  const questionDataMap = {};
  questions.forEach((questionData) => {
    questionDataMap[questionData.id] = questionData;
  });

  return questionDataMap;
};

const creditDetail = (state = CREDIT_DETAIL_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        sections: action.sections,
      }
    case FETCH_QUESTION_ANSWERS_SUCCESS:
      return {
        ...state,
        // We map each question with its id so we can easily access the data in the state using that id.
        questionAnswers: mapQuestionDataWithQuestionId(action.questionAnswers),
      }
    case UPDATE_SECTION_COMPLETION_STATUS:
      const { sections } = state;
      const { creditDetailSectionRefId, isCompleted } = action;

      const updatedSection = sections.find((section) => section.creditDetailSectionRefId === creditDetailSectionRefId);
      const updatedSectionIndex = sections.indexOf(updatedSection);

      const updatedSections = [...sections];
      updatedSections[updatedSectionIndex] = { ...updatedSection, isCompleted };

      return {
        ...state,
        sections: updatedSections
      }
    case UPDATE_ANSWER:
      const { questionAnswers } = state;
      const { questionId, value } = action;

      return {
        ...state,
        questionAnswers: { ...questionAnswers, [questionId]: { ...questionAnswers[questionId], answer: { value } } }
      }
    default:
      return state;
  }
};

export default creditDetail;
