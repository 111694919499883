import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  title: {
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  listContainer: {
    width: '100%',
  },
  link: {
    textDecoration: 'none'
  },
  text: {
    flex: 'none',
    display: 'inline-block',
    color: theme.palette.grey[700]
  },
  icon: {
    minWidth: '60px',
    fill: theme.palette.grey[700]

  },
  item: {
    color: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  activeItem: {
    '& .MuiListItem-button': {
      backgroundColor: theme.palette.primary.veryLight
    },
    '& .MuiListItemText-root': {
      color: theme.palette.primary.main,
    },
    '& .MuiListItemIcon-root > svg': {
      fill: theme.palette.primary.main,
    }
  }
}));
