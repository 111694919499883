import {
  FETCH_SURVEYS_SUCCESS,
  SELECT_SURVEY,
  SET_SELECTED_SURVEY_FIELD,
  SET_SELECTED_PROJECT_SURVEY,
  SET_SELECTED_EMPLOYEE_SURVEY,
  FETCH_TIME_PERIODS_SUCCESS,
  FETCH_TRACKING_TYPES_SUCCESS,
  FETCH_USERS_PROXY_SUCCESS
} from '../constants/actionTypes/survey';
import { tags } from '../constants/promiseTracker';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message';
import { trackPromise } from "react-promise-tracker";

export const fetchSurveys = (surveyTypeId, entityId) => async (dispatch) => {
  try {
    const surveys = await trackPromise(
      apiClient({
        method: 'get',
        url: `/survey?typeId=${surveyTypeId}&entityId=${entityId}`,
      }),
      tags.FETCH_SURVEYS
    );
    dispatch(fetchSurveysSuccess(surveys));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const reopenSurvey = (surveyData) => async (dispatch) => {
  try {
    const { surveyTypeId, entityId, surveyId } = surveyData;
    await apiClient({
      method: 'patch',
      url: `/survey/reopen/${surveyId}`
    });
    dispatch(fetchSurveys(surveyTypeId, entityId));
    dispatch(showMessage('success', 'Survey reopened successfully', 3000));
  } catch (err) {
    dispatch(handleError(err));
  }
}

export const fetchTimePeriods = () => async (dispatch) => {
  try {
    const timePeriods = await apiClient({
      method: 'get',
      url: '/survey/time-period',
    });

    dispatch(fetchTimePeriodsSuccess(timePeriods));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchTrackingTypes = () => async (dispatch) => {
  try {
    const trackingTypes = await apiClient({
      method: 'get',
      url: '/survey/tracking-type',
    });

    dispatch(fetchTrackingTypesSuccess(trackingTypes));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchUserByContainer = (containerId) => async (dispatch) => {
  try {
    const userProxyIds = await apiClient({
      method: 'get',
      url: `/survey/user-container?containerId=${containerId}`,
    });
    
    dispatch(fetchUserProxyIds(userProxyIds));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const editSurvey = (surveyData, body) => async (dispatch) => {
  try {
    const { surveyTypeId, entityId, surveyId } = surveyData;
    await apiClient({
      method: 'patch',
      url: `/survey/${surveyId}`,
      data: body,
    });
    dispatch(fetchSurveys(surveyTypeId, entityId));
    dispatch(showMessage('success', 'Survey updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const deleteSurvey = (surveyData) => async (dispatch) => {
  const { surveyTypeId, entityId, surveyId } = surveyData;

  try {
    await apiClient({
      method: 'delete',
      url: `/survey/${surveyId}`,
    });
    await dispatch(fetchSurveys(surveyTypeId, entityId));
    dispatch(showMessage('success', 'Survey deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const createSurvey = (surveyData, body) => async (dispatch) => {
  try {
    const { surveyTypeId, entityId } = surveyData;
    await apiClient({
      method: 'post',
      url: '/survey',
      data: body,
    });
    dispatch(fetchSurveys(surveyTypeId, entityId));
    dispatch(showMessage('success', 'Survey created successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const selectSurvey = (selectedSurvey) => ({
  selectedSurvey,
  type: SELECT_SURVEY,
});

export const setSelectedSurveyField = (key, value) => ({
  type: SET_SELECTED_SURVEY_FIELD,
  value,
  key,
});

export const setSelectedProjectSurvey = (key, value) => ({
  type: SET_SELECTED_PROJECT_SURVEY,
  value,
  key,
});

export const setSelectedEmployeeSurvey = (key, value) => ({
  type: SET_SELECTED_EMPLOYEE_SURVEY,
  value,
  key,
});

export const fetchSurveysSuccess = (surveys) => ({
  type: FETCH_SURVEYS_SUCCESS,
  surveys,
});

export const fetchTimePeriodsSuccess = (timePeriods) => ({
  type: FETCH_TIME_PERIODS_SUCCESS,
  timePeriods,
});

export const fetchTrackingTypesSuccess = (trackingTypes) => ({
  type: FETCH_TRACKING_TYPES_SUCCESS,
  trackingTypes,
});

export const fetchUserProxyIds = (userProxyIds) => ({
  type: FETCH_USERS_PROXY_SUCCESS,
  userProxyIds,
});
