import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import roundValueToGivenNumDecimalPlaces from '../../../../utils/roundValueToGivenNumDecimalPlaces';

// ACTIONS
import { getAllocatedProjects, addProjectAllocation  } from '../../../../actions/expense';

// SELECTORS
import { getAllocatedProjects as getProjects, getTotalAllocated, getProjectsToAllocate } from '../../../../selectors/expense';
import { getSelectedEntityProjects } from '../../../../selectors/common'
import AllocatedProject from '../AllocatedProject';

// COMPONENTS
import {Typography } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import Figure from '../Figure';

// CONSTANTS
import {
  MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION,
  NO_PROJECTS_TO_ALLOCATE,
  NO_REMAINING_TO_ALLOCATE
} from '../constants';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
  },
  title: {
    margin: theme.spacing(1)
  },
  containerFigures: {
    display: 'flex',
    justifyContent: 'space-around'

  },
  figureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'

  },
  figure: {
    width: '40px',
    height: '40px',
    background:'lightGrey',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'

  },
  containerProjects: {
    padding: theme.spacing(1)
  },
  addItem: {
    color: theme.palette.grey[900],
    border: '1px dashed',
    borderColor: theme.palette.grey[400],
    backgroundColor: theme.palette.grey[100],
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  addItemEnabled: {
    cursor: 'pointer',
    transition: '1s',
    '&:hover': {
      background: theme.palette.grey[400],
   },
  },
  addItemMessages: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const ProjectsAllocation = ({expenseId, qualifiedPercent}) => {
  const style = useStyles();
  const dispatch = useDispatch();
  const projectsToAllocate = useSelector(getProjectsToAllocate, shallowEqual)
  const entityProjects = useSelector(getSelectedEntityProjects, shallowEqual)
  const allocatedProjects = useSelector(getProjects, shallowEqual)
  const totalAllocated = useSelector(getTotalAllocated)
  useEffect(()=>{
    if(expenseId) {
      dispatch(getAllocatedProjects(expenseId))
    }
  },[dispatch, expenseId])

  const handleAdd = () => {
    dispatch(addProjectAllocation(expenseId));
  }

  const totalRemaining = () => {
    let remaining = 0;
    if(totalAllocated && roundValueToGivenNumDecimalPlaces(totalAllocated,2) < MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION) {
      remaining =  MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION - roundValueToGivenNumDecimalPlaces(totalAllocated,2);
    }
    return remaining;
  }

  return (
    <div className={style.container}>
      <Typography variant="h6" align={'center'} className={style.title}>Allocate expense to project</Typography>
      <div className={style.containerFigures}>
        <Figure value={qualifiedPercent} title={'Total qualifying'} valueType={'qualifying'} />
        <Figure value={roundValueToGivenNumDecimalPlaces(totalAllocated, 2)} title={'Allocated'} valueType={'allocated'}  />
        <Figure value={roundValueToGivenNumDecimalPlaces(totalRemaining(),2)} title={'Remaining'} valueType={'remaining'} />
      </div>
      <div className={style.containerProjects}>
        {allocatedProjects && allocatedProjects.map((project, index) =>
            <AllocatedProject key={`allocate-${index}`}
              projects={projectsToAllocate}
              project={project}
              index={index}
              entityProjects={entityProjects}
              remainingToAllocate={MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION - totalAllocated}
            />)}
    
        <div 
        className={`${style.addItem} ${projectsToAllocate.length && totalAllocated < MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION ? style.addItemEnabled : null}`}
        role={'button'} 
        tabIndex={projectsToAllocate.length && totalAllocated < MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION ? 0 : null }
        onClick={projectsToAllocate.length && totalAllocated < MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION ? handleAdd : null}
        >
          {Boolean(projectsToAllocate.length) && totalAllocated < MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION &&  <AddCircle fontSize={'small'}/>}
          <div className={style.addItemMessages}>
          {!Boolean(projectsToAllocate.length) && <Typography variant={'caption'}>{NO_PROJECTS_TO_ALLOCATE}</Typography>}
          {totalAllocated === MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION && <Typography variant={'caption'}>{NO_REMAINING_TO_ALLOCATE}</Typography>}
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectsAllocation.propTypes = {
  expenseId: PropTypes.number,
  qualifiedPercent: PropTypes.number,
}

export default ProjectsAllocation;
