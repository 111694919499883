const IS_ENABLED_OPTIONS = [
  {
    name: 'Enabled',
    value: 1
  },
  {
    name: 'Disabled',
    value: 0
  },
];

const setUserProjectsHelperText = (selectedEntity) => {
  if (typeof selectedEntity === 'undefined') {
    return 'Please select an entity in order to view the projects that can be assigned to this user'
  }
};

export const buildUserInputFields = (user, inputData) => [
  {
    name: 'username',
    label: 'Username',
    type: 'Input',
    value: user.username,
    required: true,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'Input',
    value: user.email,
    validationOptions: {
      regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      regexErrorMessage: 'You must enter a valid email',
    },
    required: true
  },
  {
    name: 'enabled',
    value: Number(user.enabled),
    label: 'Enable',
    type: 'Selector',
    options: IS_ENABLED_OPTIONS,
    optionKeys: { name: 'name', value: 'value' },
    required: true,
  },
  {
    label: 'Roles',
    type: 'MultipleSelect',
    options: inputData.allRoles,
    selectedOptions: inputData.selectedRoles,
    optionKeys: { name: 'role_name', value: 'id' },
    onChange: inputData.handleRoleChange,
    maxChips: 1,
    muiProps: {
      'data-testid': 'user-crud-roles'
    },
  },
  {
    label: 'Containers',
    type: 'MultipleSelect',
    options: inputData.userContainers,
    selectedOptions: inputData.selectedUserContainers,
    optionKeys: { name: 'name', value: 'container_id' },
    onChange: inputData.handleUserContainerChange,
    maxChips: 3
  },
  {
    label: 'User Projects',
    type: 'MultipleSelect',
    options: inputData.entityProjects,
    selectedOptions: inputData.validProjects,
    optionKeys: { name: 'name', value: 'project_id' },
    onChange: inputData.handleProjectChange,
    maxChips: 1,
    helperText: setUserProjectsHelperText(inputData.selectedEntity)
  }
];
