import { makeStyles } from "@material-ui/core/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

const UseStyles = makeStyles((theme) => ({
  WrapperFilter: {
    width: '100%',
    maxWidth: '32%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    position: 'relative'
  },
  FilterBox: {
    width: '100%',
    boxSizing: "border-box",
    backgroundColor: `${theme.palette.grey[100]}`,
    padding: '0 5px',
    borderRadius: '4px',
    '& .MuiInput-input em': {
      fontStyle: 'normal'
    },
    "&:hover": {
      "&:before": {
        border: '0 !important'
      }
    },
    "&:before": {
      border: 0
    },
    "&:focus": {
      border: 0,
      backgroundColor: `${theme.palette.primary.veryLight}`,
      color: `${theme.palette.primary.main}`,
    }
  },
  FilterBoxSelected: {
    backgroundColor: `${theme.palette.primary.veryLight}`,
    color: `${theme.palette.primary.main}`,
    '& .MuiSelect-icon': {
      color: `${theme.palette.primary.main}`,
    }
  },
  FilterClearButton: {
    position: 'absolute',
    right: 5,
    width: 10,
    height: 10,
    backgroundColor: `${theme.palette.primary.light}`,
    color: `${theme.palette.primary.main}`,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.light}`,
      color: `${theme.palette.primary.dark}`,
    },
    "& svg": {
      width: 15
    }
  }
}));

export { MenuProps, UseStyles };
