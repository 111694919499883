import {
  FETCH_ENTITY_DETAIL_TYPES_SUCCESS,
  FETCH_ENTITY_DETAILS_SUCCESS,
  SELECT_ENTITY_DETAIL_CRUD,
  SET_SELECTED_ENTITY_DETAIL_FIELD,
} from '../../constants/actionTypes/entityInfo';
import { ENTITY_DETAIL_INITIAL_STATE } from '../../components/ServiceManager/EntityInfo/EntityDetail/constants';

const entityInterviews = (state = ENTITY_DETAIL_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ENTITY_DETAILS_SUCCESS:
      return {
        ...state,
        list: action.entityDetails,
      };
    case SELECT_ENTITY_DETAIL_CRUD:
      return {
        ...state,
        selected: action.selectedEntityDetail,
      };
    case FETCH_ENTITY_DETAIL_TYPES_SUCCESS:
      return {
        ...state,
        listDetailTypes: action.entityDetailTypes,
      };
    case SET_SELECTED_ENTITY_DETAIL_FIELD:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.key]: action.value,
        },
      };
    default:
      return state;
  }
};

export default entityInterviews;
