import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';
import { ContactSupport } from '@material-ui/icons';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TextEditor from '../../components/TextEditor';

const Question = ({
  question,
  title,
  answer,
  type,
  onBlur = () => {},
  onChange,
  options,
  id,
  entityKey,
  name,
  textboxPlaceholder,
  descriptionPlaceholder,
  descriptionEnabled,
  maxAllowedCharacters,
}) => {
  const styles = useStyles();
  const [initialEditorContent, setInitialEditorContent] = useState(null);
  useEffect(() => {
    setInitialEditorContent(answer.description);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityKey]);

  const renderTextType = () => (
    <TextEditor
    onBlur={(content) => onBlur(content, id, 'description')}
      onChange={(content) => onChange(content, id, 'description')}
      FormatApply={(content) => onChange(content, id, 'description')}
      FormatRemove={(content) => onChange(content, id, 'description')}
      data-testid="questionTextEditor"
      initialEditorContent={initialEditorContent}
      placeholder={textboxPlaceholder}
      height={260}
      hideToolbar={false}
      maxAllowedCharacters={maxAllowedCharacters}
    />
  );

  const renderRadioSelection = () => (
    <FormControl component="fieldset">
      <RadioGroup name={name} onChange={(e) => onChange(e.target.value === 'true', id, 'value')} value={answer.value}>
        {options.map((option, i) => (
          <FormControlLabel
            key={i}
            name={name}
            id={id}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.text}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  const renderQuestionType = (questionType) => {
    const questionTypes = {
      text: renderTextType,
      simpleSelect: renderRadioSelection
    };
    return questionTypes[questionType]()
  };

  return (
    <div className={styles.question}>
      <ContactSupport className={styles.questionIcon} />
      <div className={styles.questionForm}>
        {title && <Typography className={styles.title} align={'center'} variant={'h5'}>{title}</Typography>}
        <Typography variant={'h6'}>{question}</Typography>
        {renderQuestionType(type)}
        {descriptionEnabled &&
        <>
          <Typography className={styles.description.title} variant={'subtitle1'}>Description</Typography>
          <TextEditor
            onBlur={(content) => onBlur(content, id, 'description')}
            onChange={(content) => onChange(content, id, 'description')}
            data-testid="questionTextEditor"
            initialEditorContent={initialEditorContent}
            placeholder={descriptionPlaceholder}
            height={260}
            hideToolbar={false}
            maxAllowedCharacters={maxAllowedCharacters}
          />
        </>
        }
      </div>
    </div>
  );
};

Question.propTypes = {
  question: PropTypes.string,
  title: PropTypes.string,
  answer: PropTypes.object,
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  id: PropTypes.number,
  name: PropTypes.string,
  commentsEnabled: PropTypes.bool,
  description:PropTypes.string,
  descriptionPlaceholder: PropTypes.string,
};

export default Question;
