import React, { useEffect, useState, useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import EditorIcons from './EditorIcons';
import { apiClient } from '../../api/apiClient';

const TextEditor = ({
  onBlur,
  onChange,
  initialEditorContent,
  icons = [],
  onSetupActions,
  maxAllowedCharacters,
  height = 500,
  hideToolbar,
  placeholder,
}) => {
  const editorRef = useRef(null);
  let currentEditorContent = initialEditorContent;
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    const fetchApiKey = async () => {
      const apiKeyResult = await apiClient({
        method: 'get',
        url: `/utils/tiny-mce`,
      });

      if (apiKeyResult && apiKeyResult.apiKey) {
        setApiKey(apiKeyResult.apiKey);
      }
    };

    if (!apiKey) {
      fetchApiKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setup = (editor) => {
    // Adding icons - load defaults from EditorIcons.js then icons from props
    for (const item of EditorIcons) {
      editor.ui.registry.addIcon(item.name, item.icon);
    }
    for (const item of [...icons, ...(onSetupActions?.icons ?? [])]) {
      editor.ui.registry.addIcon(item.name, item.icon);
    }

    editor.ui.registry.addMenuButton('sizeandcolor', {
      icon: 'change-case',
      fetch: (callback) => {
        const items = 'blockformats fontsizes forecolor backcolor';
        callback(items);
      }
    });

    // Adding toggle buttons
    if (onSetupActions?.toggleButtons) {
      for (const item of onSetupActions.toggleButtons) {
        editor.ui.registry.addToggleButton(item.name, {
          icon: item.icon,
          tooltip: item.tooltip,
          onAction: item.onAction
        });
      }
    }
  };

  const onChangeEvent = () => {
    const content = editorRef.current.getContent();
    if (maxAllowedCharacters && content.length > maxAllowedCharacters) {
      alert(`Description is too long. The character limit is ${maxAllowedCharacters}. Sorry for the inconvenience.`);
      editorRef.current.setContent(currentEditorContent);
    } else {
      currentEditorContent = content;
      onChange(content);
    }
  }

  const toolbar = hideToolbar ? false : 'typeStuff sizeandcolor | bullist numlist | aligntext | table | spellchecker | remainder | undo redo';

  return (
    apiKey ?
      <Editor
        apiKey={apiKey}
        onInit={function (evt, editor) {
          editorRef.current = editor;
          editor.on('keyup', function () {
            onChangeEvent();
          })
          editor.on('paste', function () {
            onChangeEvent();
          })
          editor.on('cut', function () {
            onChangeEvent();
          })
          editor.on('blur', function () {
            if (onBlur) {
              onBlur();
            } else {
              onChangeEvent();
            }
          })
        }}
        initialValue={initialEditorContent}
        placeholder={placeholder}
        init={{
          selector: 'textarea',
          height,
          menubar: false,
          plugins: [
            'lists link image charmap print preview anchor',
            'searchreplace visualblocks fullscreen',
            'insertdatetime media table paste wordcount',
            'table tinymcespellchecker'
          ],
          toolbar,
          toolbar_groups: {
            aligntext: {
              icon: 'align-left',
              tooltip: 'alignment',
              items: 'alignleft aligncenter alignright alignjustify'
            },
            typeStuff: {
              icon: 'format-icon',
              tooltip: 'formatting',
              items: 'bold italic underline strikethrough | subscript superscript'
            },
            remainder: {
              icon: 'more-drawer',
              tooltip: 'Additional Options',
              items: 'outdent indent link removeformat'
            }
          },
          setup,
          content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 13px; }',
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt',
          branding: false,
          statusbar: false,
          indentation: '20pt',
        }}
      /> : null
  );
}

export default TextEditor;