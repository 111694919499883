import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// actions
import { acceptLicenseAgreement } from '../../actions/auth';

//MUI dependencies
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

// styles
import useStyles from './styles';


const EulaCheckbox = withStyles({
  root: {
    color: 'white',
    '&$checked': {
      color: 'white'
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Eula = () => {
  const dispatch = useDispatch();
  const {
    container,
    formGroup,
    button,
    eulaError,
    eulaContent
  } = useStyles();

  const [isEulaChecked, setIsEulaChecked] = useState(false);
  const [shouldShowEulaError, setShouldShowEulaError] = useState(false);

  const handleClick = async () => {
    if (!isEulaChecked) {
      setShouldShowEulaError(true);
      return;
    }
    dispatch(acceptLicenseAgreement());
  };

  const handleEulaChange = () => {
    setIsEulaChecked(!isEulaChecked);
  };

  return (
    <div className={container}>
      <div className={eulaContent}>
        <div className={formGroup}>
          <section>
            <h1 className="text-center">
            EXACTERA'S SOFTWARE-AS-A-SERVICE (SAAS) AGREEMENT – TERMS &amp; CONDITIONS
          </h1>

            <p>
              The following terms and conditions apply to the legal agreement formed between Exactera
          (&#8220;<strong><em>Provider</em></strong>&#8221;) and the customer executing
                                                                                                                    an Order Form that is accompanied by or references this document
          (&#8220;<strong><em>Customer</em></strong>&#8221;).
        </p>
            <p>
              <strong>1. DEFINITIONS. </strong>
              As used in this Agreement:
        </p>
            <p>
              &#8220;<strong><em>Agreement</em></strong>&#8221; means the
              Software-as-a-Service agreement between Customer and Provider,
              consisting of these Terms and Conditions, including any attached
              exhibits referenced herein, any Order Form signed by Customer and
              Provider that accompanies or references this document, and any
              supplementary Statements of Work entered into by Customer and Provider
              hereunder for the provision of Professional Services by Provider.
        </p>
            <p>
              &#8220;<strong><em>Applicable Laws</em></strong>&#8221; means all
              legislation, statutes, regulations, ordinances, rules, judgments,
              orders, decrees, rulings, and other requirements enacted, promulgated,
              or imposed by any governmental authority or judicial or regulatory body
              (including any self-regulatory body) at any level (e.g., municipal,
              county, provincial, state or national) that are applicable to or
              enforceable against a party or its personnel in relation to their
              activities under or pursuant to this Agreement.
        </p>
            <p>
              &#8220;<strong><em>Authorized Customer Entities</em></strong>&#8221;
              means Customer-affiliated entities named in an Order Form who
              are authorized to access and use the Service during the Subscription
              Term under Customer&#8217;s subscription.
        </p>
            <p>
              &#8220;<strong><em>Authorized User(s)</em></strong>&#8221; means end
              users of Customer and Authorized Customer Entities who have completed
              Provider&#8217;s online registration process or who otherwise receive a
              user ID or other access credentials from Provider or Customer
              authorizing them to access and use the SaaS.
        </p>
            <p>
              &#8220;<strong><em>Authorized Purpose(s)</em></strong>&#8221; means
              those purposes set forth in an Order Form or in Provider&#8217;s proposal
              describing the purposes for which the applicable SaaS and
              associated Content are permitted to be used. If no Authorized Purpose
              is stated, the Authorized Purpose shall be limited to use of the SaaS
              in Customer&#8217;s and Authorized Customer Entities&#8217; internal
              business operations.
        </p>
            <p>
              &#8220;<strong><em>Confidential Information</em></strong>&#8221; means
              all non-public written or oral information, disclosed by either Party
              to the other, related to the business or operations of either Party or
              a third party that has been identified as confidential or that by the
              nature of the information or the circumstances surrounding its
              disclosure ought reasonably to be understood as being confidential.
        </p>
            <p>
              &#8220;<strong><em>Content</em></strong>&#8221; means any data, media,
              information and/or other type or form of content displayed, distributed
              or otherwise made available to a Party through or in connection with
              the SaaS or other Services, including User Content and Provider
              Content.
        </p>
            <p>
              &#8220;<strong><em>Customer Data</em></strong>&#8221; means any data
              owned by Customer or an Authorized Customer Entity that is submitted to
              the Service for processing, transmission, and/or storage.
        </p>
            <p>
              &#8220;<strong><em>Data Privacy and Security Laws</em></strong>&#8221;
              means all applicable federal, state, regional, territorial and local
              laws, statutes, ordinances, regulations, rules, executive orders, of or
              by any United States federal or state government entity, or any
              authority, department or agency thereof governing the privacy, data
              protection and security of Personally Identifiable Information and
              security breach notification relating to Personally Identifiable
              Information, and any other laws in force in any jurisdiction
              (regulatory or otherwise) in which the SaaS is being utilized,
              including Title V of the Gramm-Leach-Bliley Act of 1999 (Public Law
              106-102, 113 Stat. 1338), as may be amended from time to time, and its
              implementing regulations, and the &#8220;Interagency Guidelines
              Establishing Standards for Safeguarding Customer Information&#8221;
              (Exhibit B to 12 CFR Part 364).
        </p>
            <p>
              &#8220;<strong><em>Including</em></strong>&#8221; (and its derivative
              forms, whether or not capitalized) means including without limitation.
        </p>
            <p>
              <em>&#8220;<strong>Intellectual Property Rights</strong></em>
              &#8221; means the legal rights held by the owner of a copyright,
              patent, trademark, or trade secret, including (i) the rights to copy,
              publicly perform, publicly display, distribute, adapt, translate,
              modify and create derivative works of copyrighted subject matter; (ii)
              the rights to exclude others from using, making, having made, selling,
              offering to sell, and importing patented subject matter and to practice
              patented methods, (iii) the rights to use and display any marks in
              association with businesses, products or services as an indication of
              ownership, origin, affiliation, or sponsorship; and (iv) the rights to
              apply for any of the foregoing rights, and all rights in those
              applications. Intellectual Property Rights also include any and all
              rights associated with particular information that are granted by law
              and that give the owner, independent of contract, exclusive authority
              to control use or disclosure of the information, including privacy
              rights and any rights in databases recognized by applicable law.
        </p>
            <p>
              <em>&#8220;<strong>Losses</strong></em>&#8221;
              means, in connection with a Claim that is subject to defense and
              indemnification by a Party under this Agreement, all reasonable
              attorneys&#8217; fees, reasonable costs of investigation, discovery,
              litigation and settlement, and any resulting liabilities, damages,
              settlements, judgments and awards, including associated taxes, interest
              and penalties.
        </p>
            <p>
              &#8220;<strong><em>No Pay Safe Start Period</em></strong>&#8221; means a limited
               time period during which Customer has the right to cancel its Order Form,
               subject to specific conditions set forth in Clause 6.2 No Pay Safe Start
               Period Order Form Cancellation.
        </p>
            <p>
              &#8220;<strong><em>Order Form</em></strong>&#8221; means the proposal
              order form issued by Provider and executed by Customer and Provider
              setting forth the necessary information relating to the SaaS and/or
              other Services to be provided to Customer under this Agreement and the
              fees payable to Provider.
        </p>
            <p>
              &#8220;<strong><em>Professional Services</em></strong>&#8221; means any
              professional services performed or contracted to be performed by
              Provider pursuant to a Statement of Work entered into by the Parties
              under this Agreement in Exhibit B Professional Services Statement of Work. <em></em>
            </p>
            <p>
              <em>&#8220;<strong>Provider Content</strong>&#8221;</em> means Content owned,
              originated or controlled by Provider that is made
              accessible to Customer and Authorized Customer Entities via the SaaS or
              other Services.
        </p>
            <p>
              <strong><em>&#8220;Provider&#8217;s Web Site&#8221; </em></strong>
              means the web interface of the SaaS platform that Provider offers for
              interaction with and receipt of the Services.
        </p>
            <p>
              &#8220;<strong><em>SaaS</em></strong>&#8221; means Provider&#8217;s
              proprietary web-based software-as-a-service platform and related
              services made available for use by Authorized Users under this
              Agreement, as identified on the applicable Order Form, including its
              technology components, such as Provider&#8217;s Web Site and related
              documentation.
        </p>
            <p>
              &#8220;<strong><em>Services</em></strong>&#8221; means, collectively,
              the SaaS, the Support Services, and any Professional Services performed
              or provided by Provider pursuant to this Agreement.
        </p>
            <p>
              &#8220;<strong><em>Statement of Work</em></strong>&#8221;
          (or &#8220;<strong><em>SOW</em></strong>&#8221;) means Exhibit B of this document.
        </p>
            <p>
              &#8220;<strong><em>Subscription Fees</em></strong>&#8221; means the
              non-recurring and recurring fees payable by Customer to Provider for
              the SaaS and associated Support Services, as set forth in the relevant
              Order Form, which shall be payable in accordance with the payment terms
              set forth in the Order Form. Unless and except as otherwise expressly
              stated in this Agreement, the Subscription Fees are non-cancellable and
              non-refundable.
        </p>
            <p>
              &#8220;<strong><em>Subscription Term</em></strong>&#8221; means the
              period during which Customer&#8217;s Authorized Users are permitted to
              access and use the SaaS, as set forth in the applicable Order Form.
        </p>
            <p>
              &#8220;<strong><em>Support Services</em></strong>&#8221; has the
              meaning given in Section 3.
        </p>
            <p>
              &#8220;<strong>Territory</strong>&#8221; means worldwide unless
              otherwise specified in an Order Form.
        </p>
            <p>
              &#8220;<strong><em>Update</em></strong>&#8221; means any improvement,
              enhancement, modification and/or changes to the SaaS offered or
              provided by Provider to its subscribers at no charge.
        </p>
            <p>
              &#8220;<strong><em>User Content</em></strong>&#8221; means any Content
              submitted, posted or displayed by Authorized Users of the SaaS.
        </p>
            <p>
              &#8220;<strong><em>User Data</em></strong>&#8221; means any data or
              information (other than User Content) received or collected by Provider
              concerning Authorized Users of the SaaS, including data provided by
              Authorized Users to register to use the SaaS.
        </p>
            <p>
              <strong>2. ACCESS TO AND USE OF THE SAAS. </strong>
            </p>
            <p>
              <strong>2.1. Limited-Purpose Access Grant. </strong>
              Subject to Customer&#8217;s and its Authorized Users&#8217; continuing
              compliance with this Agreement and payment of the applicable fees,
              Provider hereby grants to Customer a limited, personal, non-exclusive,
              non-transferable right for Authorized Users of Customer and any other
              Authorized Customer Entities to access the features and functions of
              the SaaS in the Territory during the Subscription Term, solely through
              Provider&#8217;s Web Site and solely for the Authorized Purpose(s).
              This access grant may not be sublicensed, in whole or in part. The
              scope of Customer&#8217;s use of the SaaS is subject to the terms and
              conditions of this Agreement, including any usage or other parameters
              or limitations set forth in the applicable Order Form.
        </p>
            <p>
              <strong>2.2 Access Protocols. </strong>
              On or as soon as reasonably practicable after the execution of this
              Agreement, Provider shall provide to Customer the necessary access
              credentials and protocols to allow Authorized Users to access the SaaS
          (the &#8220;<strong><em>Access Protocols</em></strong>&#8221;).
                                      Customer acknowledges and agrees that, as between Customer and
                                      Provider, Customer shall be responsible for all acts and omissions of
                                      Authorized Users, including any act or omission by an Authorized User,
                                      which, if undertaken by Customer, would constitute a breach of this
                                      Agreement and any act by a person (whether or not an Authorized User)
                                      using Customer&#8217;s Access Protocols. Customer shall undertake
                                      reasonable efforts to make all Authorized Users aware of the provisions
                                      of this Agreement that are applicable their use of the SaaS and shall
                                      cause them to comply with such provisions.
        </p>
            <p>
              <strong>2.3. Company Account Administration. </strong>
              Customer shall designate at least one Authorized User to act as
              Customer&#8217;s principal point of contact with Provider for purposes
              of this Agreement.
        </p>
            <p>
              <strong>2.4. Content. </strong>
              The SaaS may enable Customer&#8217;s Authorized Users to search for,
              find, store, manage and use Content of interest that is provided or
              made accessible through the SaaS. Customer acknowledges that Provider
              does not endorse, support, represent or guarantee the completeness,
              truthfulness, accuracy, reliability or other attributes of any Content,
              nor does Provider review or attempt to verify the accuracy or currency
              of any Content other than Provider Content. As between Customer and
              Provider, Customer is solely responsible for (i) determining the
              suitability of any Content for its intended use by Customer, and (ii)
              as necessary for its intended use, verifying the authenticity,
              integrity, and accuracy of the User Content prior to using it. Provider
              has no obligation to preview, verify, flag, modify, filter or remove
              any Content other than Provider Content. Provider may remove or disable
              access to any Content at its sole discretion, but is not responsible
              for any failures or delays in removing or disabling access to any
              Content unless otherwise provided herein, including Content that may be
              considered harmful, inaccurate, unlawful or otherwise objectionable.
        </p>
            <p>
              <strong>2.5. Compliance. </strong>
              (a) Customer&#8217;s and its Authorized Users&#8217; access to and use
              of the SaaS is subject to their continuing compliance with all of
              the terms and conditions set forth in this
              Agreement and Applicable Laws, including Data Privacy and Security Laws.
        </p>
            <p>
              (b) In addition to complying with applicable Data Privacy and Security
              Laws, Provider will employ commercially reasonable security and access
              controls designed to protect the types of data collected and stored by
              the Service, including Personally Identifiable Information.
        </p>
            <p>
              <strong>2.6. Restrictions. </strong>
              Customer agrees not to act outside the scope of the rights that are
              expressly granted by Provider in this Agreement. Further, Customer will
              not (i) use the SaaS in any manner that is inconsistent with this
              Agreement; (ii) access or use the SaaS or in order to develop or
              support, or assist another party in developing or supporting, any
              products or services competitive with the SaaS; (iii) decompile,
              reverse engineer (unless required by law for interoperability), or use
              any other method in an attempt to view or recreate any of the source
              code of the SaaS or extract any trade secrets from it; (iv) use the
              SaaS to operate the business of a third party or to process data or
              content provided by a third party for the operation of a third
              party&#8217;s business, or otherwise use the SaaS on a third
              party&#8217;s behalf, or to act as a service bureau or provider of
              application services to any third party; (v) knowingly or intentionally
              re-use, disseminate, copy, or otherwise use the SaaS or associated
              Content in a way that infringes, misappropriates, or violates any
              trademark, copyright, patent, trade secret, publicity, privacy or other
              right of Provider or any third party; or (vi) sell, lend, lease,
              assign, transfer, pledge, permit a lien upon, or sublicense any of the
              rights granted by this Agreement with respect to the SaaS.
        </p>
            <p>
              <strong>2.7. No Interference with Service Operations. </strong>
              Customer and its Authorized Users will not take any action designed or
              intended to: (a) interfere with the proper working of the SaaS or (b)
              circumvent, disable, or interfere with security-related features of the
              SaaS or features that prevent or restrict use, access to, or copying
              the SaaS or any Content or other data, or that enforce limitations on
              use of the SaaS or Content.
        </p>
            <p>
              <strong>3. SUPPORT SERVICES; PROFESSIONAL SERVICES. </strong>
            </p>
            <p>
              <strong>3.1. </strong>
              <strong>Technical Support. </strong>
              At no additional charge and during Provider&#8217;s normal business
              hours (which are 9:00 a.m. to 6:00 p.m. Eastern Time and 9:00 – 17:00 GMT,
              Monday through
              Friday, excluding Provider-designated holidays unless otherwise
              specified in the applicable Order Form), Provider will provide
              reasonable technical support and assistance for Authorized User
              requests by telephone or sent via email to customer support. Provider
              may also offer upgraded support services for an additional fee.
        </p>
            <p>
              <strong>3.2. Updates. </strong>
              Customer will be given access to Updates of the SaaS that Provider
              implements during the Subscription Term. Customer acknowledges,
              however, that Provider may in the future offer optional value-added
              functions, features, or other capabilities for a separate fee.
        </p>
            <p>
              <strong>3.3. </strong>
              <strong>Scheduled Maintenance. </strong>
              Provider reserves the right to take down applicable servers hosting the
              SaaS to conduct scheduled and emergency maintenance. Provider will use
              commercially reasonable efforts to perform scheduled maintenance
              outside regular business hours and will provide at least 24 hours&#8217;
              advance notice for non-emergency maintenance. Provider will not be
              responsible for any damages or costs incurred by Customer due to
              unavailability of the SaaS during scheduled or emergency maintenance.
        </p>
            <p>
              <strong>3.4. Professional Services. </strong>
              Provider has agreed to perform the Professional Services as specified
              in Exhibit B Professional Services Statement of Work.
        </p>
            <p>
              <strong>4. FEES. </strong>
            </p>
            <p>
              Provider&#8217;s fees for the Services are as set forth in the
              applicable Order Form and shall be payable as stated in the Order Form.
        </p>
            <p>
              <strong>5. ALLOCATIONS OF RISK. </strong>
            </p>
            <p>
              <strong>5.1. Representations and Warranties. </strong>
              (a) Each Party represents to the other (i) that the execution and
              performance of its obligations under this Agreement will not conflict
              with or violate any provision of Applicable Law or any other agreement
              or order by which the representing Party is bound; and (ii) that this
              Agreement, when executed and delivered, will constitute a valid and
              binding obligation of such Party and will be enforceable against such
              Party in accordance with its terms.
        </p>
            <p>
              (b) Provider warrants that any Professional Service performed by
              Provider under this Agreement will be performed in a good and
              workmanlike manner in accordance with prevailing industry standards. In
              the event of a breach of this warranty, Provider&#8217;s sole
              obligation and Customer&#8217;s sole remedy will be for Provider to
              correct or re-perform the affected Professional Service without undue
              delay to remedy the breach, at no charge to Customer.
        </p>
            <p>
              <strong>
                5.2. DISCLAIMERS. (a) CUSTOMER REPRESENTS THAT IT IS ENTERING THIS
                AGREEMENT WITHOUT RELYING UPON ANY PROVIDER REPRESENTATION OR
                WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT. TO THE MAXIMUM
                EXTENT PERMITTED BY APPLICABLE LAW, PROVIDER DISCLAIMS ANY AND ALL
                PROMISES, REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR
                STATUTORY, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, DATA ACCURACY,
                SYSTEM INTEGRATION, SYSTEM RELIABILITY, TITLE, NON-INFRINGEMENT,
                NON-INTERFERENCE AND/OR QUIET ENJOYMENT, AND ALL WARRANTIES THAT
                MAY OTHERWISE BE IMPLIED. NO WARRANTIES ARE MADE ON THE BASIS OF
                TRADE USAGE, COURSE OF DEALING, OR
          </strong>
              <strong> COURSE OF </strong>
              <strong>PERFORMANCE. </strong>
            </p>
            <p>
              <strong>
                (b) CUSTOMER ASSUMES COMPLETE RESPONSIBILITY, WITHOUT ANY RECOURSE
                AGAINST PROVIDER, FOR THE SELECTION OF THE SAAS TO ACHIEVE
                CUSTOMER&#8217;S INTENDED RESULTS AND FOR ITS USE OF THE RESULTS
                OBTAINED FROM THE SAAS IN CUSTOMER&#8217;S BUSINESS.&nbsp;
          </strong>
              <strong>
                CUSTOMER ACKNOWLEDGES THAT IT IS SOLELY RESPONSIBLE FOR THE RESULTS
                OBTAINED FROM USE OF THE SAAS, INCLUDING THE COMPLETENESS,
                ACCURACY, AND CONTENT OF SUCH RESULTS.&nbsp;
          </strong>
              <strong>
                PROVIDER DOES NOT WARRANT THAT THE SAAS WILL MEET CUSTOMER&#8217;S
                REQUIREMENTS, THAT THE OPERATION OF THE SAAS WILL BE UNINTERRUPTED
                OR ERROR-FREE, OR THAT ALL ERRORS WILL BE CORRECTED.
          </strong>
            </p>
            <p>
              <strong>
                (c) THE SAAS IS NOT DESIGNED OR PERMITTED TO BE USED IN OR FOR
                HIGH-RISK OR HAZARDOUS ENVIRONMENTS REQUIRING FAIL-SAFE
                PERFORMANCE, INCLUDING OPERATION OF NUCLEAR FACILITIES, AIRCRAFT
                NAVIGATION, COMMUNICATION SYSTEMS, AIR TRAFFIC CONTROL, WEAPONS
                SYSTEMS, DIRECT LIFE-SUPPORT MACHINES, OR ANY OTHER APPLICATION IN
                WHICH THE FAILURE OF THE SAAS COULD LEAD DIRECTLY TO DEATH,
                PERSONAL INJURY, OR SEVERE PHYSICAL OR PROPERTY DAMAGE
                (COLLECTIVELY, "HIGH RISK ACTIVITIES"). PROVIDER EXPRESSLY
                DISCLAIMS ANY EXPRESS OR IMPLIED WARRANTY OF FITNESS OF THE SAAS
                FOR HIGH RISK ACTIVITIES.
          </strong>
            </p>
            <p>
              <strong>5.3. Indemnification of Customer by Provider. </strong>
              Provider agrees to defend, indemnify, and hold harmless Customer and
              its Affiliates from and against all third-party claims and actions
          (collectively, &#8220;<strong><em>Claims</em></strong>&#8221; and
          individually, a &#8220;<strong><em>Claim</em></strong>&#8221;), that
                                                                                                                  may, at any time, arise out of or relate to (a) a breach or alleged
                                                                                                                  breach by Provider of any of its representations given in Section
          5.1(a);<strong> </strong>or (b) a Claim that the SaaS or any Provider
                                                                                                                  Content (excluding, however, User Content) provided by Provider
                                                                                                                  hereunder or Customer&#8217;s use of same in accordance with the terms
                                                                                                                  hereof infringes any third party&#8217;s Intellectual Property Rights;
                                                                                                                  or (c) a Claim arising with respect to Provider&#8217;s posting or
                                                                                                                  displaying Provider Content on Provider&#8217;s Web Site; and, in each
                                                                                                                  case, associated Losses.
        </p>
            <p>
              <strong>5.4. Indemnification of Provider by Customer. </strong>
              Except for any Claims in respect of which Provider is obligated to
              indemnify Customer under Section 5.3, Customer agrees to defend,
              indemnify and hold harmless Provider and its Affiliates from and
              against all Claims that may, at any time, arise out of or relate to:
              (a) use of the SaaS or any Content by or on behalf of Customer or an
              Authorized Customer Entity other than in accordance with this
              Agreement; (b) the posting, display, distribution, broadcast or other
              use of User Content by or on behalf of Customer or an Authorized
              Customer Entity, including Claims that any such use infringes or
              otherwise violates the rights of any third party, including
              Intellectual Property Rights, privacy, publicity or other personal or
              proprietary rights, or that the User Content posted, displayed,
              distributed, broadcast or otherwise published contains libelous,
              defamatory or otherwise injurious or unlawful material; and, in each
          case, associated Losses. <strong></strong>
            </p>
            <p>
              <strong>5.5. Indemnification Procedures. </strong>
              If any third party makes a Claim covered by Section 5.3 or Section 5.4
          against an indemnified Party (the &#8220;<strong><em>Covered Party</em></strong>&#8221;)
                                                                                                                  with respect to which
                                                                                                                  the Covered Party intends to seek indemnification under this Agreement,
                                                                                                                  the Covered Party shall give prompt written notice of the Claim to the
                                                                                                                  indemnifying Party, including a brief description of the amount and
                                                                                                                  basis for the claim, if known. Upon receiving such notice, the
                                                                                                                  indemnifying Party shall be obligated to defend the Covered Party (and
                                                                                                                  its indemnitees) against the Claim, and shall be entitled to assume
                                                                                                                  control of the defense and settlement of the Claim. The Covered Party
                                                                                                                  may participate in the defense and settlement of the Claim at its own
                                                                                                                  expense, using its own counsel, but without any right of control. The
                                                                                                                  indemnifying Party shall keep the Covered Party reasonably apprised as
                                                                                                                  to the status of the Claim. Neither the indemnifying Party nor any
                                                                                                                  Covered Party shall be liable for any settlement of a Claim made
                                                                                                                  without its consent. Notwithstanding the foregoing, the Covered Party
                                                                                                                  shall retain responsibility for all aspects of the Claim that are not
                                                                                                                  subject to indemnification by the indemnifying Party hereunder.
        </p>
            <p>
              <strong>5.6. Limitation of Liability. </strong>
              Except as expressly provided in this Section 5.6, neither Party shall
              have any liability under or in connection with this Agreement for any
              indirect, incidental, consequential, special, exemplary or punitive
              damages, nor any liability for lost profits, loss of data, loss of
              business opportunity, or business interruption, regardless of the
              theory of liability (including theories of contractual liability, tort
              liability, or strict liability), even if the liable Party knew or
              should have known that those kinds of damages were possible. Each
              Party&#8217;s maximum cumulative liability under or in connection with
              this Agreement shall never exceed the injured Party&#8217;s actual
              direct damages, capped at an amount equal to the
              total amount paid under this Agreement by Customer to Provider during
              the 12-month period preceding the occurrence of the event giving rise
              to liability. The foregoing limitations of liability shall not be
              applicable to a Party&#8217;s indemnification obligations under this
              Section 5 or to any damages that the liable Party is not permitted to
              disclaim (or, as applicable, limit) under Applicable Law. Customer
              acknowledges that this Section 5.6 is an essential part of this
              Agreement, absent which the economic terms and other provisions of this
          Agreement would be substantially different. <strong> </strong>
            </p>
            <p>
              <strong>6. DURATION AND TERMINATION. </strong>
            </p>
            <p>
              <strong>6.1. Duration of Agreement. </strong>
              This Agreement commences on the Subscription Term start date set forth
              in the first Order Form executed by both Parties and continues until
              all Order Forms entered into by the Parties have expired or been
              terminated in accordance with this Agreement.
        </p>
            <p>
              <strong>6.2. No Pay Safe Start Period Order Form Cancellation. </strong>
              During the No Pay Safe Start Period, if Customer wishes to cancel its Order Form,
              notification must be made to Exactera, in writing citing deficiencies
              causing the workproduct to be unacceptable. Exactera will have 15 days
              to cure said deficiencies.  If deficiencies are not cured to the good faith satisfaction
              of Customer, then the Order Form may be cancelled. If the Order Form is not cancelled according
              the procedure listed above, the Customer is deemed to have accepted Exactera's work
              product and Customer may only terminate this Agreement for reasons provided in Clause 6.3 Termination.
              <br></br>
              All past studies and all other requested information must be provided to Exactera within
              thirty (30) days of the executed Order Form. If requested information is not provided within the above
              time frame, the No Pay Safe Start Period is terminated and Customer forfeits its option to cancel the Order
              Form and terminate this Agreement, but for standard termination reasons provided for in Clause 6.3 Termination.
              Upon termination of the No Pay Safe Start Period, full payment as specified in the Order Form is due immediately.
              <br></br>
              Customer agrees that if any of the work product  or any of the information and/or analysis delivered
              by Exactera to Customer is 1) referenced,  reproduced or otherwise utilized in connection with
              any governmental filing or 2) provided to a third party for similar purposes,  such use of the work product
              constitutes acceptance of the work product and as such, the No Pay Safe Start Period terminates.
              <br></br>
              Upon acceptance of Exactera work product, the No Pay Safe Start Period terminates and
              full payment is due immediately.
        </p>
            <p>
              <strong>6.3. Termination. </strong>
              Either Customer or Provider may terminate this Agreement, and all Order
              Forms or only affected Order Forms (a) for cause upon written notice to
              the other Party if the other Party has committed a material breach of this
              Agreement and the breach remains uncured 30 days after the breaching party
              has received written notice of the breach from the non-breaching party, or
              (b) if the other Party becomes the subject of a petition in bankruptcy or
              any other proceeding relating to insolvency, receivership, liquidation or
              assignment for the benefit of creditors.
        </p>
            <p>
              <strong>6.4. Effect of Termination on Fees. </strong>
              If this Agreement is terminated by Customer pursuant to Section 6.3,
              any pre-paid fees for the unused portion of the terminated Subscription
              Term will be refunded to Customer.  In all other cases, all fees paid or
              payable for the terminated Subscription Term are non-cancellable and non-refundable,
              and any unpaid fees for the remainder of the terminated Subscription Term
              will become immediately due and payable.
        </p>
            <p>
              <strong>6.5. Other Effects of Termination. </strong>
              Effective immediately upon expiration or termination of this Agreement,
              (i) all rights granted under this Agreement will become void, (ii)
              Customer shall cease all use of the SaaS, and (iii) neither Party
              will have continuing rights to use any Confidential Information of the
              other Party or to exercise any Intellectual Property Rights of the other Party
              that were licensed under this Agreement.  However, Customer shall have 30 days
              after any such expiration or termination to download or otherwise obtain an extract
              of any Customer Data stored by the Service at the time of expiration or termination.
        </p>
            <p>
              <strong>6.6. Survival. </strong>
              Any provision of the Agreement that contemplates or governs performance
              or observance subsequent to its termination or expiration, or which is
              necessary for the proper interpretation, administration or enforcement
              of this Agreement, will survive the expiration or termination of this
              Agreement (or the applicable Order Form) for any reason.
        </p>
            <p>
              <strong>7. PROPRIETARY RIGHTS. </strong>
            </p>
            <p>
              <strong>7.1. Services and Provider Content. </strong>
              The Services (including the SaaS) and Provider Content, and all
              Intellectual Property Rights in and to them, are and shall remain owned
              by Provider (and its licensors, as applicable) and are protected by
              copyright, trademark, patent, trade secret and other laws and treaties.
              Subject to the terms and conditions of this Agreement, Provider hereby
              grants Customer and Authorized Customer Entities a limited, personable,
              revocable, non-sublicensable and non-transferable license for their
              Authorized Users to access and use the functions and features of the
              SaaS during the Subscription Term solely for the Authorized Purpose(s).
              Any derivative work Customer, an Authorized Customer Entity, or any
              Authorized Users may create of any part of the SaaS or Provider
              Content, and all rights therein, shall be owned solely by Provider.
              To that end, Customer hereby irrevocably transfers and conveys to
              Provider, without further consideration, all right, title and interest
              that Customer or any Authorized User may have or acquire in any such
              derivative work and, upon Provider&#8217;s request, Customer shall
              perform, during and after the term of this Agreement, all acts that
              Provider reasonably deems necessary or desirable to permit and assist
              Provider, at its expense, to obtain, perfect, and enforce the full
              benefits, enjoyment, rights and title throughout the world in any such
              derivative works as provided herein.
        </p>
            <p>
              <strong>7.2. User Content</strong>
              <strong> License. </strong>
              Customer hereby grants to Provider a non-exclusive, non-transferable
              right and license to access, use, host, copy, display, process,
              transmit, and deliver the User Content as necessary or convenient for
              Provider to comply with its obligations and exercise its rights under
              this Agreement.
        </p>
            <p>
              <strong>7.3. </strong>
              <strong>Provider Content and Service Usage Data. </strong>
              As between Provider and Customer, Provider shall be and remain the sole
              owner of all Provider Content, as well as all data in de-identified
          form pertaining to usage of the Services. <strong></strong>
            </p>
            <p>
              <strong>7.4. Feedback. </strong>
              If Provider receives from Customer or any of its Authorized Users any
              suggestions, ideas, improvements, modifications, feedback, error
              identifications or other information related to the Services or any
              other Provider products, offerings or services
          (&#8220;<strong><em>Feedback</em></strong>&#8221;), Provider may use, disclose
                                                                                                                  and exploit such Feedback without restriction and without paying any
                                                                                                                  royalties or other compensation, including to improve the Services and
                                                                                                                  to develop, market, offer, sell and provide other products and
                                                                                                                  services.
        </p>
            <p>
              <strong>7.5. No Implied Licenses by Provider. </strong>
              Customer acknowledges that there are no licenses granted by Provider by
              implication under this Agreement. Provider reserves all rights that are
              not expressly granted herein. Customer acknowledges that, as between
              the Parties, Provider owns all Intellectual Property Rights and
              proprietary interests that are embodied in, or practiced by, the SaaS
              or other Services, with the exception of Intellectual Property Rights
              in or to Customer Data or to User Content that may be distributed
          through the SaaS. <strong></strong>
            </p>
            <p>
              <strong>8. CONFIDENTIALITY OBLIGATIONS; PUBLICITY. </strong>
              All activities of the parties under or in relation to this Agreement
          are subject to the confidentiality terms attached hereto as <strong><em>Exhibit A</em></strong>.
                                                                                                                  Neither Party may use the name of
                                                                                                                  the other in any published advertising or publicity materials without
                                                                                                                  the prior written consent of the other party. However, and
          notwithstanding anything to the contrary in <strong><em>Exhibit A</em></strong>,
                                                                                                                  Provider may include Customer&#8217;s name on Provider&#8217;s customer list and may
                                                                                                                  describe briefly, and in general terms, the nature of the services
                                                                                                                  provided by Provider to Customer.
        </p>
            <p>
              <strong>9. GENERAL. </strong>
            </p>
            <p>
              <strong>9.1. Governing Law. </strong>
              The validity, construction, and interpretation of this Agreement and
              the rights and duties of the Parties shall be governed by the internal
              laws of the New York without regard to principles of conflicts of laws.
        </p>
            <p>
              <strong>9.2. </strong>
              <strong>Force Majeure. </strong>
              Notwithstanding any other provision of this Agreement, neither Party
              shall be deemed in default or breach of this Agreement or liable for
              any loss or damages or for any delay or failure in performance (except
              for the payment of money) due to any cause beyond the reasonable
              control of, and without fault or negligence by, such Party.
        </p>
            <p>
              <strong>9.3. Insurance. </strong>
              Provider shall have and maintain in force throughout the Subscription
              Term insurance coverage in types and amounts customarily maintained by
              reputable companies in the same or similar line of business as
          Provider. <strong></strong>
            </p>
            <p>
              <strong>9.4. Dispute Resolution. </strong>
              Any controversy or claim arising out of or relating to this Agreement,
              or the breach thereof, shall be decided by a single arbitrator in
              binding arbitration administered by the American Arbitration
              Association (&#8220;AAA&#8221;) in accordance with its then-current
              Commercial Arbitration Rules, and judgment on the award rendered by the
              arbitrator may be entered in any court having jurisdiction thereof.
              Each Party shall bear its own costs, fees and expenses incurred in
              connection with the arbitration proceeding, including attorneys&#8217;
              fees and expenses and witness costs and expenses. The arbitrator shall
              apportion the fees, expenses and compensation of the American
              Arbitration Association and the arbitrator between the parties in such
              amount as the arbitrator determines is appropriate. Arbitration shall
              take place in New York unless the Parties mutually agree to another
              location. Notwithstanding the foregoing, a Party may, without waiving
              any remedy under this Agreement, seek from any court with jurisdiction,
              interim or provisional equitable relief necessary to protect such
              party&#8217;s rights or property. Any civil action seeking injunctive
              relief, challenging an arbitration proceeding or award or otherwise
              related to this Agreement will be instituted and maintained exclusively
          in the federal or state courts situated in New York. <strong></strong>
            </p>
            <p>
              <strong>9.5. Notice. </strong>
              All notices required or permitted under this Agreement will be in
              writing and sent by certified mail, return receipt requested, or by
              reputable oversight courier, or by hand delivery. The notice address
              for Provider and Customer shall be their respective addresses specified
              in the applicable Order Form. Any notice sent in the manner sent forth
              above shall be deemed sufficiently given for all purposes hereunder (i)
              in the case of certified mail, on the second business day after
              deposited in the U.S. mail and (ii) in the case of overnight courier or
              hand delivery, upon delivery. Either party may change its notice
              address by giving written notice to the other party by the means
          specified in this Section. <strong></strong>
            </p>
            <p>
              <strong>9.6. Construction; Headings. </strong>
              No provision of this Agreement shall be construed against or
              interpreted to the disadvantage of any Party by any court or arbitrator
              by reason of such Party having or being deemed to have structured or
              drafted such provision. The headings in this Agreement are for
              reference purposes only and shall not be deemed to have any substantive
          effect. <strong></strong>
            </p>
            <p>
              <strong>9.7. </strong>
              <strong>Severability. </strong>
              If any provision of this Agreement is held by a court or arbitrator of
              competent jurisdiction to be contrary to law, then the Parties agree to
              replace it with an enforceable provision reflecting the intent of the
              original provision as nearly as possible in accordance with applicable
              law, and the remaining provisions of this Agreement will remain in full
              force and effect.
        </p>
            <p>
              <strong>9.8. </strong>
              <strong>Waiver. </strong>
              The failure of either Party at any time to require performance by the
              other Party of any provision of this Agreement shall not affect in any
              way the full right to require the performance at any subsequent time.
              The waiver by either Party of a breach of any provision of this
              Agreement shall not be taken or held to be a waiver of the provision
              itself. Any course of performance shall not be deemed to amend or limit
              any provision of this Agreement.
        </p>
            <p>
              <strong>9.9. Entire Agreement; Amendments. </strong>
              This Agreement (including Order Forms entered under it) constitutes the
              entire agreement between Provider and Customer with respect to the
              subject matter hereof. There are no restrictions, promises, warranties,
              covenants, or undertakings other than those expressly set forth herein
              and therein. This Agreement supersedes all prior negotiations,
              agreements, and undertakings between the Parties with respect to such
              matter. This Agreement may be amended only by an instrument in writing
              executed by the Parties&#8217; duly authorized representatives.
        </p>
            <p>
              <strong>9.10. Counterparts; Signatures. </strong>
              This Agreement may be signed in counterparts with the same effect
              as if the signatures were upon a single instrument, and all such
              counterparts together shall be deemed an original of this
              Agreement. For purposes of this Agreement, a facsimile copy of a
              Party&#8217;s signature made by reliable means shall be sufficient
              to bind such Party.
            </p>
            <p>
              [<em>End of Terms and Conditions</em>]
            </p>

            <br clear="all" />

            <p className="text-center">
              <strong>EXHIBIT A</strong>
            </p>
            <p className="text-center">
              <strong>Non-Disclosure Terms </strong>
            </p>
            <p>
              All activities of the Parties under or in relation to this Agreement are
              subject to the following terms and conditions with respect to Confidential
              Information:
        </p>

            <h1>
              1. OBLIGATIONS AND PERMITTED USES
        </h1>
            <p>
              With respect to the disclosing Party&#8217;s Confidential Information, the
              receiving Party and its personnel will:
        </p>
            <h3>
              (a) hold all Confidential Information received from or on behalf of the
              disclosing Party in strict confidence and protect the disclosing Party
              Confidential Information from any unauthorized disclosure or use by using
              the same degree of care as it uses to protect its own similar confidential
              information, but no less than a reasonable degree of care;
        </h3>
            <h3>
              (b) not, except with the prior written approval of the disclosing Party,
              disclose any the disclosing Party&#8217;s Confidential Information to any
              third party except as expressly permitted by Section 1(e) below;
        </h3>
            <h3>
              (c) use the disclosing Party&#8217;s Confidential Information only for the
              purpose of performing the receiving Party&#8217;s obligations and
              exercising its rights under this Agreement, and not otherwise for the
              benefit of the receiving Party or any other party (which prohibition
              includes use of the disclosing Party Confidential Information in or for
              published papers and presentations, or its use in or for research that is
              subject to licensing or ownership obligations to any government or other
              entity);
        </h3>
            <h3>
              (d) reproduce the disclosing Party&#8217;s Confidential Information only as
              reasonably necessary for the purpose of performing the receiving
              Party&#8217;s obligations and exercising its rights under this Agreement;
        </h3>
            <h3>
              (e) limit disclosure of the disclosing Party&#8217;s Confidential
              Information to only those of its personnel (employees, contractors, and
              professional and legal advisors) who have a need to know such Confidential
              Information for the purposes of this Agreement, who have been advised of
              the receiving Party&#8217;s obligations herein, and who are bound to the
              receiving Party to preserve the confidentiality of such Confidential
              Information consistent with the terms hereof; and
        </h3>
            <h3>
              (f) not engage in or permit any efforts to reverse engineer, disassemble or
              decompile any prototypes, software or other tangible objects provided to it
              pursuant to this Agreement that embody the disclosing Party&#8217;s
              Confidential Information in an attempt to derive the source code for any
              software or discern any trade secrets.
        </h3>
            <p>
              Any breach or violation of this Section 1 shall constitute a material
              breach of this Agreement.
        </p>

            <h1>
              2. EXCEPTIONS
        </h1>
            <p>
              The foregoing obligations of confidentiality shall not apply to any
              particular disclosing Party Confidential Information that the receiving
              Party can demonstrate by written records:
        </p>
            <h3>
              (a) was publicly disclosed prior to disclosure to the receiving Party, or,
              subsequent to disclosure to the receiving Party, is publicly disclosed
              through no fault of the receiving Party;
        </h3>
            <h3>
              (b) was known to or otherwise independently developed by the receiving
              Party prior to the date of its disclosure by or on behalf of the disclosing
              Party, which knowledge was acquired independently and not from the
              disclosing Party or its personnel, as shown by documents and other
              competent evidence in the receiving Party&#8217;s possession prior to the
              time of disclosure; or
        </h3>
            <h3>
              (c) is subsequently disclosed to the receiving Party in good faith by a
              third party who has a right to make such disclosure to the receiving Party
              without any obligation to restrict its further use or disclosure.
        </h3>

            <h1>
              3. COURT ORDERS OR GOVERNMENT ORDERS
        </h1>
            <p>
              If the receiving Party is required to disclose any of the disclosing
              Party&#8217;s Confidential Information in response to a valid order of a
              court or other valid governmental body in the United States, the receiving
              Party agrees to give the disclosing Party reasonable advance notice of the
              required disclosure (unless it is not legally permitted to do so) in order
              to afford the disclosing Party a reasonable opportunity to contest the
              disclosure or seek a protective order, and the receiving Party agrees to
              reasonably cooperate with such the disclosing Party efforts.
        </p>

            <h1>
              4. NO LICENSE
        </h1>
            <p>
              No license to any of the Parties&#8217; respective trademarks, patents,
              copyrights, or any other intellectual property rights is either granted or
          implied by this <strong><em>Exhibit A</em></strong> or any disclosure of
                                                                                                                  the disclosing Party&#8217;s Confidential Information pursuant to this
                                                                                                                  Agreement, including but not limited to, any license to make, have made,
                                                                                                                  use, sell, offer to sell, or import any device or item embodying any the
                                                                                                                  disclosing Party&#8217;s Confidential Information.
        </p>

            <h1>
              5. NO OBLIGATION
        </h1>
            <p>
              Neither this Agreement nor the disclosure or receipt of the disclosing
              Party&#8217;s Confidential Information shall be construed as creating any
              obligation of the disclosing Party to furnish more or other the disclosing
              Party Confidential Information to the receiving Party.
        </p>

            <h1>
              6. RETURN OF CONFIDENTIAL INFORMATION
        </h1>
            <p>
              Upon the disclosing Party's written request to the receiving Party, the
              receiving Party will return to the disclosing Party (or, at the disclosing
              Party&#8217;s direction, destroy) all of the Confidential Information
              received from or on behalf of the disclosing Party (including all copies
              and derivative works made by the receiving Party) and will cease all use of
              such the disclosing Party&#8217;s Confidential Information. Upon request of
              the disclosing Party, the receiving Party will certify to the disclosing
              Party, in writing, that all such disclosing Party Confidential Information
              (including all copies thereof) has been returned or destroyed and all use
              of the disclosing Party&#8217;s Confidential Information has been
              discontinued.
        </p>

            <h1>
              7. REPRESENTATION
        </h1>
            <p>
              The disclosing Party represents that it has the right to disclose its
              Confidential Information disclosed to the receiving Party under this
              Agreement.
        </p>

            <br clear="all" />

            <p className="text-center">
              <strong>EXHIBIT B</strong>
            </p>
            <p className="text-center">
              <strong>Professional Services</strong>
            </p>
            <p>
              All activities of the Parties under or in relation to this Agreement are
              subject to the following terms and conditions with respect to the
              Professional Services:
        </p>

            <h1>
              1. Implementation Services
        </h1>
            <p>
              (a) Work Scope.
        </p>
            <p>
              Pursuant to the Order Form, for year one of the Agreement, Provider
              will perform implementation services. Implementation services includes the
              identification and collection of research and development (R&amp;D) tax credit
              documentation, performance of technical interviews, preparation of the R&amp;D tax
              credit for each entity and jurisdiction as specified in the Proposal, and preparation
              of supporting workpapers for each R&amp;D tax credit.  Such entities and jurisdictions
              which documentation is provided shall remain consistent for the term of the Agreement.
              Documentation for additional entities and jurisdictions can be included at a later date
              for the fees set forth in the Proposal.
              <br></br>
              All R&amp;D tax credit calculations and supporting documentation produced will meet the R&amp;D
              tax credit regulations of each jurisdiction.  All R&amp;D tax credits will be completed by the
              agreed upon date between Provider and Customer.  Customer will be notified in the case of any
              unforeseen delays, and will agree upon an acceptable completion date, dependent on the facts and circumstances.
              <br></br>
              After year one of the Agreement, the software will automatically update each entity and credit
              jurisdiction, with the applicable documentation from year one.
              <br></br>
              In addition to the above credit documentation, for the term of the Agreement, Provider will provide
              unlimited R&amp;D tax credit technical support from Provider’s professional services team.  Furthermore,
              Provider will also provide audit support for any entity which Provider has provided documentation.  Such
              audit support includes but is not limited to meeting with tax authorities (travel expenses to such meetings
              to be reimbursed by Customer) and answering IDRs.
              <br></br>
              For the term of the Agreement, Provider will assign to Customer a team of professionals including a
              Client Success Manager, a Research and Development Tax Credit Manager, and a Research and Development Tax
              Credit Analyst.
        </p>
            <p>
              (b) Fees
        </p>
            <p>
              As outlined in the Order Form, the implementation services will be
              performed by Provider at the agreed upon fees in the Order Form.
        </p>
            <p>
              (c) Providers Performance
        </p>
            <p>
              Providers will perform the implementation services in a workmanlike manner
              using qualified, efficient, and careful workers. Providers warrants that it
              will conduct all activities hereunder in accordance with all applicable
              governmental laws, rules, and regulations (collectively, &#8220;Applicable
              Law&#8221;), this Agreement, and good standard industry practices.
        </p>
            <p>
              (d) Customer Performance
        </p>
            <p>
              Customer will be responsible for providing Provider with all information
              reasonably necessary to permit Providers to provide the implementation
              services. Company will provide Provider with all such information, signoffs
              and assistance as may be necessary or as Provider may reasonably request to
              permit Providers to provide the implementation services. In providing these
              services, Provider will be entitled to rely on all information provided by
              the Customer. Customer will remain solely responsible for the accuracy and
              completeness of all information provided.
        </p>
            <p>
              (e) Implementation Work Assignment
        </p>
            <p>
              Provider hereby assigns all right, title and interest in any work product
              generated by Provider in the course of the implementation services, or
              within the scope of this Agreement, to Customer. This shall include,
              without limitation, any and all reports, analysis, assessments, and
              business plans and strategies developed in the course of the implementation
              services. Where applicable, work generated hereunder shall be considered a
              work for hire. In the event that Provider should otherwise, by operation of
              law, be deemed to retain any rights (whether moral rights or otherwise) to
              any work product generated hereunder, Provider agrees to assign to
              Customer, without further consideration, and agrees to cause any of its
              employees and/or agents to assign to Customer its and/or their entire
              right, title and interest in and to all such work product.
        </p>
            <p>
              <strong>2. </strong>
              <strong>Use Services</strong>
            </p>
            <p>
              (a) Pursuant to the order form, Provider will use the Service on behalf of
              the Customer to update the work, that was completed as implementation
              services, for each subsequent year of the Agreement.
        </p>
            <p>
              (b) All use services will be done in accordance to the terms and conditions
              outlined in regards to implementation services.
        </p>
          </section>
        </div>

        <FormControlLabel
          control={
            <EulaCheckbox
              disableRipple
              checked={isEulaChecked}
              onChange={handleEulaChange}
            />
          }
          label="by selecting this check box and clicking on the button below you agree to this License Agreement"
        />
      </div>
      {
        shouldShowEulaError && (
          <div className={eulaError}>You must accept and agree to the End User License Agreement by checking the check box above. </div>
        )
      }
      <Button className={button} variant="contained" onClick={handleClick}>I Agree</Button>


    </div >
  );
};

Eula.propTypes = {
  history: PropTypes.shape(),
};

export default Eula;
