import React, { useEffect } from 'react';
import SurveyResponseWidget from '../wizardComponents/SurveyResponseWidget';
import LoadingElement from '../../../../commonComponents/LoadingElement';
import SimplePromptResponsePage from '../../../../commonComponents/SimplePromptResponsePage';
import useDebounce from '../../../../hooks/useDebounce';
import {
  setSelectedExpenseType,
  nextExpenseQuestion,
  previousExpenseQuestion,
  fetchExpenseTypes,
  fetchExpenseQuestions,
  createUpdateExpenseQuestionAnswer,
  updateExpenseQuestionAnswer
} from '../../../../actions/surveyWizard/expenses';
import {
  surveyWizardExpenseSelectedExpenseType,
  surveyWizardExpenseTabsSelector,
  surveyWizardExpenseTotalQuestionsSelector,
  surveyWizardSelectedQuestionSelector,
  surveyWizardCurrentQuestionDetailsSelector
} from '../../../../selectors/surveyWizard/expenses';
import {
  currentWizardExpenses,
  currentWizardSurvey,
  currentSurveySections
} from '../../../../selectors/surveyWizard/surveyWizard';
import { toggleAndSaveSurveySectionCompletion } from '../../../../actions/surveyWizard/surveyWizard';
import { useSelector, useDispatch } from 'react-redux';
import { SUB_SECTION_IDS, EXPENSES_INITIAL_STATE } from '../constants';
import { usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../../constants/promiseTracker';
import { makeStyles } from '@material-ui/core/styles';
import EmployeeResourcesTimesheet from '../EmployeeResourcesTimesheet';
import { EXPENSE_TYPES_WHICH_REQUIRE_EMPLOYEE_RESOURCES } from '../constants';
import { expenseTypeIconsByTypeId } from './expenseTypeIconsByTypeId';

const useStyles = makeStyles((theme) => ({
  notFound: {
    padding: theme.spacing(2)
  }
}));

const ExpenseResponseWidget = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {
    jurisdictionId: currentSurveyJurisdictionId,
    taxYear :currentSurveyTaxYear,
    surveyId: currentSurveyId
  } = useSelector(currentWizardSurvey);
  const selectedExpenseTypeId = useSelector(surveyWizardExpenseSelectedExpenseType);
  const {
    title : questionTitle,
    hint : questionHint,
    text : questionCaption,
    answer = {},
    id: questionId
  } = useSelector(surveyWizardCurrentQuestionDetailsSelector);
  const {
    description : answerDescription,
    value: answerBoolValue
  } = answer;
  const tabs = useSelector(surveyWizardExpenseTabsSelector).map(tab => ({
    ...tab,
    onClick: () => dispatch(setSelectedExpenseType(tab.id))
  }));
  const surveySections = useSelector(currentSurveySections);
  const subSectionId = SUB_SECTION_IDS['Expenses'];
  const sectionCompleted = !!surveySections[subSectionId].isCompleted;
  const surveyWizardExpenses = useSelector(currentWizardExpenses);
  const { promiseInProgress: areQuestionsFetchInProgress } = usePromiseTracker({ area: tags.FETCH_EXPENSE_QUESTIONS, delay: 0 });
  const { promiseInProgress: areExpenseTypesFetchInProgress } = usePromiseTracker({ area: tags.FETCH_EXPENSE_TYPES_FOR_SURVEYS, delay: 0 });

  // Get Expense Types for current survey
  useEffect(() => {
    dispatch(fetchExpenseTypes(currentSurveyJurisdictionId, currentSurveyTaxYear));
  }, [dispatch, currentSurveyJurisdictionId, currentSurveyId, currentSurveyTaxYear]);

  // Whenever we select a new expense type, get the questions
  useEffect(() => {
    selectedExpenseTypeId &&
      dispatch(fetchExpenseQuestions(selectedExpenseTypeId, currentSurveyJurisdictionId, currentSurveyTaxYear, currentSurveyId))
  }, [dispatch, currentSurveyJurisdictionId, selectedExpenseTypeId, currentSurveyId, currentSurveyTaxYear]);

  const insertIconsToExpenseSectionTabs = () => {
    return tabs.map((tab) => ({
      ...tab,
      icon: expenseTypeIconsByTypeId[tab.id]
    }));
  };

  const tabsWithIcons = insertIconsToExpenseSectionTabs();
  const sideNavProps = {
    tabs: tabsWithIcons,
    title: 'Expenses',
    completed: sectionCompleted,
    toggleCallback: () => dispatch(toggleAndSaveSurveySectionCompletion(currentSurveyId, subSectionId, !sectionCompleted))
  };

  const [debouncedSave] = useDebounce(({ answer, answerDescription, surveyId, questionId, expenseTypeId }) => {
    dispatch(createUpdateExpenseQuestionAnswer({
      answer,
      answerDescription,
      surveyId,
      questionId,
      expenseTypeId
    }))
  }, 2000)

  const simplePromptProps = {
    // update redux synchronously then debounce a save
    onChange: (textValue) => {
      if (!textValue) {
        textValue = '<p><br /></p>';
      }
      dispatch(updateExpenseQuestionAnswer({
        answerDescription: textValue,
        questionId,
        expenseTypeId: selectedExpenseTypeId
      }));

      debouncedSave({
        answerDescription: textValue,
        surveyId: currentSurveyId,
        questionId,
        expenseTypeId: selectedExpenseTypeId
      });
    },
    // update redux synchronously then immediately save
    onToggle: (val) => {
      dispatch(updateExpenseQuestionAnswer({
        answer: val ? 1 : 0,
        questionId,
        expenseTypeId: selectedExpenseTypeId
      }));
      dispatch(createUpdateExpenseQuestionAnswer({
        answer: val ? 1 : 0,
        surveyId: currentSurveyId,
        questionId,
        expenseTypeId: selectedExpenseTypeId
      }))
    },
    title: questionTitle,
    caption: questionCaption,
    placeholder: 'Enter your answer here',
    textValue: answerDescription,
    boolValue: !!parseInt(answerBoolValue, 10),
    editorKey: `${selectedExpenseTypeId}-${questionId}`,
    hideToolbar: false,
  };

  const renderQuestions = () => {
    if (areQuestionsFetchInProgress || (selectedExpenseTypeId && surveyWizardExpenses.expenseQuestions === EXPENSES_INITIAL_STATE.expenseQuestions)) {
      return <LoadingElement />;
    }

    if (!surveyWizardExpenses.expenseTypes.length) {
      return <div className={styles.notFound}>No expense types found</div>
    }

    return <SimplePromptResponsePage { ...simplePromptProps } />
  };

  const determineContentBasedOnExpenseType = () => {
    const isWageExpense = EXPENSE_TYPES_WHICH_REQUIRE_EMPLOYEE_RESOURCES.includes(selectedExpenseTypeId);
    if (isWageExpense) {
      return <EmployeeResourcesTimesheet question={{ questionTitle, questionCaption }} />;
    }
    return renderQuestions();
  };

  const selectedTab = tabsWithIcons.find(tab => tab.id === selectedExpenseTypeId) || {};
  const contentPaneProps = {
    onNext: () => dispatch(nextExpenseQuestion()),
    onPrevious: () => dispatch(previousExpenseQuestion()),
    totalSubSections: useSelector(surveyWizardExpenseTotalQuestionsSelector),
    currentSubsection: useSelector(surveyWizardSelectedQuestionSelector),
    children: determineContentBasedOnExpenseType(),
    title: selectedTab.title,
    questionTitle,
    questionHelp: questionHint,
    Icon: selectedTab.icon,
  };

  if (areExpenseTypesFetchInProgress) {
    return <LoadingElement />
  }

  return (
    <SurveyResponseWidget sideNavProps={sideNavProps} contentPaneProps={contentPaneProps} />
  );
};

export default ExpenseResponseWidget;
