import {
  FETCH_ENTITIES_SUCCESS,
  SELECT_ENTITY,
  SELECT_ENTITY_FOR_CRUD_SUCCESS,
  GET_CURRENCIES_SUCCESS,
  UPDATE_SELECTED_ENTITY,
  CLEAN_ENTITY,
  SET_ORIGINAL_PARENT,
  SAVE_ENTITY_PROJECTS,
} from '../constants/actionTypes/entity';
import { clearProjects } from '../actions/project';
import store from '../store';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message';

export const fetchEntities = (containerId) => async (dispatch) => {
  try {
    const entities = await apiClient({
      method: 'get',
      url: `/entity?containerId=${containerId}`,
    });
    dispatch(fetchEntitiesSuccess(entities));

    const selectedEntityId = store.getState().common.selectedEntity;
    dispatch(fetchEntityProjects(selectedEntityId));
    if (selectedEntityId) {
      return;
    }

    const defaultEntity = entities[0];
    if (defaultEntity) {
      dispatch(selectEntity(defaultEntity.entity_id));
    } else {
      dispatch(selectEntity(null));
      dispatch(clearProjects());
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const selectEntity = selectedEntity => dispatch => {
  dispatch(selectEntitySuccess(selectedEntity));
  dispatch(fetchEntityProjects(selectedEntity));
};

const selectEntitySuccess = selectedEntity => ({
  selectedEntity,
  type: SELECT_ENTITY,
});

export const fetchEntitiesSuccess = (entities) => ({
  type: FETCH_ENTITIES_SUCCESS,
  entities,
});

export const selectEntityForCrud = entity => dispatch => {
  try {
    dispatch(selectEntityForCrudSuccess(entity));
    dispatch(setOriginalParent(entity.parentEntities));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const selectEntityForCrudSuccess = (entity) => ({
  type: SELECT_ENTITY_FOR_CRUD_SUCCESS,
  entity,
});

export const setOriginalParent = (parentEntities) => ({
  type: SET_ORIGINAL_PARENT,
  parentEntities,
});

export const getCurrencies = () => async dispatch => {
  try {
    const getCurrencies = await apiClient({
      method: 'get',
      url: 'currency',
    });
    return dispatch(getCurrenciesSuccess(getCurrencies));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const getCurrenciesSuccess = (currencies) => ({
  type: GET_CURRENCIES_SUCCESS,
  currencies,
});

export const updateSelectedEntity = (key, value) => ({
  type: UPDATE_SELECTED_ENTITY,
  key,
  value,
});

export const createNewEntity = (entity) => async dispatch => {
  try {
    await apiClient({
      method: 'post',
      url: '/entity',
      data: entity,
    });
    dispatch(showMessage('success', 'Entity created successfully', 3000))
    return dispatch(fetchEntities(entity.containerId));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const deleteExistingEntity = (entityId, container) => async dispatch => {
  try {
    await apiClient({
      method: 'delete',
      url: `/entity/${entityId}`,
    });
    dispatch(fetchEntities(container));
    dispatch(showMessage('success', 'Entity deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const cleanEntity = () => ({
  type: CLEAN_ENTITY,
});

export const editExistingEntity = (entity) => async dispatch => {
  try {
    await apiClient({
      method: 'patch',
      url: `/entity/${entity.entityId}`,
      data: entity,
    });
    dispatch(fetchEntities(entity.containerId));
    dispatch(showMessage('success', 'Entity updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

const fetchEntityProjects = entityId => async dispatch => {
  try {
    const projects = await apiClient({
      method: 'get',
      url: `/project?entityId=${entityId}`,
    });
    dispatch(saveEntityProjects(projects));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const saveEntityProjects = projects => ({
  type: SAVE_ENTITY_PROJECTS,
  projects,
});

export const setDefaultSelectedEntity = (entities) => async (dispatch) => {
  try {
    const defaultEntity = entities[0];

    if (defaultEntity) {
      dispatch(selectEntity(defaultEntity.entity_id));
    } else {
      dispatch(selectEntity(null));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};
