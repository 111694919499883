import Typography from "@material-ui/core/Typography";
import Button from "../../../../../commonComponents/Button";
import React, {useCallback} from "react";
import {makeStyles} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import formatCreditForTableDisplay from "../../../../../utils/formatCreditForTableDisplay";

const useStyles = makeStyles((theme) => ({
  animationModalContainer: {
    width: '550px',
    height: '550px',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    background: theme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  content: {
    background: theme.palette.grey[100],
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  footer: {
    background: theme.palette.primary.contrastText,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
    borderTop: `2px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: theme.palette.primary.main,
    '& span': {
      background: '#F1EAF8',
      borderRadius: '50%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '40px',
      height: '40px',
      marginRight: theme.spacing(1)
    }
  },
  creditAmountContainer: {
    boxShadow: `0px 0px 15px 2px ${theme.palette.grey[400]}`,
    width: '80%',
    borderRadius: '5px',
  },
  creditAmountItem1: {
    padding: theme.spacing(2),
    color: theme.palette.primary.main,
    background: theme.palette.primary.contrastText,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  creditAmountItem2: {
    padding: theme.spacing(2),
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px',
  }
}));

const ResultModal = ({handleClose, credit}) => {
  const styles = useStyles();
  const [formattedCredit] = formatCreditForTableDisplay([credit]);
  const onModalClose = useCallback(() => {
    handleClose();
  }, [handleClose]);
  const creditAmountToDisplay = () => {
    // is it a group credit?
    if (formattedCredit.groupCreditAmount && formattedCredit.groupCreditAmount !== '-') {
      return formattedCredit.groupCreditAmount;
    }
    // else it is an entity credit
    return formattedCredit.entityCreditAmount === '' ? `(${formattedCredit.currencyIsoCode}) $0.00` : formattedCredit.entityCreditAmount;
  }

  return (
    <div className={styles.animationModalContainer}>
      <div className={styles.header}>
        <div className={styles.title}>
          <span>
            <Typography variant="h5">$</Typography>
          </span>
          <Typography variant="h6">
            R&D Tax Credit Amount
          </Typography>
        </div>
        <IconButton edge="start" color="inherit" onClick={onModalClose} aria-label="close" >
          <CloseIcon />
        </IconButton>
      </div>
      <div className={styles.content}>
        <div className={styles.creditAmountContainer}>
          <div className={styles.creditAmountItem1}>
            <Typography color='inherit' variant="h6">Calculation Results</Typography>
            <Typography color='inherit' variant="subtitle1">{formattedCredit.creditMethod}</Typography>
          </div>
          <div className={styles.creditAmountItem2}>
            <Typography variant="h4">{creditAmountToDisplay()}</Typography>
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <Button data-testid={'animation-modal-button-test-id'} onClick={onModalClose}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default ResultModal;