/* eslint-disable camelcase */

const generateHierarchyStructure = (entityDefinition) => {
  const findRoot = () => {
    for (let i = 0; i < entityDefinition.length; i++) {
      const entity = entityDefinition[i];
      if (entity.parentEntities === null) {
        entity.parentEntities = [];
      }

      let hasParent = false;

      for (let j = 0; j < entity.parentEntities.length; j++) {
        const parentEntity = entity.parentEntities[j];

        if (parentEntity.parent) {
          hasParent = true;
          break;
        }
      }
      if (!hasParent) {
        return entity;
      }
    }
  };

  const findChildren = (entity_id) => {
    let childrensFound = [];

    for (let i = 0; i < entityDefinition.length; i++) {
      const entity = entityDefinition[i];
      if (entity.parentEntities === null) {
        entity.parentEntities = [];
      }
      let parentWithMostOwnerShip;

      for (let j = 0; j < entity.parentEntities.length; j++) {
        const parentEntity = entity.parentEntities[j];

        if (parentEntity.parent_entity_id) {
          if (parentWithMostOwnerShip) {
            if (parentEntity.ownership > parentWithMostOwnerShip.ownership) {
              parentWithMostOwnerShip = parentEntity;
            }
          } else {
            parentWithMostOwnerShip = parentEntity;
          }
        }
      }
      if (parentWithMostOwnerShip && parentWithMostOwnerShip.parent_entity_id === entity_id) {
        childrensFound.push(
          {
            name: entity.name,
            ownership: parentWithMostOwnerShip.ownership,
            entity_id: entity.entity_id,
            ...entity,
          }
        );
      }
    }
    return childrensFound;
  };

  const addChildrenToHieracy = (parentId, children, hierarchyStructure) => {
    // If is first level attach to root
    if (hierarchyStructure.entity_id === parentId) {
      hierarchyStructure.children = children.map(child => child);
    } else {
      let parent;
      const findParent = (childrenEntities)=>{
        for (let i = 0; i < childrenEntities.length; i++) {
          let childEntity = childrenEntities[i];
          if (childEntity.entity_id === parentId) {
            parent = childEntity;
            return;
          } else {
            if (childEntity.children && childEntity.children.length) {
              findParent(childEntity.children);
            }
          }
        }
      };
      findParent(hierarchyStructure.children);
      parent.children = children.map(child => child);
    }
  };

  const root = findRoot();
  let hierarchyStructure = {
    entity_id: root.entity_id,
    name: root.name,
    children: [],
    ...root,
  };

  let nodesAdded = [root.entity_id];
  for (let i = 0; i < nodesAdded.length; i++) {
    let foundChildren = findChildren(nodesAdded[i]);
    if (foundChildren.length) {
      addChildrenToHieracy(nodesAdded[i], foundChildren, hierarchyStructure);
      nodesAdded = nodesAdded.concat(foundChildren.map(child => child.entity_id));
    }

  }
  return (hierarchyStructure);
};

export default generateHierarchyStructure;
