import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  hintWrapper: {
    width: '100%',
    alignSelf: 'center',
    boxShadow: 'none',
    '&:before': {
      backgroundColor: 'unset'
    },
    '&.Mui-expanded': {
      margin: 0,
    }
  },
  hintSummary: {
    alignItems: 'center',
    flexDirection: 'row-reverse',
    '&.Mui-expanded': {
      alignItems: 'center',
      minHeight: 40,
      height: 40
    }
  },
  hintDetails: {
    whiteSpace: 'break-spaces',
    backgroundColor: theme.palette.grey[100],
  },
  infoIcon: {
    padding: 0,
    '&.Mui-expanded': {
      transform: 'none',
      minHeight: 'unset'
    }
  }
}));
