import { GET_REPORT_VERSIONS_SUCCESS, GET_REPORTS_DATA_SUCCESS, GET_PUBLISHED_REPORT_VERSION_SUCCESS } from '../constants/actionTypes/report';
import { apiClient } from '../api/apiClient'
import store from '../store'
import { handleError } from './error';
import { showMessage } from './message'
import uploadToS3ViaPresignedUrl from '../utils/uploadToS3ViaPresignedUrl'
import validatePdfMimeType from '../utils/validatePdfMimeType'
import { fetchCreditsForDropdownSuccess, selectCreditForDropdown } from './credit';
import { LocalStorageProxy } from '../proxies/localStorageProxy';

export const getReportVersions = (stageId, creditId, entityId) => async dispatch => {
  try {
    const reportVersions = await apiClient({
      method: 'GET',
      url: `report/version?stageId=${stageId}&creditId=${creditId}&entityId=${entityId}`
    })

    dispatch(getReportVersionsSuccess(reportVersions))

  } catch (err) {
    dispatch(handleError(err));
  }
}

const getReportVersionsSuccess = reportVersions => ({
  type: GET_REPORT_VERSIONS_SUCCESS,
  reportVersions
})

export const getReportsData = (entityId, creditId) => async dispatch => {
  try {
    const credits = await apiClient({
      method: 'get',
      url: `/credit/entity?entityId=${entityId}`,
    });

    dispatch(fetchCreditsForDropdownSuccess(credits));

    const hasCreditBeenSelected = store.getState().common.selectedCredit;

    const studiedSaved = LocalStorageProxy.getItem('study-id-report-section');
    const [defaultCredit] = credits;
    let usableId = creditId || hasCreditBeenSelected || Number(studiedSaved) || null;
    const creditIsValid = credits.find(cred => cred.id === usableId);
    if (!creditIsValid) {
      usableId = defaultCredit.id || null;
    }

    dispatch(selectCreditForDropdown(usableId));

    const reports = await apiClient({
      method: 'GET',
      url: `/report?creditId=${usableId}&entityId=${entityId}`,
    })

    const reportTypes = await apiClient({
      method: 'GET',
      url: '/report/templates'
    })
    dispatch(getPublishedReportVersion(entityId, creditId))
    dispatch(getReportsDataSuccess({ reports, reportTypes }))

  } catch (err) {
    dispatch(handleError(err));
  }
}

const getReportsDataSuccess = payload => ({
  type: GET_REPORTS_DATA_SUCCESS,
  payload,
})

export const changeReportStage = (id, actionType, stageId, creditId, entityId) => async dispatch => {
  try {
    await apiClient({
      method: 'patch',
      url: '/report/version/change-stage',
      data: { id, actionType }
    })

    dispatch(getReportVersions(stageId, creditId, entityId))
    dispatch(getPublishedReportVersion(entityId, creditId));
    dispatch(showMessage('success', 'Report stage changed successfully', 3000));

  } catch (err) {
    dispatch(handleError(err));
  }
}

export const changePublishedStatus = (id, actionType, stage, creditId, entityId) => async dispatch => {
  try {
    await apiClient({
      method: 'PATCH',
      url: '/report/version/change-published-status',
      data: {
        id,
        actionType
      }
    })
    dispatch(getReportVersions(stage, creditId, entityId));
    dispatch(getPublishedReportVersion(entityId, creditId))
    dispatch(showMessage('success', 'Report status changed successfully', 3000));
  } catch (err) {
    dispatch(handleError(err));
  }
}

export const getPublishedReportVersion = (entityId, creditId) => async dispatch => {
  try {
    const reportVersion = await apiClient({
      method: 'GET',
      url: `/report/version/published?creditId=${creditId}&entityId=${entityId}`
    })
    dispatch(getPublishedReportVersionSuccess(reportVersion))

  } catch (err) {
    dispatch(handleError(err));
  }
}

export const generateReport = (newReport, creditId) => async dispatch => {
  try {
    const { reportStage, entityId } = newReport;
    const reportGenEndpoint = await store.getState().config.REPORT_GEN_API_URL;
    await apiClient({
      method: 'POST',
      url: '/generate-report',
      baseURL: reportGenEndpoint,
      data: newReport
    })
    dispatch(getReportsData(entityId, creditId))
    dispatch(getReportVersions(reportStage, creditId, entityId));
    dispatch(showMessage('success', 'Report generated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
}
export const uploadReport = (pdfData, reportData) => async dispatch => {
  try {
    const preValidation = await validatePdfMimeType(pdfData)
    if (!preValidation.isPdf) {
      return dispatch(showMessage('error', preValidation.message, preValidation.status))
    }
    const { stageId, entityId, creditId } = reportData;

    reportData.name = pdfData.name;
    reportData.type = pdfData.type;
    const reportFileSignedUrl = await apiClient({
      method: 'POST',
      url: '/report/upload',
      data: reportData
    })
    await uploadToS3ViaPresignedUrl(reportFileSignedUrl, pdfData);
    dispatch(getReportsData(entityId, creditId))
    dispatch(getReportVersions(stageId, creditId, entityId));
    dispatch(showMessage('success', 'Report uploaded successfully', 3000))
  } catch (err) {
    if (!err.isAxiosError) {
      dispatch(showMessage('error', 'Could not upload report', 3000))
    }
    dispatch(handleError(err));
  }
}

export const deleteReport = (reportVersionId, reportStage, creditId, entityId) => async dispatch => {
  try {
    const body = { reportVersionId };
    await apiClient({
      method: 'POST',
      url: `/report/delete`,
      data: body
    });
    dispatch(getReportVersions(reportStage, creditId, entityId));
    dispatch(getPublishedReportVersion(entityId, creditId));
    dispatch(showMessage('success', 'Report deleted successfully', 3000));
  } catch (err) {
    dispatch(handleError(err));
  }
}

const getPublishedReportVersionSuccess = reportVersion => ({
  type: GET_PUBLISHED_REPORT_VERSION_SUCCESS,
  reportVersion
})
