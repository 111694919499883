import {
  DELETE_CREDIT,
  GET_CREDITS_BEGIN,
  GET_CREDITS_SUCCESS,
  GET_CREDIT_BY_ID_SUCCESS,
  SELECT_CREDIT,
  SET_SELECTED_CREDIT_FIELD,
  SET_CALCULATION_MODAL_STATE,
} from '../constants/actionTypes/credit';
import { STUDY_CENTRAL_INITIAL_STATE } from '../components/ServiceManager/StudyCentral/constants';

const credit = (state = STUDY_CENTRAL_INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CREDITS_BEGIN:
      return {
        ...state,
        list: [],
        isFetchingCredits: true,
      };

    case GET_CREDITS_SUCCESS:
      return {
        ...state,
        list: action.credits,
        isFetchingCredits: false,
      };

    case GET_CREDIT_BY_ID_SUCCESS:
      const creditIndex = state.list.findIndex(cred => cred.id === action.id);
      const newList = [...state.list];
      if (creditIndex >= 0) {
        newList[creditIndex] = action.credit;
      } else {
        newList.push(action.credit);
      }
      return {
        ...state,
        list: newList,
        selected: action.credit,
        isFetchingCredits: false,
      };

    case SELECT_CREDIT:
      return {
        ...state,
        selected: action.selectedCredit,
      };

    case SET_SELECTED_CREDIT_FIELD:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.key]: action.value,
        },
      };

    case DELETE_CREDIT:
      const listToDeleteCredit = [...state.list];
      const indexOfCreditToDelete = listToDeleteCredit.findIndex(cred => cred.id === action.id);
      if (indexOfCreditToDelete >= 0) {
        listToDeleteCredit.splice(indexOfCreditToDelete, 1);
      }
      return {
        ...state,
        list: listToDeleteCredit,
      };

    case SET_CALCULATION_MODAL_STATE:
      // 'closed', 'calculating', 'showResult'
      return {
        ...state,
        calculationModalState: action.calculationModalState,
      };

    default:
      return state;
  }
};

export default credit;
