const headConfig = [
  { name: 'Jurisdiction', sortable: true, id: 'jurisdictionName' },
  { name: 'Credit Status', sortable: true, id: 'creditStatus' },
  { name: 'Entity Credit Amount', sortable: true, id: 'entityCreditAmount' },
  { name: 'Group Credit Amount', sortable: true, id: 'groupCreditAmount' },
  { name: 'Credit Method', sortable: true, id: 'creditMethod' },
  { name: 'Due Date', sortable: true, id: 'dueDateFormatted' },
];

const rowsConfig = ['legalEntityName', 'jurisdictionName', 'creditStatus', 'entityCreditAmount', 'groupCreditAmount', 'creditMethod', 'dueDateFormatted'];

export {
  headConfig,
  rowsConfig,
};
