import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'

// ACTIONS
import { selectContainer } from '../../../actions/container';
import { selectEntity } from '../../../actions/entities';
import { fetchTimesheets } from '../../../actions/timesheets';
import { fetchProjects } from '../../../actions/project'

//SELECTORS
import { getTimesheets, getTableConfig } from '../../../selectors/timesheets';
import { getSelectedEntity } from '../../../selectors/entities';
import { getProjects } from '../../../selectors/projects';

// COMPONENTS
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import EntitiesDropdown from '../../GlobalDropdowns/EntitiesDropdown';
import Table from '../../../commonComponents/Table';
import MultipleSelect from '../../../commonComponents/FormBuilder/fieldTypes/MultipleSelect';
import SnackBar from '../../../commonComponents/SnackBar';
import { FormControl, FormHelperText, Typography } from '@material-ui/core/';
import {  MuiPickersUtilsProvider,  KeyboardDatePicker } from '@material-ui/pickers';

// CONSTANTS
import { SECTION_TITLE, SELECT_PROJECT, SELECT_ENTITY, NO_DATA } from './constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  filtersContainer: {
    display: 'flex',
    width: '70%'
  },
  filters: {
    marginRight: theme.spacing(4),
    flex: 1,
  },
  multiple: {
    marginTop: theme.spacing(0.75)
  },
  noData: {
    width: '80%',
    background: theme.palette.grey[100],
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(2)
  },
  formHelperTextRoot: {
    '&.Mui-error': {
      color: theme.palette.primary.main,
    }
  }
}));

const Timesheets = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const selectedEntity = useSelector(getSelectedEntity, shallowEqual);
  const projects = useSelector(getProjects, shallowEqual);
  const [filterDates, setFilterDates] = useState({startDate: null, endDate: null});
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [alert, setAlert] = useState(true);

  useEffect(() => {
    if(selectedEntity) {
      dispatch(fetchProjects(selectedEntity))
      setSelectedProjects([])
    }
    else{
      setAlert(true)
    }
  }, [dispatch, selectedEntity]);

  const timesheets = useSelector(getTimesheets)
  const {rowConfig, headConfig} = useSelector(getTableConfig)

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId))
  };

  const handleEntitySelect = (value) => {
    const entityId = parseInt(value);
    dispatch(selectEntity(entityId))
  };

  const handleDateChange = (kind, date) => {
    const newFilters = {...filterDates, [kind]: date };
    setFilterDates(newFilters);
    const selectedProjectIds = selectedProjects.map(project => project.value);
    let payload = {};

    if (newFilters.startDate && newFilters.endDate){
      payload = {
        startDate: format(new Date(newFilters.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(newFilters.endDate), 'yyyy-MM-dd'),
        projects: selectedProjectIds
      }
      dispatch(fetchTimesheets(payload))
    }
  }

  const handleSelectedProject = (selected) => {
    const selectedProjectIds = selected.map(project => project.value);
    setSelectedProjects(selected);
    let payload = {};
    if(selected.length) {
      if(!filterDates.startDate && !filterDates.endDate) {
        payload = {
          projects: selectedProjectIds
        }
        dispatch(fetchTimesheets(payload))
      }
    else if(filterDates.startDate && filterDates.endDate) {
      payload = {
        projects: selectedProjectIds,
        startDate: format(new Date(filterDates.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(filterDates.endDate), 'yyyy-MM-dd'),
      }
      dispatch(fetchTimesheets(payload))
      }
    } else {
      setAlert(true)
    }

  }

  return (
    <>
      <ContainersDropdown onChange={handleContainerSelect} />
      <EntitiesDropdown onChange={handleEntitySelect} />

      <div className={styles.container}>
      <h1 className={styles.title}>{SECTION_TITLE}</h1>
      <div className={styles.filtersContainer}>
      <FormControl  className={styles.filters} error>
        <MultipleSelect
          options={projects}
          sortOptions={{
            propertyToSortBy: 'key',
            direction: 'ascending'
          }}
          selectedOptions={selectedProjects}
          label="Projects"
          onChange={handleSelectedProject}
          optionKeys={{ name: 'key', value: 'value' }}
          customClassName={styles.multiple}
          maxChips={1}
        />
        {
          Boolean(projects.length && !selectedProjects.length) && (
            <FormHelperText className={styles.helper} classes={{ root: styles.formHelperTextRoot }}>
              Select a project
            </FormHelperText>
          )
        }
      </FormControl>
      <MuiPickersUtilsProvider
         utils={DateFnsUtils}
        >
          <FormControl error className={styles.filters} >
        <KeyboardDatePicker
          disabled = {!selectedProjects.length}
          maxDate={filterDates.endDate || '2100-01-01'}
          disableToolbar
          variant="inline"
          format="MM-dd-yyyy"
          margin="normal"
          label="Select start date"
          value={filterDates.startDate}
          onChange={(_, date) => handleDateChange("startDate", date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
         { filterDates.endDate && !filterDates.startDate && <FormHelperText >Please select an start date</FormHelperText>}
        </FormControl >
        <FormControl error  className={styles.filters}>
        <KeyboardDatePicker
          disabled = {!selectedProjects.length}
          minDate={filterDates.startDate || '1900-01-01'}
          disableToolbar
          variant="inline"
          format="MM-dd-yyyy"
          margin="normal"
          label="Select end date"
          value={filterDates.endDate}
          onChange={(_, date) => handleDateChange("endDate", date)}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        { filterDates.startDate &&  !filterDates.endDate && <FormHelperText >Please select an end date date</FormHelperText>}
       </FormControl>
        </MuiPickersUtilsProvider>
        </div>
        {Boolean(selectedProjects.length) && timesheets.length > 1 &&
          <Table
          head={headConfig || []}
          tableRows={rowConfig || []}
          data={timesheets || []}
          searchBy={'name'}
          searchByPlaceholder="Name"
          />
        }
        {Boolean(selectedProjects.length) && timesheets.length === 1 && <Typography className={styles.noData} variant={'subtitle1'}>{NO_DATA}</Typography>}

          <SnackBar
          variant="warning"
          message={projects.length ? SELECT_PROJECT : SELECT_ENTITY}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={(alert && !selectedProjects.length) || (alert && !selectedEntity)}
          handleClose={() => setAlert(false)}
          autoHideDuration={null}
        />
      </div>
    </>
  )
};

export default Timesheets
