import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Checkbox } from '@material-ui/core';
import Button from '../../../../commonComponents/Button'
import SubmitSvg from '../../../../svgs/SubmitSvg';
import { toggleAndSaveSurveySectionCompletion } from '../../../../actions/surveyWizard/surveyWizard';
import { currentWizardSurvey, currentSurveySections } from '../../../../selectors/surveyWizard/surveyWizard';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { apiClient } from '../../../../api/apiClient';
import LoadingElement from '../../../../commonComponents/LoadingElement'
import { useHistory } from 'react-router-dom';
import { handleError } from '../../../../actions/error';
import { tags } from '../../../../constants/promiseTracker';
import { SUB_SECTION_IDS } from '../constants';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.common.common.white,
    width: '40%',
    marginTop: theme.spacing(2),
    height: '80vh',
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    padding: `2rem 4rem 2rem 4rem`
  },
  icon: {
    fill: 'none',
    width: '1.25rem',
    height: '1.25rem',
    transform: 'translateY(5%)',
    stroke: theme.palette.secondary.dark
  },
  bubble: {
    backgroundColor: theme.palette.primary.veryLight,
    display: 'inline-block',
    padding: theme.spacing(0.25),
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    marginBottom: theme.spacing(1)
  },
  title: {
    color: theme.palette.secondary.dark,
    marginBottom: theme.spacing(1)
  },
  prompt: {
    marginBottom: '2rem'
  },
  confirmationContainer: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px'
  },
  affirmation: {
    margin: theme.spacing(2),
    color: theme.palette.secondary.dark,
  },
  checkContainer: {
    borderTop: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(1),
    textAlign: 'left'
  },
  checkbox: {
    color: theme.palette.grey[600]
  },
  confirm: {
    display: 'inline',
    fontSize: '1rem',
    verticalAlign: 'middle',
    color: theme.palette.grey[600],
    '&$confirmed': {
      color: theme.palette.secondary.dark
    }
  },
  button: {
    marginTop: theme.spacing(4),
    width: theme.spacing(20)
  },
  confirmed: {},
  errors: {
    color: 'red',
    fontWeight: 400
  }
}))

const SubmitSection = () => {
  const styles = useStyles();
  const surveySections = useSelector(currentSurveySections);
  const surveySectionRefId = SUB_SECTION_IDS['Submit'];
  const checked = !!(surveySections[surveySectionRefId] && surveySections[surveySectionRefId].isCompleted);
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.SUBMIT_SURVEY_COMPLETION, delay: 0 });
  const {
    jobTitle = '',
    proxyName = '',
    surveyId,
    surveyTypeRefId,
    username
  } = useSelector(currentWizardSurvey);
  const history = useHistory();
  const dispatch = useDispatch();
  const confirmationClasses = classNames({
    [styles.confirm]: true,
    [styles.confirmed]: checked
  })
  const [errors, setErrors] = useState([]);
  const getEmployeeCopy = () => `I certify that I ${username}${jobTitle ? `, ${jobTitle}` : ''}, have direct knowledge of the information provided and have responded to the best of my ability.`
  const getProxyCopy = () => `This information has been gathered through discussions with ${username}${jobTitle ? `, ${jobTitle}` : ''}, who has direct knowledge of the information provided.`

  const submitSurvey = async () => {
    try {
      await trackPromise(apiClient({
          method: 'post',
          url: `/survey/submit`,
          data: {
            surveyId: parseInt(surveyId, 10),
            surveyTypeRefId: parseInt(surveyTypeRefId, 10)
          }
        }),
        tags.SUBMIT_SURVEY_COMPLETION
      );
      history.push('/survey-central');
    } catch (err) {
      setErrors(err.response.data);
      dispatch(handleError(err));
    }
  }

  return (
    isDataFetchInProgress ? <LoadingElement /> :
    <div className={styles.container}>
      <div className={styles.bubble}>
        <SubmitSvg className={styles.icon} />
      </div>
      <Typography className={styles.title} variant="h5" >Submit your survey</Typography>
      <Typography className={styles.prompt}>Thank you for completing the survey. Please confirm the statement below and submit your final survey.</Typography>
      <div className={styles.confirmationContainer}>
        <Typography data-testid="affirmation" className={styles.affirmation} align="left">
          { proxyName ? getProxyCopy() : getEmployeeCopy() }
        </Typography>
        <div className={styles.checkContainer}>
          <Checkbox className={styles.checkbox} checked={checked} onClick={() => dispatch(toggleAndSaveSurveySectionCompletion(surveyId, surveySectionRefId, !checked))} />
          <Typography data-testid="affirmation-2" className={confirmationClasses}>{`Confirmed by ${proxyName ? proxyName : username}`}</Typography>
        </div>
      </div> 
      <Button onClick={submitSurvey} disabled={!checked} className={styles.button}>Submit Survey Now</Button>
      {errors.map(error => <Typography className={styles.errors}>{error}</Typography>)}
    </div>
  );
}

export default SubmitSection;