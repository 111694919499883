const headConfig = [
  { name: 'Id', sortable: true, id: 'id' },
  { name: 'Department', sortable: true, id: 'department' },
  { name: 'Job Title', sortable: true, id: 'jobTitle' },
  { name: 'Email', sortable: true, id: 'email' },
  { name: 'R&D  Hours', sortable: true, id: 'rdHours' },
];
const rowsConfig = ['name', 'id', 'department', 'jobTitle', 'email', 'rdHours'];

export { headConfig, rowsConfig };
