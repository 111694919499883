const headConfig = [
  { name: 'Title', id: 'title', sortable: true },
];

const rowsConfig = ['name', 'title']

export {
  headConfig,
  rowsConfig
}
