import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// actions
import { setUserCredentials } from '../../actions/auth';

// selectors
import { getAuthData } from '../../selectors/auth';
import { getConfig } from '../../selectors/config';

import { LocationProxy } from '../../proxies/locationProxy';
import { LocalStorageProxy } from '../../proxies/localStorageProxy';

//constants
import { LOGIN_REDIRECT, EULA_REDIRECT, ENVIRONMENT_SHORT_MAP } from '../../constants/utils';

const LoginRedirect = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { auth } = useSelector(getAuthData);
  const config = useSelector(getConfig);

  useEffect(() => {
    const hasLoggedIn = LocalStorageProxy.getItem('redirectFlag') === 'true';
    const doesAppConfigExist = config && Object.values(config).length;

    if (!hasLoggedIn) {
      const currentEnv = window.location.hostname;
      if (doesAppConfigExist && currentEnv !== 'localhost' && !currentEnv.includes('exactera')) {
        const redirectUrl = config.XbsEnvironment === 'production'
          ? 'https://rdtax.exactera.com'
          : `https://rdtax.${ENVIRONMENT_SHORT_MAP[config.XbsEnvironment]}.exactera.com`;
        LocationProxy.replace(`${redirectUrl}/login-ui/`);
      } else {
        LocationProxy.replace(`${LocationProxy.getOrigin()}/login-ui/`);
      }
    } else {
      if (doesAppConfigExist) {
        dispatch(setUserCredentials());
      }
    }
    /* eslint-disable-next-line */
  }, [config]);

  useEffect(() => {
    if (auth) {
      if (auth.licenseAgreement === 1) {
        history.push(LOGIN_REDIRECT);
      } else {
        history.push(EULA_REDIRECT);
      }
    }
    /* eslint-disable-next-line */
  }, [auth]);

  return null;
};

export default LoginRedirect;
