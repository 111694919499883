import React, { useState, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import classNames from 'classnames';

const TabPanel = (props) => {
  const { children, value, index, PanelBoxProps, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3} {...PanelBoxProps}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    '&.MuiTabs-flexContainer': {
      overflow: 'auto'
    }
  },
  defaultTabContent: {
    '&:.MuiBox-root': {
      padding: `${theme.spacing(2)}px 0 0 0`,
    }
  },
  defaultTabsBar: {
    background: theme.palette.grey[50],
    color: theme.palette.primary.main,
  },
  flatTabsBar: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: theme.palette.common.black,
    '& .MuiButtonBase-root': {
      textTransform: 'capitalize',
    },
    '& .Mui-selected': {
      fontWeight: 700,
      color: theme.palette.primary.main
    }
  },
  flatTabIndicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: '100%',
      width: "100%",
      backgroundColor: theme.palette.primary.main
    }
  },
  flatTab: {
    minWidth: 0,
    padding: 0,
    marginRight: theme.spacing(2)
  }
}));

const TabsNavigation = ({ tabs, children, type = 'default', AppBarProps = {}, TabsProps = {}, TabPanelProps = {}, TabProps = {} }) => {
  const style = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const stylesByType = {
    default: {
      appBar: style.defaultTabsBar,
      flatTabIndicator: '',
      disableRipple: false,
      tab: ''
    },
    flat: {
      appBar: style.flatTabsBar,
      flatTabIndicator: style.flatTabIndicator,
      disableRipple: true,
      tab: style.flatTab
    }
  };

  const typeStyle = stylesByType[type];
  // Combine internal and external classnames in order to make the component way more reusable and extensible
  const appBarClassNames = classNames(typeStyle.appBar, AppBarProps.className);
  const tabsClassNames = classNames(TabsProps.className);
  const tabClassNames = classNames(typeStyle.tab, TabProps.className);
  const tabPanelClassNames = classNames(style.defaultTabContent, TabPanelProps.className);
  return (
    <div className={style.root}>
      <AppBar position="static" {...AppBarProps} className={appBarClassNames}>
        <Tabs
          TabIndicatorProps={{ className: typeStyle.flatTabIndicator, children: <div /> }}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          {...TabsProps}
          className={tabsClassNames}
        >
          {tabs.map((tab, i) => <Tab disableRipple={typeStyle.disableRipple} key={i} label={tab} {...a11yProps(i)} {...TabProps} className={tabClassNames} />)}
        </Tabs>
      </AppBar>
      {children.map((Child, i) =>
        <TabPanel key={i} value={value} index={i} {...TabPanelProps} className={tabPanelClassNames}>
          {cloneElement(Child)}
        </TabPanel>
      )}
    </div>
  );
}

TabsNavigation.propTypes = {
  children: PropTypes.node,
  tabs: PropTypes.arrayOf(PropTypes.string)
};

export default TabsNavigation;
