const headConfig = [
  { name: 'Status', id: 'status', sortable: true },
  { name: 'Active Projects', id: 'activeProjects', sortable: true },
];

const rowsConfig = ['name', 'status', 'activeProjects'];

export {
  headConfig,
  rowsConfig
}
