const headConfig = [
  { name: 'Department', sortable: true, id: 'surveyLeadDepartment' },
  { name: 'Job Title', sortable: true, id: 'surveyLeadJobTitle' },
  { name: 'Status', sortable: true, id: 'surveyStatus' },
  { name: 'Created By', sortable: true, id: 'createdBy' },
  { name: 'Date Created', sortable: true, id: 'createdAt', type: 'date' },
  { name: 'Due Date', sortable: true, id: 'dueDate', type: 'date' },
  { name: 'Date Completed', sortable: true, id: 'completedDate', type: 'date' },
];

const rowsConfig = ['surveyLead', 'surveyLeadDepartment', 'surveyLeadJobTitle', 'surveyStatus', 'createdBy', 'createdAt', 'dueDate', 'completedDate'];

export {
  headConfig,
  rowsConfig
}
