import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { logout } from '../../actions/auth';
import { NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Avatar, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import Subheader from '../Subheader';
import { getSelectedTenant } from '../../selectors/tenants'
import { getAuthorizedUserData, getRoleData } from '../../selectors/auth';
import { roles, rolesById } from '../../constants/roles';
import ExacteraLogoWhiteSvg from '../../svgs/ExacteraLogoWhiteSVG';
import { apiClient } from '../../api/apiClient';
import launchPadConfig from '../LaunchPad/launchPadConfig';
import getNecessaryRolesUserHas from '../../utils/getNecessaryRolesUserHas';

const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.common.white
  },
  toolbar: {
    display: 'flex',
    minHeight: theme.navbarHeight,
    justifyContent: 'space-between',
  },
  logo: {
    width: 19,
    height: '100%',
    fill: theme.palette.common.white,
    marginRight: theme.spacing(1),
  },
  logoLink: {
    textDecoration: 'none'
  },
  logoLinkDisabled: {
    textDecoration: 'none',
    pointerEvents: 'none'
  },
  logoWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    color: theme.palette.common.white,
    fontSize: '1.05rem',
    fontWeight: '700'
  },
  userIcon: {
    height: 40,
    width: 40,
    backgroundColor: theme.palette.common.black,
    textAlign: 'center',
    lineHeight: '40px',
    color: theme.palette.common.white,
    fontSize: '1.1em',
    borderRadius: '50%',
    cursor: 'pointer'
  },
  menuItem: {
    padding: theme.spacing(1),
    fontSize: '1em',
    color: theme.palette.grey[900],
    '&:hover': {
      color: theme.palette.common.black
    }
  },
  dropdownAvatar: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  rightSideWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  entityName: {
    marginRight: theme.spacing(2),
    fontSize: '1rem'
  }
}));

const Header = ({ shouldShowSubheader = true, toolbarTitle = 'R&D Tax Credits', shouldFetchEmployeeEntity = false }) => {
  const styles = useStyles();
  const [employeeEntity, setEmployeeEntity] = useState({});
  const tenant = useSelector(getSelectedTenant);
  const auth = useSelector(getAuthorizedUserData);
  const userRoles = useSelector(getRoleData) || [];
  const { PS_MANAGER, FULL_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, LIMITED_ACCESS_ADMIN } = roles;

  useEffect(() => {
    const fetchEmployeeEntity = async () => {
      const employeeEntityResult = await apiClient({
        method: 'get',
        url: '/entity/employee-entity'
      });
      
      setEmployeeEntity(employeeEntityResult);
    }
    
    if (shouldFetchEmployeeEntity && !userRoles.some(role => rolesById[role] === PS_MANAGER || rolesById[role] === LIMITED_ACCESS_ADMIN || rolesById[role] === FULL_ACCESS_ADMIN || rolesById[role] === ACCOUNT_EXECUTIVE)) {
      fetchEmployeeEntity()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchEmployeeEntity, userRoles]);
  
  const username = auth.username || ""
  const usernameFirstInitial = username ? username[0].toUpperCase() : ""

  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const roleFilteredLaunchPadConfig = userRoles ? launchPadConfig.filter(item => getNecessaryRolesUserHas(item.roles, userRoles).length) : [];
  
  const navLinkClassNames = classNames({
    [ styles.logoLinkDisabled ] : roleFilteredLaunchPadConfig.length === 1,
    [ styles.logoLink ] : roleFilteredLaunchPadConfig.length > 1
  });

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

const handleClose = (kind) => {
    if (kind === 'logout'){
      dispatch(logout())
    }
    setAnchorEl(null);
};

return (
  <>
    <AppBar className={styles.appbar} position="static">
      <Toolbar className={styles.toolbar}>
        <NavLink
          className={navLinkClassNames}
          to="/"
          exact
        >
          <div className={styles.logoWrapper}>
            <ExacteraLogoWhiteSvg className={styles.logo}/>
            <span className={styles.logoText}>{toolbarTitle}</span>
          </div>
        </NavLink>
        <div className={styles.rightSideWrapper}>
          <span className={styles.entityName}>
            {employeeEntity.name}
          </span>
          <Avatar
            className={styles.userIcon}
            onClick={handleClick}>{usernameFirstInitial}
          </Avatar>
        </div>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
          <MenuItem>
            <ListItemIcon>
            <Avatar
            className={`${styles.userIcon} ${styles.dropdownAvatar}`}
>{usernameFirstInitial}</Avatar>
            </ListItemIcon>
            <ListItemText primary={username} secondary={tenant.name} />
          </MenuItem>
          <Divider/>
          <MenuItem className={styles.menuItem} onClick={() =>handleClose('logout')}>Sign out</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>

    {
      shouldShowSubheader && auth && (
        <Subheader/>
      )
    }
  </>
)};

export default Header;
