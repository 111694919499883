import React from 'react';
import useStyles from './Heading.styles';
import { CREDIT_STATUSES } from '../../../../constants/credit';
import Dials from '../../../../commonComponents/Dials';

const calculateJurisdictions = rows => {
  let jurisdictions = [];
  let exists;
  rows.forEach(row => {
    exists = jurisdictions.some(jurisdiction => jurisdiction.jurisdictionId === row.jurisdictionId);
    if (!exists) { jurisdictions = [...jurisdictions, row]; }
  });
  return jurisdictions.length;
};


const Heading = ({ credits }) => {
  const styles = useStyles();
  const pending = CREDIT_STATUSES.PENDING;
  const completed = CREDIT_STATUSES.COMPLETED;
  const valuePending = credits.filter(credit => credit.creditStatus === pending).length;
  const valueCompleted = credits.filter(credit => credit.creditStatus === completed).length;
  const dialsConfig = [
    {type: 'default',   title: 'jurisdictions', value: calculateJurisdictions(credits)},
    {type: 'default',   title: 'total credits', value: credits.length},
    {type: 'pending',   title: 'Pending',       value: valuePending},
    {type: 'completed', title: 'Completed',     value: valueCompleted}
  ];

  return (
    <div className={styles.heading}>
      <div className={styles.title}>
        Credit Central
      </div>
      <div className={styles.dials}>
        <Dials dialConfig={dialsConfig} />
      </div>
    </div>
  );
};

export default Heading;
