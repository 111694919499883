import { DISPLAY_MODAL, HIDE_MODAL } from '../constants/actionTypes/modal';

export const showModal = ( name, attributes ) => ({
  type: DISPLAY_MODAL,
  modal: { name, attributes }
});

export const hideModal = () => ({
  type: HIDE_MODAL,
  modal: { name: '', attributes: {} }
});
