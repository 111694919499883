import React, {useCallback, useState} from "react";
import Filter from "./Filter";
import { UseStyles } from './Filters.styles';

const Filters = (props) => {
  const { values, onChange } = props;

  const styles = UseStyles();
  const [filters, setFilters] = useState({});

  const onHandleChange = useCallback((key, value) => {
    value ? filters[key] = value : filters.hasOwnProperty(key) && delete filters[key];

    setFilters(filters);
    onChange && onChange(filters);
  }, [filters, onChange]);

  return (
    <div className={styles.FilterWrapper}>
      {
        values.map((filter) => {
          return (
            <Filter
              key={filter.key}
              onChange={(value) => onHandleChange(filter.key, value)}
              options={filter.options}
              placeholder={filter.placeholder}
            />
          );
        })
      }
    </div>
  );
};

export default Filters;
