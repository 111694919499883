import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  createEntityGroup,
  deleteEntityGroup,
  editEntityGroup,
  selectEntityGroupCrud,
  setSelectedEntityGroupField,
} from '../../../../../actions/entityGroups';
import { ENTITY_GROUPS_INITAL_STATE } from '../constants';
import { getSelectedContainer } from '../../../../../selectors/entities';
import { getSelectedEntityGroup } from '../../../../../selectors/entityGroups';
import { buildEntityGroupInputFields } from './buildEntityGroupInputFields';
import FormBuilder from '../../../../../commonComponents/FormBuilder';
import getValueFromEvent from '../../../../../utils/getValueFromEvent';

const EntityGroupsCrud = (props) => {
  const dispatch = useDispatch();
  const containerId = useSelector(getSelectedContainer, shallowEqual);
  const entityGroup = useSelector(getSelectedEntityGroup, shallowEqual);

  useEffect(() => {
    if (props.crudType !== 'create') {
      dispatch(selectEntityGroupCrud(props));
    } else {
      dispatch(selectEntityGroupCrud(ENTITY_GROUPS_INITAL_STATE.selected));
    }
  }, [dispatch, props]);

  const handleChange = (event) => {
    const { name } = event.target;
    const value = getValueFromEvent(event);
    dispatch(setSelectedEntityGroupField(name, value));
  };

  const handleEntityGroupUpdate = () => {
    const { id } = entityGroup;
    const body = makeEntityGroupBody();
    dispatch(editEntityGroup(id, containerId, body));
    props.closeCrudPanel();
  };

  const handleEntityGroupDelete = () => {
    const { id } = entityGroup;

    dispatch(deleteEntityGroup(id, containerId));
    props.closeCrudPanel();
  };

  const handleEntityGroupCreate = () => {
    const body = {
      ...entityGroup,
      containerId,
    };

    dispatch(createEntityGroup(body));
    props.closeCrudPanel();
  };

  const makeEntityGroupBody = () => {
    const { name } = entityGroup;
    return {
      name,
      containerId,
    };
  };

  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleEntityGroupCreate,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleEntityGroupUpdate,
      color: 'secondary',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleEntityGroupDelete,
      color: 'secondary',
      shouldAskConfirmation: true
    },
  ];
  const inputFields = buildEntityGroupInputFields(entityGroup);
  return (
    <div>
      <FormBuilder onChange={handleChange} fields={inputFields} formActions={formActions} />
    </div>
  );
};

export default EntityGroupsCrud;
