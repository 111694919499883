import { combineReducers } from 'redux';
import survey from './survey';
import expenses from './expenses';
import projects from './projects';

const rootSurveyWizardReducer = combineReducers({
  survey,
  expenses,
  projects
});

export default rootSurveyWizardReducer
