import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(2),
    justifyContent: 'space-around',
    flexDirection: 'row',
  },
  fullSizeButton: {
    width: '100%'
  },
  input: {
    marginTop: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(.5),
  },
  buttonLink: {
    color: 'unset',
    textDecoration: 'none'
  },
  title: {
    textTransform: 'capitalize'
  },
  buttonWithText: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    marginTop: theme.spacing(1)
  },
  readOnlyTitle: {
    fontWeight: 700,
    display: 'block'
  },
  loadingDownload: {
    marginLeft: theme.spacing(1)
  }
}));
