const FETCH_SURVEYS_SUCCESS = 'FETCH_SURVEYS_SUCCESS';
const FETCH_SURVEYS_FAIL = 'FETCH_SURVEYS_FAIL';
const SELECT_SURVEY = 'SELECT_SURVEY';
const TOGGLE_SURVEY_LOADING_STATE = 'TOGGLE_SURVEY_LOADING_STATE';
const SET_SELECTED_SURVEY_FIELD = 'SET_SELECTED_SURVEY_FIELD';
const SET_SELECTED_PROJECT_SURVEY = 'SET_SELECTED_PROJECT_SURVEY';
const SET_SELECTED_EMPLOYEE_SURVEY = 'SET_SELECTED_EMPLOYEE_SURVEY';
const FETCH_TIME_PERIODS_SUCCESS = 'FETCH_TIME_PERIODS_SUCCESS';
const FETCH_TRACKING_TYPES_SUCCESS = 'FETCH_TRACKING_TYPES_SUCCESS';
const FETCH_USERS_PROXY_SUCCESS = 'FETCH_USERS_PROXY_SUCCESS';

export {
  FETCH_SURVEYS_SUCCESS,
  FETCH_SURVEYS_FAIL,
  SELECT_SURVEY,
  TOGGLE_SURVEY_LOADING_STATE,
  SET_SELECTED_SURVEY_FIELD,
  SET_SELECTED_PROJECT_SURVEY,
  SET_SELECTED_EMPLOYEE_SURVEY,
  FETCH_TIME_PERIODS_SUCCESS,
  FETCH_TRACKING_TYPES_SUCCESS,
  FETCH_USERS_PROXY_SUCCESS
}
