import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import CentralSvg from '../../svgs/CentralSvg'
import GlobalSvg from '../../svgs/GlobalSvg'
import { NavLink } from 'react-router-dom';
import useStyle from './Sidenav.styles';
import {getRoleData} from '../../selectors/auth'
import {useSelector} from 'react-redux'
import getNecessaryRolesUserHas from '../../utils/getNecessaryRolesUserHas'

const Sidenav = ({sideNavItems}) => {
  const styles = useStyle();
  const userRoles = useSelector(getRoleData)
  const renderIcon = (icon) => {
    const icons = {
      creditCentral: <CentralSvg className={styles.icon} />,
      globalGuide: <GlobalSvg className={styles.icon} />,
    };
    return icons[icon]
  };

  const roleFilteredSidenavItems = sideNavItems ? sideNavItems.filter(sidenavItem => getNecessaryRolesUserHas(sidenavItem.roles, userRoles).length) : []
  const renderSidenavItems = () => roleFilteredSidenavItems.map(({text, to, icon, title }, i) => (
    <Fragment key={`${i}_text`}>
    {title ? <Typography variant="subtitle1" className={styles.title}>{title}</Typography> :
    <NavLink activeClassName={styles.activeItem} className={styles.link} to={to} exact>
      <ListItem className={styles.item} button>
        {icon && <ListItemIcon >
        {renderIcon(icon)}
        </ListItemIcon>}
        <ListItemText className={styles.text} primary={text} />
      </ListItem>
    </NavLink>}

    </Fragment>
  ));

  return (
    <List className={styles.listContainer} disablePadding component="nav" >
      {sideNavItems && sideNavItems.length && renderSidenavItems()}
    </List>
  );
};

Sidenav.propTypes = {
  sideNavItems: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    to: PropTypes.string
  }))
};
export default Sidenav;
