import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Redirect} from 'react-router-dom'
import NavItem from './NavItem';
import launchPadConfig from './launchPadConfig';
import { logout } from '../../actions/auth';
import { LAUNCH_PAD_TITLE } from '../../constants/utils';
import { getRoleData } from '../../selectors/auth'
import { useSelector, useDispatch } from 'react-redux'
import getNecessaryRolesUserHas from '../../utils/getNecessaryRolesUserHas'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflowY: 'hidden',
    backgroundColor: theme.palette.common.black,
  },
  title: {
    marginTop: 0,
    marginBottom: '4rem',
    fontSize: '2rem',
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightRegular,
  },
  itemsContainer: {
    display: 'flex'
  }
}));

const LaunchPad = () => {
  const dispatch = useDispatch();
  const classes = useStyles()
  const userRoles = useSelector(getRoleData)
  const roleFilteredLaunchPadConfig = userRoles ? launchPadConfig.filter(item => getNecessaryRolesUserHas(item.roles, userRoles).length) : [];

  useEffect(() => {
    if (roleFilteredLaunchPadConfig && roleFilteredLaunchPadConfig.length === 0) {
      dispatch(logout());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const renderLaunchPadLinks = () => {
    return (
      <main className={classes.container}>
        <h1 className={classes.title}>{LAUNCH_PAD_TITLE}</h1>
        <nav className={classes.itemsContainer}>
          {roleFilteredLaunchPadConfig.map(({ img, link, title, subtitle, imgAlt }, i) =>
            <NavItem
              img={img}
              link={link}
              title={title}
              subtitle={subtitle}
              imgAlt={imgAlt}
              key={`${i}_${title}`}
            />
          )}
        </nav>
      </main>
    )
  };

  return (
    <div >
      {roleFilteredLaunchPadConfig.length === 1
        ? <Redirect to={roleFilteredLaunchPadConfig[0].link} />
        : renderLaunchPadLinks()
      }
    </div>
  );
};

export default LaunchPad
