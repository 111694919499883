import { DISPLAY_MESSAGE, HIDE_MESSAGE } from '../constants/actionTypes/toastMessage';
import { TOASTMESSAGE_INITIAL_STATE } from '../commonComponents/ToastMessage/constants';

const message = (state = TOASTMESSAGE_INITIAL_STATE, action) => {
  switch (action.type) {
    case DISPLAY_MESSAGE:
      return {
        ...state,
        isOpen: true,
        ...action.message
      };
  
    case HIDE_MESSAGE:
      return {
        ...state,
        isOpen: false,
        ...action.message
      };
    
    default: return state;
  }

};

export default message;
