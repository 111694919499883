import {
  FETCH_EXPENSE_TYPES_FOR_WIZARD_SUCCESS,
  FETCH_EXPENSE_QUESTIONS_SUCCESS,
  SET_SELECTED_EXPENSE_SUB_SECTION,
  PREVIOUS_EXPENSE_QUESTION,
  NEXT_EXPENSE_QUESTION,
  UPDATE_EXPENSE_QUESTION_ANSWER,
  RESET_STATE_SURVEY_WIZARD_EXPENSES
} from '../../constants/actionTypes/surveyWizard';
import { EXPENSES_INITIAL_STATE } from '../../components/SurveyCentral/SurveyWizard/constants';

const surveyWizard = (state = EXPENSES_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EXPENSE_TYPES_FOR_WIZARD_SUCCESS:
      return {
        ...state,
        expenseTypes: action.types,
        selectedExpenseType: (action.types[0] || {}).id
      }
    case FETCH_EXPENSE_QUESTIONS_SUCCESS:
      return {
        ...state,
        expenseQuestions: {
          ...(state.expenseQuestions || {}),
          [action.expenseTypeId]: action.questions.sort((a, b) => (a.order < b.order))
        }
      }
    case SET_SELECTED_EXPENSE_SUB_SECTION:
      return {
        ...state,
        selectedExpenseType: action.expenseTypeId,
        selectedQuestion: 0
      };
    case NEXT_EXPENSE_QUESTION:
      return {
        ...state,
        selectedQuestion: state.selectedQuestion === state.expenseQuestions[state.selectedExpenseType].length -1 ?
          state.selectedQuestion : state.selectedQuestion + 1
      }
    case PREVIOUS_EXPENSE_QUESTION:
      return {
        ...state,
        selectedQuestion: state.selectedQuestion === 0 ?
          0 : state.selectedQuestion - 1
      }
    case UPDATE_EXPENSE_QUESTION_ANSWER:
      const { expenseTypeId, questionId, answer, answerDescription } = action;
      const typedQuestions = state.expenseQuestions[expenseTypeId]

      // We allow for partial writes to answers. If we're missing a value get it from the store
      const question = typedQuestions.find(question => question.id === questionId);
      if (!question.answer) {
        question.answer = {};
      }
      question.answer.value = (answer === undefined) ? question.answer.value : answer;
      question.answer.description = (answerDescription === undefined) ? question.answer.description : answerDescription;
      return {
        ...state,
        expenseQuestions: {
          ...state.expenseQuestions,
          [expenseTypeId]: [...typedQuestions]
        }
      }
    case RESET_STATE_SURVEY_WIZARD_EXPENSES:
      return EXPENSES_INITIAL_STATE
    default: return state;
  }
};

export default surveyWizard;
