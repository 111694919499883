import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

// SELECTORS
import { getSelectedTenant } from '../../../selectors/tenants';
import { getEntities, getSelectedContainer } from '../../../selectors/entities';

// ACTIONS
import { selectContainer } from '../../../actions/container';

// COMPONENTS
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import SnackBar from '../../../commonComponents/SnackBar';
import TabNavigation from '../../../commonComponents/TabNavigation';
import EntitiesDirectory from './EntitiesDirectory';
import Chart from './Chart';
import EntityGroups from './EntityGroups';

// CONSTANTS
import {SELECT_CLIENT_MSG, ENTITIES_TITLE, NECESSARY_ROLES_PER_COMPONENT} from './constants';
import { fetchEntityGroups } from '../../../actions/entityGroups';
import useRoleRestriction from "../../../hooks/useRoleRestriction";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
}));

const Entities = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [alert, setAlert] = useState(true);
  const entities = useSelector(getEntities, shallowEqual);
  const selectedTenant = useSelector(getSelectedTenant, shallowEqual);
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual);
  const hasRoleAccessTo = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);

  useEffect(() => {
    if (selectedContainer) {
      dispatch(fetchEntityGroups(selectedContainer))
    }
  }, [dispatch, selectedContainer]);

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId));
  };

  const shouldDisplayEntityChartTab = hasRoleAccessTo.EntityChartTab;
  const tabs = [
    {
      name: 'Entity Directory',
      shouldDisplay: true
    },
    {
      name: 'Entity Chart',
      shouldDisplay: shouldDisplayEntityChartTab
    },
    {
      name: 'Entity Groups',
      shouldDisplay: true
    }
  ];

const activeTabs = tabs.reduce((activeTab,tab) => {
  if(tab.shouldDisplay) {
    activeTab.push(tab.name);
  }
  return activeTab;
},[]);

const navigation = () => {
  return shouldDisplayEntityChartTab ?
     [
      <EntitiesDirectory key="navigation-1"/>,
      <Chart key="navigation-2" entities={entities} />,
      <EntityGroups key="navigation-3"/>
    ] : [
      <EntitiesDirectory key="navigation-4"/>,
      <EntityGroups key="navigation-5"/>
    ]
}

  return (
    <>
      <ContainersDropdown onChange={handleContainerSelect} />
      <div id="entities-content" className={styles.container}>
        <h1 className={styles.title}>{selectedTenant ? selectedTenant.name : ENTITIES_TITLE.plural}</h1>
        <SnackBar
          variant="warning"
          message={SELECT_CLIENT_MSG}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={alert && !selectedTenant}
          handleClose={() => setAlert(false)}
          autoHideDuration={null}
        />
        {selectedTenant &&
          <TabNavigation tabs={activeTabs}>
            {navigation()}
          </TabNavigation>}
      </div>
    </>
  );
};

export default Entities;
