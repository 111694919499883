import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { headConfig, rowsConfig } from './tableConfig';
import TableAndCrud from '../../TableAndCrud';
import EntityGroupsCrud from './EntityGroupsCrud';
import { getEntityGroups } from '../../../../selectors/entityGroups';
import { MAX_TABLE_HEIGHT } from './constants';

const EntityGroups = () => {
  const entityGroups = useSelector(getEntityGroups, shallowEqual);

  return (
    <TableAndCrud
      idKey="id"
      tableHead={headConfig}
      tableData={entityGroups}
      searchBy="name"
      searchByPlaceholder="Name"
      tableRows={rowsConfig}
      maxTableHeight={MAX_TABLE_HEIGHT}
    >
      <EntityGroupsCrud />
    </TableAndCrud>
  );
};

export default EntityGroups;
