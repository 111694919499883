export const APP_SECTIONS = {
    credit_central: {
        name: 'Credit Central',
        subtitle: 'View Credits',
        baseUrl: '/credit-central',
    },
    service_manager: {
        name: 'Service Manager',
        baseUrl: '/service-manager',
        subtitle: 'Calculate Credits'
    },
    survey_central: {
        name: 'Surveys',
        baseUrl: '/survey-central',
        subtitle: 'Take Surveys'
    }
}
