import {APP_SECTIONS} from "./appSections";
import Tenants from "../components/ServiceManager/Tenants";
import Users from "../components/ServiceManager/Users";
import Containers from "../components/ServiceManager/Containers";
import Entities from "../components/ServiceManager/Entities";
import EntityInfo from "../components/ServiceManager/EntityInfo";
import Projects from "../components/ServiceManager/Projects";
import ProjectQualification from "../components/ServiceManager/Projects/ProjectQualification";
import Timesheets from "../components/ServiceManager/Timesheets";
import Employees from "../components/ServiceManager/Employees";
import StudyCentral from "../components/ServiceManager/StudyCentral";
import CreditDetails from '../components/ServiceManager/CreditDetails/CreditDetails';
import Expenses from "../components/ServiceManager/Expenses";
import Surveys from "../components/ServiceManager/Surveys";
import Reports from "../components/ServiceManager/Reports";
import {roles} from "./roles";

const { FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, PS_ASSOCIATE, ACCOUNT_EXECUTIVE, PS_MANAGER, SUPER_ACCESS_ADMIN, DIY_CLIENT } = roles;

//route order matters for default selections (first route default by policy section)
export const serviceManagerRoutes  = [
  //admin policy section
  {
    name: 'Clients',
    path: `${APP_SECTIONS.service_manager.baseUrl}/clients`,
    component: Tenants,
    exactly: true,
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN]

  },
  {
    name: 'Users',
    path: `${APP_SECTIONS.service_manager.baseUrl}/user-manager`,
    component: Users,
    exactly: true,
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, SUPER_ACCESS_ADMIN]
  },
  {
    name: 'Containers',
    path: `${APP_SECTIONS.service_manager.baseUrl}/containers`,
    component: Containers,
    exactly: true,
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN]
  },

  //workspace policy section
  {
    name: 'Entities',
    path: `${APP_SECTIONS.service_manager.baseUrl}/entities`,
    component: Entities,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Entity Info',
    path: `${APP_SECTIONS.service_manager.baseUrl}/entities/:entityId/entity-info`,
    component: EntityInfo,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Projects',
    path: `${APP_SECTIONS.service_manager.baseUrl}/projects`,
    component: Projects,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Project Qualification',
    path: `${APP_SECTIONS.service_manager.baseUrl}/projects/qualify`,
    component: ProjectQualification,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Timesheet',
    path: `${APP_SECTIONS.service_manager.baseUrl}/timesheet`,
    component: Timesheets,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER]
  },
  {
    name: 'Employees',
    path: `${APP_SECTIONS.service_manager.baseUrl}/employees`,
    component: Employees,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Study Central',
    path: `${APP_SECTIONS.service_manager.baseUrl}/study-central`,
    component: StudyCentral,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Credit Details',
    path: `${APP_SECTIONS.service_manager.baseUrl}/study-central/:creditId/credit-details`,
    component: CreditDetails,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Expenses',
    path: `${APP_SECTIONS.service_manager.baseUrl}/expenses`,
    component: Expenses,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Surveys',
    path: `${APP_SECTIONS.service_manager.baseUrl}/surveys`,
    component: Surveys,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, DIY_CLIENT]
  },
  {
    name: 'Reports',
    path: `${APP_SECTIONS.service_manager.baseUrl}/reports`,
    component: Reports,
    exactly: true,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER]
  }
];

export const creditCentralRouter = [
  {
    name: 'Global Guide',
    path: `${APP_SECTIONS.credit_central.baseUrl}/global-guide`
  }
]
