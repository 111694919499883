import React, { useEffect, useState } from 'react';
import TableAndCrud from '../TableAndCrud';
import { makeStyles } from '@material-ui/core';
import { headConfig, rowsConfig } from './tableConfig';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import SurveysCrud from './SurveysCrud';
import LoadingElement from '../../../commonComponents/LoadingElement';
import { MAX_TABLE_HEIGHT } from './constants';
import { getSelectedEntity, getSelectedEntityObject } from '../../../selectors/entities';
import Dials from '../../../commonComponents/Dials';
import { apiClient } from '../../../api/apiClient';
import { fetchSurveys, fetchTimePeriods, fetchTrackingTypes, fetchUserByContainer } from '../../../actions/survey';
import { usePromiseTracker } from "react-promise-tracker";
import { tags } from '../../../constants/promiseTracker';
import { getSelectedContainerId } from '../../../selectors/containers';
import { getEmployees } from '../../../selectors/employees';
import AutocompleteSelect from '../../../commonComponents/FormBuilder/fieldTypes/AutocompleteSelect';
import { selectEntity } from '../../../actions/entities';

const useStyles = makeStyles((theme) => ({
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  entityDropdown: {
    width: '230px',
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end'
  }
}));

const SurveyType = ({ surveyType }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const surveys = useSelector(state => state.surveys.list);
  const entityId = useSelector(getSelectedEntity);
  const selectedContainerId = useSelector(getSelectedContainerId, shallowEqual);
  const entities = useSelector(state => state.common.entities);
  const selectedEntity = useSelector(getSelectedEntityObject, shallowEqual);
  const employeeSurveys = useSelector(getEmployees, shallowEqual);
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_SURVEYS, delay: 0 });
  const { id: surveyTypeId } = surveyType;
  const [projectSurveys, setProjectSurveys] = useState([]);

  useEffect(() => {
    dispatch(fetchTimePeriods());
    dispatch(fetchTrackingTypes());
  }, [dispatch]);

  useEffect(() => {
    if (selectedContainerId) {
      dispatch(fetchUserByContainer(selectedContainerId));
    }
  }, [dispatch, selectedContainerId]);

  useEffect(() => {
    if (surveyTypeId && entityId) {
      dispatch(fetchSurveys(surveyTypeId, entityId));
    }
  }, [dispatch, surveyTypeId, entityId]);

  useEffect(() => {
    const fetchProjectSurveys = async () => {
      const projectEntitysResult = await apiClient({
        method: 'get',
        url: `/project?entityId=${entityId}`,
      });
      setProjectSurveys(projectEntitysResult);
    };

    if (entityId) {
      fetchProjectSurveys();
    }
  }, [entityId]);

  if (!surveys || isDataFetchInProgress) {
    return <LoadingElement />;
  }

  const handleEntitySelect = (event, name, value) => {
    const entityId = parseInt(value.entity_id)
    dispatch(selectEntity(entityId))
  };

  const totalSurveys = surveys.length;
  const completedSurveys = surveys.filter((survey) => survey.surveyStatus === 'Completed').length;
  const pendingSurveys = surveys.filter((survey) => survey.surveyStatus === 'Pending').length;
  const dialsConfig = [
    { type: 'default', title: 'Total', value: totalSurveys },
    { type: 'pending', title: 'Pending', value: pendingSurveys },
    { type: 'completed', title: 'Completed', value: completedSurveys }
  ];
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <div className={styles.entityDropdown}>
          <AutocompleteSelect
            name="entity_id"
            label="Entities"
            muiProps={{
              forcePopupIcon: true
            }}
            sortOptions={{
              propertyToSortBy: 'name',
              direction: 'ascending'
            }}
            options={entities}
            optionKeys={{
              name: 'name',
              value: 'entity_id'
            }}
            value={selectedEntity.entity_id}
            onChange={handleEntitySelect}
          />
        </div>
        <CreateNewButton />
        <Dials dialConfig={dialsConfig} />
      </div>
    )
  };
  return (
    <TableAndCrud
      idKey="id"
      tableHead={headConfig}
      tableData={surveys}
      searchBy={'surveyLead'}
      searchByPlaceholder="Survey Lead"
      tableRows={rowsConfig}
      maxTableHeight={MAX_TABLE_HEIGHT}
      createNewButtonTitle="New Survey"
      renderComponentBeforeTable={renderComponentBeforeTable}
    >
      <SurveysCrud
        surveyType={surveyType}
        allProjectSurveys={projectSurveys}
        allEmployeeSurveys={employeeSurveys}
      />
    </TableAndCrud>
  );
};

export default SurveyType;
