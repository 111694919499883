import {
  GET_EMPLOYEE_INTERVIEWS_SUCCESS,
  SELECT_EMPLOYEE_INTERVIEW,
  SET_SELECTED_EMPLOYEE_INTERVIEW_FIELD,
  CLEAN_SELECTED_EMPLOYEE_INTERVIEW,
  FETCH_ENTITY_DETAILS_SUCCESS,
  SET_SELECTED_ENTITY_DETAIL_FIELD,
  SELECT_ENTITY_DETAIL_CRUD, FETCH_ENTITY_DETAIL_TYPES_SUCCESS,
} from '../constants/actionTypes/entityInfo';
import { tags } from '../constants/promiseTracker';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message';
import { trackPromise } from 'react-promise-tracker';

const formatValueByType = (value, type) => {
  const valueTypes = {
    percentage: (value * 100).toFixed(2)
  }

  return valueTypes[type] || value;
};

const formatEntityDetails = entityDetails => {
  return entityDetails.map((entityDetail) => {
    const entityDetailValueFormatted = formatValueByType(entityDetail.value, entityDetail.type);
    return { ...entityDetail, value: entityDetailValueFormatted }
  });
};

export const getEmployeeInterviews = entityId => async dispatch => {
  try {
    const employeeInterviews = await trackPromise(
      apiClient({
        method: 'get',
        url: `/employee-interview?entityId=${entityId}`,
      }),
      tags.GET_EMP_INTERVIEWS
    );
    dispatch(getEmployeeInterviewsSuccess(employeeInterviews));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const getEmployeeInterviewsSuccess = employeeInterviews => ({
  type: GET_EMPLOYEE_INTERVIEWS_SUCCESS,
  employeeInterviews,
});

export const selectEmployeeInterview = employeeInterview => ({
  type: SELECT_EMPLOYEE_INTERVIEW,
  employeeInterview,
});

export const setSelectedEmployeeInterviewField = (key, value) => ({
  type: SET_SELECTED_EMPLOYEE_INTERVIEW_FIELD,
  key,
  value,
});

export const updateEmployeeInterview = (payload, entityId) => async dispatch => {
  try {
    await apiClient({
      method: 'patch',
      url: `/employee-interview/${payload.id}`,
      data: payload,
    });
    dispatch(getEmployeeInterviews(entityId));
    dispatch(showMessage('success', 'Employee interview updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }

};

export const cleanSelectedEmployeeInterview = () => ({
  type: CLEAN_SELECTED_EMPLOYEE_INTERVIEW,
});

export const createEmployeeOverview = payload => async dispatch => {
  try {
    await apiClient({
      method: 'post',
      url: '/employee-interview',
      data: payload,
    });
    dispatch(getEmployeeInterviews(payload.entityId));
    dispatch(showMessage('success', 'Employee overview created successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }

};

export const deleteEmployeeOverview = (employeeId, entityId) => async dispatch => {
  try {
    await apiClient({
      method: 'delete',
      url: `/employee-interview/${employeeId}`,
    });
    dispatch(getEmployeeInterviews(entityId));
    dispatch(showMessage('success', 'Employee overview deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }

};

export const fetchEntityDetails = (entityId) => async (dispatch) => {
  try {
    const entityDetails = await trackPromise(
        apiClient({
        method: 'get',
        url: `/entity-detail?entityId=${entityId}`,
      }),
      tags.FETCH_ENTITY_DETAILS
    );
    const entityDetailsFormatted = formatEntityDetails(entityDetails);
    dispatch(fetchEntityDetailsSuccess(entityDetailsFormatted));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchEntityDetailTypes = (jurisdictionId, taxYear) => async (dispatch) => {
  try {
    const entityDetails = await apiClient({
      method: 'get',
      url: `/entity-detail/type?jurisdictionId=${jurisdictionId}&taxYear=${taxYear}`,
    });
    dispatch(fetchEntityDetailTypesSuccess(entityDetails));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const fetchEntityDetailsSuccess = (entityDetails) => ({
  type: FETCH_ENTITY_DETAILS_SUCCESS,
  entityDetails,
});

const fetchEntityDetailTypesSuccess = (entityDetailTypes) => ({
  type: FETCH_ENTITY_DETAIL_TYPES_SUCCESS,
  entityDetailTypes,
});

export const editEntityDetail = (entityDetailId, entityId, body) => async (dispatch) => {
  try {
    await apiClient({
      method: 'patch',
      url: `/entity-detail/${entityDetailId}`,
      data: body,
    });
    await dispatch(fetchEntityDetails(entityId));
    dispatch(showMessage('success', 'Entity detail updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const deleteEntityDetail = (entityDetailId, entityId) => async (dispatch) => {
  try {
    await apiClient({
      method: 'delete',
      url: `/entity-detail/${entityDetailId}`,
    });
    await dispatch(fetchEntityDetails(entityId));
    dispatch(showMessage('success', 'Entity detail deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const createEntityDetail = (body, entityId) => async (dispatch) => {
  try {
    await apiClient({
      method: 'post',
      url: '/entity-detail',
      data: body,
    });
    await dispatch(fetchEntityDetails(entityId));
    dispatch(showMessage('success', 'Entity detail created successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const setSelectedEntityDetailField = (key, value) => ({
  type: SET_SELECTED_ENTITY_DETAIL_FIELD,
  value,
  key,
});

export const selectEntityDetailCrud = (selectedEntityDetail) => ({
  selectedEntityDetail,
  type: SELECT_ENTITY_DETAIL_CRUD,
});
