import {
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_WITH_EXPENSES_FOR_TAX_YEAR_SUCCESS,
  GET_EMPLOYEE_FILTER_SUCCESS,
  SELECT_EMPLOYEE,
  SET_SELECTED_EMPLOYEE_FIELD,
} from '../constants/actionTypes/employee';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message';

export const fetchEmployees = (entityId, expenseTypeRefId = '') => async dispatch => {
  try {
    const employee = await apiClient({
      method: 'get',
      url: `/employee?entityId=${entityId}&expenseTypeRefId=${expenseTypeRefId}`,
    });
    dispatch(fetchEmployeesSuccess(employee));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchEmployeesWithExpensesForTaxYear = (entityId, expenseTaxYear) => async dispatch => {
  try {
    if (expenseTaxYear) {
      const employee = await apiClient({
        method: 'get',
        url: `/employee/employee-entity-year?entityId=${entityId}&expenseTaxYear=${expenseTaxYear}`,
      });
      dispatch(fetchEmployeesWithExpensesForTaxYearSuccess(employee));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchEmployeeFilterOptions = (entityId, expenseTaxYear) => async dispatch => {
  try {
    const employeeFilterData = await apiClient({
      method: 'get',
      url: `/employee/employee-filters?entityId=${entityId}&expenseTaxYear=${expenseTaxYear}`,
    });
    dispatch(fetchEmployeeFilterSuccess(employeeFilterData));
  } catch (err) {
    dispatch(handleError(err));
  }
}

const fetchEmployeesWithExpensesForTaxYearSuccess = employee => ({
  type: GET_EMPLOYEES_WITH_EXPENSES_FOR_TAX_YEAR_SUCCESS,
  employee,
});

const fetchEmployeesSuccess = employee => ({
  type: GET_EMPLOYEES_SUCCESS,
  employee,
});

const fetchEmployeeFilterSuccess = filterData => ({
  type: GET_EMPLOYEE_FILTER_SUCCESS,
  filterData,
});

export const clearEmployeeFilter = () => ({
  type: GET_EMPLOYEE_FILTER_SUCCESS
});

export const selectEmployee = selectedEmployee => ({
  type: SELECT_EMPLOYEE,
  selectedEmployee,
});

export const setSelectedEmployeeField = (key, value) => ({
  type: SET_SELECTED_EMPLOYEE_FIELD,
  key,
  value,
});

export const createEmployee = payload => async dispatch => {
  try {
    await apiClient({
      method: 'post',
      url: `/employee`,
      data: payload,
    });
    dispatch(fetchEmployees(payload.entityId));
    dispatch(showMessage('success', 'Employee created successfully', 3000))
  }catch (err) {
    dispatch(handleError(err));
  }
  };


export const deleteEmployee = (employeeId, entityId) => async dispatch => {
 try {
   await apiClient({
     method: 'delete',
     url: `/employee/${employeeId}`,
   });
   dispatch(fetchEmployees(entityId));
   dispatch(showMessage('success', 'Employee deleted successfully', 3000))
 }catch (err) {
   dispatch(handleError(err));
 }

};

export const updateEmployee = (employeeId, entityId, body) => async dispatch => {
  try {
    await apiClient({
      method: 'patch',
      url: `/employee/${employeeId}`,
      data: body,
    });
    dispatch(fetchEmployees(entityId));
    dispatch(showMessage('success', 'Employee updated successfully', 3000))
  }catch (err) {
    dispatch(handleError(err));
  }

};
