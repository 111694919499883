import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { ArrowBack } from '@material-ui/icons';

// ACTIONS
import { selectContainer } from '../../../actions/container';

// COMPONENTS
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import TabNavigation from '../../../commonComponents/TabNavigation';
import EntityOverview from './EntityOverview';
import EmployeeInterviews from './EmployeeInterviews';
import EntityDetail from './EntityDetail';

// CONSTANTS
import { TABS } from './constants'
import { apiClient } from '../../../api/apiClient';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import LoadingElement from '../../../commonComponents/LoadingElement';
import { getSelectedContainerId } from '../../../selectors/containers';
import usePreviousState from '../../../hooks/usePreviousState';
import { tags } from '../../../constants/promiseTracker';
import Button from '../../../commonComponents/Button';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  backIcon: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
    fontSize: '1.8rem',
    position: 'relative',
    transition: 'right 0.3s ease',
    right: 0,
    '&:hover': {
      right: 3
    }
  },
  notFoundDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2)
  },
  goBackButton: {
    width: '150px',
    marginTop: theme.spacing(1)
  }
}));

const entityInitialState = {};

const EntityInfo = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const selectedContainer = useSelector(getSelectedContainerId);
  const previousSelectedContainer = usePreviousState(selectedContainer);
  const [entity, setEntity] = useState(entityInitialState);
  const routeParams = useParams();
  const history = useHistory();
  const entityId = Number(routeParams.entityId);
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_ENTITY_FOR_ENTITY_INFO, delay: 0 });
  
  const goToEntities = () => {
    history.push('/service-manager/entities');
  };
  
  useEffect(() => {
    const fetchEntity = async () => {
      const result = await trackPromise(
        apiClient({
          method: 'get',
          url: `/entity/${entityId}`
        }),
        tags.FETCH_ENTITY_FOR_ENTITY_INFO
      )
      
      setEntity(result);
    };
    
    fetchEntity();
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
     if (typeof previousSelectedContainer === 'undefined') {
      return;
    }
    
    const didContainerChange = previousSelectedContainer !== selectedContainer
    if (didContainerChange) {
      goToEntities();
    }
  });
  
  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId))
  };
  
  if (!entity || isNaN(entityId)) {
    return (
      <div className={styles.notFoundDiv} data-testid="entity-not-found-entity-info">
        Entity not found.
        <Button onClick={goToEntities} className={styles.goBackButton}>Go Back</Button>
      </div>
    );
  }
  
  if (isDataFetchInProgress || entity === entityInitialState) {
    return (
      <LoadingElement />
    );
  }
  
  return (
    <>
      <ContainersDropdown onChange={handleContainerSelect} />
      
      <div className={styles.container}>
        <div className={styles.titleContainer}>
          <ArrowBack onClick={goToEntities} className={styles.backIcon} />
          <h1 className={styles.title}>{entity.name}</h1>
        </div>
        <TabNavigation tabs={TABS}>
          <EntityOverview entity={entity} />
          <EmployeeInterviews entity={entity} />
          <EntityDetail entity={entity} />
        </TabNavigation>
      </div>
    </>
  )
};

export default EntityInfo;
