import React from 'react';
import { Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Summary from './Summary/';
import { Switch, Redirect } from 'react-router-dom';
import getNecessaryRolesUserHas from '../../utils/getNecessaryRolesUserHas'
import { getRoleData } from '../../selectors/auth'
import { roles } from '../../constants/roles';
import {creditCentralRouter} from "../../constants/routes";



const CreditCentralRouter = () => {
  const match = useRouteMatch();
  const userRoles = useSelector(getRoleData) || []
  const { CREDIT_CENTRAL_FULL_ACCESS, ACCOUNT_EXECUTIVE, REPOSITORY_ONLY, FULL_ACCESS_ADMIN, PS_MANAGER, PS_ASSOCIATE, LIMITED_ACCESS_ADMIN, DIY_CLIENT } = roles

  const filterRoutesByRoles = () => {
    if (getNecessaryRolesUserHas([PS_ASSOCIATE, PS_MANAGER, CREDIT_CENTRAL_FULL_ACCESS, ACCOUNT_EXECUTIVE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, DIY_CLIENT], userRoles).length) {
      return (
        <>
          <Route exact path={match.url} component={Summary} />
          {/* TODO: add back GlobalGuide as part of story DUO-2146*/}
          <Route exact path={creditCentralRouter[0].path} component={Summary} />
        </>
      ); 
    } else if (getNecessaryRolesUserHas([REPOSITORY_ONLY], userRoles).length) {
      return (
        <>
          <Route exact path={match.url} component={Summary} />
        </>
      )
    }
  }
  return (
    <Switch>
      {filterRoutesByRoles()}
      <Redirect to={'/'} />
    </Switch>
  );
};

export default CreditCentralRouter;
