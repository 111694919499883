import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

// ACTIONS
import { getReportVersions } from '../../../../actions/report';
import { selectEntity } from '../../../../actions/entities';
import { selectCreditForDropdown } from '../../../../actions/credit'
import { fetchProjects } from '../../../../actions/project';

// SELECTORS
import { getReportVersions as getReportsFromState } from '../../../../selectors/report';
import { getSelectedEntity, getSelectedEntityObject } from '../../../../selectors/entities';
import { getSelectedCreditForDropdown } from '../../../../selectors/common';
import { getSelectedContainer } from '../../../../selectors/containers';
import { getProjects } from '../../../../selectors/projects';

// COMPONENTS
import TableAndCrud from '../../../../components/ServiceManager/TableAndCrud';
import ReportCrud from '../ReportCrud'
import AutocompleteSelect from '../../../../commonComponents/FormBuilder/fieldTypes/AutocompleteSelect';
import { useStyles } from './styles';

// CONSTANTS
import tableConfigBuilder from '../tableConfig';
import { REPORT_VERSION_STATUSES, MAX_TABLE_HEIGHT } from '../constants'
import { DescriptionOutlined } from '@material-ui/icons';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MuiTooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { LocalStorageProxy } from '../../../../proxies/localStorageProxy';

const Tooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '1rem',
  },
}))(MuiTooltip);

const ReportStage = ({ stage }) => {
  const dispatch = useDispatch();
  const reports = useSelector(getReportsFromState, shallowEqual)
  const creditId = useSelector(getSelectedCreditForDropdown)
  const entityId = useSelector(getSelectedEntity)
  const tableConfig = useMemo(() => tableConfigBuilder(stage), [stage])
  const selectedEntity = useSelector(getSelectedEntityObject, shallowEqual);
  const selectedContainer = useSelector(getSelectedContainer);
  const projects = useSelector(getProjects);
  const entities = (useSelector(state => state.common.entities) || []);
  const credits = (useSelector(state => state.common.credits) || []);
  const selectedCredit = useSelector(state => state.common.selectedCredit);
  const styles = useStyles();

  useEffect(() => {
    dispatch(getReportVersions(stage, creditId, entityId))
  }, [dispatch, stage, creditId, entityId])

  useEffect(() => {
    dispatch(fetchProjects(entityId, selectedContainer.tax_year));
  }, [dispatch, entityId, selectedContainer]);

  useEffect(() => {
    let interval
    const setPendingReportsToUpdatedStatus = () => {
      const currentReports = reports || []
      const doesAnyReportNeedStatusUpdate = currentReports.some((report) => report.reportVersionStatus === REPORT_VERSION_STATUSES.Pending)
      if (doesAnyReportNeedStatusUpdate) {
        interval = setInterval(() => {
          dispatch(getReportVersions(stage, creditId, entityId));
        }, 30000)
      }
    }
    setPendingReportsToUpdatedStatus()
    return () => clearInterval(interval);
  }, [dispatch, reports, stage, creditId, entityId]);

  const handleEntitySelect = (event, name, value) => {
    const entityId = parseInt(value.entity_id)
    dispatch(selectCreditForDropdown(null));
    LocalStorageProxy.removeItem('study-id-report-section');
    dispatch(selectEntity(entityId))
  };

  const handleCreditSelect = (event, name, value) => {
    const creditId = parseInt(value.id);
    LocalStorageProxy.setItem('study-id-report-section', creditId);
    dispatch(selectCreditForDropdown(creditId))
  };

  const isCreditIdSelected = Boolean(creditId);
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <div className={styles.entityDropdown}>
            <AutocompleteSelect
            name="entity_id"
            label="Entities"
            options={entities}
            muiProps={{
              forcePopupIcon: true
            }}
            sortOptions={{
              propertyToSortBy: 'name',
              direction: 'ascending'
            }}
            optionKeys={{
              name: 'name',
              value: 'entity_id'
            }}
            value={selectedEntity.entity_id}
            onChange={handleEntitySelect}
          />
        </div>
        <div className={styles.creditDropdown}>
          <AutocompleteSelect
            name="id"
            label="Studies"
            options={credits}
            sortOptions={{
              propertyToSortBy: 'key',
              direction: 'ascending'
            }}
            optionKeys={{
              name: 'id',
              value: 'id'
            }}
            value={selectedCredit}
            onChange={handleCreditSelect}
          />
        </div>

        <Tooltip
          title="You need to select a study id before you can create a new report"
          arrow
          enterDelay={0}
          placement="top-start"
          disableHoverListener={isCreditIdSelected}
        >
          <div className={styles.createNewReportButtonContainer}>
            <CreateNewButton disabled={!isCreditIdSelected} />
          </div>
        </Tooltip>
      </div>
    )
  };

  const NeedsCreditDetailsIcon = ({ children, rowData: reportVersion }) => {
    return (
      <div className={styles.versionCell}>
        {children}
        {reportVersion.needsCreditDetails ? (
          <Tooltip
            className={styles.needsCreditDetailsTooltip}
            title="Required details are missing for this report. Please enter all required information on the study and generate a new version of the report"
            enterDelay={0}
            enterNextDelay={500}
            arrow
            placement="top-start"
          >
            <div className={styles.needsCreditDetailsIcon}>
              <FiberManualRecordIcon className={styles.circleIcon} />
              <DescriptionOutlined />
            </div>
          </Tooltip>
        ) : null}
      </div>
    )
  };

  const cellWrappers = {
    version: {
      CellWrapper: NeedsCreditDetailsIcon,
    }
  };

  return (
    <>
      <TableAndCrud
        tableHead={tableConfig.columns}
        tableRows={tableConfig.rows}
        tableData={reports}
        searchBy={'reportName'}
        searchByPlaceholder="Report Name"
        hideCreateButton={stage !== 1}
        maxTableHeight={MAX_TABLE_HEIGHT}
        idKey="id"
        createNewButtonTitle="New Report"
        renderComponentBeforeTable={renderComponentBeforeTable}
        cellWrappers={cellWrappers}
      >
        <ReportCrud projects={projects.map(project => ({name: project.key, id: project.value}))} stage={stage} creditId={creditId} entityId={entityId} />
      </TableAndCrud>
    </>
  );
};

ReportStage.propTypes = {
  stage: PropTypes.number.isRequired
}

export default ReportStage;
