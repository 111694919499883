import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import CreditCentralRouter from './CreditCentralRouter';
import Sidenav from '../Sidenav';
import SidenavItems from './sidenavItems';
import { makeStyles } from '@material-ui/core';
import TenantsDropdown from '../GlobalDropdowns/TenantsDropdown';
import ContainersDropdown from '../GlobalDropdowns/ContainersDropdown';
import { fetchTenantsForDropdown, selectTenant } from '../../actions/tenant';
import { fetchContainersForDropdown, selectContainer } from '../../actions/container';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSelectedContainerId } from '../../selectors/containers';
import { getSelectedTenant } from '../../selectors/tenants';
import { fetchCredits } from '../ServiceManager/StudyCentral/api';
import { fetchCreditsBegin, fetchCreditsSuccess } from '../../actions/credit';


const useStyles = makeStyles((theme) => ({
  creditCentralContainer: {
    height: '100%',
    alignItems: 'stretch',
  },
  sidenavContainer: {
    height: '100%',
    display: 'flex',
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.grey[400]}`
  },
  tableContainer: {
    height: '100%',
    paddingBottom:'10%'
  }
}));

const CreditCentral = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const selectedTenant = useSelector(getSelectedTenant, shallowEqual);
  const containerId = useSelector(getSelectedContainerId);

  useEffect(() => {
    async function getCredits() {
      if (containerId) {
        dispatch(fetchCreditsBegin());
        const credits = await fetchCredits(containerId);
        dispatch(fetchCreditsSuccess(credits));
      }
    }
    getCredits();
  }, [dispatch, containerId]);

  useEffect(() => {
    dispatch(fetchTenantsForDropdown());
  }, [dispatch]);

  useEffect(() => {
    selectedTenant.tenant_id && dispatch(fetchContainersForDropdown(selectedTenant.tenant_id));
  }, [dispatch, selectedTenant.tenant_id]);

  const handleTenantSelect = (value) => {
    const tenantId = parseInt(value);
    dispatch(selectTenant(tenantId));
  };

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId))
  };

  return (
    <>
      <TenantsDropdown onChange={handleTenantSelect}/>
      <ContainersDropdown onChange={handleContainerSelect} />

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="stretch"
        className={styles.creditCentralContainer}
      >
        <Grid item className={styles.sidenavContainer} md={2}>
          <Sidenav sideNavItems={SidenavItems}/>
        </Grid>

        <Grid item md={10} className={styles.tableContainer}>
          <CreditCentralRouter/>
        </Grid>
      </Grid>
    </>
  );
};

export default CreditCentral;
