import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  goToCreditDetailsButton: {
    height: '100%',
    width: '80%',
    fontSize: '1rem',
    padding: 0,
    '&:hover': {
      boxShadow: 'none'
    },
    '& .MuiButton-label': {
      padding: 0,
      display: 'flex',
      justifyContent: 'space-evenly'
    },
  },
  goToCreditDetailsButtonIcon: {
    fontSize: '1rem'
  },
}));