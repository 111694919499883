const sortObjectListAsc = (objectList, propertyToSortBy) => {
  const newObjectList = [...objectList];
  newObjectList.sort((a, b) => {
    return a[propertyToSortBy] - b[propertyToSortBy];
  });

  return newObjectList;
};

export default sortObjectListAsc;
