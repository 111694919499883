import React, { useEffect, useState } from 'react';
import { apiClient } from '../../../api/apiClient';
import { makeStyles } from '@material-ui/core/styles';
import TabsNavigation from '../../../commonComponents/TabNavigation';
import SurveyType from './SurveyType';
import { SECTION_TITLE } from './constants';
import { selectContainer } from '../../../actions/container';
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployeesWithExpensesForTaxYear } from '../../../actions/employee';
import { fetchLeadersSurvey } from '../../../actions/users';
import { getSelectedEntity } from '../../../selectors/entities';
import { getSelectedContainer } from '../../../selectors/containers';
import { getSelectedTenant } from '../../../selectors/tenants';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
}));

const Surveys = () => {
  const [surveyTypes, setSurveyTypes] = useState([]);
  const entityId = useSelector(getSelectedEntity);
  const selectedTenant = useSelector(getSelectedTenant);
  const container = useSelector(getSelectedContainer);
  const dispatch = useDispatch();
  const styles = useStyles();

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId))
  };

  useEffect(() => {
    if (entityId && container?.tax_year) {
      dispatch(fetchLeadersSurvey(selectedTenant.tenant_id));
      dispatch(fetchEmployeesWithExpensesForTaxYear(entityId, container.tax_year));
    }
  }, [dispatch, container, entityId, selectedTenant])


  useEffect(() => {
    const fetchSurveyTypes = async () => {
      const surveyTypesResult = await apiClient({
        method: 'get',
        url: '/survey/type'
      });

      setSurveyTypes(surveyTypesResult);
    };

    fetchSurveyTypes();
  }, []);

  // TODO: remove this piece and pass surveyTypes to the maps below instead when the individual surveys are implemented
  const surveyTypesToShow = surveyTypes.filter((surveyType) => (
    surveyType.name !== 'Individual'
  ));

  return (
    <div className={styles.container}>
      <h1>{SECTION_TITLE.plural}</h1>

      <ContainersDropdown onChange={handleContainerSelect} />

      <TabsNavigation tabs={surveyTypesToShow.map((surveyType) => surveyType.name)}>
        {surveyTypesToShow.map((surveyType) => (
          <SurveyType
            key={surveyType.id}
            surveyType={surveyType}
          />
        ))}
      </TabsNavigation>
    </div>
  );
};

export default Surveys;
