import { useSelector } from 'react-redux';
import { getAuthData } from '../selectors/auth';
import getNecessaryRolesUserHas from '../utils/getNecessaryRolesUserHas';

function useRoleRestriction(necessaryRolesPerComponent) {
  const auth = useSelector(getAuthData).auth;
  if(auth) {
    const { roles: userRoleIds } = auth;
    const listOfNecessaryRolesPerComponent = Object.entries(necessaryRolesPerComponent);
    const roleAccessPerComponent = {};

    listOfNecessaryRolesPerComponent.forEach((rolesPerComponent) => {
      const component = rolesPerComponent[0];
      const necessaryRoles = rolesPerComponent[1];
      const necessaryRolesUserHas = getNecessaryRolesUserHas(necessaryRoles, userRoleIds);

      const userHasAccess = necessaryRolesUserHas.length > 0;
      roleAccessPerComponent[component] = userHasAccess;
    });

    return roleAccessPerComponent;
  }

}

export default useRoleRestriction;
