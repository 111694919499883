export const surveyWizardExpenseTypes = state => state.surveyWizard.expenses.expenseTypes;
export const surveyWizardExpenseQuestions = state => state.surveyWizard.expenses.expenseQuestions;
export const surveyWizardExpenseSelectedExpenseType = state => state.surveyWizard.expenses.selectedExpenseType;
export const surveyWizardSelectedQuestionSelector = state => state.surveyWizard.expenses.selectedQuestion;
export const surveyWizardCurrentQuestionDetailsSelector = state => {
  const index = surveyWizardSelectedQuestionSelector(state);
  return surveyWizardCurrentExpenseQuestions(state)[index] || {};
}
export const surveyWizardCurrentExpenseQuestions = state => {
  const currentExpense = surveyWizardExpenseSelectedExpenseType(state);
  return (state.surveyWizard.expenses.expenseQuestions[currentExpense] || []);
}
export const surveyWizardExpenseTotalQuestionsSelector = state => {
  return surveyWizardCurrentExpenseQuestions(state).length;
}
export const surveyWizardExpenseTabsSelector = state => surveyWizardExpenseTypes(state || [])
  .map(type => ({
    title: type.title,
    id: type.id,
    selected: surveyWizardExpenseSelectedExpenseType(state) === type.id
  }));