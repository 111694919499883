import { useEffect, useRef } from 'react';

function usePreviousState(state) {
  const ref = useRef();
  
  useEffect(() => {
    ref.current = state;
  });
  
  return ref.current;
}

export default usePreviousState;
