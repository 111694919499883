export const EMPLOYEE_INITIAL_STATE = {
  list: [],
  selected: {
    name: '',
    jobTitle: '',
    department: '',
    email: '',
    hireDate: null,
    termDate: null,
    entityId: '',
    userId: '',
    rdHours: 0,
    totalHours: '',
  },
};
