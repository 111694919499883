import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { usePromiseTracker } from "react-promise-tracker";
import { tags } from '../../../../constants/promiseTracker';

// SELECTORS
import { getSelectedContainer } from '../../../../selectors/containers';

// COMPONENTS
import LoadingElement from '../../../../commonComponents/LoadingElement';
import Question from '../../../../commonComponents/Question';
import Typography from '@material-ui/core/Typography'

// CONSTANTS
import { TEXTBOX_PLACEHOLDER, ENTITY_OVERVIEW_NO_DATA } from '../constants'
import useDebounce from '../../../../hooks/useDebounce';
import { getOverviewQuestions, updateOrCreateAnswers } from '../api';
import { handleError } from '../../../../actions/error';

const EntityOverview = ({ entity }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([]);
  const [overviewQuestionsLoadingState, setOverviewQuestionsLoadingState] = useState('');
  const selectedContainer = useSelector(getSelectedContainer)
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.GET_OVERVIEW_QUESTIONS, delay: 0 });

  useEffect(() => {
    if (selectedContainer.tax_year) {
      const payload = {
        jurisdictionId: entity.jurisdictionId,
        entityId: entity.entityId,
        taxYear: selectedContainer.tax_year
      }
      async function getQuestions() {
        setOverviewQuestionsLoadingState('loading');
        try {
          const response = await getOverviewQuestions(payload);
          await Promise.all(response.map(async (question) => {
            if (!question.answer) {
              question.answer = {
                entityId: payload.entityId,
                questionId: question.id,
                value: '',
              };
              try {
                const payload = [question.answer];
                const { answerId } = await updateOrCreateAnswers(payload, question.answer.projectId);
                question.answer.answerId = answerId;
              } catch (err) {
                dispatch(handleError(err));
              }
            } else {
              question.answer.answerId = question.answer.id;
            }
          }));
          setQuestions(response);
          setOverviewQuestionsLoadingState('done');
        } catch (err) {
          dispatch(handleError(err));
          setOverviewQuestionsLoadingState('error');
        }
      };
      getQuestions();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainer, entity]);

  const [debouncedSave] = useDebounce((questionId) => {
    const question = questions.find(question => question.id === questionId);
    if (question === undefined) return;
    const answer = question.answer;

    const saveNow = async () => {
      try {
        await updateOrCreateAnswers([answer]);
      } catch (err) {
        dispatch(handleError(err));
        console.error('err', err);
      }
    };
    saveNow();
  }, 2000);

  if (!questions || isDataFetchInProgress) {
    return <LoadingElement />;
  }
  else if (questions && !questions.length) {
    return <Typography className={styles.noData} variant={'subtitle1'}>{ENTITY_OVERVIEW_NO_DATA}</Typography>
  }

  const handleChange = async (value, id) => {
    const questionsCopy = [...questions];
    const question = questionsCopy.find(quest => quest.id === id);
    const newAnswer = { ...question.answer, value };
    question.answer = newAnswer;
    setQuestions(questionsCopy);
    debouncedSave(newAnswer.questionId);
  };

  const handleBlur = async (_, id) => {
    const questionsCopy = [...questions];
    const question = questionsCopy.find(quest => quest.id === id);
    try {
      const payload = [question.answer];
      await updateOrCreateAnswers(payload, question.answer.projectId);
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  return (
    <div className={styles.questionsContainer}>
      {overviewQuestionsLoadingState === 'done' && questions.map((question, i) => (
        <Question
          key={`${entity.entityId}-${i}-question`}
          entityKey={`${entity.entityId}-${i}-question`}
          question={question.text}
          title={question.title}
          answer={{ description: question?.answer?.value || '' }}
          type={'text'}
          onBlur={handleBlur}
          onChange={handleChange}
          id={question.id}
          textboxPlaceholder={TEXTBOX_PLACEHOLDER}
        />
      ))}
    </div>
  );
};

export default EntityOverview;
