import { combineReducers } from 'redux';
import { AUTH_LOGOUT_SUCCESS } from '../constants/actionTypes/auth';
import auth from './auth';
import users from './users';
import common from './common';
import tenants from './tenants';
import projects from './projects';
import entities from './entities';
import timesheets from './timesheets';
import containers from './containers';
import entityGroups from './entityGroups';
import entityInfo from './entityInfo';
import employees from './employees';
import credits from './credits';
import expense from './expense';
import config from './config';
import reports from './report'
import message from './message';
import surveys from './surveys';
import surveyWizard from './surveyWizard/index.js';
import creditDetail from './creditDetail';
import modal from "./modal";

const mainReducer = combineReducers({
  auth,
  common,
  users,
  tenants,
  projects,
  entities,
  timesheets,
  containers,
  entityGroups,
  credits,
  employees,
  entityInfo,
  expense,
  config,
  reports,
  message,
  surveys,
  surveyWizard,
  creditDetail,
  modal
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT_SUCCESS || action.type === 'RESET_REDUCERS') {
    // clearing up all the reducers but the config one
    const config = state.config;
    state = undefined;
    state = {...state, config}
  }
  return mainReducer(state, action);
};

export default rootReducer
