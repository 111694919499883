import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_FAIL,
  CLEAN_LOGIN_ERROR,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  AUTH_LICENSE_AGREEMENT_SUCCESS,
  AUTH_LICENSE_AGREEMENT_FAIL,
  CHANGE_PASSWORD_FAIL,
  ASK_FOR_NEW_PASSWORD_AFTER_RESET, 
  SET_ROLES_SUCCESS,
} from '../constants/actionTypes/auth';

const initialState = {
  auth: null,
  message: ''
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        auth: action.auth,
        error: null,
      }
    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        error: action.err,
        message: action.message,
        auth: null
      }
    case AUTH_LICENSE_AGREEMENT_SUCCESS:
      return {
        ...state,
        auth: { ...state.auth, licenseAgreement: 1 },
        error: null,
      }
    case AUTH_LICENSE_AGREEMENT_FAIL:
      return {
        ...state,
        error: action.err,
        message: action.message,
        auth: { ...state.auth, licenseAgreement: 0 }
      }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        auth: null,
        error: null,
      }
    case AUTH_LOGOUT_FAIL:
      return {
        ...state,
        message: action.message,
        error: action.err,
      }
    case CLEAN_LOGIN_ERROR:
      return {
        ...state,
        message: '',
        error: null,
      }
    case RESET_PASSWORD_SUCCESS: 
      return {
        ...state,
        isResetPasswordSuccessful: true,
        shouldAskForNewPassword: true,
      }
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        resetPasswordError: action.error,
        isResetPasswordSuccessful: false,
        shouldAskForNewPassword: false,
      }
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changePasswordError: action.error,
      }
    case ASK_FOR_NEW_PASSWORD_AFTER_RESET:
      return {
        ...state,
        shouldAskForNewPassword: action.shouldAskForNewPassword
      }
    case SET_ROLES_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          roles: action.roles
        }
      }
    default: return state;
  }
}
export default auth;
