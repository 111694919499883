import React from 'react';

const SurveyDocumentsSvg = ({ className }) => {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Icon/DocChecklist</title>
      <g id="Icon/DocChecklist" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M17.0003662,15 L17.0003662,17 L11.0003662,17 L11.0003662,15 L17.0003662,15 Z M8,15 C8.55228475,15 9,15.4477153 9,16 C9,16.5522847 8.55228475,17 8,17 C7.44771525,17 7,16.5522847 7,16 C7,15.4477153 7.44771525,15 8,15 Z M8,11 C8.55228475,11 9,11.4477153 9,12 C9,12.5522847 8.55228475,13 8,13 C7.44771525,13 7,12.5522847 7,12 C7,11.4477153 7.44771525,11 8,11 Z M8,7 C8.55228475,7 9,7.44771525 9,8 C9,8.55228475 8.55228475,9 8,9 C7.44771525,9 7,8.55228475 7,8 C7,7.44771525 7.44771525,7 8,7 Z M11.0003662,11 L17.0003662,11 L17.0003662,13 L11.0003662,13 L11.0003662,11 Z M11.0003662,7 L17.0003662,7 L17.0003662,9 L11.0003662,9 L11.0003662,7 Z M19,3 L14.82,3 C14.4,1.84 13.3,1 12,1 C10.7,1 9.6,1.84 9.18,3 L5,3 C4.86,3 4.73,3.01 4.6,3.04 C4.21,3.12 3.86,3.32 3.59,3.59 C3.41,3.77 3.26,3.99 3.16,4.23 C3.06,4.46 3,4.72 3,5 L3,19 C3,19.27 3.06,19.54 3.16,19.78 C3.26,20.02 3.41,20.23 3.59,20.42 C3.86,20.69 4.21,20.89 4.6,20.97 C4.73,20.99 4.86,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z M11.25,3.5 C11.25,3.09 11.59,2.75 12,2.75 C12.41,2.75 12.75,3.09 12.75,3.5 L12.75,4.98809814 L11.25,4.98809814 L11.25,3.5 Z M19,19 L5,19 L5,5 L19,5 L19,19 Z" id="Doc-Checklist-24px" fill="#000000"></path>
      </g>
    </svg>
  );
};

export default SurveyDocumentsSvg;
