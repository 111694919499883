import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';

import sortDropdownOptions from '../../utils/sortDropdownOptions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  label:{
    marginBottom: theme.spacing(1)
  },
  select: {
    marginBottom: theme.spacing(2),
    width: '100%',
    minHeight: '40px',
  },
  chip: {
    margin: 3,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  menuItem: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.veryLight,
      color: theme.palette.primary.main
    }
  }
}));

const MultipleSelect = ({ sortOptions, options, status, handleChange, label, maxChips}) => {
  const styles = useStyles();
  const theme = useTheme();

  const getStyles = (option, status, theme) => {
    return {
      fontWeight:
      status.indexOf(option) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const renderChips = (selectedItems) => {
    const MAX_CHIPS_TO_SHOW = maxChips ? maxChips : 1;
    let selectedItemsToShow = selectedItems;

    const doesChipsLengthExceedLimit = selectedItems.length > MAX_CHIPS_TO_SHOW;
    if (doesChipsLengthExceedLimit) {
      selectedItemsToShow = selectedItems.slice(0, MAX_CHIPS_TO_SHOW);
      selectedItemsToShow.push('See More...');
    }

    return (
      <div className={styles.chips}>
        {selectedItemsToShow.map(value => (
          <Chip key={value} label={value} className={styles.chip} />
        ))}
      </div>
    )
  };

  const sortedOptions = sortDropdownOptions(options, sortOptions);

  return (
    <div className={styles.searchStatusContainer}>
      <InputLabel className={styles.label} id="demo-mutiple-chip-label">{label || 'Status'}</InputLabel>
      <Select
        placeholder=""
        className={styles.select}
        multiple
        displayEmpty={true}
        value={status}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={renderChips}
        MenuProps={MenuProps}
      >
        {sortedOptions.map((option) => (
          <MenuItem className={styles.menuItem} key={option} value={option} style={getStyles(option, status, theme)}>
            {option}
          </MenuItem>
        ))}
      </Select>
  </div>
  )
}

export default MultipleSelect
