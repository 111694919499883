import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import downloadBuffer from '../../../../utils/downloadBuffer';

// ACTIONS
import { bulkDeleteExpense, getExpenses, uploadXLSXFile, clearValidationErrors } from '../../../../actions/expense';
import TableAndCrud from '../../TableAndCrud';
import { toggleCrudPanel } from '../../../../actions/common';
import { selectEntity } from '../../../../actions/entities';
import { showModal } from "../../../../actions/modal";

// SELECTORS
import {
  getExpensesByType,
  getExpensesTaxYears,
  getTotalExpenses,
  getUploadValidationErrors,
} from '../../../../selectors/expense';

// COMPONENTS
import ExpenseCrud from '../ExpenseCrud';
import FileUpload from '../../../../commonComponents/FileUpload';
import ExpensesDownload from './ExpensesDownload';
import { CircularProgress, Typography } from '@material-ui/core/';
import { Select, MenuItem, InputLabel, FormControl} from '@material-ui/core';
import ExpenseValidationModal from '../ExpenseValidationModal'
import AutocompleteSelect from '../../../../commonComponents/FormBuilder/fieldTypes/AutocompleteSelect';

import { useStyles } from './styles';

// CONSTANTS
import {NO_DATA, MAX_TABLE_HEIGHT, TOTAL_EXPENSE, NECESSARY_ROLES_PER_COMPONENT} from '../constants';
import { apiClient } from '../../../../api/apiClient';
import { handleError } from '../../../../actions/error';
import { showMessage } from '../../../../actions/message';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../../constants/promiseTracker';
import { getSelectedEntityObject } from '../../../../selectors/entities';
import { formatResultByType } from '../../../../utils/customFormatter';
import useRoleRestriction from "../../../../hooks/useRoleRestriction";

const ExpenseType = ({
  tableConfig: { tableHead, searchBy, searchByPlaceholder, rowsConfig },
  expenseTypeId,
  selectedEntity,
  currencyCode,
  name
}) => {
  const [isExpenseDownloading, setIsExpenseDownloading] = useState(false);
  const [isExpenseTemplateDownloading, setIsExpenseTemplateDownloading] = useState(false);
  const [taxYear, setTaxYear] = useState('');
  const dispatch = useDispatch();
  const expenses = useSelector(getExpensesByType(selectedEntity, expenseTypeId), shallowEqual);
  const entities = useSelector(state => state.common.entities);
  const taxYears = (useSelector(getExpensesTaxYears, shallowEqual) || []).sort((a, b) => (a < b) ? 1 : -1);
  const totalExpenses = useSelector(getTotalExpenses, shallowEqual);
  const validationErrors = useSelector(getUploadValidationErrors, shallowEqual);
  const selectedEntityObject = useSelector(getSelectedEntityObject, shallowEqual);
  const { promiseInProgress: isBulkUploadInProgress } = usePromiseTracker({ area: tags.EXPENSES_BULK_UPLOAD, delay: 0 });
  const { promiseInProgress: isFetchExpensesInProgress } = usePromiseTracker({ area: tags.FETCH_EXPENSES, delay: 0 });

  const countryTotalExpenses = totalExpenses[TOTAL_EXPENSE.country];
  const jurisdictionIsoCode = selectedEntityObject.jurisdiction && selectedEntityObject.jurisdiction.iso_code;
  const currencyIsoCode = selectedEntityObject.currency && selectedEntityObject.currency.iso_code;
  const countryTotalExpensesFormatted = formatResultByType({ value: countryTotalExpenses, type: 'currency', currencyIsoCode });
  const hasRoleAccessTo = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);
  const shouldShowCheckBoxes = hasRoleAccessTo.expensesTypes;

  const styles = useStyles();

  useEffect(() => {
    const fetchExpenses = () => {
      trackPromise(
        dispatch(getExpenses(expenseTypeId, selectedEntity)),
        tags.FETCH_EXPENSES
      );
    }

    if (selectedEntity) {
      fetchExpenses()
    }
  }, [dispatch, expenseTypeId, selectedEntity]);

  const handleFileUpload = e => {
    const [file] = e.target.files;

    dispatch(uploadXLSXFile(file, expenseTypeId, selectedEntity, currencyCode))
    resetExpenseTaxYearFilter()
  };

  const clearUploadValidationErrors = () => {
    dispatch(clearValidationErrors())
  }

  const resetExpenseTaxYearFilter = () => {
    setTaxYear('');
  };

  const handleFileDownload = async () => {
    try {
      setIsExpenseDownloading(true);

      let queryParams = `expenseTypeRefId=${expenseTypeId}&entityId=${selectedEntity}`;
      if (taxYear) {
        queryParams += `&expenseTaxYear=${taxYear}`;
      }
      const fileDownload = await apiClient({
        method: 'get',
        url: `/expense/export?${queryParams}`,
      });

      setIsExpenseDownloading(false);

      if (fileDownload.Body.data.length) {
        downloadBuffer(fileDownload, `${name}_${Date.now()}.xlsx`);
        return;
      }

      dispatch(showMessage('info', 'There are no expenses to download in this section'));
    } catch (err) {
      setIsExpenseDownloading(false);
      dispatch(handleError(err));
    }
  };

  const handleExpenseTemplateDownload = async () => {
    try {
      setIsExpenseTemplateDownloading(true);

      const fileDownload = await apiClient({
        method: 'get',
        url: `/expense/export-template?expenseTypeRefId=${expenseTypeId}`,
      });

      setIsExpenseTemplateDownloading(false);

      if (fileDownload.Body.data.length) {
        downloadBuffer(fileDownload, `${name}_${Date.now()}.xlsx`);
      }

    } catch (err) {
      setIsExpenseTemplateDownloading(false);
      dispatch(handleError(err));
    }
  };

  const handleTaxYearChange = e => {
    const { value } = e.target;
    setTaxYear(value);
    dispatch(getExpenses(expenseTypeId, selectedEntity, value));
  };

  const handleConfirm = ({ actionToExecuteOnConfirm, actionName, buttonText }) => {
    dispatch(showModal('confirmation', {
      title: 'We need your approval',
      textBody: `Are you sure you want to ${actionName.toLowerCase()}?`,
      buttonText,
      onConfirm: actionToExecuteOnConfirm
    }));
  };

  const handleBulkDelete = (selectedRowsData, setSelectedRowsData) => {
    function actionToExecuteOnConfirm() {
      const expenseIdsToDelete = selectedRowsData.map(row => row.expense_id);
      dispatch(bulkDeleteExpense(expenseIdsToDelete, expenseTypeId, selectedEntity));
      dispatch(toggleCrudPanel(false));
      setSelectedRowsData([])
    };
    handleConfirm({
      actionToExecuteOnConfirm,
      actionName: `delete ${selectedRowsData.length} ${selectedRowsData.length > 1 ? 'expenses' : 'expense'}`,
      buttonText: 'Delete',
    });
  };

  const handleEntitySelect = (event, name, value) => {
    const entityId = parseInt(value.entity_id)
    dispatch(selectEntity(entityId))
  };

  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <div className={styles.entityDropdown}>
          <AutocompleteSelect
            name="entity_id"
            label="Entities"
            muiProps={{
              forcePopupIcon: true
            }}
            sortOptions={{
              propertyToSortBy: 'name',
              direction: 'ascending'
            }}
            options={entities}
            optionKeys={{
              name: 'name',
              value: 'entity_id'
            }}
            value={selectedEntity}
            onChange={handleEntitySelect}
          />
        </div>
        <CreateNewButton />
      </div>
    )
  };

  return (
    <>
      <div className={styles.topControlsContainer}>
        <div className={styles.topControlsLeftSection}>
          {expenseTypeId && selectedEntity && <FormControl>
            <InputLabel id="expenseTaxYear">Expense Tax Year</InputLabel>
            <Select
              className={styles.selector}
              labelId="expenseTaxYear"
              onChange={handleTaxYearChange}
              value={taxYear}
            >
              <MenuItem value={''}>{'-'}</MenuItem>
              {taxYears && taxYears.map(year =>
                <MenuItem key={year} value={year}>{year}</MenuItem>
              )}

            </Select>
          </FormControl>}
          <div className={styles.totalCountryContainer}>
            <span className={styles.countryTotalExpensesSubtitle}>Total {jurisdictionIsoCode} Qualified Expenses: </span>
            <span className={styles.countryTotalExpensesValue} data-testid="totalCountryContainer">{countryTotalExpensesFormatted}</span>
          </div>
        </div>


        {validationErrors.length ? <ExpenseValidationModal errors={validationErrors} handleClose={clearUploadValidationErrors}/> : null}


        <div className={styles.ButtonContainer}>
          <div className={styles.ButtonContainerChild}>
            <FileUpload isLoading={isBulkUploadInProgress} onChange={handleFileUpload} buttonText={"Upload Expenses"} type="round" />
          </div>
          <div className={styles.ButtonContainerChild}>
            <ExpensesDownload
              onClick={handleFileDownload}
              isLoading={isExpenseDownloading}
              title={'Download Expenses'}
            />
          </div>
          <div className={styles.ButtonContainerChild}>
            <ExpensesDownload
              onClick={handleExpenseTemplateDownload}
              isLoading={isExpenseTemplateDownloading}
              title={'Download Expenses Template'}
            />
          </div>
        </div>

      </div>
      {
        isFetchExpensesInProgress ?
          <CircularProgress className={styles.circularProgress} size={24} /> :
          (!expenses || !expenses.length) && <Typography className={styles.noData} variant={'subtitle1'}>{NO_DATA}</Typography>
      }
      {selectedEntity &&
        <TableAndCrud
          tableHead={tableHead}
          miscParams={{ selectedEntity, expenseTypeId }}
          tableData={expenses}
          searchBy={searchBy}
          searchByPlaceholder={searchByPlaceholder}
          tableRows={rowsConfig}
          shouldShowCheckBoxesOnClick={shouldShowCheckBoxes}
          maxTableHeight={MAX_TABLE_HEIGHT}
          formatConfig={{
            locale: 'en-US',
            currency: currencyCode,
            shouldIncludeCents: true
          }}
          idKey='expense_id'
          createNewButtonTitle="Add Expense"
          renderComponentBeforeTable={renderComponentBeforeTable}
          bulkActions={{
            delete: handleBulkDelete,
          }}
        >
          <ExpenseCrud
            expense_type_ref_id={expenseTypeId}
            resetExpenseTaxYearFilter={resetExpenseTaxYearFilter}
            currencyCode={currencyCode}
          />
        </TableAndCrud>}
    </>
  );
};

ExpenseType.propTypes = {
  tableConfig: PropTypes.shape({
    tableHead: PropTypes.arrayOf(PropTypes.shape({})),
    tableRows: PropTypes.arrayOf(PropTypes.string),
    searchBy: PropTypes.string,
    expenseTypeId: PropTypes.number,
    selectedEntity: PropTypes.number,
  })
};

export default ExpenseType;
