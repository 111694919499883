import React from 'react';
import MuiRadio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';

export const radioStyleTypes = [
  'full-small'
];

export const propsByRadioType = {
  'full-small': {
    size: 'small'
  }
};

export const useStyles = makeStyles((theme) => ({
  'full-small': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    height: '0.75em',
    width: '0.75em'
  }
}));

const Radio = ({ radioStyle, ...props }) => {
  const styles = useStyles();
  const additionalProps = {...propsByRadioType[radioStyle]} || {};
  if (styles[radioStyle]) {
    additionalProps.checkedIcon = <span className={styles[radioStyle]} />
  }

  return <MuiRadio {...props} {...additionalProps} />
}

export default Radio;
