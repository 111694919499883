import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  circularProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    left: '50%',
    top: '50%',
  }
}));

const LoadingElement = () => {
  const styles = useStyles();

  return (
    <CircularProgress className={styles.circularProgress} />
  );
};

export default LoadingElement;
