import React from 'react';
import { useSelector } from 'react-redux';
import { getAuthData } from '../selectors/auth';
import { Redirect } from 'react-router-dom';

const WithAuth = (Wrapped) => {
  const { auth } = useSelector(getAuthData);
  const isAuthenticated = auth !== undefined && auth !== null && Object.entries(auth).length !== 0;

  return () => {
    return isAuthenticated ? <Wrapped/> : <Redirect to='/login-redirect'/>
  }
};

export default WithAuth;
