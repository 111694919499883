import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { STUDY_CENTRAL_INITIAL_STATE } from './constants';
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import { selectContainer } from '../../../actions/container';
import { selectCredit } from '../../../actions/credit';
import { getCredits, getIsFetchingCredits } from '../../../selectors/credits';
import TableAndCrud from '../TableAndCrud';
import { headConfig, rowsConfig } from './tableConfig';
import StudyCentralCrud from './StudyCentralCrud';
import { fetchCredits } from './api';
import { getSelectedContainer } from '../../../selectors/containers';
import { getJurisdictions } from '../../../selectors/common';
import { getEntities } from '../../../selectors/entities';
import formatCreditForTableDisplay from '../../../utils/formatCreditForTableDisplay';
import { apiClient } from '../../../api/apiClient';
import { MAX_TABLE_HEIGHT } from '../constants'
import Dials from '../../../commonComponents/Dials';
import GoToCreditDetailsButton from './GoToCreditDetailsButton';
import { useStyles } from './styles';
import { fetchCreditsBegin, fetchCreditsSuccess } from '../../../actions/credit';

const StudyCentral = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const container = useSelector(getSelectedContainer, shallowEqual);
  const credits = useSelector(getCredits, shallowEqual);
  const jurisdictions = useSelector(getJurisdictions, shallowEqual);
  const entities = useSelector(getEntities, shallowEqual);
  const isFetchingCredits = useSelector(getIsFetchingCredits, shallowEqual);

  const [taxPayerTypes, setTaxPayerTypes] = useState([]);
  const [publishedStatuses, setPublishedStatuses] = useState([]);
  const [creditStatuses, setCreditStatuses] = useState([]);
  const { container_id: containerId } = container;

  useEffect(() => {
    async function fetchTheCredits() {
      dispatch(fetchCreditsBegin());
      const credits = await fetchCredits(containerId);
      dispatch(fetchCreditsSuccess(credits));
    }
    if (containerId) {
      fetchTheCredits();
    }
  }, [dispatch, containerId]);

  useEffect(() => {
    const fetchEnumData = async () => {
      const taxPayerTypesResult = await apiClient({
        method: 'get',
        url: '/tax-payer-type',
      });
      const publishedStatusesResult = await apiClient({
        method: 'get',
        url: '/published-status',
      });
      const creditStatusesResult = await apiClient({
        method: 'get',
        url: '/credit-status',
      });

      setTaxPayerTypes(taxPayerTypesResult);
      setPublishedStatuses(publishedStatusesResult);
      setCreditStatuses(creditStatusesResult);
    };
    fetchEnumData();
  }, []);

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId));
  };

  const totalCredits = credits.length;
  const completedCredits = credits.filter((credit) => credit.creditStatus === 'Completed').length;
  const pendingCredits = credits.filter((credit) => credit.creditStatus === 'Pending').length;
  const dialsConfig = [
    { type: 'default', title: 'All Studies ', value: totalCredits },
    { type: 'pending', title: 'Pending', value: pendingCredits },
    { type: 'completed', title: 'Completed', value: completedCredits }
  ];
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <CreateNewButton />
        <Dials dialConfig={dialsConfig} />
      </div>
    )
  };

  const cellWrappers = {
    creditDetailStatus: {
      CellWrapper: GoToCreditDetailsButton,
    }
  };

  const createNewCalculation = () => {
    dispatch(selectCredit(STUDY_CENTRAL_INITIAL_STATE.selected));
  }

  return (
    <>
      <ContainersDropdown onChange={handleContainerSelect} />

      <div className={styles.container}>
        <h1>Study Central</h1>
        <TableAndCrud
          tableHead={headConfig}
          tableData={formatCreditForTableDisplay(credits)}
          searchBy="legalEntityName"
          searchByPlaceholder="Entity Name"
          initialOrderBy="legalEntityName"
          tableRows={rowsConfig}
          maxTableHeight={MAX_TABLE_HEIGHT}
          idKey="id"
          createNewButtonTitle="New Calculation"
          hideCreateButton={isFetchingCredits}
          renderComponentBeforeTable={renderComponentBeforeTable}
          cellWrappers={cellWrappers}
          isDataFetchInProgress={isFetchingCredits}
          handleButtonClickEffect={createNewCalculation}
        >
          <StudyCentralCrud
            entities={entities}
            jurisdictions={jurisdictions}
            taxPayerTypes={taxPayerTypes}
            publishedStatuses={publishedStatuses}
            creditStatuses={creditStatuses}
          />
        </TableAndCrud>
      </div>
    </>
  );
};

export default StudyCentral;
