import React, { useEffect } from 'react';
import TableAndCrud from '../TableAndCrud';
import { headConfig, rowsConfig } from './tableConfig';
import EmployeesCrud from './EmployeesCrud';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getEmployees } from '../../../selectors/employees';
import { makeStyles } from '@material-ui/core/styles';
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import EntitiesDropdown from '../../GlobalDropdowns/EntitiesDropdown';
import { selectContainer } from '../../../actions/container';
import { selectEntity } from '../../../actions/entities';
import { getSelectedEntity } from '../../../selectors/entities';
import { fetchEmployees } from '../../../actions/employee';
import { MAX_TABLE_HEIGHT  } from '../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
}));

const Employees = () => {
  const employees = useSelector(getEmployees, shallowEqual);
  const dispatch = useDispatch();
  const entityId = useSelector(getSelectedEntity, shallowEqual);
  const styles = useStyles();

  useEffect(() => {
    if (entityId) {
      dispatch(fetchEmployees(entityId));
    }
  }, [dispatch, entityId]);

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId))
  };

  const handleEntitySelect = (value) => {
    const entityId = parseInt(value);
    dispatch(selectEntity(entityId))
  };

  return (
    <>
      <ContainersDropdown onChange={handleContainerSelect} />
      <EntitiesDropdown onChange={handleEntitySelect} />

      <div className={styles.container}>
        <h1>Employees</h1>
        <TableAndCrud
          tableHead={headConfig}
          tableData={employees}
          searchBy="name"
          searchByPlaceholder="Name"
          tableRows={rowsConfig}
          maxTableHeight={MAX_TABLE_HEIGHT}
          idKey="id"
        >
          <EmployeesCrud/>
        </TableAndCrud>
      </div>

    </>
  );
};

export default Employees;
