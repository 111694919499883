import { apiClient } from "../../../api/apiClient";

export const getQuestions = (taxYear, jurisdictionId, projectToQualify) =>  {
  return apiClient({
    method: 'get',
    url: `/project-qualification?taxYear=${taxYear}&jurisdictionId=${jurisdictionId}&projectId=${projectToQualify}`,
  });
};

export const updateOrCreateAnswers = (payload, projectId) =>  {
  return apiClient({
    method: 'post',
    url: '/project-qualification/answer',
    data: { answers: payload, projectId },
  });
};