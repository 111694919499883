import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => {

  const resolveBackground = valueType => {
    const backgrounds = {
      qualifying: 'white',
      allocated: theme.palette.primary.main,
      remaining: theme.palette.grey[100]
    }
    return backgrounds[valueType]
  }

  const resolveBorder = valueType => {
    const borders = {
      qualifying: theme.palette.primary.main,
      allocated: theme.palette.primary.main,
      remaining: theme.palette.grey[500]
    }
    return borders[valueType]
  }

  const resolveColor = valueType => {
    const colors = {
      qualifying: theme.palette.primary.main,
      allocated: theme.common.common.white,
      remaining: theme.palette.grey[500]
    }
    return colors[valueType]
  }

  return ({
  figureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  figure: {
    width: '57px',
    height: '57px',
    background: props => resolveBackground(props.valueType),
    border: '2px solid',
    borderColor: props => resolveBorder(props.valueType),
    color: props => resolveColor(props.valueType),
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold'
  },

})})

const Figure = ({value, title, valueType}) => {
  const style = useStyles({valueType});
  return (
    <div className={style.figureContainer}>
      <div className={style.figure}>
        <Typography align={'center'} variant={'caption'}>{value}%</Typography>
      </div>
      <Typography align={'center'} variant={'caption'}>{title}</Typography>
    </div>
  );
};

Figure.propTypes = {
  value: PropTypes.number,
  title: PropTypes.string,
  valueType: PropTypes.string,

};

export default Figure;
