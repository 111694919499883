import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeFilterData } from "../../../selectors/employees";
import { getSelectedEntity } from "../../../selectors/entities";
import { getSelectedContainer } from '../../../selectors/containers';
import { fetchEmployeeFilterOptions } from "../../../actions/employee";
import { PROJECT_QUALIFICATION_STATUSES } from "../../../components/ServiceManager/Projects/constants";

const EmployeesModal = {
  key: 'assignEmployees',
  title: 'Assign Employees',
  searchPlaceholder: 'Search employee names',
  filters: [
    { placeholder: 'Local Jurisdiction', key: 'jurisdictions', fetchType: 'employeeJurisdictions', options: [] },
    { placeholder: 'Department', key: 'department', fetchType: 'departments', options: [] },
    { placeholder: 'Job Title', key: 'jobTitle', fetchType: 'jobTitles', options: [] }
  ]
};

const ProjectsModal = {
  key: 'assignProjects',
  title: 'Assign Projects',
  searchPlaceholder: 'Search projects by name',
  filters: [
    { key: 'qualification_status_id', placeholder: 'Project Qualification', options: [] }
  ]
};

const ProjectQualification = {
  'qualification_status_id': [
    { key: 'Pending', value: PROJECT_QUALIFICATION_STATUSES.Pending },
    { key: 'Qualified', value: PROJECT_QUALIFICATION_STATUSES.Qualified },
    { key: 'Not qualified', value: PROJECT_QUALIFICATION_STATUSES["Not qualified"] }
  ]
};

const ModalInfo = {
  'employees': EmployeesModal,
  'projects': ProjectsModal
}

export const useLoadFetchData = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const entityId = useSelector(getSelectedEntity);
  const allFilterData = useSelector(getEmployeeFilterData);
  const container = useSelector(getSelectedContainer);

  useEffect(() => {
    if (entityId && container?.tax_year) {
      dispatch(fetchEmployeeFilterOptions(entityId, container.tax_year));
    }
  }, [dispatch, container, entityId]);

  useEffect( () => {
    if (allFilterData) {
      setLoading(false);
    }
  }, [allFilterData]);

  return loading;
}

const useOptionsData = (dataRef) => {
  const [modalData, setModalData] = useState(dataRef);
  const allFilterData = useSelector(getEmployeeFilterData);

  useEffect(() => {
    if (allFilterData && dataRef.key === EmployeesModal.key) {
      const currentData = modalData;
      currentData.filters.forEach((filter) => {
        filter.options = allFilterData && allFilterData[filter.fetchType];
      });

      setModalData(currentData);
    }
  }, [allFilterData, dataRef, modalData]);

  useEffect(() => {
    if (dataRef.key === ProjectsModal.key) {
      const currentData = modalData;
      currentData.filters.forEach((filter) => {
        filter.options = ProjectQualification[filter.key];
      });

      setModalData(currentData);
    }
  }, [dataRef, modalData]);

  return modalData;
}

export const useDataModal = ({ kind }) => {
  const [modalData] = useState(useOptionsData(ModalInfo[kind]));
  return modalData;
};
