import * as d3 from 'd3';

const drawNodeConnector = (node, yrad) => {
  const context = d3.path();
  const { source, target } = node;

  const curveSize = 0;
  const connectorSize = 50 + yrad;
  const sourceConnectorY = source.y + connectorSize;

  context.moveTo(source.x, source.y);
  context.lineTo(source.x, sourceConnectorY - curveSize);

  context.moveTo(target.x, sourceConnectorY + curveSize + 3);
  context.bezierCurveTo(
    source.x, sourceConnectorY,
    source.x, sourceConnectorY,
    source.x, sourceConnectorY - curveSize
  );

  context.moveTo(target.x, sourceConnectorY + curveSize);
  context.lineTo(target.x, target.y);

  return context.toString();
};

export default drawNodeConnector;
