import React from 'react';
import GlobalDropdown from '../../commonComponents/GlobalDropdown';
import { getDropdownContainers } from '../../selectors/common';
import { useSelector } from 'react-redux';

const ContainersDropdown = ({ onChange }) => {
  const containers = useSelector(getDropdownContainers);
  const selectedContainer = useSelector(state => state.common.selectedContainer);

  return (
    <GlobalDropdown
      options={containers}
      onChange={onChange}
      value={selectedContainer}
      optionAccessors={{ name: 'name', value: 'container_id' }}
      label="Tax Year"
    />
  );
};

export default ContainersDropdown;
