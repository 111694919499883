export const roles = {
  FULL_ACCESS_ADMIN: 'FULL_ACCESS_ADMIN',
  CREDIT_CENTRAL_FULL_ACCESS: 'CREDIT_CENTRAL_FULL_ACCESS',
  RESOURCE_TRACKER_FULL_ACCESS: 'RESOURCE_TRACKER_FULL_ACCESS',
  PS_ASSOCIATE: 'PS_ASSOCIATE',
  ACCOUNT_EXECUTIVE: 'ACCOUNT_EXECUTIVE',
  REPOSITORY_ONLY: 'REPOSITORY_ONLY',
  SURVEY_RESPONDENT: 'SURVEY_RESPONDENT',
  PS_MANAGER: 'PS_MANAGER',
  LIMITED_ACCESS_ADMIN: 'LIMITED_ACCESS_ADMIN',
  SUPER_ACCESS_ADMIN: 'SUPER_ACCESS_ADMIN',
  DIY_CLIENT: 'DIY_CLIENT'
};

export const rolesByName = {
  FULL_ACCESS_ADMIN: 1,
  CREDIT_CENTRAL_FULL_ACCESS: 2,
  RESOURCE_TRACKER_FULL_ACCESS: 3,
  PS_ASSOCIATE: 4,
  ACCOUNT_EXECUTIVE: 5,
  REPOSITORY_ONLY: 6,
  SURVEY_RESPONDENT: 7,
  PS_MANAGER: 8,
  LIMITED_ACCESS_ADMIN: 9,
  SUPER_ACCESS_ADMIN: 10,
  DIY_CLIENT: 11
};

export const rolesById = {
  1: roles.FULL_ACCESS_ADMIN,
  2: roles.CREDIT_CENTRAL_FULL_ACCESS,
  3: roles.RESOURCE_TRACKER_FULL_ACCESS,
  4: roles.PS_ASSOCIATE,
  5: roles.ACCOUNT_EXECUTIVE,
  6: roles.REPOSITORY_ONLY,
  7: roles.SURVEY_RESPONDENT,
  8: roles.PS_MANAGER,
  9: roles.LIMITED_ACCESS_ADMIN,
  10: roles.SUPER_ACCESS_ADMIN,
  11: roles.DIY_CLIENT
};
