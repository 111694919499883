import currencySymbols from '../assets/currencySymbols';
import dateParser from './dateToDisplayableFormat';

const formatCreditForTableDisplay = (credits) => {
  const convertToFixedDecimal = (creditAmount) => {
    const fixedDecimal = creditAmount.toFixed(2);
    const withThousandsSeparator = Number(fixedDecimal).toLocaleString();
    return withThousandsSeparator;
  }
  const formatEntityCreditAmount = (entityCreditAmount, currencyIsoCode) => {
    if (!entityCreditAmount) {
      return '';
    }
    const currencySymbol = currencySymbols[currencyIsoCode].symbol;

    if (!isNaN(entityCreditAmount) || entityCreditAmount.indexOf(currencySymbol) === -1) {
      entityCreditAmount = `${currencySymbol}${convertToFixedDecimal(entityCreditAmount)}`;
    }
    return `(${currencyIsoCode}) ${entityCreditAmount}`;

  };
  const formatGroupCreditAmount = (groupCreditAmount, currencyIsoCode) => {
    if (!groupCreditAmount) {
      return '-';
    }

    const currencySymbol = currencySymbols[currencyIsoCode].symbol;
    if (!isNaN(groupCreditAmount) || groupCreditAmount.indexOf(currencySymbol) === -1) {
      groupCreditAmount = `${currencySymbol}${convertToFixedDecimal(groupCreditAmount)}`;
    }

    return `(${currencyIsoCode}) ${groupCreditAmount}`;
  };

  return credits.map((credit) => {
    const { dueDate, entityCreditAmount, groupCreditAmount, currencyIsoCode } = credit;
    return {
      ...credit,
      dueDateFormatted: dateParser(dueDate),
      entityCreditAmount: formatEntityCreditAmount(entityCreditAmount, currencyIsoCode),
      groupCreditAmount: formatGroupCreditAmount(groupCreditAmount, currencyIsoCode)
    }
  });
};

export default formatCreditForTableDisplay;
