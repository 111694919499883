import { roles } from '../../../constants/roles';

export const SECTION_TITLE = {
  plural: 'Expenses',
  singular: 'Expense'
}

export const NECESSARY_ROLES_PER_COMPONENT = {
  viewAnalysisButton: [],
  expensesTypes: [roles.FULL_ACCESS_ADMIN,roles.PS_ASSOCIATE,roles.ACCOUNT_EXECUTIVE,roles.REPOSITORY_ONLY,roles.PS_MANAGER,roles.LIMITED_ACCESS_ADMIN,roles.SUPER_ACCESS_ADMIN],
  expensesTypesFormActionsButton: [roles.FULL_ACCESS_ADMIN,roles.PS_ASSOCIATE,roles.ACCOUNT_EXECUTIVE,roles.REPOSITORY_ONLY,roles.PS_MANAGER,roles.LIMITED_ACCESS_ADMIN,roles.SUPER_ACCESS_ADMIN]
}

export const EXPENSES_TYPES_EXCLUDED_BY_ROLES = {
  [roles.DIY_CLIENT] : ['Wages for Qualified Services','Salary expenditures','Salary or wages of specified employees','Salary or wages of nonspecified employees','Employee Costs'],
}

export const CRUD_TYPES = {
  edit: 'edit',
  create: 'create'
}

export const EMPLOYEE_FIELD = 'employee'

export const NO_DATA = 'There is no data for the current selection'

export const MANDATORY_FIELDS = [ 'country_total_expense_amount', 'expense_tax_year'];

export const MAX_TABLE_HEIGHT = '90%'

export const NO_PROJECTS_TO_ALLOCATE = 'No available projects to allocate';

export const NO_REMAINING_TO_ALLOCATE =  'This expense has 100% allocated'

export const HOUR_SOURCES_BY_NAME = {
  custom: { sourceRefId: 1 },
  survey: { sourceRefId: 2 },
  resource: { sourceRefId: 3 }
};

export const TOTAL_EXPENSE = {
  country: 'country',
}

export const LOCAL_JURISDICTION_IDS = {
  NEBRASKA: 276,
  TEXAS: 294,
};

export const MAXIMUM_PERCENTAGE_FOR_PROJECT_ALLOCATION = 100;
