const getValueFromEvent = (event) => {
  let { value } = event.target;
  if (event.target.nodeName === 'INPUT') {
    value = event.target.value === '' ? null : event.target.value;
  }

  return value;
};

export default getValueFromEvent;
