const headConfig = [
  { name: 'Tax Year', id: 'tax_year', sortable: true },
];

const rowsConfig = ['name', 'tax_year'];

export {
  headConfig,
  rowsConfig,
};
