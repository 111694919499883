export const getEntities = state => state.entities.list;
export const getSelectedEntityForCrud = state => state.entities.selectedEntity;
export const getCurrencies = state => state.entities.currencies || [];
export const getSelectedContainer = state => state.common.selectedContainer;
export const getSelectedEntity = state => state.common.selectedEntity;
export const getSelectedEntityObject = state => {
  const { selectedEntity: selectedEntityId, entities } = state.common;
  if (!selectedEntityId || !entities) {
    return {};
  }
  
  const selectedEntity = entities.find(({ entity_id }) => entity_id === selectedEntityId);
  const dropdownSelectedEntity = typeof selectedEntity === 'undefined' ? {} : selectedEntity;
  return dropdownSelectedEntity;
}
