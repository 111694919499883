import React from 'react';
import { useRouteMatch, Switch, Route, Redirect } from 'react-router-dom';

import SurveyLists from './SurveyLists';
import SurveyWizard from './SurveyWizard';
import { useSelector } from 'react-redux';
import { getRoleData } from '../../selectors/auth';
import { roles } from '../../constants/roles';
import getNecessaryRolesUserHas from '../../utils/getNecessaryRolesUserHas';

const SurveyCentralRouter = () => {
  const match = useRouteMatch();
  const userRoles = useSelector(getRoleData) || [];
  const { PS_MANAGER, SURVEY_RESPONDENT, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE } = roles;
  
  const filterRoutesByRoles = () => {
    if(getNecessaryRolesUserHas([PS_MANAGER, SURVEY_RESPONDENT, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE], userRoles).length){
      return (
        <>
          <Route exact path={match.url} component={SurveyLists} />
          <Route path={`${match.url}/survey/:surveyId/:section?`} component={SurveyWizard} />
        </>
      );
    }
  };
  return (
    <Switch>
      {filterRoutesByRoles()}
      <Redirect to={'/'}/>
    </Switch>
  );
};

export default SurveyCentralRouter;
