import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import sortDropdownOptions from '../../../utils/sortDropdownOptions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const useStyles = makeStyles(theme => ({
  label:{
    marginBottom: theme.spacing(1)
  },
  select: {
    marginBottom: theme.spacing(0.5),
    width: '100%',
    minHeight: '40px',
    '&.MuiInput-underline.Mui-error::after': {
      borderBottomColor: theme.palette.primary.main,
    }
  },
  chip: {
    margin: 3,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  menuItem: {
    '&.MuiListItem-root.Mui-selected': {
      backgroundColor: theme.palette.primary.veryLight,
      color: theme.palette.primary.main
    }
  },
  inputLabelRoot: {
    '&.Mui-error': {
      color: theme.palette.primary.main,
    },
  },
}));

const MultipleSelect = ({ sortOptions, options, selectedOptions, label, maxChips, customClassName, onChange, optionKeys, isInvalid, muiProps }) => {
  const styles = useStyles();
  const theme = useTheme();

  const getStyles = (option, theme) => {
    return {
      fontWeight:
      options.indexOf(option) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const renderChips = () => {
    const MAX_CHIPS_TO_SHOW = maxChips ? maxChips : 1;
    const { name, value } = optionKeys;
    let selectedItemsToShow = selectedOptions;

    const doesChipsLengthExceedLimit = selectedOptions.length > MAX_CHIPS_TO_SHOW;
    if (doesChipsLengthExceedLimit) {
      selectedItemsToShow = selectedOptions.slice(0, MAX_CHIPS_TO_SHOW);
    }

    return (
      <div className={styles.chips}>
        {Boolean(options.length) && selectedItemsToShow.map((selectedItem) => (
          <Chip key={selectedItem[value]} label={selectedItem[name]} className={styles.chip} />
        ))}
        {doesChipsLengthExceedLimit &&
        <Chip label={`+${selectedOptions.length - maxChips}`} className={styles.chip} />}
      </div>
    )
  };

  const handleOnChange = (event) => {
    const { value } = optionKeys;
    const allSelectedItemValues = event.target.value;
    const allSelectedItems = options.filter((option) => (
      allSelectedItemValues.find((itemValue) => option[value] === itemValue)
      ));
    onChange(allSelectedItems);
  };

  const sortedOptions = sortDropdownOptions(options, sortOptions);

  const selectedOptionValues = selectedOptions.map((selectedOption) => selectedOption[optionKeys.value]);
  return (
    <div className={customClassName}>
      <InputLabel
        className={styles.label}
        classes={{ root: styles.inputLabelRoot }}
        id="demo-mutiple-chip-label"
      >
        {label || 'Status'}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        placeholder={'select'}
        className={styles.select}
        multiple
        displayEmpty={true}
        value={selectedOptionValues}
        onChange={handleOnChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={renderChips}
        MenuProps={MenuProps}
        error={isInvalid}
        {...muiProps}
      >
        {sortedOptions.map((option) => {
          const { name, value } = optionKeys;
          return (
            <MenuItem
              className={styles.menuItem}
              key={option[value]}
              value={option[value]}
              style={getStyles(value, theme)}
              disabled={option.disabled || false}
            >
              {option[name]}
            </MenuItem>
          )
        })}
      </Select>
  </div>
  )
}

export default MultipleSelect
