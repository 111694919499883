import React from 'react';
import ReactDOM from 'react-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import usePortal from '../../hooks/usePortal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PORTAL_IDS } from '../../constants/portalIds';

const useStyles = makeStyles((theme) => ({
  selectContainer: {
    width: '230px',
    color: theme.palette.primary.main,
    height: '100%',
    margin: `0 ${theme.spacing(1)}px`,
    '&::before': {
      borderBottom: 'none'
    },
    '&::after': {
      borderBottom: 'none'
    },
    '&:hover&:before': {
      borderBottom: 'none'
    },
    '&:active': {
      borderBottom: 'none'
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main
    },
    '& .MuiAutocomplete-inputRoot': {
      color: theme.palette.primary.main
    }
  },
  circularProgressContainer: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  circularProgress: {
    color: theme.palette.primary.main,
    width: 20,
    height: 20
  }
}));

const GlobalDropdown = ({ options, onChange, optionAccessors, value, label }) => {
  const styles = useStyles();
  const target = usePortal(PORTAL_IDS.subheaderDropdowns);

  if (options && options.length > 0) {
    options.sort((a, b) => {
      // Allow us to sort strings and numbers depending on options type
      var nameA = typeof a[optionAccessors.name] === 'string' ? a[optionAccessors.name].toUpperCase() : a[optionAccessors.name];
      var nameB = typeof b[optionAccessors.name] === 'string' ? b[optionAccessors.name].toUpperCase() : b[optionAccessors.name];

      let returnValue = 0;
      if (nameA < nameB) {
        returnValue = -1;
      }
      if (nameA > nameB) {
        returnValue = 1;
      }
      return returnValue;
    });
  }

  const loadingElement = (
    <div className={styles.circularProgressContainer}>
      <CircularProgress size={20} className={styles.circularProgress} />
    </div>
  );

  const renderOption = (option) => {
    if (options.length === 0) {
      return (
        <option value={''} disabled>
          No {label}
        </option>
      )
    }

    return (
      <option
        key={option[optionAccessors.value]}
        value={parseInt(option[optionAccessors.value])}
      >
        {option[optionAccessors.name]}
      </option>
    )
  };

  const getOptionLabel = (option) => {
    let optionLabel;

    if (typeof option[optionAccessors.name] === 'string') {
      optionLabel = option[optionAccessors.name];
    } else {
      optionLabel = (option[optionAccessors.name] && option[optionAccessors.name].toString()) || '';
    }
    return optionLabel;
  };

  const renderDropdown = () => (
    <Autocomplete
      disableClearable
      noValidate
      fullWidth
      className={styles.selectContainer}
      onChange={(event, value) => onChange(value[optionAccessors.value])}
      options={options}
      getOptionLabel={(option) => getOptionLabel(option)}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          className={styles.selectContainer}
          margin="normal"
          label={label}
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
      value={options.find(option => option[optionAccessors.value] === parseInt(value)) || null}
      renderOption={(option, state) => renderOption(option, state)}
    >
    </Autocomplete>
  );

  return ReactDOM.createPortal(
    options ? renderDropdown() : loadingElement,
    target
  );
};

GlobalDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  value: PropTypes.any,
  optionAccessors: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  })
};

export default GlobalDropdown;
