const tableConfigBuilder = stage =>{
  const commonColumns = [
    { name: 'Report Version', id: 'version', sortable: true },
    { name: 'Report Type', id: 'templateDisplayName', sortable: true },
  ]
  const preliminaryColumns = [
    { name: 'Created on', id: 'createdOn' , type: 'date', sortable: true},
    { name: 'Created by', id: 'createdBy', sortable: true },
    { name: 'Report Creation Status', id: 'creationStatus', sortable: true },
  ]
  const lastStepsColumns = [
    { name: 'Published Status', id: 'publishedStatus', type: 'boolean', booleanValues: {1: 'Published', 0: 'Not Published'}, sortable: true }
  ]

  const columns = {
    1: [...commonColumns, ...preliminaryColumns],
    2: commonColumns,
    3: [...commonColumns, ...lastStepsColumns],
    4: [...commonColumns, ...lastStepsColumns],
  }
  return {
    columns: columns[stage],
    rows: ['reportName', ...columns[stage].map(({id}) => id)]
  
  }
};

export default tableConfigBuilder;
