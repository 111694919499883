import { useRef } from "react";

function useDebounce(fn, timeMs = 500) {
  const debounceTimeout = useRef(0);

  return [(...fnParams) => {
    const functionCall = () => fn(...fnParams);

    clearTimeout(debounceTimeout.current);

    debounceTimeout.current = setTimeout(functionCall, timeMs);
  }, () => {
    clearTimeout(debounceTimeout.current);
  }];
}

export default useDebounce;
