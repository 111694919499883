import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import useStyles from './Subheader.styles';
import Button from '../../commonComponents/Button';

// CONSTANTS
import { NECESSARY_ROLES_PER_COMPONENT, EXCLUDED_PATHS_FOR_CAN_CONTAINER_ROLL } from '../../constants/RollForward';
import useRoleRestriction from "../../hooks/useRoleRestriction";
import { rollContainerForward } from "../../actions/container";
import { getSelectedContainer } from "../../selectors/containers";
import { getSelectedTenant } from '../../selectors/tenants';
import { getCanContainerRoll } from "../../selectors/common";
import CircularProgress from "@material-ui/core/CircularProgress";
import { PORTAL_IDS } from '../../constants/portalIds';
import PortalContainer from '../../commonComponents/PortalContainer/';
import { HIDE_SUBHEADER_LIST } from './constants';

const Subheader = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [shouldHideSubheader, setShouldHideSubheader] = useState(false);
  const currentPathName = useLocation().pathname;
  const isRouteExcluded = EXCLUDED_PATHS_FOR_CAN_CONTAINER_ROLL.includes(currentPathName);
  const hasRoleAccessTo = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual);
  const selectedTenant = useSelector(getSelectedTenant, shallowEqual);
  const canContainerRoll = useSelector(getCanContainerRoll, shallowEqual);

  useEffect(() => {
    let shouldHide = false;
    HIDE_SUBHEADER_LIST.forEach(url => {
      if (currentPathName.indexOf(url) >= 0) {
        shouldHide = true;
      }
    });
    setShouldHideSubheader(shouldHide);
  }, [currentPathName]);

  if (selectedTenant === null || shouldHideSubheader) {
    return null;
  }

  const handleContainerRollForward = () => {
    const rollContainerParams = {
      containerId: selectedContainer.container_id,
      taxYear: selectedContainer.tax_year,
      rollContainerForward: canContainerRoll};
    dispatch(rollContainerForward(rollContainerParams));
  };

  const canRollForward = canContainerRoll && canContainerRoll.canRoll && canContainerRoll.canRoll.canRollForward
  const isRollForwardLoading = canContainerRoll && canContainerRoll.isLoadingRollForward;

  return (
    <nav className={styles.nav}>
      <PortalContainer className={styles.navDropdown} id={PORTAL_IDS.subheaderDropdowns} />

      <div className={styles.containerRollForwardButton}>
        {hasRoleAccessTo && hasRoleAccessTo.rollForwardButton && !isRouteExcluded && canRollForward &&(
          <Button data-testid={'roll-forward-button'} disabled={isRollForwardLoading} className={styles.button} onClick={handleContainerRollForward}>
            Roll Forward
            {isRollForwardLoading && <CircularProgress size={20} className={styles.buttonProgress} />}
          </Button>
        )}
      </div>

      <PortalContainer className={styles.subheaderExternalContent} id={PORTAL_IDS.subheaderExternalContent} />
    </nav>
  );
};

export default Subheader;
