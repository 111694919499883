export const CREDIT_DETAIL_INITIAL_STATE = {
  questionAnswers: {},
  sections:[],
}
export const CREDIT_DETAIL_SECTIONS_REF_ID = {
  directiveExpenseDetails:  1,
  directiveQAndA: 2,
  documentationReview: 3
}

export const itemLetters = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

export const questionsToRenderPerSection = {
  entityJurisdictionItems: [1, 2, 3, 39, 4, 5],
  activityItems: [6, 7, 8, 9, 10, 11, 12, 13],
  compensationItems: [14, 15, 16, 17],
  otherGeneralExpenseItems: [18, 19, 20, 21, 22, 23, 24, 25, 26],
};
