export const getIsCrudPanelOpen = state => state.common.isCrudPanelOpen;
export const getJurisdictions = state => state.common.jurisdictions || [];
export const getSelectedEntityProjects = state => state.common.selectedEntityProjects;
export const getDropdownContainers = state => state.common.containers;
export const getCanContainerRoll = state => state.common.canContainerRoll || {};
export const getDropdownTenants = state => state.common.tenants;
export const getDropdownEntities = state => state.common.entities;
export const getJurisdictionsByParentId = id => state => {
  const jurisdictions = state.common.jurisdictions.filter(({parent_jurisdiction_id}) => parent_jurisdiction_id === id)
  return jurisdictions
}
export const getSelectedCreditForDropdown = state => state.common.selectedCredit;

export const getHourSources = state => state.common.hourSources;
