import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grapeDarker,
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-25%, -25%)',
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: 6,
    fontSize: '1.1rem',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(1),
    fontSize: '2rem'
  }
}));

const NotAllowedBanner = ({ className, message = "You don't have access" }) => {
  const styles = useStyles();
  
  return (
    <div data-testid="not-allowed-banner" className={`${className} ${styles.root}`}>
      <ErrorOutline className={styles.icon} />
      {message}
    </div>
  );
};

export default NotAllowedBanner;
