import {roles} from "../../../constants/roles";

const NECESSARY_ROLES_PER_COMPONENT = {
  EntityChartTab: [roles.FULL_ACCESS_ADMIN,roles.PS_ASSOCIATE,roles.ACCOUNT_EXECUTIVE,roles.REPOSITORY_ONLY,roles.PS_MANAGER,roles.LIMITED_ACCESS_ADMIN,roles.SUPER_ACCESS_ADMIN]
};
const SELECT_CLIENT_MSG = 'Please select a client to show the data';
const ENTITIES_TITLE = {
  plural: 'Entities',
  singular: 'Entity',
};
const CRUD_INPUT_TYPES = {
  name: { name: 'name', title: 'Name' },
  code: { name: 'code', title: 'Code' },
  jurisdiction: { name: 'jurisdiction', title: 'Jurisdiction' },
  currency: { name: 'currency_id', title: 'Currency' },
  fiscalYearEnd: { name: 'fiscal_year_end', title: 'Fiscal Year End' },
  yearOfIncorporation: { name: 'year_of_incorporation', title: 'Year Of Incorporation' },
  entityType: { name: 'entity_type', title: 'Entity Type' },
  description: { name: 'description', title: 'Description' },
  industry: { name: 'industry', title: 'Industry' },
  entityGroup: { name: 'entity_group_id', title: 'Group' },
  fiscalYearStart: { name: 'fiscal_year_start', title: 'Fiscal Year Start' },
  firstParent: { name: 'parentEntities-0', title: 'First Parent Entity' },
  firstParentPercentage: { name: 'parentEntities-0', title: 'Percentage % Ownership 1st Parent' },
  secondParent: { name: 'parentEntities-1', title: 'Second Parent Entity' },
  secondParentPercentage: { name: 'parentEntities-1', title: 'Percentage % Ownership 2nd Parent' },
};
const CRUD_TYPES = {
  edit: 'edit',
  create: 'create',
};

const MAX_TABLE_HEIGHT = '90%'

export {
  SELECT_CLIENT_MSG,
  ENTITIES_TITLE,
  CRUD_INPUT_TYPES,
  CRUD_TYPES,
  MAX_TABLE_HEIGHT,
  NECESSARY_ROLES_PER_COMPONENT,
};
