import store from '../store';
import { authSuccess, logout } from '../actions/auth';
import url from 'url';

const requestInterceptor = async config => {
  const { auth } = store.getState().auth;
  const baseURL = await store.getState().config.API_URL;

  if (!config.baseURL) {
    config.baseURL = baseURL;
  }
  if (auth === null) {
    return config;
  }
  const { accessToken, exp: tokenExpiration } = auth.tokens;
  const hasTokenExpired = checkHasTokenExpired(tokenExpiration, accessToken);

  if (hasTokenExpired) {
    const refreshAuthData = await getRefresedAuthData();
    if (!refreshAuthData) {
      return config;
    }
    const refreshedAccessToken = refreshAuthData.tokens.accessToken;
    store.dispatch(authSuccess(refreshAuthData));

    config.headers.Authorization = `Bearer ${refreshedAccessToken}`;
    return config;
  }

  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

function checkHasTokenExpired(tokenExpiration, accessToken) {
  if (!accessToken) {
    return;
  }

  const now = new Date();
  const expiration = new Date(tokenExpiration * 1000);

  return now >= expiration;
}

async function getRefresedAuthData() {
  let { id: userId, tokens, userEmail } = store.getState().auth.auth;
  const { refreshToken, accessToken } = tokens;
  const baseURL = store.getState().config.API_URL;

  if (!accessToken) {
    return;
  }

  try {
    const refreshedAuthResponse = await fetch(url.resolve(baseURL, 'auth/refreshToken'), {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ refreshToken, email: userEmail })
    });
    if (!refreshedAuthResponse.ok) {
      throw(refreshedAuthResponse)
    }
    let refreshedAuth = await refreshedAuthResponse.json();
    refreshedAuth.id = Number(userId);
    return refreshedAuth;

  } catch (error) {
    store.dispatch(logout());
  }
}

export default requestInterceptor
