import { checkIsDateValid } from '../../../../customValidations/checkIsDateValid';
import { SURVEY_TYPE, SURVEY_STATUS_REF_ID_BY_NAME } from '../constants';

export const buildSurveyInputFields = (survey, inputData) => [
  {
    name: 'userId',
    label: 'Survey Lead',
    type: 'AutocompleteSelectVirtualized',
    options: inputData.leaders,
    value: survey.userId,
    optionKeys: { name: 'label', value: 'userId' },
    muiProps: {
      disabled: inputData.isEdit,
    },
    required: true,
  },
  {
    name: 'dueDate',
    label: 'Due Date',
    type: 'Date',
    required: true,
    value: survey.dueDate,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
  {
    label: 'Projects',
    type: 'Modal',
    value: inputData.selectedProjectSurvey,
    options: inputData.allProjectSurveys,
    optionKeys: { name: 'name', value: 'project_id' },
    onChange: inputData.handleProjectSurveyChange,
    maxChips: 2,
    shouldDisplay: inputData.surveyTypeId === SURVEY_TYPE.lead,
    modalOptions: {
      name: 'assignEmployeesProjects',
      kind: 'projects'
    }
  },
  {
    label: 'Assign Employees',
    type: 'Modal',
    value: inputData.selectedEmployeeSurvey,
    options: inputData.allEmployeeSurveys,
    optionKeys: { name: 'name', value: 'id' },
    onChange: inputData.handleEmployeeSurveyChange,
    shouldDisplay: inputData.surveyTypeId === SURVEY_TYPE.lead,
    maxChips: 2,
    modalOptions: {
      name: 'assignEmployeesProjects',
      kind: 'employees'
    }
  },
  {
    name: 'timePeriodId',
    label: 'Time Period',
    type: 'Selector',
    // POST MVP: REMOVE FILTER
    options: inputData.timePeriods.filter((timePeriod) => timePeriod.name === 'Annually'),
    value: survey.timePeriodId,
    optionKeys: {
      name: 'name',
      value: 'id',
    },
    required: true,
  },
  {
    name: 'trackingTypeId',
    label: 'Tracking Type',
    type: 'Selector',
    // POST MVP: REMOVE FILTER
    options: inputData.trackingTypes.filter((trackingType) => trackingType.name === 'Retroactive'),
    value: survey.trackingTypeId,
    optionKeys: {
      name: 'name',
      value: 'id',
    },
    required: true,
  },
  {
    name: 'userProxyId',
    label: 'Response Proxy (User)',
    type: 'AutocompleteSelect',
    options: [{name: null, id: 0}, ...inputData.userProxyIds],
    value: survey.userProxyId,
    optionKeys: { name: 'name', value: 'id' },
    shouldDisplay: inputData.surveyTypeId === SURVEY_TYPE.lead,
    muiProps: {
      disabled: survey && survey.surveyStatusRefId === SURVEY_STATUS_REF_ID_BY_NAME.completed.id,
    }
  },
  {
    name: 'surveyStartDate',
    label: 'Survey Start Date',
    type: 'Date',
    value: survey.surveyStartDate,
    required: true,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
  {
    name: 'surveyEndDate',
    label: 'Survey End Date',
    type: 'Date',
    value: survey.surveyEndDate,
    required: true,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
];
