import { FETCH_DROPDOWN_TENANTS_SUCCESS, SELECT_TENANT } from '../constants/actionTypes/tenant';
import { FETCH_ENTITIES_SUCCESS, SELECT_ENTITY, SAVE_ENTITY_PROJECTS } from '../constants/actionTypes/entity';
import {
  FETCH_DROPDOWN_CONTAINERS_SUCCESS,
  SELECT_CONTAINER,
  SET_CAN_CONTAINER_ROLL,
  SET_IS_LOADING_ROLL_FORWARD
} from '../constants/actionTypes/container';
import { GET_JURISDICTIONS_SUCCESS, TOGGLE_CRUD_PANEL, GET_HOUR_SOURCES } from '../constants/actionTypes/common';
import { CLEAR_PROJECTS } from '../constants/actionTypes/project';
import {
  GET_CREDITS_SUCCESS_FOR_DROPDOWN,
  SELECT_CREDIT_FOR_DROPDOWN,
} from '../constants/actionTypes/credit';



const initialState = {
  entities: [],
  isCrudPanelOpen: false,
  projects: [],
  canContainerRoll:{
    canRoll: {
      canRollForward: false,
      canRollBack: false
    },
    isLoadingRollForward: false
  }
}

const common = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DROPDOWN_TENANTS_SUCCESS:
      return {
        ...state,
        tenants: action.tenants
      };
    case FETCH_ENTITIES_SUCCESS:
      return {
        ...state,
        entities: action.entities
      };
    case FETCH_DROPDOWN_CONTAINERS_SUCCESS:
      return {
        ...state,
        containers: action.containers
      };
    case SELECT_TENANT:
      return {
        ...state,
        selectedTenant: action.selectedTenant
      };
    case SELECT_ENTITY:
      return {
        ...state,
        selectedEntity: action.selectedEntity
      };
    case SELECT_CONTAINER:
      return {
        ...state,
        selectedContainer: action.selectedContainer
      };
    case TOGGLE_CRUD_PANEL:
      return {
        ...state,
        isCrudPanelOpen: action.isCrudPanelOpen
      };
    case GET_JURISDICTIONS_SUCCESS:
      return({
        ...state,
        jurisdictions: action.jurisdictions,
      });
    case SAVE_ENTITY_PROJECTS:
      return {
        ...state,
        selectedEntityProjects: action.projects
      };
    case CLEAR_PROJECTS:
      return {
        ...state,
        selectedEntityProjects: []
      };
    case GET_CREDITS_SUCCESS_FOR_DROPDOWN:
      return {
        ...state,
        credits: action.credits,
      };
    case SELECT_CREDIT_FOR_DROPDOWN:
      return {
        ...state,
        selectedCredit: action.creditId
      };
    case GET_HOUR_SOURCES:
        return {
          ...state,
          hourSources: action.hourSources
        }
    case SET_CAN_CONTAINER_ROLL:
      return {
        ...state,
        canContainerRoll: { ...state.canContainerRoll, canRoll: action.canContainerRoll.canRoll},
      };
    case SET_IS_LOADING_ROLL_FORWARD:
      return {
        ...state,
        canContainerRoll: { ...state.canContainerRoll, isLoadingRollForward: action.isLoadingRollForward }
      };

    default: return state;
  }
};

export default common;
