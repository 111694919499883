import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
    container: {
      position: 'relative',
      borderBottom: `0.5px solid ${theme.palette.grey[300]}`,
      minHeight: '3em',
      display: 'flex',
      justifyContent: 'space-between',
      color: theme.palette.grey[500],
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.spacing(1.5),
      cursor: 'pointer',
      '&$selected': {
        borderBottom: `0.5px solid ${theme.palette.primary.light}`,
        borderTop: `0.5px solid ${theme.palette.primary.light}`
      }
    },
    borderBottom: {
      borderBottom: `0.5px solid ${theme.palette.grey[300]}`
    },
    borderTop: {
      borderTop: `0.5px solid ${theme.palette.grey[300]}`
    },
    left: {
      display: 'flex',
      flexDirection: 'row',
    },
    title: {
      fontSize: '1rem',
      fontWeight: 400,
      marginLeft: theme.spacing(1)
    },
    selected: {
      backgroundColor: theme.palette.primary.veryLight,
      color: theme.palette.secondary.dark,
      fontWeight: 500,
    },
    progress: {
      width: '4rem',
      height: '0.5em',
      position: 'absolute',
      transform: 'translateX(-130%) translateY(100%)',
      borderRadius: '25px',
      '& > div': {
        borderRadius: '25px'
      }
    },
    icon: {
      fill: theme.palette.grey[500],
      '& path': {
        fill: theme.palette.grey[500],
      }
    },
    iconSelected: {
      fill: theme.palette.secondary.dark,
      '& path': {
        fill: theme.palette.secondary.dark,
      }
    }
}));

const SideNavTab = ({ Icon = () => null, title, progress = 50, selected, borderStyle, onClick = () => null }) => {
  const styles = useStyles();
  const containerClasses = classNames({
    [styles.container]: true,
    [styles.borderBottom]: borderStyle === 'bottom',
    [styles.borderTop]: borderStyle === 'top',
    [styles.selected]: selected
  });
  
  const iconClasses = classNames({
    [styles.icon]: !selected,
    [styles.iconSelected]: selected,
  });
  
  return (<div data-testid="tab" onClick={onClick} className={containerClasses}>
    <span className={styles.left}>
      <Icon className={iconClasses} />
      <Typography data-testid="title" className={styles.title}>{ title }</Typography>
    </span>
    <span className={styles.right}>
      {/*
      TODO: Bring back this Linear Progress once implemented on backend
      <LinearProgress className={styles.progress} variant="determinate" value={progress} /> 
      */}
    </span>
  </div>);
};

export default SideNavTab
