import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import SurveyCard from './SurveyCard';
import { surveyStatusByRefId } from '../constants';
import { apiClient } from '../../../api/apiClient';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../constants/promiseTracker';
import LoadingElement from '../../../commonComponents/LoadingElement';
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import { makeStyles } from '@material-ui/core/styles';
import useRoleRestriction from '../../../hooks/useRoleRestriction';
import { roles } from '../../../constants/roles';
import { getSelectedContainer } from '../../../selectors/containers';
import { getSelectedTenant } from '../../../selectors/tenants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchTenantsForDropdown, selectTenant, setDefaultSelectedTenant } from '../../../actions/tenant';
import TenantsDropdown from '../../GlobalDropdowns/TenantsDropdown';
import { getJurisdictions, toggleCrudPanel } from '../../../actions/common';
import { fetchContainersForDropdown, selectContainer, setDefaultSelectedContainer } from '../../../actions/container';
import { getDropdownContainers, getDropdownTenants } from '../../../selectors/common';

const useStyles = makeStyles((theme) => ({
  cardsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  noSurveysDiv: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(3),
    borderRadius: 5,
    '& > span': {
      color: theme.palette.primary.main,
      fontWeight: 700
    }
  }
}));

const FilteredSurveyList = ({ type }) => {
  const styles = useStyles();
  const history = useHistory();
  const [surveys, setSurveys] = useState([]);
  const [fetchTenantsInProgress, setFetchTenantsInProgress] = useState(true);
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_FILTERED_SURVEYS_FOR_SURVEY_CENTRAL, delay: 0 });

  const hasRoleAccessTo = useRoleRestriction({
    cardEntityName: [roles.PS_MANAGER, roles.FULL_ACCESS_ADMIN, roles.LIMITED_ACCESS_ADMIN, roles.ACCOUNT_EXECUTIVE]
  });
  const selectedTenant = useSelector(getSelectedTenant, shallowEqual);
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual);
  const dropdownTenants = useSelector(getDropdownTenants, shallowEqual);
  const dropdownContainers = useSelector(getDropdownContainers, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchFilteredSurveys = async () => {
      const filtersByType = {
        open: surveyStatusByRefId.Pending,
        closed: surveyStatusByRefId.Completed,
      };

      const resultSurveys = await trackPromise(
        apiClient({
          url: `/survey/filter?surveyStatusRefId=${filtersByType[type]}`,
          method: 'get'
        }),
        tags.FETCH_FILTERED_SURVEYS_FOR_SURVEY_CENTRAL
      );

      if (selectedTenant === null || (selectedContainer && Object.keys(selectedContainer).length === 0)) {
        setSurveys(resultSurveys);
      } else {
        const surveysFilteredByContainer = resultSurveys.filter(survey => {
          return survey.companyName === selectedTenant.name && survey.taxYear === selectedContainer.tax_year;
        });

        setSurveys(surveysFilteredByContainer);
      }
    };

    fetchFilteredSurveys(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, selectedContainer, selectTenant]);

  useEffect(() => {
    async function fetchTenants() {
      dispatch(getJurisdictions());
      await dispatch(fetchTenantsForDropdown());
      setFetchTenantsInProgress(false);
    }
    fetchTenants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    selectedTenant.tenant_id && dispatch(fetchContainersForDropdown(selectedTenant.tenant_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant]);

  useEffect(() => {
    if (!selectedTenant.tenant_id) {
      dispatch(setDefaultSelectedTenant(dropdownTenants));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownTenants, selectedTenant.tenant_id]);

  useEffect(() => {
    if (!selectedContainer.container_id) {
      dispatch(setDefaultSelectedContainer(dropdownContainers));
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainer.container_id]);

  const handleTenantSelect = (value) => {
    const tenantId = parseInt(value);
    dispatch(toggleCrudPanel(false));
    dispatch(selectTenant(tenantId));
  };

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId));
  };

  const handleSurveyCardClick = (survey) => {
    history.push(`survey-central/survey/${survey.id}`);
  }

  if (fetchTenantsInProgress || isDataFetchInProgress) {
    return <LoadingElement />
  }

  const TenantsAndContainerDropdowns = () => {
    if ((selectedTenant && Object.keys(selectedTenant).length) && (selectedContainer && Object.keys(selectedContainer).length)) {
      return (
        <>
          <TenantsDropdown onChange={handleTenantSelect} />
          <ContainersDropdown onChange={handleContainerSelect} />
        </>
      );
    }
    return null;
  }

  if (surveys.length === 0) {
    return (
      <>
        { TenantsAndContainerDropdowns() }
        <div className={styles.noSurveysDiv}>You don't have <span>{type}</span> surveys yet. Please check back in.</div>
      </>
    );
  }

  return (
    <>
      { TenantsAndContainerDropdowns() }
      <div data-testid="cards-wrapper" className={styles.cardsWrapper}>
        {surveys.map((survey) => (
          <SurveyCard key={survey.id} survey={survey} onClick={type === 'open' ? handleSurveyCardClick : () => null} shouldDisplayEntityName={hasRoleAccessTo.cardEntityName} />
        ))}
      </div>
    </>
  );
};

FilteredSurveyList.propTypes = {
  type: PropTypes.oneOf(['open', 'closed'])
}

export default FilteredSurveyList;
