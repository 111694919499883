import { InputAdornment } from '@material-ui/core';
import { IntlFormatter } from '../../../../utils/IntlFormatter';
import React from 'react';
import { checkIsDateValid } from '../../../../customValidations/checkIsDateValid';
import { checkIsPercentageValid } from '../../../../customValidations/checkIsPercentageValid';
import { checkIsIntegerYear } from '../../../../customValidations/checkIsIntegerYear';

import { EMPLOYEE_FIELD, LOCAL_JURISDICTION_IDS } from '../constants';

export const buildExpenseInputTypes = (expense, inputData) => {
  const {
    jurisdictions,
    employees,
    isEmployee,
    resolveDisabledField,
    crudType,
    isCreateCrud,
    resolveValue,
    resolveIsMandatory,
    expenseInputs,
    hourSources,
    handleHourSourceChange,
    handleRdHoursInputChange,
    handleQualifiedPercentBlur,
    directiveClassifications,
    handleClassificationChange
  } = inputData;

  const fields = [
    {
      name: 'local_jurisdiction_id',
      label: 'Local jurisdiction',
      type: 'AutocompleteSelect',
      options: jurisdictions,
      value: expense['local_jurisdiction_id'],
      allowEmptyOption: true,
      optionKeys: {
        name: 'name',
        value: 'jurisdiction_id',
      },
    },
    {
      name: 'employee_id',
      label: 'Employee',
      type: 'AutocompleteSelectVirtualized',
      options: employees || [],
      value: expense['employee_id'],
      allowEmptyOption: true,
      shouldDisplay: isEmployee && employees && isCreateCrud,
      optionKeys: {
        name: 'name',
        value: 'id',
      },
      required: !expense.employee_name,
    },
  ];

  expenseInputs.forEach(({ type, id, name }) => {
    const inputTypeBasedProperties = getInputPropertiesBasedOnTypeOfInput(type, inputData, id);
    if (!inputTypeBasedProperties) {
      return
    }

    const isEmployeeField = id.includes(EMPLOYEE_FIELD) && id !== 'employee_rd_hours' && id !== 'employee_total_hours';
    let disabled = resolveDisabledField(isEmployeeField, crudType, id);
    const isFieldMandatory = resolveIsMandatory(id);
    fields.push({
      ...inputTypeBasedProperties,
      value: resolveValue(id),
      name: id,
      label: name,
      muiProps: {
        ...inputTypeBasedProperties.muiProps,
        id,
        disabled,
      },
      required: isFieldMandatory
    });
  });

  return fields;

  function getInputPropertiesBasedOnTypeOfInput(type, inputData, expenseFieldName) {
    if (expenseFieldName === 'expense_tax_year') {
      type = 'year';
    }

    if (expenseFieldName === 'source_ref_id') {
      type = 'hoursDropdown';
    }

    if (expenseFieldName === 'directive_classification') {
      type = 'classificationDropdown';
    }

    if (expenseFieldName === 'employee_rd_hours') {
      type = 'rdHoursInput';
    }

    if (expenseFieldName === 'qualified_percent') {
      type = 'qualifiedPercent';
    }

    if (expenseFieldName === 'employee_total_hours') {
      type = 'totalEmployeeHours';
    }

    if (expenseFieldName === 'is_asc_730') {
      type = 'isAsc730';
    }

    if (expenseFieldName === 'higher_education_contract') {
      type = 'higherEducationContract';
    }
    if (expenseFieldName === 'on_campus') {
      type = 'onCampus';
    }

    const inputProperties = {
      number: {
        type: 'Input',
        muiProps: {
          type: 'number',
        }
      },
      year: {
        type: 'Year',
        validationOptions: {
          customValidate: checkIsIntegerYear,
        },
      },
      text: {
        type: 'Input',
        muiProps: {}
      },
      value: {
        type: 'ReadOnly',
        muiProps: {}
      },
      boolean: {
        type: 'Bool',
        onChange: inputData.handleBooleanChange,
        muiProps: {}
      },
      currency: {
        type: 'Input',
        muiProps: {
          type: 'number',
          startAdornment: <InputAdornment position="start">{IntlFormatter('en-US', { style: 'currency', currency: inputData.currencyCode }).format(null).replace(/[0-9.,]/g, '')}</InputAdornment>,
        }
      },
      percentage: {
        type: 'Input',
        name: 'percentage',
        helperText: inputData.percentageError[expenseFieldName],
        error: inputData.percentageError[expenseFieldName],
        onChange: inputData.handlePercentageChange,
        validationOptions: {
          customValidate: checkIsPercentageValid,
        },
        muiProps: {
          type: 'number',
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }
      },
      date: {
        type: 'Date',
        validationProps: {
          customValidate: checkIsDateValid
        },
        muiProps: {}
      },
      classificationDropdown: {
        name: 'directive_classification',
        label: expenseFieldName,
        type: 'Selector',
        options: directiveClassifications || [],
        onChange: handleClassificationChange,
        optionKeys: {
          name: 'name',
          value: 'name',
        }
      },
      hoursDropdown: {
        name: 'source_ref_id',
        label: expenseFieldName,
        type: 'Selector',
        options: hourSources || [],
        onChange: handleHourSourceChange,
        optionKeys: {
          name: 'title',
          value: 'sourceRefId',
        }
      },
      rdHoursInput: {
        type: 'Input',
        onChange: handleRdHoursInputChange,
        onBlur: handleQualifiedPercentBlur,
        muiProps: {
          type: 'number',
        }
      },
      totalEmployeeHours: {
        type: 'Input',
        onBlur: handleQualifiedPercentBlur,
        muiProps: {
          type: 'number',
        }
      },
      qualifiedPercent: {
        type: 'Input',
        name: 'percentage',
        helperText: inputData.percentageError[expenseFieldName],
        error: inputData.percentageError[expenseFieldName],
        onChange: inputData.handlePercentageChange,
        onBlur: handleQualifiedPercentBlur,
        validationOptions: {
          customValidate: checkIsPercentageValid,
        },
        muiProps: {
          type: 'number',
          endAdornment: <InputAdornment position="start">%</InputAdornment>,
        }
      },
      isAsc730: {
        type: 'Bool',
        onChange: inputData.handleIsAsc730Change,
        muiProps: {}
      },
      higherEducationContract: {
        type: 'Bool',
        shouldDisplay: expense.local_jurisdiction_id === LOCAL_JURISDICTION_IDS.TEXAS,
        muiProps: {}
      },
      onCampus: {
        type: 'Bool',
        shouldDisplay: expense.local_jurisdiction_id === LOCAL_JURISDICTION_IDS.NEBRASKA,
        muiProps: {}
      },
    };

    return inputProperties[type];
  }
};
