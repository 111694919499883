const FETCH_ENTITIES_SUCCESS = 'FETCH_ENTITIES_SUCCESS';
const FETCH_ENTITIES_FAIL = 'FETCH_ENTITIES_FAIL';
const SELECT_ENTITY = 'SELECT_ENTITY';
const TOGGLE_ENTITY_LOADING_STATE = 'TOGGLE_ENTITY_LOADING_STATE';
const SELECT_ENTITY_FOR_CRUD_SUCCESS = 'SELECT_ENTITY_FOR_CRUD_SUCCESS';
const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
const UPDATE_SELECTED_ENTITY = 'UPDATE_SELECTED_ENTITY';
const CLEAN_ENTITY = 'CLEAN_ENTITY';
const SET_ORIGINAL_PARENT = 'SET_ORIGINAL_PARENT';
const SAVE_ENTITY_PROJECTS = 'SAVE_ENTITY_PROJECTS';
export {
  FETCH_ENTITIES_SUCCESS,
  FETCH_ENTITIES_FAIL,
  SELECT_ENTITY,
  TOGGLE_ENTITY_LOADING_STATE,
  SELECT_ENTITY_FOR_CRUD_SUCCESS,
  GET_CURRENCIES_SUCCESS,
  UPDATE_SELECTED_ENTITY,
  CLEAN_ENTITY,
  SET_ORIGINAL_PARENT,
  SAVE_ENTITY_PROJECTS
}
