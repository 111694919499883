import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  navCard: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.66),
    border: '1px solid',
    borderColor: theme.palette.grey[200],
    borderRadius: '4px',
    padding: theme.spacing(2.66, 2),
    background: theme.palette.common.white,
    cursor: 'pointer',

    '&[data-is-active=true]': {
      borderColor: theme.palette.grey[900],
    },

    '&:last-child': {
      marginBottom: 0,
    },

    '& ul': {
      margin: 0,
      padding: 0,
      listStyleType: 'none',
    },
  },

  icon: {
    marginRight: theme.spacing(1.33),
    borderRadius: '50%',
    padding: theme.spacing(0.4),
    fontSize: theme.spacing(2.66),
    background: theme.palette.grey[400],
    color: theme.palette.grey[800],
  },

  requirementNote: {
    fontSize: '0.7rem',
    textTransform: 'uppercase',
    color: theme.palette.grey[800],
  },

  status: {
    display: 'inline-block',
    marginLeft: 'auto',
    borderRadius: '3px',
    padding: theme.spacing(0.266, 0.66),
    fontSize: '0.8rem',
    background: theme.palette.grey[100],
    color: theme.palette.grey[800],
  },
}));
