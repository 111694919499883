import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CheckCircle, Error, Info, Close, Warning } from '@material-ui/icons';
import { amber, green } from '@material-ui/core/colors';
import { IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getMessage } from '../../selectors/toastMessage';
import { hideMessage } from '../../actions/message';

const variantIcon = {
  success: CheckCircle,
  warning: Warning,
  error: Error,
  info: Info,
};

const useStyles = makeStyles(theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.grape,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const ToastMessage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let message = useSelector(getMessage);
  
  const handleClose = () => {
    dispatch(hideMessage());
  };
  
  const renderMessages = () => {
    const messageBody = message.message;
    if (!messageBody) {
      return ''
    }
    
    if (typeof messageBody === 'string') {
      return messageBody;
    }
    
    if (messageBody.length) {
      return (
        <div>
          {messageBody.map((detail) => (
            <Fragment key={detail.message}>
              <span> &bull; {detail.message}</span> <br/>
            </Fragment>
          ))}
        </div>
      )
    }
  };
  
  const renderContentWrapper = () => {
    const typeMessage = typeof message.type === 'undefined' ? 'info': message.type;
    const Icon = variantIcon[typeMessage];
    return (
      <SnackbarContent
        className={classes[message.type]}
        aria-describedby="client-snackbar"
        message={
          <span data-testid="toast-message" id="client-snackbar" className={classes.message}>
          <Icon className={`${classes.icon} ${classes.iconVariant}`} />
          {renderMessages()}
        </span>
        }
        action={[
          <IconButton data-testid="toast-message-close" key="close" aria-label="close" color="inherit" onClick={handleClose}>
            <Close className={classes.icon} />
          </IconButton>,
        ]}
      
      />
    );
  }
  
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={message.isOpen}
        autoHideDuration={message.autoHideDuration}
        onClose={handleClose}
      >
        {renderContentWrapper()}
      </Snackbar>
    </div>
  );
};

export default ToastMessage;
