import { combineReducers } from 'redux';
import employeeInterviews from './employeeInterviews';
import entityDetails from './entityDetails';

const entityInfo = combineReducers({
  employeeInterviews,
  entityDetails,
});

export default entityInfo
