import { checkIsDateValid } from '../../../../customValidations/checkIsDateValid';

const STATUS_OPTIONS = [
  {
    name: 'Setup',
    value: 'Setup',
  },
  {
    name: 'Active',
    value: 'Active',
  },
  {
    name: 'Archived',
    value: 'Archived',
  },
];

export const buildProjectInputFields = (project, inputData) => [
  {
    name: 'name',
    label: 'Name',
    type: 'Input',
    value: project.name,
    required: true,
  },
  {
    name: 'description',
    label: 'Description',
    type: 'Input',
    value: project.description,
    muiProps: {
      multiline: true,
      rows: 3,
    },
  },
  {
    name: 'status',
    label: 'Status',
    type: 'Selector',
    options: STATUS_OPTIONS,
    value: project.status,
    optionKeys: {
      name: 'name',
      value: 'value',
    },
    required: true,
  },
  {
    label: 'Project Types',
    type: 'MultipleSelect',
    options: inputData.allProjectTypes,
    selectedOptions: inputData.selectedProjectTypes,
    optionKeys: { name: 'name', value: 'id' },
    onChange: inputData.handleProjectTypeChange,
    maxChips: 2
  },
  {
    name: 'project_start_date',
    label: 'Project Start Date',
    type: 'Date',
    value: project.project_start_date,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
  {
    name: 'project_end_date',
    label: 'Project End Date',
    type: 'Date',
    value: project.project_end_date,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
];
