import { DISPLAY_MODAL, HIDE_MODAL } from '../constants/actionTypes/modal';

const modal = (state = {}, action) => {
  switch (action.type) {
    case DISPLAY_MODAL:
      return {
        ...state,
        isOpen: true,
        ...action.modal
      };

    case HIDE_MODAL:
      return {
        ...state,
        isOpen: false,
        ...action.modal
      };

    default: return state;
  }

};

export default modal;
