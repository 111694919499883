import React from 'react';
import classNames from 'classnames';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import RDPlannerModalShared from './RDPlannerModalShared';
import { useStyles } from './styles';

const TotalQualifiedExpensesRow = ({ totalQrePerExpenseType }) => {
  const styles = useStyles();
  
  const totalQreSumForAllExpenseTypes = RDPlannerModalShared.getTotalQreSumForAllExpenseTypes(totalQrePerExpenseType);
  const totalQreSumForAllExpenseTypesNextYear = RDPlannerModalShared.getTotalQreSumForAllExpenseTypesNextYear(totalQrePerExpenseType);
  const totalQualifiedClassNames = classNames({
    [styles.modifiedTotals]: totalQreSumForAllExpenseTypes !== totalQreSumForAllExpenseTypesNextYear
  });
  
  return (
    <TableRow className={styles.totalQualifiedExpensesRow}>
      <TableCell>Total Qualified Expenses</TableCell>
      <TableCell align="right">{RDPlannerModalShared.formatCurrency(totalQreSumForAllExpenseTypes)}</TableCell>
      <TableCell className={totalQualifiedClassNames} colSpan={2} align="right">{RDPlannerModalShared.formatCurrency(totalQreSumForAllExpenseTypesNextYear)}</TableCell>
    </TableRow>
  );

};

export default TotalQualifiedExpensesRow;
