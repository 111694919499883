export const getCredits = state => state.credits.list;
export const getSelectedCredit = state => state.credits.selected;
export const getIsFetchingCredits = state => state.credits.isFetchingCredits;
export const getCalculationModalState = state => state.credits.calculationModalState;

export const getCreditById = (creditId) => {
  return state => state.credits.list.find(credit => credit.id === creditId);
}
export const hasCreditBeenSelected = () => {
  return state => state.common.selectedCredit;
}
