const CRUD_TYPES = {
    edit: 'edit',
    create: 'create'
  }

const CRUD_INPUT_TYPES = {
  username: 'username',
  email: 'email',
  enabled: 'enabled',
  projects: 'projects'
}

const CONTAINER_MESSAGES = {
  noContainerSelected: 'To view / assign projects to this user you must select a container',
  noContainertProjects: 'No projects available. Please create Projects to enable access.'
}

export {
    CRUD_TYPES,
    CRUD_INPUT_TYPES,
    CONTAINER_MESSAGES
}