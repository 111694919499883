import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { usePromiseTracker } from "react-promise-tracker";
import { tags } from '../../../../constants/promiseTracker';
import Dials from '../../../../commonComponents/Dials';

// ACTIONS
import { getEmployeeInterviews, cleanSelectedEmployeeInterview } from '../../../../actions/entityInfo'

// SELECTORS
import { getEmployeeInterviewsList } from '../../../../selectors/entityInfo';

// COMPONENTS
import TableAndCrud from '../../TableAndCrud';
import EmployeeInterviewCrud from './EmployeeInterviewCrud';
import LoadingElement from '../../../../commonComponents/LoadingElement';
import SnackBar from '../../../../commonComponents/SnackBar'

// CONSTANTS
import { headConfig, rowsConfig } from './tableConfig';
import { SELECT_ENTITY, MAX_TABLE_HEIGHT } from '../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  noData: {
    width: '80%',
    background: theme.palette.grey[100],
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(2)
  },
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  }
}));

const EmployeeInterviews = ({ entity }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [alert, setAlert] = useState(true);
  const tableData = useSelector(getEmployeeInterviewsList, shallowEqual);
  const { entityId } = entity;
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.GET_EMP_INTERVIEWS, delay: 0 });

  useEffect(() => {
    if (entityId) {
      dispatch(getEmployeeInterviews(entityId));
    }
  }, [dispatch, entityId]);

  const handleButtonClickEffect = () => {
    dispatch(cleanSelectedEmployeeInterview());
  };

  if (!entityId) {
    return (
      <SnackBar
        variant="warning"
        message={SELECT_ENTITY}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={alert && !entityId}
        handleClose={() => setAlert(false)}
        autoHideDuration={5000}
      />
    )
  }
  else if (isDataFetchInProgress) {
    return <LoadingElement />;
  }

  const totalEmployeeInterviews = tableData && tableData.length;
  const dialsConfig = [
    { type: 'default', title: 'All Interviews', value: totalEmployeeInterviews }
  ];
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <CreateNewButton />
        <Dials dialConfig={dialsConfig} />
      </div>
    )
  };
  return (
    <div className={styles.container}>
      <TableAndCrud
        tableHead={headConfig}
        tableData={tableData}
        searchBy={'name'}
        searchByPlaceholder="Name"
        tableRows={rowsConfig}
        handleButtonClickEffect={handleButtonClickEffect}
        maxTableHeight={MAX_TABLE_HEIGHT}
        idKey="id"
        createNewButtonTitle="Add Interview"
        renderComponentBeforeTable={renderComponentBeforeTable}
      >
        <EmployeeInterviewCrud entityId={entityId} />
      </TableAndCrud>
    </div>
  );
};

export default EmployeeInterviews;
