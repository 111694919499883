import React from 'react'
import Header from '../components/Header';

const WithHeader = (headerProps) => {
  return (Wrapped) => {
    const WithHeaderComponent = () => {
      return (
        <>
          <Header {...headerProps} />
          <Wrapped/>
        </>
      )
    };
    
    return WithHeaderComponent; 
  }
};

export default WithHeader;
