import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  question: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    margin: `${theme.spacing(2)}px 0`,
    display: 'flex'
  },
  questionIcon: {
    fontSize: '5rem',
    color: theme.palette.grape,
    marginRight: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(1)
  },
  questionForm: {
    margin: 'auto',
    fontSize: '1.2rem',
    width: '100%'
  },
  questionText: {
    textAlign: 'center'
  },
  description: {
    title: {
      marginTop: theme.spacing(1)
    }
  },
  textEditor: {
    marginTop: '24px'
  }
}));
