import { checkIsDateValid } from '../../../../customValidations/checkIsDateValid';

export const buildEmployeeInputFields = (employee, inputData) => [
  {
    name: 'name',
    label: 'Name',
    type: 'Input',
    value: employee.name,
    required: true,
  },
  {
    name: 'email',
    label: 'Email',
    type: 'Input',
    value: employee.email,
    validationOptions: {
      regex: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
      regexErrorMessage: 'You must enter a valid email',
    },
    muiProps: {
      disabled: inputData.isEditCrud
    }
  },
  {
    name: 'department',
    label: 'Department',
    type: 'Input',
    value: employee.department,
  },
  {
    name: 'jobTitle',
    label: 'Job Title',
    type: 'Input',
    value: employee.jobTitle,
  },
  {
    name: 'hireDate',
    label: 'Hire Date',
    type: 'Date',
    value: employee.hireDate,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
  {
    name: 'termDate',
    label: 'Term Date',
    type: 'Date',
    value: employee.termDate,
    validationOptions: {
      customValidate: checkIsDateValid,
    },
  },
  {
    name: 'totalHours',
    label: 'Total Hours',
    type: 'Input',
    muiProps: {
      type: 'number'
    },
    value: employee.totalHours,
  },
];
