const sortOptions = (options = [], sortConfig = {}) => {
  const { propertyToSortBy, direction } = sortConfig;
  let sortedOptions = options;
  if (propertyToSortBy && direction) {
    if (direction === 'ascending') {
      sortedOptions = options.sort((a, b) => (a[propertyToSortBy] < b[propertyToSortBy]) ? -1 : 1);
    } else  if (direction === 'descending') {
      sortedOptions = options.sort((a, b) => (a[propertyToSortBy] > b[propertyToSortBy]) ? -1 : 1);
    }
  }
  return sortedOptions;
};

export default sortOptions;
