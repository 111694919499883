import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SideNav from './SideNav';
import SurveyContentPane from './SurveyContentPane';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
  surveyResponseWidgetContainer: {
    height: '100%',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[50]
  },
}));

const SurveyResponseWidget = ({ sideNavProps, contentPaneProps }) => {
  const [hideQuestionHelp, setHideHelp] = useState(true);
  const styles = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      className={styles.surveyResponseWidgetContainer}
    >
      <Grid item xs={3}>
        <SideNav {...sideNavProps} questionHelpTooltip={{ hideQuestionHelp, setHideHelp }} />
      </Grid>

      <Grid item xs={9}>
        <SurveyContentPane {...contentPaneProps} questionHelpTooltip={{ hideQuestionHelp, setHideHelp }} />
      </Grid>
    </Grid>
  );
};

export default SurveyResponseWidget;
