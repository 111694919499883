const headConfig = [
  { name: 'Email', id: 'email', sortable: true },
  { name: 'Active Projects', id: 'activeProjects', sortable: true },
];

const rowsConfig = ['username', 'email', 'activeProjects']

export {
  headConfig,
  rowsConfig
}
