import React from 'react';
import Menu from '@material-ui/core/Menu';
import { MoreHoriz } from '@material-ui/icons';
import useStyles from './styles';
import classNames from 'classnames';

const ActionsMenu = ({ children, isHighlighed }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const styles = useStyles();

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const iconClassNames = classNames(
    styles.actionsIcon,
    {
      [styles.actionsIconComplete]: isHighlighed,
    },
  );

  const extendOnClickToAlsoCloseMenu = (onClick) => {
    onClick();
    handleClose();
  };

  return (
    <>
      <button className={styles.actionsButton} onClick={handleClick}>
        <MoreHoriz className={iconClassNames}/>
      </button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children.map((child) => {
          const onClick = child.props.onClick;
          return (
            React.cloneElement(child, { onClick: () => extendOnClickToAlsoCloseMenu(onClick) })
          );
        })}
      </Menu>
    </>
  );
};

export default ActionsMenu;
