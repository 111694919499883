import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TITLE_EXPANSION_PANEL } from '../constants';
import useStyles from './styles';

const ExpansionPanelActivities = ({ activities }) => {
  const styles = useStyles();
  const [isOpen , setIsOpen] = useState(false);

  const handleChange = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Accordion data-testid="expansionPanel" className={styles.accordionHideBorder} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={styles.accordionSummary}
         >
          <Typography className={styles.accordionHeading} data-testid="title">
            {isOpen ? TITLE_EXPANSION_PANEL.HideActivities : TITLE_EXPANSION_PANEL.ShowActivities}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={styles.accordionBody} data-testid="details">
          <Typography>
            {activities.map((activity) => activity.name).join(', ')}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ExpansionPanelActivities;
