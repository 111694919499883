const FETCH_TENANTS_SUCCESS = 'FETCH_TENANTS_SUCCESS';
const FETCH_TENANTS_FAIL = 'FETCH_TENANTS_FAIL';
const SELECT_TENANT = 'SELECT_TENANT';
const TOGGLE_TENANT_LOADING_STATE = 'TOGGLE_TENANT_LOADING_STATE';
const SELECT_TENANT_CRUD = 'SELECT_TENANT_CRUD';
const SET_SELECTED_TENANT_FIELD = 'SET_SELECTED_TENANT_FIELD';
const FETCH_DROPDOWN_TENANTS_SUCCESS = 'FETCH_DROPDOWN_TENANTS_SUCCESS';

export {
  FETCH_TENANTS_SUCCESS,
  FETCH_TENANTS_FAIL,
  SELECT_TENANT,
  SELECT_TENANT_CRUD,
  TOGGLE_TENANT_LOADING_STATE,
  SET_SELECTED_TENANT_FIELD,
  FETCH_DROPDOWN_TENANTS_SUCCESS,
}
