const FETCH_SECTIONS_SUCCESS = 'FETCH_SECTIONS_SUCCESS';
const FETCH_QUESTION_ANSWERS_SUCCESS = 'FETCH_QUESTION_ANSWERS_SUCCESS';
const UPDATE_ANSWER = 'UPDATE_ANSWER';
const UPDATE_SECTION_COMPLETION_STATUS = 'UPDATE_SECTION_COMPLETION_STATUS';


export {
  FETCH_SECTIONS_SUCCESS,
  FETCH_QUESTION_ANSWERS_SUCCESS,
  UPDATE_ANSWER,
  UPDATE_SECTION_COMPLETION_STATUS,
}
