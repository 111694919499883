import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  projectTitle: {
    textAlign: 'center'
  },
  questionsContainer: {
    width: '75%',
    margin: 'auto'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  qualificationButton: {
    margin: theme.spacing(1),
    boxShadow: 'none',
  },
  modal: {
    backgroundColor: theme.palette.common.white,
    width: '70%',
    padding: theme.spacing(2),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 20,
  }
}));
