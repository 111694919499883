import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import dateToDisplayableFormat from '../../../utils/dateToDisplayableFormat';

const Date = ({ value, label, onChange, name, muiProps, isInvalid }) => {
  const handleChange = (date) => {
    onChange({ target: { name, value: dateToDisplayableFormat(date) } });
  };

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
    >
      <KeyboardDatePicker
        disableToolbar
        autoOk
        variant="inline"
        format="MM-dd-yyyy"
        margin="normal"
        label={label}
        value={value ? dateToDisplayableFormat(value) : null}
        onChange={handleChange}
        error={isInvalid}
        {...muiProps}
      />
    </MuiPickersUtilsProvider>
  );
};

export default Date;
