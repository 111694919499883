import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

// ACTIONS
import { selectContainer } from '../../../actions/container';
import { fetchDirectiveClassifications, getExpensesTypes } from '../../../actions/expense';

// SELECTORS
import { getSelectedEntityObject } from '../../../selectors/entities';
import { getSelectedContainer } from '../../../selectors/containers';
import { getExpensesTypes as selectExpensesTypes } from '../../../selectors/expense'

// COMPONENTS
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import TabNavigation from '../../../commonComponents/TabNavigation';
import ExpenseType from './ExpenseType';
import { Typography } from '@material-ui/core';

// CONSTANTS
import {
  SECTION_TITLE,
  NO_DATA,
  NECESSARY_ROLES_PER_COMPONENT,
  EXPENSES_TYPES_EXCLUDED_BY_ROLES
} from './constants';
import ViewAnalysisModal from './ViewAnalysisModal/ViewAnalysisModal';
import useRoleRestriction from '../../../hooks/useRoleRestriction';

import viewAnalysisModalIcon from './ViewAnalysisModal/svgs/icon-colored.png';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  noData: {
    width: '80%',
    background: theme.palette.grey[100],
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(2)
  },
  expenseHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  viewAnalysisModalButton: {
    boxShadow: 'none',
    cursor: 'pointer',
  }
}));

const Expenses = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [shouldDisplayAnalysisModal, setShouldDisplayAnalysisModal] = React.useState(false);
  const selectedEntity = useSelector(getSelectedEntityObject, shallowEqual)
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual)
  const isEntitySelected = selectedEntity.entity_id;
  const isContainerSelected = selectedContainer.tax_year;
  let expensesTypes = useSelector(selectExpensesTypes, shallowEqual);
  const hasRoleAccessTo = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);
  const hasRoleAccessForExpensesTypes = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);

  useEffect(() => {
    if (isEntitySelected && isContainerSelected) {
      const { jurisdiction: { jurisdiction_id } } = selectedEntity;
      const { tax_year } = selectedContainer;
      dispatch(getExpensesTypes(jurisdiction_id, tax_year));
      dispatch(fetchDirectiveClassifications(jurisdiction_id, tax_year));
    }
  }, [dispatch, isEntitySelected, isContainerSelected, selectedEntity, selectedContainer]);

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId))
  };

  const toggleAnaylisisModal = (shouldDisplayModal) => {
    setShouldDisplayAnalysisModal(shouldDisplayModal);
  };

  const addDefaultFormattingToBooleanValues = (tableHead) => {
    return tableHead.map((tableHeadConfig) => {
      if (tableHeadConfig.type === 'boolean') {
        return { booleanValues: { 1: 'Yes', 0: 'No' }, ...tableHeadConfig };
      }

      return tableHeadConfig;
    });
  };

  const buildTableConfig = columns => {
    let tableHead = columns.length ? columns.filter(({ viewable }) => viewable) : [];
    tableHead = addDefaultFormattingToBooleanValues(tableHead);
    const rowsConfig = tableHead.map(({ id }) => id);
    tableHead.shift();
    return {
      searchBy: columns[0].id,
      searchByPlaceholder: columns[0].name,
      tableHead,
      rowsConfig
    }
  }


  if(!hasRoleAccessForExpensesTypes.expensesTypes) {

    expensesTypes = expensesTypes.reduce((expensesTypeNew, expensesType) => {
      const isExpensesTypeExcluded = EXPENSES_TYPES_EXCLUDED_BY_ROLES.DIY_CLIENT.some(expensesTypePerRole => expensesTypePerRole === expensesType.name);

      if(!isExpensesTypeExcluded) {
        expensesTypeNew.push(expensesType);
      }

      return expensesTypeNew;
    }, []);

  }

  return (
    <>
      <ContainersDropdown onChange={handleContainerSelect} />

      <div className={styles.container}>
        <div className={styles.expenseHeading}>
          <h1>{SECTION_TITLE.plural}</h1>
          {hasRoleAccessTo.viewAnalysisButton && (
            <img className={styles.viewAnalysisModalButton} onClick={() => toggleAnaylisisModal(true)} src={viewAnalysisModalIcon} alt="view-modal-icon" />
          )}
        </div>
        {Boolean(expensesTypes.length) && selectedEntity.entity_id && <TabNavigation tabs={expensesTypes.map(type => type.name)}>
          {expensesTypes.map(type => (
            <ExpenseType
              tableConfig={buildTableConfig(type.columns)}
              key={type.name}
              expenseTypeId={type.id}
              selectedEntity={selectedEntity.entity_id}
              currencyCode={selectedEntity.currency && selectedEntity.currency.iso_code}
              name={type.name}
            />)
          )}
        </TabNavigation>}
        {(!expensesTypes.length || !selectedEntity.entity_id) && <Typography className={styles.noData} variant={'subtitle1'}>{NO_DATA}</Typography>}
      </div>

      <ViewAnalysisModal isOpen={shouldDisplayAnalysisModal} onClose={() => toggleAnaylisisModal(false)} />
    </>
  );
};

export default Expenses;
