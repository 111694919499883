import React from 'react';

const ExpenseTypeId12Svg = ({ className }) => {
  return (
    <svg className={className} width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <title>Icon/ContractResearch</title>
      <g id="Icon/ContractResearch" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
        <path d="M13,11.33 L18,18 L6,18 L11,11.33 L11,6 L13,6 M15.96,4 L8.04,4 C7.62,4 7.39,4.48 7.65,4.81 L9,6.5 L9,10.67 L3.2,18.4 C2.71,19.06 3.18,20 4,20 L20,20 C20.82,20 21.29,19.06 20.8,18.4 L15,10.67 L15,6.5 L16.35,4.81 C16.61,4.48 16.38,4 15.96,4 L15.96,4 Z" id="ContractResearch-24px" fill="#000000"></path>
      </g>
    </svg>
  );
};

export default ExpenseTypeId12Svg;
