import { DISPLAY_MESSAGE, HIDE_MESSAGE } from '../constants/actionTypes/toastMessage';

export const showMessage = ( type, message, autoHideDuration = 5000 ) => ({
  type: DISPLAY_MESSAGE,
  message: { type: type, message: message, autoHideDuration: autoHideDuration }
});

export const hideMessage = () => ({
  type: HIDE_MESSAGE,
  message: {}
});

