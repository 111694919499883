import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  EDIT_USER_FAIL,
  SET_SELECTED_USER,
  SET_SELECTED_USER_FIELD,
  CLEAN_USER,
  GET_LIST_SURVEY_LEADERS,
} from '../constants/actionTypes/users';

import { CLEAR_PROJECTS } from '../constants/actionTypes/project'

import { SAVE_ENTITY_PROJECTS } from '../constants/actionTypes/entity'


const initialState = {
  list: [],
  selectedUser: {
    username: '',
    email: '',
    enabled: 1,
    projects: [],
    selectedProjects: [],
    roles: [],
    containers: [],
    tenant: null
  },
  tenantProjects: null,
  leaders: [],
};

const users = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        list: action.data,
      };
    case SET_SELECTED_USER: {
      return {
        ...state,
        selectedUser: action.user,
      };
    }
    case SET_SELECTED_USER_FIELD:
      return {
        ...state,
        selectedUser: { ...state.selectedUser, [action.key]: action.value },
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        error: action.error,
      };
    case EDIT_USER_FAIL:
      return {
        ...state,
        error: action.error,
        selectedUserProjects: null,
      };
    case CLEAN_USER:
      return {
        ...state,
        selectedUser: initialState.selectedUser,
      };
    case SAVE_ENTITY_PROJECTS:
      return {
        ...state,
        entityProjects: action.projects
      }
    case CLEAR_PROJECTS:
      return {
        ...state,
        containerProjects: []
      }
    case GET_LIST_SURVEY_LEADERS:
      return {
        ...state,
        leaders: action.data
      }
    default:
      return state;
  }
};

export default users;
