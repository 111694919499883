const headConfig = [
  { name: 'Entity Name', id: 'entity_name', sortable: true },
  { name: 'Project Id', id: 'project_id', sortable: true },
  { name: 'Project  Status', id: 'status', sortable: true },
  { name: 'Qualification Status', id: 'project_qualification_status', sortable: true },
];

const rowsConfig = ['name', 'entity_name', 'project_id', 'status', 'project_qualification_status'];

export {
  headConfig,
  rowsConfig,
};
