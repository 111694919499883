import React, { useEffect } from 'react';

function usePortal(id) {
  const rootElementRef = React.useRef(document.createElement('div'));

  useEffect(() => {
    const parent = document.getElementById(id);
    const { current } = rootElementRef;
    parent.appendChild(current);

    return function removeElement() {
      current.remove();
    };
  }, [id]);

  return rootElementRef.current;
}

export default usePortal;
