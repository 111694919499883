import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useStyles } from './styles';
import { usePromiseTracker } from "react-promise-tracker";
import { tags } from '../../../../constants/promiseTracker';

// API
import { getQuestions, updateOrCreateAnswers } from '../api';

// SELECTORS
import { getSelectedContainer } from '../../../../selectors/containers';
import { getSelectedEntityObject } from '../../../../selectors/entities';

// COMPONENTS
import LoadingElement from '../../../../commonComponents/LoadingElement';
import Question from '../../../../commonComponents/Question'

// CONSTANTS
import { SIMPLE_SELECT_OPTIONS, ANSWER_DESCRIPTION_PLACEHOLDER, PROJECT_QUALIFICATION_MAX_CHARACTERS } from '../constants'
import useDebounce from '../../../../hooks/useDebounce';
import { handleError } from '../../../../actions/error';

const ProjectQualification = () => {
  const styles = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const [questions, setQuestions] = useState([]);
  const selectedContainer = useSelector(getSelectedContainer)
  const selectedEntity = useSelector(getSelectedEntityObject)
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_PROJECT_QUALIFICATION_QUESTIONS, delay: 0 });
  const projectToQualify = location.state && location.state.project;

  useEffect(() => {
    async function fetchQuestions() {
      const questionsFromDB = await getQuestions(selectedContainer.tax_year, selectedEntity.jurisdiction.jurisdiction_id, projectToQualify.id);
      const DEFAULT_ANSWER = false;
      await Promise.all(questionsFromDB.map(async (question) => {
        if (!question.answer) {
          question.answer = {
            value: DEFAULT_ANSWER ? 1 : 0,
            description: '',
            projectId: projectToQualify.id,
            questionId: question.id,
          };
          try {
            const payload = [question.answer];
            const { answerIds } = await updateOrCreateAnswers(payload, question.answer.projectId);
            question.answer.id = answerIds[0];
          } catch (err) {
            dispatch(handleError(err));
          }
        } else {
          question.answer.value = Boolean(question.answer.value);
        }
      }));
      setQuestions(questionsFromDB);
    }
    if (selectedContainer.tax_year && selectedEntity) {
      fetchQuestions();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContainer, selectedEntity, projectToQualify]);

  const [saveToggleValue] = useDebounce((questionId, value) => {
    const saveNow = async () => {
    const questionsCopy = [...questions];
    const question = questionsCopy.find(quest => quest.id === questionId);
      if (question === undefined) return;

      const newAnswer = { ...question.answer, value: value ? 1 : 0 };
      question.answer = newAnswer;
      setQuestions(questionsCopy);

      try {
        const payload = [newAnswer];
        await updateOrCreateAnswers(payload, newAnswer.projectId);
      } catch (err) {
        dispatch(handleError(err));
      }
    };
    saveNow();
  }, 100);

  const [debouncedSave] = useDebounce((questionId, projectId) => {
    const saveNow = async () => {
      try {
        const question = questions.find(quest => quest.id === questionId);
        if (question === undefined) return;

        const payload = [question.answer];
        await updateOrCreateAnswers(payload, projectId);
      } catch (err) {
        dispatch(handleError(err));
      }
    };
    saveNow();
  }, 2000);

  if (!projectToQualify) {
    return (
      <Redirect
        to="/service-manager/projects"
      />
    );
  }

  if (!questions || isDataFetchInProgress) {
    return <LoadingElement />;
  }

  const handleChange = async (value, id, kind) => {
    const questionsCopy = [...questions];
    const question = questionsCopy.find(quest => quest.id === id);
    if (question === undefined) return;

    if (kind === 'value') {
      const newAnswer = { ...question.answer, value: value ? 1 : 0 };
      question.answer = newAnswer;
      setQuestions(questionsCopy);
      saveToggleValue(question.id, value ? 1 : 0);
    } else {
      const newAnswer = { ...question.answer, description: value };
      question.answer = newAnswer;
      setQuestions(questionsCopy);
      debouncedSave(question.id, newAnswer.projectId);
    }
  };

  const handleBlur = (_, id) => {
    const questionsCopy = [...questions];
    const question = questionsCopy.find(quest => quest.id === id);
    try {
      const payload = [question.answer];
      updateOrCreateAnswers(payload, question.answer.projectId);
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  return (
    <div className={styles.questionsContainer}>
      <h2 className={styles.projectTitle}> Qualifying project: {projectToQualify.name}</h2>
      {questions.map((question, i) => (
        <Question
          key={i}
          question={question.text}
          title={question.title}
          options={SIMPLE_SELECT_OPTIONS}
          type={'simpleSelect'}
          onChange={handleChange}
          onBlur={handleBlur}
          id={question.id}
          descriptionEnabled={true}
          answer={{ value: Boolean(question.answer.value), description: question.answer.description || '' }}
          descriptionPlaceholder={ANSWER_DESCRIPTION_PLACEHOLDER}
          maxAllowedCharacters={PROJECT_QUALIFICATION_MAX_CHARACTERS}
        />
      ))}
    </div>
  );
};

export default ProjectQualification;
