import ICCreditCentral from './imgs/ic-credit-central.svg';
import ICCalculateCredit from './imgs/ic-service-manager.svg';
import ICSurveys from './imgs/ic-surveys.svg';
import  { APP_SECTIONS } from '../../constants/appSections';
import { roles } from '../../constants/roles';

const { FULL_ACCESS_ADMIN, PS_ASSOCIATE, CREDIT_CENTRAL_FULL_ACCESS, ACCOUNT_EXECUTIVE, REPOSITORY_ONLY, SURVEY_RESPONDENT, PS_MANAGER, LIMITED_ACCESS_ADMIN, SUPER_ACCESS_ADMIN, DIY_CLIENT } = roles;

const launchPadConfig = [
  {
    title: APP_SECTIONS.credit_central.name,
    subtitle: APP_SECTIONS.credit_central.subtitle,
    img: ICCreditCentral,
    imgAlt: APP_SECTIONS.credit_central.name,
    link: APP_SECTIONS.credit_central.baseUrl,
    roles: [PS_MANAGER, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, CREDIT_CENTRAL_FULL_ACCESS, ACCOUNT_EXECUTIVE, REPOSITORY_ONLY, PS_ASSOCIATE]
  },
  {
    title: APP_SECTIONS.service_manager.name,
    subtitle: APP_SECTIONS.service_manager.subtitle,
    img: ICCalculateCredit,
    imgAlt: APP_SECTIONS.credit_central.name,
    link: APP_SECTIONS.service_manager.baseUrl,
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, PS_ASSOCIATE, ACCOUNT_EXECUTIVE, PS_MANAGER, PS_ASSOCIATE, SUPER_ACCESS_ADMIN, DIY_CLIENT]
  },
  {
    title: APP_SECTIONS.survey_central.name,
    subtitle: APP_SECTIONS.survey_central.subtitle,
    img: ICSurveys,
    imgAlt: APP_SECTIONS.credit_central.name,
    link: APP_SECTIONS.survey_central.baseUrl,
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, SURVEY_RESPONDENT, PS_MANAGER, ACCOUNT_EXECUTIVE]
  },
];

export default launchPadConfig;
