import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux'

// ACTIONS
import { editAllocatedProject, deleteProjectAllocation } from '../../../../actions/expense'

// COMPONENTS
import { Select, MenuItem, TextField, InputAdornment, IconButton } from '@material-ui/core'
import {Delete, Edit, Check, Close } from '@material-ui/icons';

import roundValueToGivenNumDecimalPlaces from '../../../../utils/roundValueToGivenNumDecimalPlaces';

const useStyles = makeStyles (theme => ({
  container: {
    marginTop: theme.spacing(),
    padding: theme.spacing(.5),
    background: theme.palette.grey[100],
    border: theme.palette.grey[200],
    display: 'flex',
    justifyContent: 'space-between'
  },
  select:{
    width: '50%'
  },
  input: {
    width: '25%'
  },
  buttonsContainer: {
    width: '25%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    padding: 0
  },
  icon: {
    fontSize: '1em'
  }
}))

const AllocatedProject = ({projects, project, index, entityProjects, remainingToAllocate }) => {
  const style = useStyles();
  const dispatch = useDispatch()
  const [editing, setEditing] = useState(false)
  const [selectedProject, setSelectedProject] = useState(project)
  const [percentageError, setPercentageError] = useState('')

  useEffect(() => {
    setSelectedProject(project)
  }, [project])

  const resolveProjectName = () => {
    const project = entityProjects.filter(project => project.project_id === selectedProject.projectId)
    return project.length ? project[0].name : ''
  }

  const handleConfirm = () => {
    dispatch(editAllocatedProject(selectedProject, index))
    setEditing(false)
  }

  const handleCancel = () => {
    setSelectedProject(project)
    setEditing(false)
  }

  const handleDelete = () => {
    dispatch(deleteProjectAllocation(index))
  }

  const handlePercentageChange = (e) => {
    const { value } = e.target;
    const allowed = remainingToAllocate + project.projectPercent;
    setPercentageError('');
    
    if (value <= allowed && value >= 0) {
      setSelectedProject({...selectedProject, projectPercent: Number(e.target.value)})
    } else if (Number(value) > allowed) {
      setPercentageError(`Max value allowed 100`)
    } else if (Number(value) < 0) {
      setPercentageError(`Min value allowed 0`)
    }
  };

  return (
    <div className={style.container}>
      {editing &&
        <>
          <Select
            id="typeId"
            className={style.select}
            value={selectedProject.projectId || ''}
            name="project"
            disabled={!Boolean(projects.length)}
            onChange={(e) => setSelectedProject({...selectedProject, projectId: e.target.value} )}
            required
          >
            {Boolean(projects.length) && projects.map(({project_id, name}) => <MenuItem key={project_id} value={project_id}>{name}</MenuItem>)}
              <MenuItem value={selectedProject.projectId}>{resolveProjectName()}</MenuItem>
          </Select>

          <TextField
            value={Number(roundValueToGivenNumDecimalPlaces(selectedProject.projectPercent, 2)) || ''}
            className={style.input}
            type={'number'}
            name="percentage"
            onChange={handlePercentageChange}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            helperText={percentageError}
            required
            error={percentageError}
            />
        </>
      }
      {!editing &&
        <>
          <span className={style.select}>{resolveProjectName()}</span>
          <span className={style.input}>{Number(roundValueToGivenNumDecimalPlaces(selectedProject.projectPercent, 2))}%</span>
        </>
      }
      <div className={style.buttonsContainer}>
        {editing ?
          <IconButton size={'small'} className={style.button} onClick={ handleConfirm } disabled={!(selectedProject.projectId && selectedProject.projectPercent)} >
            <Check className={style.icon} fontSize={'small'} />
          </ IconButton>
          :
          <IconButton size={'small'} className={style.button} onClick={() => setEditing(true)} >
            <Edit  className={style.icon} />
          </ IconButton>
        }

      {editing &&
      <IconButton size={'small'} className={style.button} onClick={handleCancel}>
        <Close className={style.icon} fontSize={'small'}/>
      </IconButton> }

      <IconButton size={'small'} className={style.button} onClick={ handleDelete }>
        <Delete className={style.icon} fontSize={'small'}/>
      </IconButton>
      </div>
    </div>
  );
};

AllocatedProject.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  project: PropTypes.shape({}),
  index: PropTypes.number,
  entityProjects: PropTypes.arrayOf(PropTypes.shape({})),
  remainingToAllocate: PropTypes.number,
};

export default AllocatedProject;
