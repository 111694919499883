const tags = {
    GET_EMP_INTERVIEWS: 'getEmployeeInterviews',
    GET_OVERVIEW_QUESTIONS: 'getOverviewQuestions',
    FETCH_EXPENSES_TYPES_FOR_PROJECTS: 'fetchExpenseTypesForProjects',
    FETCH_PROJECT_QUALIFICATION_QUESTIONS: 'fetchProjectQualificationQuestions',
    FETCH_SURVEYS: 'fetchSurveys',
    FETCH_TENANTS: 'fetchTenants',
    FETCH_ENTITY_DETAILS: 'fetchEntityDetails',
    FETCH_ENTITY_FOR_ENTITY_INFO: 'fetchEntityForEntityInfo',
    FETCH_SURVEY_BY_ID: 'fetchSurveyById',
    FETCH_FILTERED_SURVEYS_FOR_SURVEY_CENTRAL: 'fetchFilteredSurveysForSurveyCentral',
    FETCH_EXPENSE_QUESTIONS: 'fetchExpenseQuestions',
    FETCH_DOCUMENTS_PER_PROJECT: 'fetchDocumentsPerProject',
    FETCH_SURVEY_RETROACTIVE_TIMESHEETS: 'fetchSurveyRetroactveTimesheets',
    ADD_EMPLOYEE_TO_SURVEY: 'addEmployeeToSurvey',
    FETCH_SURVEY_PROJECTS: 'fetchSurveyProjects',
    FETCH_SURVEY_PROJECT_QUALIFICATIONS: 'fetchSurveyProjectQualifications',
    FETCH_EXPENSE_TYPES_FOR_SURVEYS: 'fetchExpenseTypesForSurveys',
    SUBMIT_SURVEY_COMPLETION: 'submitSurveyCompletion',
    EXPENSES_BULK_UPLOAD: 'expensesBulkUpload',
    FETCH_EXPENSES: 'fetchExpenses',
    FETCH_CREDIT_DETAIL_QUESTIONS: 'fetchCreditDetailQuestions',
    FETCH_CREDIT_DETAIL_SECTIONS: 'fetchCreditDetailSections',
    GENERATE_GLOBAL_GUIDE_REPORT: 'generateGlobalGuideReport',
    FETCH_GLOBAL_GUIDE_REPORT_META: 'fetchGlobalGuideReportMeta',
    PROJECTS_BULK_UPLOAD: 'uploadProjectsViaExcelTemplate',
}

export {
    tags
}
