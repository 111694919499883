import React, { Component } from 'react';
// components
import ErrorMessage from './ErrorMessage';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }
  clearErrorBoundary = () => {
    this.setState({
      error: null,
      errorInfo: null
    })
  };

  render() {

    if (this.state.errorInfo) {
      return (
        <div>
          <ErrorMessage clearErrorBoundary={this.clearErrorBoundary}/>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
