import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import ExpansionPanelHint from '../../../../commonComponents/ExpansionPanelHint';
import { useDispatch } from 'react-redux';
import { handleAnswerChange, updateOrCreateAnswers } from '../../../../actions/creditDetail';
import useDebounce from "../../../../hooks/useDebounce";

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[800],
      },
    },
  },
  expansionPanel: {
    paddingTop: theme.spacing(2),
  }
}));

const QuestionAndAnswers = ({ question, isCompleted, creditId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [saveAnswer] = useDebounce(async (questionId, answer) => {
    const dataAnswerDetail = {
      creditId: creditId,
      creditDetailQuestionId: questionId,
      answer
    };
    dispatch(updateOrCreateAnswers(dataAnswerDetail))
  }, 3000)

  const handleOnChange = (event) => {
    const { value } = event.target;
    dispatch(handleAnswerChange(value, question.id));
    saveAnswer(question.id, value);
  };

  const renderHint = (questionHint) => {
    if (questionHint) {
      return (
        <ExpansionPanelHint>
          {questionHint}
        </ExpansionPanelHint>
      );
    }
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={6}>
          <TextField
            disabled={Boolean(isCompleted)}
            id='long-answer'
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            value={question.answer.value}
            onChange={handleOnChange}
            className={classes.input}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderHint(question.hint)}
        </Grid>
      </Grid>
    </>
  )

}

export default QuestionAndAnswers;