import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  entityDropdown: {
    width: '230px',
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end'
  },
  creditDropdown: {
    width: '100px',
    paddingRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'flex-end'
  },
  versionCell: {
    display: 'flex',
  },
  needsCreditDetailsTooltip: {
    fontSize: '1.6rem',
    marginLeft: theme.spacing(1)
  },
  needsCreditDetailsIcon: {
    position: 'relative',
    display: 'flex'
  },
  circleIcon: {
    position: 'absolute',
    right: '0px',
    top: '-3px',
    color: theme.palette.red,
    fontSize: '1rem'
  },
  createNewReportButtonContainer: {
    display: 'flex'
  }
}));