import { roles } from '../../../constants/roles';

export const SECTION_TITLE = {
  plural: 'Surveys',
  singular: 'Survey'
}
export const NECESSARY_ROLES_PER_COMPONENT = {
  viewSurveyButton: [roles.PS_MANAGER, roles.FULL_ACCESS_ADMIN, roles.LIMITED_ACCESS_ADMIN, roles.PS_MANAGER, roles.ACCOUNT_EXECUTIVE]
}

export const SURVEY_TYPE = {
  lead: 1,
  individual: 2
}

export const SURVEY_STATUS_REF_ID_BY_NAME = {
  completed: { name: 'Completed', id: 1 },
  pending: { name: 'Pending', id: 2 }
}


export const SURVEY_INITIAL_STATE = {
  list: [],
  userProxyIds: [],
  timePeriods: [],
  selected: {
    dueDate: null,
    completedDate: null,
    surveyStatus: '',
    projectSurveys: [],
    employeeSurveys: [],
    userProxyId: '',
    timePeriodId: '',
    trackingTypeId: '',
    surveyStartDate: null,
    surveyEndDate: null,
    employeeId: '',
    employeeDepartment: '',
    employeeName: '',
    createdAt: '',
    createdBy: '',
  },
};

export const MAX_TABLE_HEIGHT = '90%'
