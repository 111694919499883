import React from 'react';
import ApproveDirectiveSection from "../../../../commonComponents/CreditDetails/ApproveDirectiveSection";
import DetailFrame from "../../../../commonComponents/CreditDetails/DetailFrame";
import { useStyles } from './styles';
import QuestionRow from './QuestionRow';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getCurrentCreditDetailQuestionAnswers } from '../../../../selectors/creditDetail';
import {
  changeSectionCompletionStatus,
  handleAnswerChange,
} from '../../../../actions/creditDetail';
import { questionsToRenderPerSection } from '../constants';

const DirectiveExpenseDetails = ({ section, creditId }) => {
  const styles = useStyles();
  const questions = useSelector(getCurrentCreditDetailQuestionAnswers, shallowEqual);
  const dispatch = useDispatch();
  const { creditDetailSectionRefId, isCompleted } = section;

  const handleToggleComplete = (isCompleted) => {
    const payload = {
      creditId: creditId,
      creditDetailSectionRefId: creditDetailSectionRefId,
      isCompleted: isCompleted
    };
    dispatch(changeSectionCompletionStatus(payload));
  }

  const handleChange = (value, questionId) => {
    dispatch(handleAnswerChange(value, questionId));
  };

  const renderQuestionAnswers = (questionsToRender) => {
    return questionsToRender.map((questionId) => {
      const questionData = questions[questionId];
      if (questionData) {
        const { answer, hint, question } = questionData;

        return (
          <QuestionRow
            key={questionId}
            creditId={creditId}
            questionId={questionId}
            question={question}
            hint={hint}
            answer={answer}
            isSectionCompleted={isCompleted}
            onAnswerChange={handleChange}
          />
        );
      }

      return null;
    });
  };

  return (
    <div>
      <ApproveDirectiveSection
        title="Directive Expense Detail"
        body="Answers provided in this section will populate the Directive Appendix of the report"
        onToggleComplete={handleToggleComplete}
        isCompleted={isCompleted}
        disabled={false}
      />

      <DetailFrame
        title="Entity/Jurisdiction Items"
        iconText="1"
        className={styles.detailFrame}
        divider
      >
        {renderQuestionAnswers(questionsToRenderPerSection.entityJurisdictionItems)}
      </DetailFrame>

      <DetailFrame
        title="Activity Items"
        iconText="2"
        className={styles.detailFrame}
        divider
      >
        {renderQuestionAnswers(questionsToRenderPerSection.activityItems)}
      </DetailFrame>

      <DetailFrame
        title="Compensation Items"
        iconText="3"
        className={styles.detailFrame}
        divider
      >
        {renderQuestionAnswers(questionsToRenderPerSection.compensationItems)}
      </DetailFrame>

      <DetailFrame
        title="Other General Expense Items"
        iconText="4"
        className={styles.detailFrame}
        divider
      >
        {renderQuestionAnswers(questionsToRenderPerSection.otherGeneralExpenseItems)}
      </DetailFrame>
    </div>
  );
};

export default DirectiveExpenseDetails;