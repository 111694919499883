import {
  GET_EMPLOYEE_INTERVIEWS_SUCCESS,
  SELECT_EMPLOYEE_INTERVIEW,
  SET_SELECTED_EMPLOYEE_INTERVIEW_FIELD,
  CLEAN_SELECTED_EMPLOYEE_INTERVIEW
} from '../../constants/actionTypes/entityInfo'

const initialState = {
  selected: {}
}

const entityInterviews = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMPLOYEE_INTERVIEWS_SUCCESS:
      return {
        ...state,
        list: action.employeeInterviews
      }
    case SELECT_EMPLOYEE_INTERVIEW:
      return {
        ...state,
        selected: action.employeeInterview
      }
    case SET_SELECTED_EMPLOYEE_INTERVIEW_FIELD:
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.key]: action.value
        }
      }
    case CLEAN_SELECTED_EMPLOYEE_INTERVIEW:
      return {
        ...state,
        selected: {}
      }

    default: return state;
  }
};

export default entityInterviews;
