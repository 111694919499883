import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import { useStyles } from './styles';
import Button from '../../../../commonComponents/Button';

const GoToCreditDetailsButton = ({ children: cellData, rowData: credit }) => {
  const history = useHistory();
  const styles = useStyles();

  const goToCreditDetails = (creditId) => {
    history.push(`/service-manager/study-central/${creditId}/credit-details`)
  };

  let returnedComponent;

  if (cellData !== 'N/A') {
    returnedComponent = (
      <Button onClick={() => goToCreditDetails(credit.id)} className={styles.goToCreditDetailsButton} color="gray">
        {cellData}
        <ArrowForwardIosIcon className={styles.goToCreditDetailsButtonIcon} />
      </Button>
    )
  } else {
    returnedComponent = (
      <>
        {cellData}
      </>
    );
  }

  return returnedComponent;
};

export default GoToCreditDetailsButton;