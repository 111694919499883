import { IntlFormatter } from './IntlFormatter';
import dateToDisplayableFormat from './dateToDisplayableFormat';
import roundValueToGivenNumDecimalPlaces from '../utils/roundValueToGivenNumDecimalPlaces'

export const formatResultByType = ({ value, type, currencyIsoCode = 'USD', booleanValues, locale = 'en-US', shouldIncludeCents = true }) => {
  let formattedValue;
  const formatPercent = () => (`${value}%`);
  const formatDecimalPercentage = () => {
    const result = roundValueToGivenNumDecimalPlaces(value * 100, 4)
    return `${result}%`;
  };

  const formatCurrency = () => {
    const maximumFractionDigits = shouldIncludeCents ? 2 : 0;
    const minimumFractionDigits = shouldIncludeCents ? 2 : 0;

    return IntlFormatter(locale, { style: 'currency', currency: currencyIsoCode, maximumFractionDigits, minimumFractionDigits }).format(value)
  };

  const defaultFormatter = () => value;
  const formatNumber = () => (value.toString());
  const formatDate = () => {
    return dateToDisplayableFormat(value)};
  const formatBoolean = () => {
    return booleanValues ? booleanValues[value] : value;
  };
  const formatDecimal4PrecisionPlaces = () => {
    return value.toFixed(4);
  }

  const formatTypes = {
    percentage: formatPercent,
    currency: formatCurrency,
    number: formatNumber,
    date: formatDate,
    boolean: formatBoolean,
    decimalPercentage: formatDecimalPercentage,
    decimal4PrecisionPlaces: formatDecimal4PrecisionPlaces
  };
  
  const formatFunc = formatTypes[type] || defaultFormatter;
  
  formattedValue = formatFunc();

  return formattedValue;
};
