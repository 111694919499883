import { trackPromise } from 'react-promise-tracker';
import { apiClient } from '../../api/apiClient';
import { handleError } from '../error';

import {
  FETCH_SURVEY_PROJECTS_SUCCESS,
  FETCH_SURVEY_PROJECT_QUALIFICATIONS_SUCCESS,
  SELECT_SURVEY_PROJECT_QUALIFICATION,
  PREVIOUS_PROJECT_QUESTION,
  NEXT_PROJECT_QUESTION,
  UPDATE_PROJECT_QUESTION_ANSWER,
  SET_IS_SAVE_IN_PROGRESS,
} from '../../constants/actionTypes/surveyWizard';


import { tags } from '../../constants/promiseTracker';

const getProjectQualificationQuestions = async (taxYear, jurisdictionId, projectId) => {
  const qualifications = await apiClient({
    method: 'get',
    url: `/project-qualification?taxYear=${taxYear}&jurisdictionId=${jurisdictionId}&projectId=${projectId}`,
  });
  return qualifications;
};

export const fetchSurveyProjectsSuccess = projects => ({
  type: FETCH_SURVEY_PROJECTS_SUCCESS,
  projects
});

export const fetchSurveyProjectQualificationsSuccess = (project, qualifications) => ({
  type: FETCH_SURVEY_PROJECT_QUALIFICATIONS_SUCCESS,
  qualifications,
  project
});

export const nextProjectQuestion = () => ({
  type: NEXT_PROJECT_QUESTION
});

export const previousProjectQuestion = () => ({
  type: PREVIOUS_PROJECT_QUESTION
});

export const selectProject = id => ({
  type: SELECT_SURVEY_PROJECT_QUALIFICATION,
  selectedProjectId: id
})

export const updateSurveyProjectQualificationAnswer = ({
  boolValue,
  textValue,
  questionId,
  projectId
}) => ({
  type: UPDATE_PROJECT_QUESTION_ANSWER,
  boolValue,
  textValue,
  questionId,
  projectId
})

export const getProjectsBySurveyId = surveyId => async dispatch  => {
  try {
    const projects = await trackPromise(
      apiClient({
        method: 'get',
        url: `survey/project-survey?surveyId=${surveyId}`
      }),
      tags.FETCH_SURVEY_PROJECTS
    );
    const projectMap = projects.reduce((a, project) => ({
      ...a,
      [project.id]: project
    }), {});
    dispatch(fetchSurveyProjectsSuccess(projectMap));
    return projects;
  } catch (err) {
    return dispatch(handleError(err));
  }
};


export const getProjectQualifications = (taxYear, jurisdictionId, project) => async (dispatch) => {
  try {
    const qualifications = await trackPromise(
      getProjectQualificationQuestions(taxYear, jurisdictionId, project),
      tags.FETCH_SURVEY_PROJECT_QUALIFICATIONS
    );
    dispatch(fetchSurveyProjectQualificationsSuccess(project, qualifications));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const setIsSaveInProgress = (isSaveInProgress) => ({
  type: SET_IS_SAVE_IN_PROGRESS,
  isSaveInProgress
});

export const updateOrCreateAnswers = (projectId, survey) => async (dispatch, getState) => {
  const state = getState();
  if (!Object.keys(state.surveyWizard.projects.qualifications).length || !state.surveyWizard.projects.qualifications[projectId]) {
    return;
  }
  const questions = state.surveyWizard.projects.qualifications[projectId];
  const questionsWithAnswers = questions.filter((question) => (question.answer));
  const payload = questionsWithAnswers.map(question => ({
    ...question.answer,
    projectId,
    questionId: question.id
  }));

  try {
    dispatch(setIsSaveInProgress(true))
    await apiClient({
      method: 'post',
      url: '/project-qualification/answer',
      data: { answers: payload, projectId },
    });
    dispatch(setIsSaveInProgress(false));
  } catch (err) {
    dispatch(setIsSaveInProgress(false))
    dispatch(handleError(err));
  }
};
