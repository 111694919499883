export const getAuthData = (state) => state.auth
export const getChangePasswordError = (state) => state.changePasswordError
export const getAuthorizedUserData = (state) => {
  if (!state.auth.auth) {
    return {}
  }
  const {username, userEmail} = state.auth ? state.auth.auth : {username : "", userEmail: ""}

  return {username, userEmail}
}

export const getRoleData = (state) => (state.auth.auth || []).roles;

