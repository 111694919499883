import { FETCH_CONTAINERS_SUCCESS, SELECT_CONTAINER_CRUD, SET_SELECTED_CONTAINER_FIELD } from '../constants/actionTypes/container';
import { CONTAINER_INITIAL_STATE } from '../components/ServiceManager/Containers/constants';

const containers = (state = CONTAINER_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONTAINERS_SUCCESS:
      return {
        ...state,
        list: action.containers
      };
    case SELECT_CONTAINER_CRUD:
      return {
        ...state,
        selected: action.selectedContainer
      };
    case SET_SELECTED_CONTAINER_FIELD:
      return {
        ...state,
        selected: { ...state.selected, [action.key]: action.value }
      }

    default: return state;
  }
};

export default containers;
