export const welcomePageSections = [
  {
    header: `Welcome to R&D Surveys`,
    body: `Thank you for assisting with the R&D work. The innovative work you are doing will help in taking advantage of certain tax incentives available to help companies grow.`
  },
  {
    header: `Help us learn more about your projects`,
    body: `In order to qualify for these incentives, the team here at Exactera needs to learn more about the R&D projects you and your team work on.`
  },
  {
    header: `Simply add project details and time spent`,
    body: `We have provided a survey with a short series of questions to be answered to the best of your knowledge, along with an estimate of time you and others spent on certain projects.`
  },
  {
    header: `Questions? Just reach out to an expert`,
    body: `If you need assistance with completing the survey or understanding more about the questions being asked, please reach out to the Exactera R&D Tax Advisor.`
  }
];
