const headConfig = [
  { name: 'Tax Year', sortable: true, id: 'taxYear' },
  { name: 'Jurisdiction', sortable: true, id: 'jurisdictionName' },
  { name: 'Value', sortable: true, id: 'value', useTypeFromData: true },
];

const rowsConfig = ['typeName', 'taxYear', 'jurisdictionName', 'value'];

export {
  headConfig,
  rowsConfig
}
