import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  main: {
    display: 'flex',
  },
  nav: {
    width: '28%',
    marginRight: '1.5rem',
  },
  info: {
    width: '72%',
  },
  backButton: {
    position: 'relative',
    right: 0,
    marginRight: theme.spacing(1),
    fontSize: '1.8rem',
    cursor: 'pointer',
    transition: 'right 0.3s ease',

    '&:hover': {
      right: 3,
    },
  },
  creditName: {
    marginLeft: '1rem',
    fontWeight: 'normal',
  }
}));
