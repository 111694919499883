import ExpenseTypeIconId1 from '../../../../svgs/expense-types/ExpenseType-id-1';
import ExpenseTypeIconId2 from '../../../../svgs/expense-types/ExpenseType-id-2';
import ExpenseTypeIconId3 from '../../../../svgs/expense-types/ExpenseType-id-3';
import ExpenseTypeIconId4 from '../../../../svgs/expense-types/ExpenseType-id-4';
import ExpenseTypeIconId5 from '../../../../svgs/expense-types/ExpenseType-id-5';
import ExpenseTypeIconId6 from '../../../../svgs/expense-types/ExpenseType-id-6';
import ExpenseTypeIconId7 from '../../../../svgs/expense-types/ExpenseType-id-7';
import ExpenseTypeIconId8 from '../../../../svgs/expense-types/ExpenseType-id-8';
import ExpenseTypeIconId9 from '../../../../svgs/expense-types/ExpenseType-id-9';
import ExpenseTypeIconId10 from '../../../../svgs/expense-types/ExpenseType-id-10';
import ExpenseTypeIconId11 from '../../../../svgs/expense-types/ExpenseType-id-11';
import ExpenseTypeIconId12 from '../../../../svgs/expense-types/ExpenseType-id-12';
import ExpenseTypeIconId13 from '../../../../svgs/expense-types/ExpenseType-id-13';
import ExpenseTypeIconId14 from '../../../../svgs/expense-types/ExpenseType-id-14';
import ExpenseTypeIconId15 from '../../../../svgs/expense-types/ExpenseType-id-15';
import ExpenseTypeIconId16 from '../../../../svgs/expense-types/ExpenseType-id-16';
import ExpenseTypeIconId17 from '../../../../svgs/expense-types/ExpenseType-id-17';
import ExpenseTypeIconId18 from '../../../../svgs/expense-types/ExpenseType-id-18';
import ExpenseTypeIconId19 from '../../../../svgs/expense-types/ExpenseType-id-19';
import ExpenseTypeIconId20 from '../../../../svgs/expense-types/ExpenseType-id-20';
import ExpenseTypeIconId21 from '../../../../svgs/expense-types/ExpenseType-id-21';
import ExpenseTypeIconId22 from '../../../../svgs/expense-types/ExpenseType-id-22';
import ExpenseTypeIconId23 from '../../../../svgs/expense-types/ExpenseType-id-23';
import ExpenseTypeIconId24 from '../../../../svgs/expense-types/ExpenseType-id-24';
import ExpenseTypeIconId25 from '../../../../svgs/expense-types/ExpenseType-id-25';
import ExpenseTypeIconId26 from '../../../../svgs/expense-types/ExpenseType-id-26';

export const expenseTypeIconsByTypeId = {
  1: ExpenseTypeIconId1,
  2: ExpenseTypeIconId2,
  3: ExpenseTypeIconId3,
  4: ExpenseTypeIconId4,
  5: ExpenseTypeIconId5,
  6: ExpenseTypeIconId6,
  7: ExpenseTypeIconId7,
  8: ExpenseTypeIconId8,
  9: ExpenseTypeIconId9,
  10: ExpenseTypeIconId10, 
  11: ExpenseTypeIconId11, 
  12: ExpenseTypeIconId12, 
  13: ExpenseTypeIconId13, 
  14: ExpenseTypeIconId14, 
  15: ExpenseTypeIconId15, 
  16: ExpenseTypeIconId16, 
  17: ExpenseTypeIconId17, 
  18: ExpenseTypeIconId18, 
  19: ExpenseTypeIconId19, 
  20: ExpenseTypeIconId20, 
  21: ExpenseTypeIconId21, 
  22: ExpenseTypeIconId22, 
  23: ExpenseTypeIconId23, 
  24: ExpenseTypeIconId24, 
  25: ExpenseTypeIconId25, 
  26: ExpenseTypeIconId26,
}
