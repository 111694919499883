import React, {useCallback, useState} from "react";
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from "prop-types";

import  { UseStyles, MenuProps } from "./Filter.styles";
import classNames from "classnames";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

const Filter = (props) => {
  const { placeholder, onChange = (value) => {}, options } = props;
  const styles = UseStyles();
  const [values, setValues] = useState('');

  const onHandleChange = useCallback((e) => {
    const value = e.target.value;
    setValues(value);
    onChange(value);
  }, [onChange]);

  const onHandleClear = useCallback(() => {
    onChange('');
    setValues('');
  }, [onChange]);

  const mainClassNames = classNames({
    [styles.FilterBox]: true,
    [styles.FilterBoxSelected]: values.toString().length > 0
  });

  return (
    <div className={styles.WrapperFilter}>
      <Select
        className={mainClassNames}
        value={values}
        input={<Input />}
        displayEmpty
        MenuProps={MenuProps}
        onChange={onHandleChange}
      >
        <MenuItem disabled value=""><em>{placeholder}</em></MenuItem>
        {options.map((option, index) => {
          return (
            <MenuItem key={`op-${index}`} value={option instanceof Object ? option.value : option}>
              {option instanceof Object ? option.key : option}
            </MenuItem>
          );
        })}
      </Select>
      { values && <IconButton onClick={onHandleClear} className={styles.FilterClearButton}><Close /></IconButton>}
    </div>
  );
}

Filter.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func
};

export default Filter;
