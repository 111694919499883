import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import Button from '../Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles((theme) => ({
  wrapper: {
  },
  appBar: {
    position: 'relative',
    boxShadow: 'none'
  },
  viewAnalysisWrapper: {
    backgroundColor: 'red'
  },
  mainContent: {
    padding: 0,
    overflowY: 'hidden',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white'
  },
  closeIcon: {
    color: 'black'
  },
  dialogActions: {
    borderTop: `${theme.palette.grey[200]} solid 1px`,
    padding: theme.spacing(1.5)
  },
  doneButton: {
    height: 45,
  }
}));

const DemoModal = ({ isOpen, onClose, onButtonClick, buttonCaption, transitionProps, head, children }) => {
  const styles = useStyles();
  
  return (
    <div>
      <Dialog maxWidth="lg" onClose={onClose} TransitionProps={transitionProps} open={isOpen} className={styles.wrapper}>
        <AppBar className={styles.appBar}>
          <Toolbar className={styles.toolbar}>
            {head}
            <IconButton edge="end" color="inherit" onClick={onClose}>
              <CloseIcon className={styles.closeIcon} />
            </IconButton>
          </Toolbar>
        </AppBar>
        
        <DialogContent className={styles.mainContent}>
          {children}
        </DialogContent>
        
        <DialogActions className={styles.dialogActions}>
          <Button className={styles.doneButton} onClick={onButtonClick}>
            {buttonCaption}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DemoModal;
