import { FETCH_TIMESHEETS_SUCCESS } from '../constants/actionTypes/timesheets';
import { SELECT_ENTITY } from '../constants/actionTypes/entity';

const initialState = {
  list: []
}

const timesheets = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIMESHEETS_SUCCESS:
      return {
        ...state,
        list: action.data.timesheets,
        tableConfig: action.data.tableConfig
      };
    case SELECT_ENTITY:
      return {
        ...state,
        list: [],
        tableConfig: {}
      }
    default: return state;
  }
}

export default timesheets