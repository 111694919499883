import React from 'react';
import SideNavTab from './SideNavTab';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Switch } from '@material-ui/core';
import classNames from 'classnames';


const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    backgroundColor: theme.palette.common.white,
    overflow: 'auto'
  },
  title: {
    margin: theme.spacing(2)
  },
  toggleComplete: {
    color: theme.palette.primary.light,
    margin: theme.spacing(2),
    padding: theme.spacing(0.5),
    border: `0.5px solid ${theme.palette.primary.light}`,
    borderRadius: '25px',
    display: 'inline-block'
  },
  toggleText: {
    color: theme.palette.grey[400],
    fontSize: '0.75rem',
    marginLeft: theme.spacing(0.5),
    display: 'inline-block',
    '&$completedText': {
      color: theme.palette.primary.main
    }
  },
  completedText: {},
  toggleSwitch: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

const SideNav = ({ title = '', tabs = [], completed, toggleCallback, questionHelpTooltip }) => {
  const styles = useStyles();
  const borderStyle = index => (index === 0) ? 'top' : 'bottom';
  const { setHideHelp } = questionHelpTooltip;

  const onNavTabClick = (tab) => {
    tab.onClick()
    setHideHelp(true);
  }

  const toggleTextClasses = classNames({
    [styles.toggleText]: true,
    [styles.completedText]: completed
  });

  const toggleText = completed ? `${title} section complete` : `Mark ${title} section as complete`;
  return (
    <div className={styles.container}>
      <div data-testid="title" className={styles.title}>
        <Typography>{ title }</Typography>
      </div>
      { tabs.map((tab, i) =>
        <SideNavTab
          key={`${title}-${i}`}
          Icon={tab.icon}
          title={tab.title}
          // progress={tab.progress}
          selected={tab.selected}
          last={i === tabs.length - 1}
          borderStyle={borderStyle(i)}
          onClick={() => onNavTabClick(tab)}
        />) }
        <div data-testid="toggle-complete" className={styles.toggleComplete}>
          <Typography className={toggleTextClasses}>{toggleText}</Typography>
          <Switch data-testid="toggle-switch" onChange={toggleCallback} checked={completed} className={styles.toggleSwitch} size="small" />
        </div>
    </div>
  );
};

export default SideNav;
