import {
  FETCH_SURVEYS_SUCCESS,
  SELECT_SURVEY,
  SET_SELECTED_SURVEY_FIELD,
  FETCH_TIME_PERIODS_SUCCESS,
  SET_SELECTED_PROJECT_SURVEY,
  SET_SELECTED_EMPLOYEE_SURVEY,
  FETCH_TRACKING_TYPES_SUCCESS,
  FETCH_USERS_PROXY_SUCCESS
} from '../constants/actionTypes/survey';
import { SURVEY_INITIAL_STATE } from '../components/ServiceManager/Surveys/constants';


const surveys = (state = SURVEY_INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_SURVEYS_SUCCESS:
      return {
        ...state,
        list: action.surveys
      };
    case SELECT_SURVEY:
      return {
        ...state,
        selected: action.selectedSurvey
      };
    case SET_SELECTED_SURVEY_FIELD:
      return {
        ...state,
        selected: { ...state.selected, [action.key]: action.value }
      };
    case SET_SELECTED_PROJECT_SURVEY:
      return {
        ...state,
        selected: { ...state.selected, [action.key]: action.value },
      };
    case SET_SELECTED_EMPLOYEE_SURVEY:
      return {
        ...state,
        selected: { ...state.selected, [action.key]: action.value },
      };
    case FETCH_TIME_PERIODS_SUCCESS:
      return {
        ...state,
        timePeriods: action.timePeriods
      };
    case FETCH_TRACKING_TYPES_SUCCESS:
      return {
        ...state,
        trackingTypes: action.trackingTypes
      };
    case FETCH_USERS_PROXY_SUCCESS:
      return {
        ...state,
        userProxyIds: action.userProxyIds
      };
    default: return state;
  }
};

export default surveys;
