import React, { useEffect, useState } from 'react';
import FormBuilder from '../../../../commonComponents/FormBuilder';
import {
  CREDIT_STATUSES, CREDIT_USER_OPTION_SINGLE_SELECTION,
} from '../constants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  deleteCreditFromRedux,
  fetchCreditByIdSuccess,
  selectCredit,
  setSelectedCreditField,
  setCalculationModalState,
} from '../../../../actions/credit';
import {
  createCredit,
  deleteCredit,
  fetchCreditById,
  fetchCreditMethods,
  fetchCreditUserOptions,
  updateCredit,
} from '../api';
import {
  getSelectedCredit,
  getCredits,
 } from '../../../../selectors/credits';
import { getSelectedContainer } from '../../../../selectors/containers';
import { getSelectedTenant } from '../../../../selectors/tenants';
import dateToMysqlFormat from '../../../../utils/dateToMysqlFormat';
import areAllTruthy from '../../../../utils/areAllTruthy';
import sortObjectListAsc from '../../../../utils/sortObjectListAsc';
import dateToDisplayableFormat from '../../../../utils/dateToDisplayableFormat';
import CreditCalculationResultsModal from './CreditCalculationResultsModal';
import { apiClient } from '../../../../api/apiClient';
import { buildCreditInputFields } from './buildCreditInputFields';
import { showMessage } from '../../../../actions/message';
import { handleError } from '../../../../actions/error';
import CreditCalculationAnimationModal from './CreditCalculationAnimationModal';
import RdPlannerModal from './RDPlannerModal';
import useRoleRestriction from '../../../../hooks/useRoleRestriction';
import { NECESSARY_ROLES_PER_COMPONENT } from './constants';
import getValueFromEvent from '../../../../utils/getValueFromEvent';
import DirectiveComparisonModal from './DirectiveComparisonModal';

const StudyCentralCrud = (props) => {
  const [creditMethods, setCreditMethods] = useState([]);
  const [creditMethodsFetching, setCreditMethodsFetching] = useState(false);
  const [hasEditedSaveFirstField, setHasEditedSaveFirstField] = useState(false);
  const [shouldDisplayCreditCalculationsModal, setShouldDisplayCreditCalculationsModal] = useState(false);
  const [shouldDisplayRDPlannerModal, setShouldDisplayRDPlannerModal] = useState(false);
  const [shouldDisplayDirectiveComparisonModal, setShouldDisplayDirectiveComparisonModal] = useState(false);
  const hasRoleAccessTo = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);
  const dispatch = useDispatch();
  const credits = useSelector(getCredits, shallowEqual);
  const credit = useSelector(getSelectedCredit, shallowEqual);
  const tenant = useSelector(getSelectedTenant, shallowEqual);
  const container = useSelector(getSelectedContainer, shallowEqual);
  const [singleSelectionUserOption, setSingleSelectionUserOption] = useState(false);
  const { tax_year: taxYear, container_id: containerId } = container;
  const { jurisdictionId } = credit;
  const isEdit = props.crudType === 'edit';
  const isCreate = props.crudType === 'create';

  // Save first fields are credit fields that disable credit calculation when they are edited (and not saved).
  // So you have to save the credit first before continuing with the calculation.
  const saveFirstFields = ['publishedStatusRefId'];

  useEffect(() => {
    if (isCreate) {
      return;
    }
    if (credits?.length) {
      const updatedCredit = credits.find(cred => cred.id === props.id);
      if (updatedCredit) {
        async function getCreditMethods() {
          setCreditMethods([]);
          setCreditMethodsFetching(true);
          const creditMethodResults = await fetchCreditMethods(updatedCredit.jurisdictionId, taxYear);
          dispatch(selectCredit(updatedCredit));
          setCreditMethods(creditMethodResults);
          setCreditMethodsFetching(false);
        };
        getCreditMethods();
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const toggleCreditCalculationsModal = (shouldDisplayCreditCalculations) => {
    setShouldDisplayCreditCalculationsModal(shouldDisplayCreditCalculations);
  };

  const toggleDirectiveComparisonModal = (shouldDisplayDirectiveComparison) => {
    setShouldDisplayDirectiveComparisonModal(shouldDisplayDirectiveComparison);
  };

  const toggleRDPlannerModal = (shouldDisplayModal) => {
    setShouldDisplayRDPlannerModal(shouldDisplayModal);
  };

  const closeCreditCalculationAnimationModal = () => {
    dispatch(setCalculationModalState('closed'));
  };

  const checkIsUserOption = (fieldName) => {
    const userOptions = credit.creditUserOptions.map(userOption => userOption.description);
    return userOptions.includes(fieldName);
  };

  const setUserOption = (name, value) => {
    let optionId;
    let userOptionsOrdered;

    const newUserOptions = credit.creditUserOptions.filter(userOption => {
      const isOptionBeingSet = userOption.description === name;
      if (isOptionBeingSet) {
        optionId = userOption.id;
      }

      return !isOptionBeingSet;
    });

    if(singleSelectionUserOption){

      newUserOptions.push({ description: name, response: Number(value), id: optionId });
      const UserOptionsUnique = newUserOptions.map((UserOption) => {
        if (UserOption.id !== optionId) {
          UserOption.response = 0;
        }
        return UserOption;
      });
      userOptionsOrdered = sortObjectListAsc(UserOptionsUnique, 'id');

    } else {
      newUserOptions.push({ description: name, response: Number(value), id: optionId });
      userOptionsOrdered = sortObjectListAsc(newUserOptions, 'id');
    }

    dispatch(setSelectedCreditField('creditUserOptions', userOptionsOrdered));
  };

  const handleChange = async (event, name) => {
    const value = getValueFromEvent(event);

    if (typeof name !== 'string') {
      name = event.target.name;
    }

    if (name === 'dueDate') {
      const creditDueDate = dateToDisplayableFormat(value);
      dispatch(setSelectedCreditField('dueDate', creditDueDate));
    }

    if (name === 'jurisdictionId') {
      if (taxYear) {
        setCreditMethods([]);
        const creditMethodResults = await fetchCreditMethods(value, taxYear);
        setCreditMethods(creditMethodResults);
      }
      dispatch(setSelectedCreditField('creditUserOptions', []));
    }

    if (name === 'creditMethodRefId') {
      if (taxYear) {
        async function getCreditUserOptions() {
          const creditUserOptionsResult = await fetchCreditUserOptions(value, taxYear);
          const userOptionsOrdered = sortObjectListAsc(creditUserOptionsResult, 'id');
          const userOptionSingleSelection = (userOption) => userOption.singleSelection === CREDIT_USER_OPTION_SINGLE_SELECTION;
          const isSingleSelection = userOptionsOrdered.some(userOptionSingleSelection);
          setSingleSelectionUserOption(isSingleSelection);
          dispatch(setSelectedCreditField('creditUserOptions', userOptionsOrdered));
        };
        getCreditUserOptions();
      } else {
        dispatch(setSelectedCreditField('creditUserOptions', []));
      }
    }

    if (saveFirstFields.includes(name)) {
      setHasEditedSaveFirstField(true);
    }
    const isUserOption = checkIsUserOption(name);
    if (isUserOption) {
      setUserOption(name, value);
      return;
    }

    dispatch(setSelectedCreditField(name, value));
  };

  const handleCreate = async () => {
    const body = makeBody();
    const isCreditStatusValid = checkIsCreditStatusValid();
    if (!isCreditStatusValid) {
      dispatch(showMessage('error', 'Cannot set credit status to completed without a credit amount', 3000))
      return;
    }
    try {
      props.closeCrudPanel();
      const response = await createCredit(body);
      const newCreditId = response.body.id;
      const newCredit = await fetchCreditById(newCreditId);
      dispatch(fetchCreditByIdSuccess(newCreditId, newCredit));
      dispatch(showMessage('success', 'Credit created successfully', 3000))
    } catch (err) {
      dispatch(handleError(err));
    }
  };

  const handleEdit = async () => {
    const { id } = credit;
    const body = makeBody();
    delete body.containerId;
    delete body.entityId;
    const isCreditStatusValid = checkIsCreditStatusValid();

    if (!isCreditStatusValid) {
      dispatch(showMessage('error', 'Cannot set credit status to completed without a credit amount', 3000))
      return;
    }

    try {
      await updateCredit(id, body);
      const updatedCredit = await fetchCreditById(id);
      dispatch(fetchCreditByIdSuccess(id, updatedCredit));
      dispatch(showMessage('success', 'Credit updated successfully', 3000))
    } catch (err) {
      dispatch(handleError(err));
    }
    props.closeCrudPanel();
  };

  const handleDelete = async () => {
    const { id } = credit;
    try {
      await deleteCredit(id);
      dispatch(showMessage('success', 'Credit deleted successfully', 3000));
      dispatch(deleteCreditFromRedux(id));
    } catch (err) {
      dispatch(handleError(err));
    }
    props.closeCrudPanel();
  };

  const handleCalculate = async () => {
    const isCreditValid = checkIsCreditValid();
    if (!isCreditValid) {
      alert('Credit is not valid, please fill all mandatory fields');
      return;
    }
    dispatch(setCalculationModalState('calculating'));

    try {
      const { id } = credit;
      await apiClient({
        method: 'post',
        url: '/credit/calculate',
        data: { creditId: id }
      });
      dispatch(setCalculationModalState('showResult'));
      const calculatedCredit = await fetchCreditById(id);
      dispatch(fetchCreditByIdSuccess(id, calculatedCredit));
    } catch (error) {
      dispatch(handleError(error));
      dispatch(setCalculationModalState('closed'));
    }
  };

  const checkIsCreditValid = () => {
    const {
      jurisdictionId,
      entityId,
      taxPayerTypeRefId,
      publishedStatusRefId,
      creditStatusRefId,
      creditMethodRefId,
    } = credit;

    const isCreditValid = areAllTruthy([
      jurisdictionId,
      entityId,
      taxPayerTypeRefId,
      publishedStatusRefId,
      creditStatusRefId,
      creditMethodRefId,
    ]);

    return isCreditValid;
  };
  const checkIsCreditStatusValid = () => {
    let isCreditStatusValid;

    if (credit.creditStatusRefId === CREDIT_STATUSES.Pending) {
      isCreditStatusValid = true;
    } else {
      isCreditStatusValid = credit.totalAmount || credit.entityCreditAmount || credit.totalAmount === 0 || credit.entityCreditAmount === 0;
    }

    return isCreditStatusValid;
  };

  const makeBody = () => {
    const {
      jurisdictionId,
      entityId,
      taxPayerTypeRefId,
      publishedStatusRefId,
      creditStatusRefId,
      creditMethodRefId,
      dueDate,
      creditUserOptions,
      notes
    } = credit;

    return {
      jurisdictionId,
      entityId,
      containerId,
      taxPayerTypeRefId,
      publishedStatusRefId,
      creditStatusRefId,
      creditMethodRefId,
      creditUserOptions,
      dueDate: dateToMysqlFormat(dueDate),
      notes,
    }
  };

  const isUsaAlternativeSimplifiedCredit = credit.creditMethod === 'USA Federal Alternative Simplified Credit';
  const shouldDisplayDirectiveComparisonButton = hasRoleAccessTo.DirectiveComparisonButton && isUsaAlternativeSimplifiedCredit;
  const formActions = [
    {
      name: 'Create',
      shouldDisplay: isCreate,
      onClick: handleCreate,
      shouldCheckInputsValidity: true,
    },
    {
      name: 'Update',
      shouldDisplay: isEdit,
      onClick: handleEdit,
      color: 'secondary',
      shouldCheckInputsValidity: true,
      shouldAskConfirmation: true
    },
    {
      name: 'Delete',
      shouldDisplay: isEdit,
      onClick: handleDelete,
      color: 'secondary',
      shouldAskConfirmation: true
    },
    {
      name: 'Calculate',
      shouldDisplay: isEdit,
      onClick: handleCalculate,
      disabled: shouldDisplayCreditCalculationsModal || hasEditedSaveFirstField,
    },
    {
      name: 'See Calculation Steps',
      shouldDisplay: isEdit,
      onClick: () => toggleCreditCalculationsModal(true),
      color: 'secondary',
      size: shouldDisplayDirectiveComparisonButton ? 'small' : 'full',
    },
    {
      name: 'Directive Comparison',
      shouldDisplay: isEdit && shouldDisplayDirectiveComparisonButton,
      onClick: () => toggleDirectiveComparisonModal(true),
      color: 'secondary',
    },
    {
      name: 'R&D Planner',
      shouldDisplay: isEdit && hasRoleAccessTo.RdPlannerButton && isUsaAlternativeSimplifiedCredit,
      onClick: () => toggleRDPlannerModal(true),
      color: 'secondary',
      size: 'full'
    }
  ];

  const inputFields = buildCreditInputFields(credit, {
    entities: props.entities,
    jurisdictions: props.jurisdictions,
    taxPayerTypes: props.taxPayerTypes,
    publishedStatuses: props.publishedStatuses,
    creditStatuses: props.creditStatuses,
    isCreate,
    isEdit,
    creditMethods,
    jurisdictionId,
  });

  const addCreditOptionsToCrud = () => {
    const userOptionFields = credit.creditUserOptions.map((userOption) => ({
      name: userOption.description,
      label: userOption.description,
      type: 'Bool',
      value: !!userOption.response || false,
      singleSelection: Boolean(userOption.singleSelection),
      muiProps: {
        disabled: isEdit
      }
    }));

    return [...inputFields, ...userOptionFields];
  };

  const crudFieldsWithUserOptions = addCreditOptionsToCrud();

  if (creditMethodsFetching) {
    return null;
  }

  return (
    <div>
      <FormBuilder
        fields={crudFieldsWithUserOptions}
        onChange={handleChange}
        formActions={formActions}
      />
      <CreditCalculationResultsModal
        credit={credit}
        open={shouldDisplayCreditCalculationsModal}
        toggle={toggleCreditCalculationsModal}
      />
      <RdPlannerModal isOpen={shouldDisplayRDPlannerModal} onClose={() => setShouldDisplayRDPlannerModal(false)} />
      <DirectiveComparisonModal isOpen={shouldDisplayDirectiveComparisonModal} onClose={() => setShouldDisplayDirectiveComparisonModal(false)} />
      <CreditCalculationAnimationModal
        handleClose={closeCreditCalculationAnimationModal}
        taxYear={taxYear}
        tenantName={tenant && tenant.name}
      />
    </div>
  );
};

export default StudyCentralCrud;
