import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  trafficLight: {
    width: 25,
    height: 25,
    fill: theme.palette.common.white,
    stroke: theme.palette.grey[700],
  },
  trafficLightActive: {
    fill: theme.palette.primary.main,
    strokeWidth: 0,
  }
}));

const TrafficLight = ({ isActive }) => {
  const styles = useStyles();

  const trafficLightClassNames = classNames(
    styles.trafficLight,
    { [styles.trafficLightActive]: isActive }
  );

  return (
    <svg className={trafficLightClassNames}>
      <circle r="30%" cx="50%" cy="50%"/>
    </svg>
  );
};

export default TrafficLight;
