import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getAuthData } from '../selectors/auth';
import { roles } from '../constants/roles';
import NotAllowedBanner from '../commonComponents/NotAllowedBanner';
import getNecessaryRolesUserHas from '../utils/getNecessaryRolesUserHas';
import { Redirect } from 'react-router-dom';

const WithRoleRestriction = (roleRestrictionOptions) => {
  const { necessaryRoles, fallbackRender = 'redirect' } = roleRestrictionOptions;
  
  const fallbackRenderTypes = {
    banner: <NotAllowedBanner message="You don't have access to this page" />,
    none: null,
    redirect: <Redirect to="/" />
  };
  
  return (Wrapped) => {
    const WithRoleRestricionComponent = () => {
      const { roles: userRoleIds } = useSelector(getAuthData).auth;
      const necessaryRolesUserHas = getNecessaryRolesUserHas(necessaryRoles, userRoleIds);
      
      const userHasAccess = necessaryRolesUserHas.length > 0;
      if (!userHasAccess) {
        return fallbackRenderTypes[fallbackRender]
      }
      
      return <Wrapped/>;
    }
    return WithRoleRestricionComponent;
  }
};

WithRoleRestriction.propTypes = {
  roleRestrictionOptions: PropTypes.shape({
    necessaryRoles: PropTypes.oneOf(Object.values(roles)).isRequired,
    fallbackRender: PropTypes.oneOf(['none', 'banner', 'redirect'])
  }).isRequired
};

export default WithRoleRestriction;
