const uploadToS3ViaPresignedUrl = async (url, body) => {
  await fetch(url, {
    method: "PUT",
    key: body.name,
    headers: {
      "content-type": body.type,
    },
    body,
  });
};
export default uploadToS3ViaPresignedUrl;
