import React from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { getEntities } from '../../../../selectors/entities';
import { headConfig, rowsConfig } from './tableConfig';
import TableAndCrud from '../../TableAndCrud';
import EntityCrud from './EntityCrud';
import { CRUD_INPUT_TYPES, MAX_TABLE_HEIGHT } from '../constants';
import { cleanEntity } from '../../../../actions/entities'
import Dials from '../../../../commonComponents/Dials';

const useStyles = makeStyles((theme) => ({
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  }
}));

const EntitiesDirectory = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const entities = useSelector(getEntities, shallowEqual)

  const handleButtonClickEffect = () => {
    dispatch(cleanEntity())
  };

  const totalEntities = entities && entities.length;
  const dialsConfig = [
    { type: 'default', title: 'All Entities', value: totalEntities }
  ];
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <CreateNewButton />
        <Dials dialConfig={dialsConfig} />
      </div>
    )
  };
  return (
    <div>
      <TableAndCrud
        tableHead={headConfig}
        tableData={entities}
        searchBy={CRUD_INPUT_TYPES.name.name}
        searchByPlaceholder="Name"
        tableRows={rowsConfig}
        handleButtonClickEffect={handleButtonClickEffect}
        maxTableHeight={MAX_TABLE_HEIGHT}
        idKey="entity_id"
        createNewButtonTitle="Add Entity"
        renderComponentBeforeTable={renderComponentBeforeTable}
      >
        <EntityCrud />
      </TableAndCrud>
    </div>
  )
}

export default EntitiesDirectory
