import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import screen1 from './svgs/screen1.svg';
import screen2 from './svgs/screen2.svg';
import classNames from 'classnames';
import DemoModal from '../../../../../commonComponents/DemoModal';

const useStyles = makeStyles((theme) => ({
  modalHead: {
    color: theme.palette.common.black
  },
  modalTitle: {
    fontWeight: 700,
  },
  modalSubtitle: {
    fontSize: '0.8rem',
    color: theme.palette.grey[900]
  },
  demoImage1: {
    cursor: 'pointer',
    width: 960,
    padding: 25,
  },
  demoImage2: {
    cursor: 'pointer',
    width: 960,
    padding: 25,
  },
}));

const DirectiveComparisonModal = ({ isOpen, onClose }) => {
  const styles = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const demoImages = [screen1, screen2];
  const demoButtonCaptions = [
    'View Comparison',
    'Done'
  ];
  
  const handleImageClick = () => {
    const areThereMoreImages = currentImageIndex < demoImages.length - 1;
    if (!areThereMoreImages) {
      return;
    }
    
    const nextImageIndex = currentImageIndex + 1;
    setCurrentImageIndex(nextImageIndex);
  };
  
  const imageClassName = classNames({
    [styles.demoImage1]: currentImageIndex === 0,
    [styles.demoImage2]: currentImageIndex === 1,
  });
  
  const transitionProps = {
    onExited: () => {
      setCurrentImageIndex(0)
    }
  }
  
  const modalHead = (
    <div className={styles.modalHead}>
      <span className={styles.modalTitle}>Directive Comparison</span> <br/>
      <span className={styles.modalSubtitle}>ASC 730 LB&I Directive Method Qualification</span>
    </div>
  );

  const buttonClickActions = [
    handleImageClick,
    onClose
  ];

  return (
    <DemoModal
      transitionProps={transitionProps}
      onButtonClick={buttonClickActions[currentImageIndex]}
      buttonCaption={demoButtonCaptions[currentImageIndex]}
      isOpen={isOpen}
      onClose={onClose}
      head={modalHead}>
      <img className={imageClassName} src={demoImages[currentImageIndex]} onClick={handleImageClick} alt="demo"/>
    </DemoModal>
  );
};

export default DirectiveComparisonModal;
