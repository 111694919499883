import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDebounce from '../../../../hooks/useDebounce';
import {
  getProjectsBySurveyId,
  getProjectQualifications,
  selectProject,
  nextProjectQuestion,
  previousProjectQuestion,
  updateSurveyProjectQualificationAnswer,
  updateOrCreateAnswers
} from '../../../../actions/surveyWizard/projects';
import {
  currentWizardSurvey,
  currentSurveySections
} from '../../../../selectors/surveyWizard/surveyWizard';
import {
  currentSurveyProjects,
  getCurrentProjectQuestions,
  getProjectQualificationTabs,
  surveyWizardSelectedProjectId,
  getCurrentProjectQuestionDetails,
  getCurrentProjectQuestionIndex
} from '../../../../selectors/surveyWizard/projects';
import { SUB_SECTION_IDS, PROJECTS_INITIAL_STATE } from '../constants';
import { toggleAndSaveSurveySectionCompletion } from '../../../../actions/surveyWizard/surveyWizard';
import SurveyResponseWidget from '../wizardComponents/SurveyResponseWidget';
import SimplePromptResponsePage from '../../../../commonComponents/SimplePromptResponsePage';
import LoadingElement from '../../../../commonComponents/LoadingElement';
import { usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../../constants/promiseTracker';
import { makeStyles } from '@material-ui/core/styles';
import SurveyProjectsSvg from '../../../../svgs/SurveyProjectsSvg'

const useStyles = makeStyles((theme) => ({
  notFound: {
    padding: theme.spacing(2)
  }
}));

const ProjectsSection = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {
    surveyId,
    jurisdictionId,
    taxYear
  } = useSelector(currentWizardSurvey);
  const survey = useSelector(currentWizardSurvey);
  const selectedProjectId = useSelector(surveyWizardSelectedProjectId);
  const selectedProject = useSelector(currentSurveyProjects)[selectedProjectId] || {};
  const currentQuestionIndex = useSelector(getCurrentProjectQuestionIndex);
  const currentQuestions = useSelector(getCurrentProjectQuestions);
  const surveySections = useSelector(currentSurveySections);
  const sectionRefId = SUB_SECTION_IDS['Projects'];
  const sectionCompleted = !!surveySections[sectionRefId].isCompleted;
  const surveyWizardProjects = useSelector(state => state.surveyWizard.projects);
  const tabs = useSelector(getProjectQualificationTabs).map(tab => ({
    ...tab,
    onClick: () => dispatch(selectProject(tab.id))
  }));
  const {
    hint: questionHint,
    id: questionId,
    text: questionText,
    title: questionTitle,
    answer = {}
  } = useSelector(getCurrentProjectQuestionDetails);

  const { promiseInProgress: areQuestionsFetchInProgress } = usePromiseTracker({ area: tags.FETCH_SURVEY_PROJECT_QUALIFICATIONS, delay: 0 });
  const { promiseInProgress: areProjectsFetchInProgress } = usePromiseTracker({ area: tags.FETCH_SURVEY_PROJECTS, delay: 0 });

  let {
    description: textValue
  } = answer;

  useEffect(() => {
    if(surveyId){
      dispatch(getProjectsBySurveyId(surveyId))
    }
  }, [dispatch, surveyId])

  useEffect(() => {
    if(selectedProjectId) {
      dispatch(getProjectQualifications(taxYear, jurisdictionId, selectedProjectId))
    }
  }, [dispatch, jurisdictionId, selectedProjectId, taxYear]);

  const insertIconsToProjectTabs = () => {
    return tabs.map((tab) => ({
      ...tab,
      icon: SurveyProjectsSvg
    }));
  };

  const tabsWithIcons = insertIconsToProjectTabs();
  const sideNavProps = {
    title: 'Projects',
    tabs: tabsWithIcons,
    completed: sectionCompleted,
    toggleCallback: () => dispatch(toggleAndSaveSurveySectionCompletion(surveyId, sectionRefId, !sectionCompleted))
  }

  const [saveToggleValue] = useDebounce((projectId, val) => {
    dispatch(updateSurveyProjectQualificationAnswer({
      boolValue: val ? 1 : 0,
      textValue,
      questionId,
      projectId: selectedProjectId,
    }));
    dispatch(updateOrCreateAnswers(
    projectId, survey
    ))
  }, 200)

  const [debouncedSave] = useDebounce((projectId ) => {
    dispatch(updateOrCreateAnswers(
    projectId, survey
    ))
  }, 2000)

  const simplePromptProps = {
    // update redux synchronously then debounce a save
    onChange: (content) => {
      dispatch(updateSurveyProjectQualificationAnswer({
        textValue: content,
        questionId,
        projectId: selectedProjectId
      }))
      debouncedSave(selectedProjectId);
    },
    onBlur: () => {
      dispatch(updateOrCreateAnswers(
        selectedProjectId, survey
      ));
    },
    // update redux synchronously then immediately save
    onToggle: (val) => {
      dispatch(updateSurveyProjectQualificationAnswer({
        boolValue: val ? 1 : 0,
        textValue,
        questionId,
        projectId: selectedProjectId,
      }));
      saveToggleValue(selectedProjectId, val);
    },
    title: questionTitle,
    caption: questionText,
    placeholder: 'Enter your answer here',
    textValue,
    boolValue: answer.value,
    editorKey: `${selectedProjectId}-${questionId}`, // always pass a unique key
    hideToolbar: false,
  };

  const renderChildren = () => {
    if (areQuestionsFetchInProgress || (selectedProjectId && surveyWizardProjects.qualifications === PROJECTS_INITIAL_STATE.qualifications)) {
      return <LoadingElement />;
    }

    if (!Object.keys(surveyWizardProjects.projects).length) {
      return <div className={styles.notFound}>No Projects added yet</div>
    }

    if (!currentQuestions.length) {
      return <div className={styles.notFound}>No Questions added yet</div>
    }

    return <SimplePromptResponsePage { ...simplePromptProps } />
  };

  const contentPaneProps = {
    onNext: () => dispatch(nextProjectQuestion()),
    onPrevious: () => dispatch(previousProjectQuestion()),
    totalSubSections: currentQuestions.length,
    currentSubsection: currentQuestionIndex,
    title: `Project ${selectedProject.name || ''}`,
    questionTitle,
    questionHelp: questionHint,
    children: renderChildren(),
    Icon: SurveyProjectsSvg,
};

  if (areProjectsFetchInProgress) {
    return <LoadingElement />
  }

  return (
    <SurveyResponseWidget sideNavProps={sideNavProps} contentPaneProps={contentPaneProps} />
  )
};

export default ProjectsSection;
