import React, { Component } from 'react';
import * as d3 from 'd3';
import { calculateChartHeight, calculateChartWidth } from './lib/calculateChartSize';
import drawNodeConnector from './lib/drawNodeConnector';
import chooseColorBasedOnDepth from './lib/chooseColorBasedOnDepth';
import generateHierarchyStructure from './lib/generateHierarchyStructure';
import './lib/chartStyles.css';

class EntityChart extends Component {
  componentDidMount() {
    const { entities } = this.props;

    if (entities.length !== 0) {
      this.drawChart();
    }
  }

  toPercentage(ownership) {
    if (ownership) {
      ownership = Math.floor((ownership * 100)) + '%';
    }

    return ownership;
  }

  drawChart = () => {
    const { entities } = this.props;
    if (!entities) {
      return null;
    }
    const entitiesData = generateHierarchyStructure(entities);

    const size = 150;
    const xpos = size + 5;
    const ypos = xpos + 5;
    const xrad = size;
    const yrad = size + 5;
    const mainColor = '#512DA8';
    const fontSize = 32;
    const strokeWidth = 10;

    const root = d3.hierarchy(entitiesData);
    const nodes = root.descendants();
    const chartWidth = calculateChartWidth(nodes);
    const chartHeight = calculateChartHeight(nodes);
    const chartTree = d3.tree()
      .size([chartWidth, chartHeight]);

    const initialPosition = { x: -64, y: 72 };
    const initialScale = 0.38;
    chartTree(root);

    const zoom = d3.zoom()
      .scaleExtent([1, 10])
      .on('zoom', zoomed);

    const slider = d3.select('#chart').append('p').append('input')
      .datum({})
      .attr('type', 'range')
      .attr('value', zoom.scaleExtent()[0])
      .attr('min', zoom.scaleExtent()[0])
      .attr('max', zoom.scaleExtent()[1])
      .attr('step', (zoom.scaleExtent()[1] - zoom.scaleExtent()[0]) / 100)
      .on('input', slided);

    const width = document.querySelector('#entities-content').offsetWidth - 50;
    const height = document.querySelector('body').offsetHeight;

    let mainSvg = d3.select('#chart').append('svg')
      .attr('id', 'main-svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr(
        'transform',
        `translate(
          ${initialPosition.x},
          ${initialPosition.y}
        )
        scale(${initialScale})`
      )
      .style('user-select', 'none')
      .call(zoom)
      .on('wheel.zoom', null);

    mainSvg.append('rect')
      .attr('width', width + 200)
      .attr('height', height + 200)
      .attr('transform', 'translate(0, -200) scale(2.64)')
      .style('fill', 'none')
      .style('pointer-events', 'all');

    const container = mainSvg.append('g');

    container
      .append('g')
      .attr(
        'transform',
        `translate(
        ${initialPosition.x},
        ${initialPosition.y}
      )
      scale(${initialScale})`
      );

    function zoomed(event) {
      const currentTransform = event?.transform;
      container.attr('transform', currentTransform);

      slider.property('value', currentTransform.k);
    }

    function slided() {
      zoom.scaleTo(mainSvg, d3.select(this).property('value'));
    }

    // Node connectors
    container.selectAll('path')
      .data(root.links())
      .enter().append('path')
      .style('fill', 'none')
      .style('stroke-width', strokeWidth)
      .style('stroke', mainColor)
      .attr('d', (data) => drawNodeConnector(data, ypos));

    // Nodes
    const chartNode = container.selectAll('svg')
      .data(nodes)
      .enter()
      .append('svg')
      .attr('x', node => node.x - size)
      .attr('y', node => node.y - size)
      .style('cursor', 'pointer')
      .append('g');

    // Outer Ellipse (to provide a second border)
    chartNode.append('ellipse')
      .attr('ry', yrad)
      .attr('rx', xrad)
      .attr('cy', ypos)
      .attr('cx', xpos)
      .attr('stroke-width', strokeWidth)
      .attr('stroke', mainColor)
      .attr('fill', '#fff');

    // Main Ellipse
    chartNode.append('ellipse')
      .attr('ry', yrad - 10)
      .attr('rx', xrad - 10)
      .attr('cy', ypos)
      .attr('cx', xpos)
      .attr('stroke-width', strokeWidth)
      .attr('stroke', '#fff')
      .attr('fill', chooseColorBasedOnDepth);

    // Entity Name
    chartNode.append('foreignObject')
      .attr('font-family', 'Helvetica, Arial, sans-serif')
      .attr('x', (xrad / 2) - 30)
      .attr('y', (yrad / 2) + 25)
      .attr('font-size', fontSize)
      .attr('width', size + (size / 2))
      .attr('height', 140)
      .attr('text-anchor', 'middle')

      .append('xhtml:div')
      .text(({ data }) => data.name)
      .style('color', '#fff')
      .style('font-weight', 700)
      .style('text-align', 'center')
      .style('text-transform', 'uppercase');

    // Ownership
    chartNode.append('text')
      .attr('font-family', 'Helvetica, Arial, sans-serif')
      .attr('x', xpos * 2)
      .attr('y', ypos / 2)
      .attr('font-size', fontSize)
      .attr('stroke', mainColor)
      .attr('fill', mainColor)
      .attr('stroke-width', 1)
      .text(({ data }) => this.toPercentage(data.ownership));
  };

  render() {
    return (
      <div id="chart" />
    );
  }
}

export default EntityChart;
