import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import sortDropdownOptions from '../../../utils/sortDropdownOptions';

const AutocompleteSelect = ({ sortOptions, name, label, value, options, optionKeys, onChange, muiProps }) => {

  const renderOption = (option) => {
    if (options.length === 0) {
      return (
        <option value={''} disabled>
          No {label}
        </option>
      )
    }

    return (
      <option
        key={option[optionKeys.value]}
        value={parseInt(option[optionKeys.value])}
      >
        {option[optionKeys.name]}
      </option>
    )
  };

  const getOptionLabel = (option) => {
    let optionLabel;

    if (typeof option[optionKeys.name] === 'string') {
      optionLabel = option[optionKeys.name];
    } else {
      optionLabel = (option[optionKeys.name] && option[optionKeys.name].toString()) || '';
    }
    return optionLabel;
  };

  const sortedOptions = sortDropdownOptions(options, sortOptions);

  return (
    <>
      <Autocomplete
        {...muiProps}
        disableClearable
        noValidate
        freeSolo
        fullWidth
        clearOnBlur
        onChange={(event, value) => {
          event.target.value = value[optionKeys.value];
          onChange(event, name, value)
        }}
        options={sortedOptions}
        getOptionLabel={(option) => getOptionLabel(option)}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label={label}
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
        value={sortedOptions.find(option => option[optionKeys.value] === parseInt(value)) || {}}
        renderOption={(option, state) => renderOption(option, state)}
      >
      </Autocomplete>
    </>
  );
};

export default AutocompleteSelect;
