const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCESS';
const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
const AUTH_LOGOUT_FAIL = 'AUTH_LOGOUT_FAIL';
const CLEAN_LOGIN_ERROR = 'CLEAN_LOGIN_ERROR';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
const AUTH_LICENSE_AGREEMENT_SUCCESS = 'AUTH_LICENSE_AGREEMENT_SUCCESS';
const AUTH_LICENSE_AGREEMENT_FAIL = 'AUTH_LICENSE_AGREEMENT_FAIL';
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
const ASK_FOR_NEW_PASSWORD_AFTER_RESET = 'ASK_FOR_NEW_PASSWORD_AFTER_RESET';
const SET_ROLES_SUCCESS = 'SET_ROLES_SUCCESS';

export {
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGIN_FAIL,
    AUTH_LOGOUT_SUCCESS,
    AUTH_LOGOUT_FAIL,
    CLEAN_LOGIN_ERROR,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    AUTH_LICENSE_AGREEMENT_SUCCESS,
    AUTH_LICENSE_AGREEMENT_FAIL,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    ASK_FOR_NEW_PASSWORD_AFTER_RESET,
    SET_ROLES_SUCCESS
}
