import {
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_LOGOUT_SUCCESS,
  CLEAN_LOGIN_ERROR,
  AUTH_LICENSE_AGREEMENT_SUCCESS,
  AUTH_LICENSE_AGREEMENT_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  ASK_FOR_NEW_PASSWORD_AFTER_RESET,
  SET_ROLES_SUCCESS
} from '../constants/actionTypes/auth';
import axios from 'axios';
import { apiClient } from '../api/apiClient';
import { UNAUTHORIZED } from '../constants/messages';
import history from '../history';
import store from '../store';

import { LocalStorageProxy } from '../proxies/localStorageProxy';

//constants
import { LOGIN_REDIRECT, EULA_REDIRECT } from '../constants/utils';
import { showMessage } from './message';

export const setUserCredentials = () => async dispatch => {
  const userEmail = LocalStorageProxy.getItem('username');

  try {
    const idToken = LocalStorageProxy.getItem('idToken');
    const exp = LocalStorageProxy.getItem('expires');
    const refreshToken = LocalStorageProxy.getItem('refreshToken');

    // TODO: actually use ID token and access token, instead of
    // using the ID token as the access token.
    const tokens = { accessToken: idToken, exp, refreshToken };
    const baseURL = await store.getState().config.API_URL;

    const options = {
      method: 'get',
      url: '/auth/getUserInfo',
      baseURL,
      headers: {
        'Authorization': `Bearer ${tokens.accessToken}`
      }
    };

    const response = await axios.request(options);
    const { id, username, licenseAgreement, roles } = response.data;
    const authData = { tokens, username, userEmail, id, licenseAgreement, roles };

    LocalStorageProxy.setItem('auth', JSON.stringify(authData));
    dispatch(authSuccess(authData));
  } catch (err) {
    return dispatch(authFail(err));
  }
};

export const authSuccess = auth => ({
  type: AUTH_LOGIN_SUCCESS,
  auth,
});

const authFail = err => dispatch => {
  let message;
  if (!err.isAxiosError) {
    message = UNAUTHORIZED;
  } else {
    message = err.response.data.message || UNAUTHORIZED;
  }

  dispatch({
    type: AUTH_LOGIN_FAIL,
    message,
    err,
  })
};

export const cleanLoginError = () => ({
  type: CLEAN_LOGIN_ERROR,
});

export const logout = () => async dispatch => {
  try {
    await apiClient({
      method: 'get',
      url: '/auth/logout',
    });

    LocalStorageProxy.clear();
    dispatch(logoutSuccess());
  } catch (error) {
    LocalStorageProxy.clear();
    dispatch(logoutSuccess());
  }
};

export const resetPassword = email => async dispatch => {
  try {
    await apiClient({
      method: 'post',
      url: '/auth/resetPassword',
      data: {
        email
      }
    });
    dispatch(resetPasswordSuccess())
  }
  catch (err) {
    dispatch(resetPasswordFail(err))
  }
}

const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
})

export const resetPasswordFail = error => ({
  type: RESET_PASSWORD_FAIL,
  error
})

export const changePassword = ({ email, verificationCode, newPassword }) => async dispatch => {
  try {
    await apiClient({
      method: 'post',
      url: '/auth/changePassword',
      data: {
        email,
        verificationCode,
        newPassword
      }
    });
    dispatch(changePasswordSuccess())
  }
  catch (err) {
    if (err.isAxiosError) {
      dispatch(showMessage('error', err.response.data.message, 3000))
    }
    dispatch(changePasswordFail(err))
  }
}

export const askForNewPassword = (shouldAskForNewPassowrd) => ({
  type: ASK_FOR_NEW_PASSWORD_AFTER_RESET,
  shouldAskForNewPassowrd
});

const changePasswordSuccess = () => (dispatch) => {
  dispatch({
    type: CHANGE_PASSWORD_SUCCESS,
  });

  history.push('/login', { isPasswordChangeSuccessful: true });
  history.go();
}

export const changePasswordFail = error => ({
  type: CHANGE_PASSWORD_FAIL,
  error
})

export const acceptLicenseAgreement = () => async dispatch => {
  try {
    await apiClient({
      method: 'put',
      url: '/user/license'
    });

    dispatch(licenseAgreementSuccess());
    history.push(LOGIN_REDIRECT);
  } catch (err) {
    dispatch(licenseAgreementFail(err.message));
    history.push(EULA_REDIRECT);
  }

  history.go();
};

export const logoutSuccess = () => ({
  type: AUTH_LOGOUT_SUCCESS,
});

const licenseAgreementSuccess = () => ({
  type: AUTH_LICENSE_AGREEMENT_SUCCESS,
})

const licenseAgreementFail = err => ({
  type: AUTH_LICENSE_AGREEMENT_FAIL,
  message: 'Something went wrong. Please try again.',
  err,
});

export const setRolesSuccess = (roles) => ({
  type: SET_ROLES_SUCCESS,
  roles
});
