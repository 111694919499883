import React, { useEffect, useState } from 'react';
import SurveyCentralRouter from './SurveyCentralRouter';
import { apiClient } from '../../api/apiClient';
import { useHistory } from 'react-router-dom';
import LoadingElement from '../../commonComponents/LoadingElement';
import { makeStyles } from '@material-ui/core/styles';

const needsWelcomeInitialState = null;

const useStyles = makeStyles(() => ({
  surveyCentralMainWrapper: {
    overflow: 'auto',
    height: '100%'
  }
}));

const SurveyCentral = () => {
  const [needsWelcome, setNeedsWelcome] = useState(needsWelcomeInitialState);
  const styles = useStyles();
  const history = useHistory();

  useEffect(() => {
    const checkShouldGoToWelcome = async () => {
      const resultCheckWelcome = await apiClient({
        url: '/user/welcome/check',
        method: 'get',
      });

      setNeedsWelcome(resultCheckWelcome.needsWelcome);
    };

    checkShouldGoToWelcome();
  }, []);

  useEffect(() => {
    if (needsWelcome) {
      history.push('/survey-central/welcome')
    }
  }, [history, needsWelcome]);

  if (needsWelcome === needsWelcomeInitialState) {
    return <LoadingElement />;
  }

  return (
    <div className={styles.surveyCentralMainWrapper}>
      <SurveyCentralRouter />
    </div>
  );
};

export default SurveyCentral;
