import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FixedSizeList } from 'react-window';
import { toggleCrudPanel } from '../../actions/common';

// COMPONENTS
import SearchInput from '../Input';
import MultipleSelect from '../MultipleSelect';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ActionsMenu from '../../components/CreditCentral/Summary/ActionsMenu';
import MenuItem from '@material-ui/core/MenuItem';
import TrafficLight from '../TrafficLight';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Checkbox from '@material-ui/core/Checkbox';
import MuiTooltip from '@material-ui/core/Tooltip';

// HELPERS
import { formatResultByType } from '../../utils/customFormatter';
import { withStyles } from '@material-ui/core';
import { NUMBER_OF_CHARS_LIMIT_TO_SHOW_TOOLTIP } from '../../constants/virtualizedTable';


const CRUD_TYPE = {
  edit: 'edit',
  create: 'create'
}

const SEARCHABLE_COLUMN_WIDTH = 358;

const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: theme.spacing(2),
    overflow: 'auto',
  },
  tableHeader: {
    fontWeight: 500,
    color: theme.palette.grey[900],
    borderTop: 'none',
    fontSize: '1rem',
    display: 'block',
    flex: 1,
  },
  tableScrollBar: {
    '&::-webkit-scrollbar': {
      height: '8px',
      width: '8px',
      border: '1px solid',
      'border-color': theme.palette.grey[400]
    },

    '&::-webkit-scrollbar-track': {
      'border-radius': 0,
      background: theme.palette.grey[100]
    },

    '&::-webkit-scrollbar-thumb': {
      'border-radius': 0,
      'background': theme.palette.grey[800]
    }
  },
  dataCell: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  tableItem: {
    whiteSpace: 'nowrap',
    fontSize: '1rem',
    color: theme.palette.grey[900],
    display: 'block',
    flex: 1,
  },
  tableItemSearchableColumn: {
    width: SEARCHABLE_COLUMN_WIDTH,
    minWidth: SEARCHABLE_COLUMN_WIDTH,
  },
  tableItemHighlight: {
    color: theme.palette.primary.main,
    fontWeight: 700
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      cursor: 'pointer'
    }
  },
  selectedRowHighlight: {
    backgroundColor: theme.palette.grapeLighter,
    color: theme.palette.grapeDarker,
    '&:hover': {
      backgroundColor: theme.palette.grapeLighter,
      cursor: 'pointer'
    }
  },
  rowCheckMark: {
    '&.Mui-checked': {
      color: theme.palette.grape
    }
  },
  checkMarkCell: {
    width: 70,
    padding: 0,
    border: 'none',
    display: 'flex',
    flex: 0
  },
  hoverCheckMarkCell: {
    opacity: '.5',
  },
  checkMarkCellHidden: {
    flex: 0,
    opacity: 0
  },
  allCheckmarksSelectorCell: {
    flex: 0,
    display: 'flex',
    width: 70,
    padding: 0,
    opacity: '.5',
    border: 'none'
  },
  statusSelectContainer: {
    width: '350px'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  container: {
    display: 'flex',
    width: '280px'
  },
  searchInputTableSort: {
    display: 'flex'
  }
}));

const Tooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: '1rem',
  },
}))(MuiTooltip);

const VirtualizedTable = ({
  head,
  data,
  handleRowClick = () => {},
  statusOptions,
  searchBy,
  searchByPlaceholder,
  initialOrderBy,
  tableRows,
  makeRowMenuItems,
  rowHighlightConditions,
  shouldShowTrafficLight,
  formatConfig = {},
  shouldShowCheckBoxesOnClick,
  selectedRowsData = [],
  setSelectedRowsData = () => { },
  idKey = 'id',
  maxTableHeight,
  cellWrappers = {},
  }) => {
  const styles = useStyles({ maxTableHeight });
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(initialOrderBy || 'name');
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();

  const checkIsRowInSelectedList = (row) => !!selectedRowsData.find((selectedRow) => selectedRow[idKey] === row[idKey])

  useEffect(() => {
    const sortedAndFilteredData = tableRowSortAndFilter(data).filter(row => row[idKey] !== '-')
    if (selectedRowsData.length !== sortedAndFilteredData.length) {
      setSelectAll(false)
    }
    /* eslint-disable-next-line */
  }, [selectedRowsData])

  useEffect(() => {
    const sortBySearchableColumnByDefault = () => {
      setOrderBy(searchBy);
    };
    if (searchBy) {
      sortBySearchableColumnByDefault();
    }
  }, [searchBy]);

  const renderVirtualizedRows = useCallback(() => {
    return VirtualizedRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchText, order, status, selectedRowsData, selectAll]);

  const tableRowSortAndFilter = (data) => {
    return stableSort(data, getSorting(order, orderBy))
      .filter(row => row[searchBy].toString().toLowerCase().includes(searchText.toLowerCase()))
      .filter(row => status.length ? status.includes(row.status) : row)
  }

  const toggleSingleRowSelection = (row) => {
    if (row[idKey] === '-') {
      return
    }
    let updatedSelectedRowsData
    const isRowInSelectedList = selectedRowsData.find((selectedRow) => selectedRow[idKey] === row[idKey]);
    if (isRowInSelectedList) {
      updatedSelectedRowsData = selectedRowsData.filter((selectedRow) => selectedRow[idKey] !== row[idKey])
    } else {
      updatedSelectedRowsData = [...selectedRowsData, row]
    }
    setSelectedRowsData(updatedSelectedRowsData);
  };

  const toggleMultipleRowSelection = (row) => {
    if (row[idKey] === '-') {
      return
    }
    let firstSelection = selectedRowsData[0]
    const sortedData = stableSort(data, getSorting(order, orderBy)).filter(row => row[searchBy].toLowerCase().includes(searchText.toLowerCase()))
    const idxFirstSelected = sortedData.findIndex((tableRow) => firstSelection[idKey] === tableRow[idKey]);
    const idxRowSelected = sortedData.findIndex((tableRow) => row[idKey] === tableRow[idKey]);
    const slicedRowData = idxFirstSelected > idxRowSelected ? sortedData.slice(idxRowSelected, idxFirstSelected + 1) : sortedData.slice(idxFirstSelected, idxRowSelected + 1)
    const unSelectedDataFiltered = slicedRowData.filter(datum => !selectedRowsData.find(rowData => datum[idKey] === rowData[idKey]))
    setSelectedRowsData([...selectedRowsData, ...unSelectedDataFiltered])
  };

  const onCheckBoxClick = (event, row) => {
    event.stopPropagation();
    if (shouldShowCheckBoxesOnClick) {
      if ((event.shiftKey) && selectedRowsData.length > 0) {
        toggleMultipleRowSelection(row)
      } else {
        toggleSingleRowSelection(row)
      }
    }
    handleRowClick(row, CRUD_TYPE.edit);
  }

  const onRowClick = (event, row) => {
    setSelectedRowsData([row]);
    handleRowClick(row, CRUD_TYPE.edit, true);
  }

  const checkShouldHighlightRow = (rowData) => {
    const { column, valueShouldBe } = rowHighlightConditions;
    return rowData[column] === valueShouldBe;
  };

  const getClassNameForRow = (rowData, colIndex) => {
    const shouldHighlightRow = rowHighlightConditions && checkShouldHighlightRow(rowData);
    const isSearchableColumn = colIndex === 0 && searchBy;
    return classNames({
      [styles.tableItem]: true,
      [styles.tableItemSearchableColumn]: isSearchableColumn,
      [styles.tableItemHighlight]: shouldHighlightRow,
      [styles.selectedRowHighlight]: checkIsRowInSelectedList(rowData)
    });
  };

  const handleRequestSort = property => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRowsData([])
      setSelectAll(false)
    } else {
      setSelectedRowsData(tableRowSortAndFilter(data).filter(row => row[idKey] !== '-'))
      setSelectAll(true)
    }
    dispatch(toggleCrudPanel(false));
  }

  const desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  const stableSort = (array, cmp) => {
    const noSort = array.filter(item => item.noSort);
    const stabilizedThis = array.filter(item => !item.noSort).map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return [...noSort, ...stabilizedThis.map(el => el[0])];
  }

  const getSorting = (order, orderBy) => {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }

  const handleChange = event => {
    setStatus(event.target.value);
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value)
    setSelectedRowsData([])
    dispatch(toggleCrudPanel(false))
    setSelectAll(false)
  }

  const renderStatusSelect = () => {
    if (statusOptions && data.length && data[0].status) {
      return (
        <div className={styles.statusSelectContainer}>
          <MultipleSelect
            options={statusOptions}
            handleChange={handleChange}
            status={status}
            maxChips={4}
          />
        </div>
      )
    }
    return null;
  };
  const handleRowMouseOver = (rowId, row) => {
    if (shouldShowCheckBoxesOnClick && ((!checkIsRowInSelectedList(row) && rowId !== '-'))) {

      const checkboxCell = document.getElementById(`table-row-${rowId}`).firstChild
      checkboxCell.className = `${getClassNameForRow(row)} ${styles.checkMarkCell} ${styles.hoverCheckMarkCell}`
    }
  }
  const handleRowMouseExit = (rowId, row) => {

    if (shouldShowCheckBoxesOnClick && ((!checkIsRowInSelectedList(row) && rowId !== '-'))) {

      const checkboxCell = document.getElementById(`table-row-${rowId}`).firstChild
      checkboxCell.className = `${getClassNameForRow(row)} ${styles.checkMarkCellHidden}`
    }
  }

  const renderRowMenuItems = (rowData) => {
    if (!makeRowMenuItems) {
      return null;
    }
    const rowMenuItems = makeRowMenuItems(rowData);
    const displayableMenuItems = rowMenuItems.filter((menuItem) => menuItem.shouldDisplay !== false);
    return (
      <TableCell className={getClassNameForRow(rowData)}>
        <ActionsMenu isHighlighed={checkShouldHighlightRow(rowData)}>
          {displayableMenuItems.map((menuItem) => (
            <MenuItem key={menuItem.name} onClick={() => menuItem.handleClick(rowData)}>
              <span>
                {menuItem.name}
              </span>
            </MenuItem>
          ))}
        </ActionsMenu>
      </TableCell>
    )
  };

  const formatData = (cellValue, rowId, row) => {
    const [rowConfig] = head.filter(({ id }) => id === rowId)
    let formatType = rowConfig && rowConfig.type;

    if (rowConfig && rowConfig.useTypeFromData && row.type) {
      formatType = row.type;
    }

    const booleanValues = (rowConfig && rowConfig.booleanValues) ? rowConfig.booleanValues : undefined;
    let formattedValue = cellValue;

    if (formattedValue === undefined || formattedValue === null || formattedValue === '-') {
      return '-';
    }

    if (formatType) {
      const { shouldIncludeCents = true, locale, currency } = formatConfig
      formattedValue = formatResultByType({ value: cellValue, type: formatType, currencyIsoCode: currency, booleanValues, locale, shouldIncludeCents });
    }

    return formattedValue
  }

  const tableContainerClassNames = classNames(styles.tableContainer, styles.tableScrollBar);

  const checkMarkRowClassName = (row) => classNames({
    [styles.checkMarkCell]: true,
    [styles.checkMarkCellHidden]: (row[idKey] === '-' || !checkIsRowInSelectedList(row)),
  });

  function buildCellWrapper(cellId, rowData) {
    let { CellWrapper } = cellWrappers[cellId] || {};
    CellWrapper = CellWrapper || React.Fragment;

    return CellWrapper === React.Fragment ?
      ({ children }) => <CellWrapper>{children}</CellWrapper> :
      ({ children }) => <CellWrapper rowData={rowData}>{children}</CellWrapper>;
  }

  function Row({ data, index, style }) {
    const currentRow = data[index];

    return (
      <TableRow
        style={style}
        component="div"
        id={`table-row-${currentRow[idKey]}`}
        className={styles.row}
        key={index}
        onMouseOver={(event) => handleRowMouseOver(currentRow[idKey], currentRow)}
        onMouseOut={(event) => handleRowMouseExit(currentRow[idKey], currentRow)}
        onClick={(event) => onRowClick(event, currentRow)}
        data-testid="crud-table-row"
      >
        {shouldShowCheckBoxesOnClick && (
          <TableCell
            component="div"
            data-testid="checkBoxCell"
            className={`${getClassNameForRow(currentRow)} ${checkMarkRowClassName(currentRow)}`}>
            <Checkbox
              checked={checkIsRowInSelectedList(currentRow)}
              icon={<CircleUnchecked />}
              checkedIcon={<CircleCheckedFilled />}
              onClick={(event) => onCheckBoxClick(event, currentRow)}
            />
          </TableCell>
        )}

        {shouldShowTrafficLight && (
          <TableCell component="div">
            <TrafficLight isActive={checkShouldHighlightRow(currentRow)} />
          </TableCell>
        )}

        {tableRows.map((cellId, colIndex) => {
          const cellData = formatData(currentRow[cellId], cellId, currentRow);
          const CellWrapper = buildCellWrapper(cellId, currentRow);

          let width = 200;
          if (colIndex === 0){
            width = SEARCHABLE_COLUMN_WIDTH;
          }
          if (colIndex > 0) {
            const respectiveHeader = head.find((header) => header.id === cellId);
            width = respectiveHeader.width;
          }

          return (
            <Tooltip
              disableHoverListener={cellData.toString().length < NUMBER_OF_CHARS_LIMIT_TO_SHOW_TOOLTIP}
              key={cellId}
              className={styles.tooltip}
              title={cellData}
              enterDelay={500}
              enterNextDelay={500}
              arrow
              placement="top-start"
            >
              <TableCell
                style={{ width: width, minWidth: width }}
                component="div"
                className={`${getClassNameForRow(currentRow, colIndex)} ${styles.dataCell}`}
                align="left"
                data-testid="crud-table-cell"
              >
                <CellWrapper>
                  {cellData}
                </CellWrapper>
              </TableCell>
            </Tooltip>
          )
        })}

        {renderRowMenuItems(currentRow)}
      </TableRow>
    );
  }

  function VirtualizedRows() {
    const itemData = tableRowSortAndFilter(data);

    return (
      <FixedSizeList
        width="100%"
        height={490}
        itemSize={55}
        itemData={itemData}
        itemCount={itemData.length}
        style={{ overflowX: 'hidden' }}
      >
        {Row}
      </FixedSizeList>
    );
  }

  return (
    <TableContainer component="div" className={tableContainerClassNames}>
      {renderStatusSelect()}
      <Table component="div" aria-label="simple table" >
        <TableHead component="div">
          <TableRow classes={{ root: styles.headerRow }} component="div" data-testid="crud-table-head">
            {shouldShowCheckBoxesOnClick && (
              <TableCell component="div" data-testid="checkbox-selector" className={styles.allCheckmarksSelectorCell}>
                <Checkbox
                  checked={selectAll}
                  icon={<CheckCircleOutlineIcon />}
                  checkedIcon={<CheckCircleIcon />}
                  onClick={handleSelectAll}
                />
              </TableCell>
            )}
            {shouldShowTrafficLight && <TableCell component="div" />}
            <TableCell component="div" className={styles.tableHeader}>
              <div className={styles.searchInputTableSort}>
                <SearchInput
                  data-testid="filter"
                  label={searchByPlaceholder}
                  onChange={handleSearchInputChange}
                  icon={'search'}
                />
                <TableSortLabel
                  data-testid="sort-label-name"
                  active={orderBy === searchBy}
                  direction={orderBy === searchBy ? order : 'asc'}
                  onClick={() => handleRequestSort(searchBy)}
                >
                  {orderBy === searchBy ? (
                    <span className={styles.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              </div>
            </TableCell>
            {head.map(({ name, id, sortable, width }) =>
              <TableCell style={{ width: width || 200, minWidth: width || 200 }} component="div" className={styles.tableHeader} align="left" key={id} data-testid="table-head-cell">
                {Boolean(sortable) &&
                  <TableSortLabel
                    data-testid="sort-label"
                    active={orderBy === id}
                    direction={orderBy === id ? order : 'asc'}
                    onClick={() => handleRequestSort(id)}
                  >
                    {name}
                    {orderBy === id ? (
                      <span className={styles.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </TableSortLabel>}
                {Boolean(!sortable) && name}
              </TableCell>)}
            {makeRowMenuItems && <TableCell component="div" className={styles.tableItem} />}
          </TableRow>
        </TableHead>
        <TableBody component="div" data-testid="crud-table-body">
          {renderVirtualizedRows()}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

VirtualizedTable.propTypes = {
  head: PropTypes.array,
  data: PropTypes.array,
  handleRowClick: PropTypes.func,
  maxTableHeight: PropTypes.string,
  initialOrderBy: PropTypes.string,
};

export default VirtualizedTable;
