import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { serviceManagerRoutes } from '../../constants/routes';
import { makeStyles } from '@material-ui/core';

// components
import Grid from '@material-ui/core/Grid';
import Sidenav from '../Sidenav';
import SidenavItems from './sidenavItems';


// constants
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchTenantsForDropdown, fetchTenantsForAdminDropdown, selectTenant, setDefaultSelectedTenant } from '../../actions/tenant';
import { fetchEntities } from '../../actions/entities';
import { fetchCanContainerRoll } from '../../actions/container';
import TenantsDropdown from '../GlobalDropdowns/TenantsDropdown';
import { getIsCrudPanelOpen } from '../../selectors/common';
import { getJurisdictions, toggleCrudPanel } from '../../actions/common';
import { fetchContainersForDropdown, fetchContainersForAdminDropdown, setDefaultSelectedContainer } from '../../actions/container';
import { setDefaultSelectedEntity } from '../../actions/entities';
import { getSelectedTenant } from '../../selectors/tenants';
import { getSelectedEntityObject } from '../../selectors/entities';
import { getSelectedContainer } from '../../selectors/containers';
import { getDropdownContainers, getDropdownTenants, getDropdownEntities } from '../../selectors/common';
import { ADMIN_ROUTES } from './constants';
import { getRoleData } from '../../selectors/auth';
import getNecessaryRolesUserHas from '../../utils/getNecessaryRolesUserHas';


const useStyles = makeStyles((theme) => ({
  serviceManagerContainer: {
    alignItems: 'stretch',
    height: '100vh',
    overflow: 'hidden',
  },
  sidenavContainer: {
    height: '100%',
    display: 'flex',
    overflowY: 'auto',
    borderRight: `1px solid ${theme.palette.grey[400]}`
  },
  contentContainer: {
    height: '100%',
    overflowY: 'auto'
  },
  crudContainer: {
    height: '100%',
    overflowY: 'auto',
    borderLeft: `1px solid ${theme.palette.grey[400]}`,
  }
}));

const ServiceManager = ({ selectedContainerId }) => {
  const isCrudPanelOpen = useSelector(getIsCrudPanelOpen);
  const selectedTenant = useSelector(getSelectedTenant, shallowEqual);
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual);
  const selectedEntity = useSelector(getSelectedEntityObject, shallowEqual);
  const dropdownTenants = useSelector(getDropdownTenants, shallowEqual);
  const dropdownContainers = useSelector(getDropdownContainers, shallowEqual);
  const dropdownEntities = useSelector(getDropdownEntities, shallowEqual);
  const userRoles = useSelector(getRoleData) || [];
  const dispatch = useDispatch();
  const styles = useStyles();
  const location = useLocation();

  useEffect(() => {
    dispatch(toggleCrudPanel(false));
    if (selectedContainerId) {
      dispatch(fetchCanContainerRoll(selectedContainerId));
      dispatch(fetchEntities(selectedContainerId));
    } else {
      dispatch(fetchEntities(null));
    }
  }, [dispatch, selectedContainerId]);

  useEffect(() => {
    dispatch(getJurisdictions());
  }, [dispatch]);

  useEffect(() => {
    if (ADMIN_ROUTES.includes(location.pathname)) {
      dispatch(fetchTenantsForAdminDropdown());
    } else {
      dispatch(fetchTenantsForDropdown());
    }
  }, [dispatch, location.pathname]);

  useEffect(() => {
    if (ADMIN_ROUTES.includes(location.pathname)) {
      selectedTenant.tenant_id && dispatch(fetchContainersForAdminDropdown(selectedTenant.tenant_id));
    } else {
      selectedTenant.tenant_id && dispatch(fetchContainersForDropdown(selectedTenant.tenant_id));
    }
  }, [dispatch, selectedTenant, location.pathname]);

  useEffect(() => {
    if (!selectedTenant.tenant_id) {
      dispatch(setDefaultSelectedTenant(dropdownTenants));
    }
  }, [dispatch, dropdownTenants, selectedTenant.tenant_id]);

  useEffect(() => {
    if (!selectedContainer.container_id) {
      dispatch(setDefaultSelectedContainer(dropdownContainers));
    }
  }, [dispatch, dropdownContainers, selectedContainer.container_id]);

  useEffect(() => {
    if (!selectedEntity.entity_id) {
      dispatch(setDefaultSelectedEntity(dropdownEntities));
    }
  }, [dispatch, dropdownEntities, selectedEntity.entity_id]);

  const handleTenantSelect = (value) => {
    const tenantId = parseInt(value);
    dispatch(toggleCrudPanel(false));
    dispatch(selectTenant(tenantId));
  };

  let roleFilteredRoutes = [];

  if (userRoles && userRoles.length) {

    roleFilteredRoutes = serviceManagerRoutes.filter((smRoute) => {
      return getNecessaryRolesUserHas(smRoute.roles, userRoles).length;
    });
  }

  return (
    <>
      <TenantsDropdown onChange={handleTenantSelect} />
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        className={styles.serviceManagerContainer}
      >
        <Grid item md={2} className={styles.sidenavContainer}>
          <Sidenav
            sideNavItems={SidenavItems}
          />
        </Grid>
        <Grid item md={isCrudPanelOpen ? 7 : 10} className={styles.contentContainer}>
          <Switch>
            {roleFilteredRoutes.map(({ path, component, exactly }, index) => (
              <Route
                key={index}
                path={path}
                component={component}
                exact={exactly}
              />
            ))}
            {roleFilteredRoutes.length && (
              <Redirect to={roleFilteredRoutes[0].path} />
            )}
          </Switch>
        </Grid>
        <Grid
          item
          md={3}
          className={styles.crudContainer}
          id="crud-panel-container"
          style={{ display: isCrudPanelOpen ? 'block' : 'none' }}
        >
        </Grid>
      </Grid>
      <Sidenav />
    </>
  );
};

export default ServiceManager;
