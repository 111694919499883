import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import getValueFromEvent from '../../../../../utils/getValueFromEvent';

// ACTIONS 
import {
  selectEmployeeInterview,
  setSelectedEmployeeInterviewField,
  updateEmployeeInterview,
  createEmployeeOverview,
  deleteEmployeeOverview
} from '../../../../../actions/entityInfo'

// SELECTORS
import { getSelectedEmployeeInterview } from '../../../../../selectors/entityInfo';

// CONSTANTS
import { buildEmployeeInterviewInputFields } from './buildEmployeeInterviewInputFields';
import FormBuilder from '../../../../../commonComponents/FormBuilder';

const EmployeeInterviewCrud = (props) => {
  const dispatch = useDispatch();
  const employeeInterview = useSelector(getSelectedEmployeeInterview, shallowEqual);

  useEffect(() => {
    if (props.crudType !== 'create') {
      dispatch(selectEmployeeInterview(props));
    }
  }, [dispatch, props]);

  const handleChange = (e) => {
    const { name } = e.target;
    const value = getValueFromEvent(e);
    dispatch(setSelectedEmployeeInterviewField(name, value))
  }

  const handleUpdate = () => {
    const { name, title, id, entityId } = employeeInterview;
    const payload = {
      id,
      name,
      title
    }
    dispatch(updateEmployeeInterview(payload, entityId))
    props.closeCrudPanel()
  }

  const handleDelete = () => {
    const { id, entityId } = employeeInterview;
    dispatch(deleteEmployeeOverview(id, entityId))
    props.closeCrudPanel()
  }

  const handleCreate = () => {
    const { name, title } = employeeInterview;
    const { entityId } = props;
    const payload = {
      name,
      title,
      entityId
    }
    dispatch(createEmployeeOverview(payload))
    props.closeCrudPanel()
  }
  
  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleCreate,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleUpdate,
      color: 'grape',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleDelete,
      color: 'rhubarb',
      shouldAskConfirmation: true
    },
  ];
  const inputFields = buildEmployeeInterviewInputFields(employeeInterview);
  return ((
    <div>
      <FormBuilder onChange={handleChange} fields={inputFields} formActions={formActions} />
    </div>
  ))
}

EmployeeInterviewCrud.propTypes = {
  entityId: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  crudType: PropTypes.string,
  closeCrudPanel: PropTypes.func,
}

export default EmployeeInterviewCrud
