import { makeStyles } from "@material-ui/core/styles";

const UseStyles = makeStyles((theme) => ({
  SearchBarWrapper: {
    display: 'flex',
    padding: '10px 20px',
    height: '60px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
  InputSearchWrapper: {
    width: '60%',
    border: `1px solid ${theme.palette.grey[100]}`,
    borderRadius: '5px',
    display: 'flex',
    height: '40px'
  },
  InputSearchBox: {
    display: 'flex',
    alignItems:'center',
    padding: '0 10px',
    boxSizing: 'border-box',
    width: '100%',
    '& input': {
      width: '100%',
      border: 0,
      '&:output': {
        border: 0
      },
      '&:focus-visible': {
        outline: 0
      }
    },
    '& svg': {
      fill: theme.palette.grey[150]
    }
  },
  InputSearchBoxIcon: {
    display: 'flex',
    alignItems:'center',
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    padding: '0 5px',
    boxSizing: 'border-box',
    '& svg': {
      fill: theme.palette.grey[150]
    }
  }
}));

export { UseStyles };