import { makeStyles } from "@material-ui/core/styles";

const UseStyles = makeStyles((theme) => ({
  HeaderModal: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    height: '60px'
  },
  HeaderModalTitle: {
    display: 'flex',
    alignItems: 'center',
    '& h3': {
      marginLeft: '10px'
    },
    color: `${theme.palette.primary.main}`
  },
  ContentModal: {
    padding:0,
    margin: 0,
    borderTop: `2px solid ${theme.palette.grey[100]}`,
    borderBottom: `2px solid ${theme.palette.grey[100]}`
  },
  ContentLoading: {
    padding: 20,
    display: 'flex',
    justifyContent: 'center'
  },
  ContentListWrapper: {
    overflowY: "auto",
    height: '50vh'
  },
  ContentListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    height: '48px',
    boxSizing: 'border-box',
    color: `${theme.palette.primary.main}`,
    '& h4': {
      fontWeight: 'normal',
      color: `${theme.palette.primary.main}`,
    },
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `${theme.palette.grey[100]}`,
      '& h4': {
        fontWeight: 'bold'
      }
    }
  },
  ContentListItemSelected: {
    backgroundColor: `${theme.palette.grey[50]}`,
    '& h4': {
      fontWeight: 'bold',
    }
  },
  FooterModal: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px',
  },
  FooterModalButton: {
    margin: '0 3px'
  }
}));

export { UseStyles };
