import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  ButtonContainer: {
    textAlign: 'right'
  },
  ButtonContainerChild: {
    display: 'inline-block',
    margin: '10px'
  },
}));