const countChildrenInDepths = (nodes) => {
  /**
   * Counts the number of children that has each depth (level)
   * @returns object with shape: {
   *   key: value,
   *   key2: value2
   *   etc...
   * }
   *
   * where key is the depth and value is the number of children
   * in that depth.
   */

  let allDepths = nodes.map(node => node.depth);
  allDepths = new Set(allDepths);
  const depthCount = {};

  allDepths.forEach(depth => {
    nodes.forEach(node => {
      let depthCurrentCount = depthCount[depth] || 0;

      if (node.depth === depth) {
        depthCount[depth] = depthCurrentCount + 1;
      }
    });
  });

  return depthCount;
};

export default countChildrenInDepths;
