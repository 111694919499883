import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  actionsButton: {
    padding: 0,
    borderRadius: '100%',
    border: 'none',
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '&:focus': {
      outline: 'none'
    }
  },
  actionsIcon: {
    position: 'relative',
    color: theme.palette.grey[900],
  },
  actionsIconComplete: {
    color: theme.palette.primary.main,
  },
  actionItem: {
    color: theme.palette.common.black,
    textDecoration: 'none'
  }
}));
