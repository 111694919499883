import moment from 'moment';

const dateToMysqlFormat = (date) => {
  if (!date) {
    return null;
  }
  return moment(new Date(date)).utc().format('YYYY-MM-DD')
};

export default dateToMysqlFormat;
