import { makeStyles } from '@material-ui/core';
import {withStyles} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";


export const useStyles = makeStyles((theme) => ({
  cardContentHead: {
    paddingTop: theme.spacing(0),
    width: theme.spacing(50),
  },
  cardMargin: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    minHeight: theme.spacing(10),
  },
  switch: {
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0.266, 0.66),
    background: theme.palette.grey[100],
    color: theme.palette.grey[800],
    fontSize: '0.9rem',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 34,
    height: 15,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[600],
    '&$checked': {
      transform: 'translateX(20px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 11,
    height: 11,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 8,
    opacity: 1,
    backgroundColor: theme.palette.grey[300],
  },
  checked: {},
}))(Switch);
