import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  createEntityDetail,
  deleteEntityDetail,
  editEntityDetail, fetchEntityDetailTypes,
  selectEntityDetailCrud,
  setSelectedEntityDetailField,
} from '../../../../../actions/entityInfo';
import { ENTITY_DETAIL_INITIAL_STATE } from '../constants';
import { getEntityDetailTypes, getSelectedEntityDetail } from '../../../../../selectors/entityInfo';
import { getJurisdictions } from '../../../../../selectors/common';
import { getSelectedContainer } from '../../../../../selectors/containers';
import { buildEntityDetailInputFields } from './buildEntityDetailInputFields';
import FormBuilder from '../../../../../commonComponents/FormBuilder';

const EntityDetailsCrud = (props) => {
  const { entity } = props;
  const dispatch = useDispatch();
  const entityDetail = useSelector(getSelectedEntityDetail, shallowEqual);
  const entityDetailTypes = useSelector(getEntityDetailTypes, shallowEqual);
  const jurisdictions = useSelector(getJurisdictions, shallowEqual);
  const container = useSelector(getSelectedContainer, shallowEqual);
  const [selectedEntityDetailType, setSelectedEntityDetailType] = useState({});
  const [initialJurisdictions, setInitialJurisdictions] = useState({
    jurisdictionCountry: '',
    jurisdictionLocal: '',
  });
  const entityId = entity.entityId;
  const jurisdictionCountry = entityDetail.jurisdictionCountry || initialJurisdictions.jurisdictionCountry;
  const jurisdictionLocal = entityDetail.jurisdictionLocal || initialJurisdictions.jurisdictionLocal;
  const valueType = selectedEntityDetailType.valueType || entityDetail.type;
  
  useEffect(() => {
    if (props.crudType !== 'create') {
      dispatch(selectEntityDetailCrud(props));
    } else {
      dispatch(selectEntityDetailCrud(ENTITY_DETAIL_INITIAL_STATE.selected));
    }
    setSelectedEntityDetailType({});
  }, [dispatch, props]);

  useEffect(() => {
    const currentTaxYear = container.tax_year;
    const currentJurisdiction = entity.jurisdictionId;
    dispatch(fetchEntityDetailTypes(currentJurisdiction, currentTaxYear));
  }, [container.tax_year, dispatch, entity.jurisdictionId]);

  useEffect(() => {
    const { jurisdictionId } = entityDetail;

    const setJurisdictionType = () => {
      const currentJurisdiction = jurisdictions.find((jurisdiction) => jurisdiction.jurisdiction_id === jurisdictionId);
      const jurisdictionParentId = currentJurisdiction.parent_jurisdiction_id;
      if (jurisdictionParentId) {
        setInitialJurisdictions({ jurisdictionCountry: jurisdictionParentId, jurisdictionLocal: jurisdictionId });
      } else {
        setInitialJurisdictions({ jurisdictionCountry: jurisdictionId, jurisdictionLocal: '' });
      }
    };

    if (jurisdictionId) {
      setJurisdictionType();
    }

    return () => {
      setInitialJurisdictions({ jurisdictionCountry: '', jurisdictionLocal: '' })
    }
  }, [dispatch, entityDetail, jurisdictions]);
  
  const handleTypeChange = (event, name, entityDetailType) => {
    // AutocompleteSelect component always makes value into string
    const value = Number(event.target.value);
    
    if (typeof name !== 'string') {
      name = event.target.name;
    }
    
    setSelectedEntityDetailType(entityDetailType);
    dispatch(setSelectedEntityDetailField(name, value));
  }
  
  const handleInputChange = (event, name) => {
    const value = Number(event.target.value) || event.target.value;

    if (typeof name !== 'string') {
      name = event.target.name;
    }

    dispatch(setSelectedEntityDetailField(name, value));
    if (name === 'jurisdictionCountry' || name === 'jurisdictionLocal') {
      dispatch(setSelectedEntityDetailField('jurisdictionId', value));
    }

    if (name === 'jurisdictionCountry') {
      dispatch(setSelectedEntityDetailField('jurisdictionLocal', ''));
      dispatch(setSelectedEntityDetailField('jurisdictionId', ''));
    }
  };

  const handleEntityDetailUpdate = () => {
    const { id } = entityDetail;
    const body = makeEntityDetailBody();
    dispatch(editEntityDetail(id, entityId, body));
    props.closeCrudPanel();
  };

  const handleEntityDetailDelete = () => {
    const { id } = entityDetail;

    dispatch(deleteEntityDetail(id, entityId));
    props.closeCrudPanel();
  };

  const handleEntityDetailCreate = () => {
    const body = makeEntityDetailBody();

    dispatch(createEntityDetail(body, entityId));
    props.closeCrudPanel();
  };
  
  const formatValueByType = (value, type) => {
    const valueTypes = {
      percentage: value / 100,
    };
    
    return valueTypes[type] || value;
  };

  const makeEntityDetailBody = () => {
    const { value: valueInput, taxYear: taxYearInput, typeId, jurisdictionCountry, jurisdictionLocal, jurisdictionId } = entityDetail;
    const value = formatValueByType(Number(valueInput), valueType);
    const taxYear = Number(taxYearInput);
    return {
      value,
      taxYear,
      typeId,
      entityId: entity.entityId,
      jurisdictionId: jurisdictionId || jurisdictionLocal || jurisdictionCountry,
    };
  };

  const getLocalJurisdictions = () => {
    const selectedCountryJurisdiction = jurisdictionCountry;
    if (!selectedCountryJurisdiction) {
      return [];
    }

    const localJurisdictions = jurisdictions.filter(jurisdiction => {
      const isChildOfSelectedJurisdiction = jurisdiction.parent_jurisdiction_id === selectedCountryJurisdiction;
      return isChildOfSelectedJurisdiction;
    });

    return localJurisdictions;
  };

  const getCountryJurisdictions = () => {
    return jurisdictions.filter((jurisdiction) => jurisdiction.type === 'country')
  };

  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleEntityDetailCreate,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleEntityDetailUpdate,
      color: 'grape',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleEntityDetailDelete,
      color: 'rhubarb',
      shouldAskConfirmation: true
    },
  ];
  const inputFields = buildEntityDetailInputFields(entityDetail, {
    countryJurisdictions: getCountryJurisdictions(),
    localJurisdictions: getLocalJurisdictions(),
    entityDetailTypes,
    jurisdictions,
    jurisdictionCountry,
    jurisdictionLocal,
    handleTypeChange,
    valueType
  });
  return (
    <div>
      <FormBuilder
        onChange={handleInputChange}
        fields={inputFields}
        formActions={formActions}
      />
    </div>
  );
};

export default EntityDetailsCrud;
