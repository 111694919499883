import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import sortDropdownOptions from '../../../utils/sortDropdownOptions';

const useStyles = makeStyles(theme => ({
  label: {
    marginBottom: theme.spacing(1)
  },
  chip: {
    margin: 3,
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  dropdown: {
    maxHeight: '300px',
    overflow: 'scroll',
    padding: 0,
    '& li[aria-selected="true"]': {
      backgroundColor: theme.palette.primary.veryLight,
      color: theme.palette.primary.main
    }
  }
}));
const AutocompleteMultipleSelect = ({ sortOptions, name, label, value: selectedOptions, options, optionKeys, maxChips, onChange, muiProps }) => {
  const styles = useStyles();


  const getOptionLabel = (option) => {
    const optionLabel = option[optionKeys.name];
    const optionLabelString = optionLabel ? String(optionLabel) : '';
    return optionLabelString;
  };


  const renderChips = () => {
    const MAX_CHIPS_TO_SHOW = maxChips ? maxChips : 1;
    const { name, value } = optionKeys;
    const doesChipsLengthExceedLimit = selectedOptions.length > MAX_CHIPS_TO_SHOW;
    const selectedItemsToShow = selectedOptions.slice(0, MAX_CHIPS_TO_SHOW);

    return (
      <div className={styles.chips}>
        {options.length > 0 && selectedItemsToShow.map((selectedItem) => (
          <Chip key={selectedItem[value]} label={selectedItem[name]} className={styles.chip} />
        ))}
        {doesChipsLengthExceedLimit &&
          <Chip label={`+${selectedOptions.length - maxChips}`} className={styles.chip} />}
      </div>
    )
  };

  const sortedOptions = sortDropdownOptions(options, sortOptions);

  return (
    <>
      <Autocomplete
        {...muiProps}
        disableClearable
        multiple
        openOnFocus={true}
        disableCloseOnSelect
        noValidate
        fullWidth
        clearOnBlur
        ListboxProps={{ className: styles.dropdown }}
        onChange={(event, selectedItems) => onChange(selectedItems)}
        options={sortedOptions}
        getOptionLabel={getOptionLabel}
        renderInput={params => (
          <TextField
            {...params}
            fullWidth
            label={label}
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
        value={selectedOptions}
        renderTags={renderChips}
      >
      </Autocomplete>
    </>
  );
};

export default AutocompleteMultipleSelect;
