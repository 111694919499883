import React, { useState } from 'react';
import Table from '../../../commonComponents/Table';
import { makeStyles } from '@material-ui/core';
import Heading from './Heading';
import { headConfig, rowsConfig } from './tableConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getCredits } from '../../../selectors/credits';
import formatCreditForTableDisplay from '../../../utils/formatCreditForTableDisplay';
import { CREDIT_STATUSES, PUBLISHED_STATUSES } from '../../../constants/credit';
import { apiClient } from '../../../api/apiClient';
import downloadFileFromURI from '../../../utils/downloadFileFromURI';
import { LinearProgress } from '@material-ui/core';
import { handleError } from '../../../actions/error';
import { MAX_TABLE_HEIGHT } from '../../ServiceManager/Tenants/constants';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  tableContainer: {
    height: '67vh',
    overflowY: 'auto',
  },
}));

const Summary = () => {
  const styles = useStyles();
  const credits = useSelector(getCredits);
  const dispatch = useDispatch();
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const publishedCredits = credits.filter(credit => credit.publishedStatus === PUBLISHED_STATUSES.PUBLISHED);

  const handleDownloadReport = async (credit) => {
    try {
      const { id } = credit;
      setIsLoadingReport(true);
      const reportFileSignedUrl = await apiClient({
        method: 'get',
        url: `/report/download-by-credit-id?creditId=${id}`
      });
      downloadFileFromURI(reportFileSignedUrl);
      setIsLoadingReport(false);
    } catch (error) {
      dispatch(handleError(error));
      setIsLoadingReport(false);
    }
  };

  const makeRowMenuItems = (credit) => {
    const hasReport = credit.reportVersionStatusRefId !== null;
    return [
      {
        name: 'Download Report',
        handleClick: handleDownloadReport,
        shouldDisplay: hasReport
      }
    ];
  };

  return (
    <div className={styles.container}>
      <Heading
        credits={publishedCredits}
      />
      {isLoadingReport && <LinearProgress />}
      <div className={styles.tableContainer}>
        <Table
          data={formatCreditForTableDisplay(publishedCredits)}
          head={headConfig}
          tableRows={rowsConfig}
          searchBy="legalEntityName"
          searchByPlaceholder="Entity Name"
          rowHighlightConditions={{ column: 'creditStatus', valueShouldBe: CREDIT_STATUSES.COMPLETED }}
          makeRowMenuItems={makeRowMenuItems}
          maxTableHeight={MAX_TABLE_HEIGHT}
          shouldShowTrafficLight
        />
      </div>
    </div>
  );
};

export default Summary;
