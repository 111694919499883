import { createTheme } from '@material-ui/core/styles';
import { regular, bold } from './fonts';

const theme = createTheme({
  palette: {
    primary: {
      main: '#42195A',
      dark: '#571E78',
      light: '#c89fdb',
      veryLight: '#F1EAF8',
      contrastText: '#fff',
    },
    secondary: {
      main: '#42195A',
      dark: '#391e48',
    },
    grey: {
      50: '#f8f8f9',
      100: '#f0f1f2',
      200: '#e2e4e6',
      400: '#c6c9cd',
      600: '#a9afb5',
      800: '#8d949c',
      900: '#707983'
    },
    black: {
      700: '#555555',
      800: '#252525',
      900: '#1e1e1e'
    },
    shades: {
      black: '#323232',
      pink: '#a03374',
    },
    green: '#4CAF50',
    red:'#FF0000',
    pink: '#C84091',
    rhubarb: '#c14c83',
    rhubarbDarker: '#a62a7c',
    grape: '#884b92',
    grapeLighter: 'rgba(136,75,146,0.27)',
    grapeDarker: '#6b3a75',
  },
  font: {
    darkOrange: '#d13b00'
  },
  spacing: (factor) => factor * 12,
  common: {
    common: {
      white: '#fff',
      black: '#212121'
    },
  },
  navbarHeight: '7vh',
  status: {
    danger: 'blue',
  },
  typography: {
    fontFamily: '\'Basis Grotesque Pro\', sans-serif',
    fontSize: 14,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [regular, bold],
      },
    },
  },
});

export default theme;
