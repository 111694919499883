import React, {useCallback, useEffect, useState} from 'react';
import { CropFree } from "@material-ui/icons";
import { IconButton, Chip } from "@material-ui/core";
import { showModal } from "../../../actions/modal";
import { useDispatch } from "react-redux";
import { useStyles } from "./Modal.styles";
import classNames from "classnames";

const Modal = (props) => {
  const { label, modalOptions, value, maxChips, optionKeys, onChange, options, muiProps } = props;
  const dispatch = useDispatch();
  const styles = useStyles();
  const [chips, setChips] = useState([]);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (value) {
      setChips(value);
    }
  }, [value]);

  useEffect(() => {
    setDisabled(muiProps && muiProps.disabled);
  }, [muiProps]);

  const handleChangeInfoModal = useCallback((data) => {
    setChips(data);
    if (onChange){
      onChange(data);
    }
  }, [onChange]);

  const handleOpenModal = useCallback(() => {
    modalOptions.onSelect = handleChangeInfoModal;
    modalOptions.keys = optionKeys;
    modalOptions.info = options.map((option) => {
      option.selected = value.find((selectedOption) => selectedOption[optionKeys.name] === option[optionKeys.name]);
      option.visible = true;
      return option;
    });
    dispatch(showModal(modalOptions.name, modalOptions));
  },[value, options, optionKeys, modalOptions, dispatch, handleChangeInfoModal]);

  const handleDelete = useCallback((id) => {
    const indexDelete = chips.findIndex((option) => option[optionKeys.value] === id);
    chips.splice(indexDelete, 1);
    if (onChange){
      onChange(chips);
    }
  }, [chips, onChange, optionKeys]);

  const classesWrapper = classNames({
    [styles.chipsWrapper]: true,
    'MuiInputBase-root': true,
    'MuiInput-root': true,
    'MuiInput-underline': true,
    'Mui-disabled': disabled,
  });

  return (
    <>
      <IconButton disabled={disabled} className={styles.openModalButton} onClick={handleOpenModal}>
        <CropFree fontSize='small' />
      </IconButton>
      <h3 className={styles.chipsTitle}>{label}</h3>
      <div className={classesWrapper}>
        {chips && chips.slice(0, maxChips).map((option, index) => {
          const onDeleteHandler = !disabled ? () => handleDelete(option[optionKeys.value]) : undefined;
          return (
            <Chip
              key={`chip-${index}`}
              size='small'
              label={option[optionKeys.name]}
              onDelete={onDeleteHandler}
            />
          );
        })}
        {chips.length > maxChips && <Chip size='small' label={`+${chips.length - maxChips}`} />}
      </div>
    </>
  );
}

export default Modal;
