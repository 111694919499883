export const getExpensesTypes = state => state.expense.expensesTypes || [];

export const getExpensesByType = (entityId, type) =>  state => {
  return state.expense.expenses && state.expense.expenses[entityId] && state.expense.expenses[entityId][type] ? state.expense.expenses[entityId][type] : null
}
export const getExpenseFieldsById = type => state => {
  const [expense] = state.expense.expensesTypes.filter(({id}) => id === type)
  return expense.columns.filter(({editable}) => editable)
}

export const getExpensesTaxYears = state => state.expense.taxYears;
export const getUploadValidationErrors = state => state.expense.uploadValidationErrors || [];

export const getEmployeesForExpense = state => state.expense.employees;

export const getAllocatedProjects = state => state.expense.allocatedProjects || [];

export const getTotalAllocated = ({expense:{allocatedProjects}}) =>  
  allocatedProjects && allocatedProjects.reduce((total, {projectPercent}) => total + projectPercent, 0)

export const getProjectsToAllocate = state => {
  const entityProjects = state.common.selectedEntityProjects
  const allocatedProjects = getAllocatedProjects(state);
  const availableProjects = entityProjects && allocatedProjects ? entityProjects.filter(({project_id}) => !allocatedProjects.some(proj => (proj.projectId === project_id))) : []
  return availableProjects
}

export const getHoursPerSource = state => state.expense.hoursBySource;

export const getTotalExpenses = state => state.expense.totalExpenses;

export const getDirectiveClassifications = state => state.expense.directiveClassifications;
