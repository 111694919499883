import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  questionsContainer: {
    width: '75%',
    margin: 'auto'
  },
  questionForm: {
    margin: 'auto',
    fontSize: '1.2rem',
    width: '100%'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  button: {
    margin: theme.spacing(1),
    boxShadow: 'none',
  },
  modal: {
    backgroundColor: theme.palette.common.white,
    width: '70%',
    padding: theme.spacing(2),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 20,
  },
  noData: {
    width: '80%',
    background: theme.palette.grey[100],
    textAlign: 'center',
    margin: '0 auto',
    marginTop: theme.spacing(2)
  }
}));
