const validateFields = (fields) => {
  let isFormValid = true;
  const inputFields = fields.map(field => {
    let isFieldValid = true;
    const { required, value, validationOptions = {} } = field;
    const { customValidate, regex, regexErrorMessage } = validationOptions;
    let validationMessage = '';
    
    const isEmpty = value === '' || typeof value === 'undefined' || value === null;
    if (!required && isEmpty) {
      return { ...field, helperText: validationMessage, isInvalid: false };
    } else if (required && isEmpty) {
      isFormValid = false;
      isFieldValid = false;
      validationMessage = 'This field is required';
    } else if (regex && !regex.test(value)) {
      isFormValid = false;
      isFieldValid = false;
      validationMessage = regexErrorMessage;
    } else if (customValidate && typeof customValidate(value) !== 'undefined') {
      isFormValid = false;
      isFieldValid = false;
      validationMessage = customValidate(value);
    }
    return { ...field, helperText: validationMessage, isInvalid: !isFieldValid };
  });
  return { inputFields, valid: isFormValid };
};

export const validateForm = (inputFields, validate) => {
  const fields = {
    inputFields,
    valid: true,
  };
  return validate ? validateFields(fields.inputFields) : fields;
};

export default validateForm;
