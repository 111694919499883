export const errorMessages = {
  RESOURCE_NOT_FOUND: 'Resource not found',
  INTERNAL_ERROR: 'There was an error. Please try again'
};

export const errorMessageByStatusCode = {
  404: errorMessages.RESOURCE_NOT_FOUND,
  500: errorMessages.INTERNAL_ERROR,
};

