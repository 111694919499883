import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import screen1 from './svgs/screen1.svg';
import screen2 from './svgs/screen2.svg';
import icon from './svgs/icon.svg';
import classNames from 'classnames';
import DemoModal from '../../../../commonComponents/DemoModal';

const useStyles = makeStyles((theme) => ({
  demoImage1: {
    cursor: 'pointer',
    width: 960,
    height: 508,
  },
  demoImage2: {
    cursor: 'pointer',
    width: 960,
    height: 508,
    position: 'relative',
    bottom: 50
  },
}));

const ViewAnalysisModal = ({ isOpen, onClose }) => {
  const styles = useStyles();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const demoImages = [screen1, screen2];
  
  const handleImageClick = () => {
    const areThereMoreImages = currentImageIndex < demoImages.length - 1;
    if (!areThereMoreImages) {
      return;
    }
    
    const nextImageIndex = currentImageIndex + 1;
    setCurrentImageIndex(nextImageIndex);
  };
  
  const imageClassName = classNames({
    [styles.demoImage1]: currentImageIndex === 0,
    [styles.demoImage2]: currentImageIndex === 1,
  });
  
  const transitionProps = {
    onExited: () => {
      setCurrentImageIndex(0)
    }
  }

  const modalHead = <img src={icon} alt="icon"/>;
  
  return (
    <DemoModal
      transitionProps={transitionProps}
      onButtonClick={onClose}
      buttonCaption={"Done"}
      isOpen={isOpen}
      onClose={onClose}
      head={modalHead}>
      <img className={imageClassName} src={demoImages[currentImageIndex]} onClick={handleImageClick} alt="demo"/>
    </DemoModal>
  );
};

export default ViewAnalysisModal;
