export const buildTenantInputFields = tenant => [{
  name: 'name',
  label: 'Name',
  type: 'Input',
  value: tenant.name,
  shouldDisplay: true,
  muiProps: {
    disabled: false
  },
  helperText: '',
  required: true,
},
{
  name: 'xb_code',
  label: 'XB Code',
  type: 'Input',
  value: tenant.xb_code,
  shouldDisplay: true,
  muiProps: {
    disabled: false
  },
  helperText: '',
  required: true,
},
{
  name: 'enabled',
  label: 'Enable',
  type: 'Selector',    
  options: [{value: 1, name: 'Enabled'}, {value: 0, name: 'Disabled'}],
  value:  tenant.enabled,      
  optionKeys: {
    name: 'name',
    value: 'value',
  },
  helperText: '',
  required: true,
},
{
  name: 'status',
  label: 'Status',
  type: 'Selector',
  options: [
    {value: 'Prospect', name: 'Prospect'},
    {value: 'Setup', name: 'Setup'},
    {value: 'Active', name: 'Active'},
    {value: 'Archived', name: 'Archived'},    
  ],
  value:  tenant.status,      
  optionKeys: {
    name: 'name',
    value: 'value',
  },
  helperText: '',
  required: true,
},  
]
