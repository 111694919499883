import {
  FETCH_SECTIONS_SUCCESS,
  FETCH_QUESTION_ANSWERS_SUCCESS,
  UPDATE_ANSWER, UPDATE_SECTION_COMPLETION_STATUS,
} from '../constants/actionTypes/creditDetail';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { trackPromise } from 'react-promise-tracker';
import { tags } from '../constants/promiseTracker';

export const getCreditDetailQuestionsAndAnswers = (creditId, creditDetailSectionRefId) => async (dispatch) => {
  const questionAnswers = await trackPromise(
    apiClient({
      method: 'get',
      url: `/credit-details/question-answers-for-section?creditId=${creditId}&creditDetailSectionRefId=${creditDetailSectionRefId}`,
    }),
    tags.FETCH_CREDIT_DETAIL_QUESTIONS
  )

  dispatch(fetchQuestionAnswersSuccess(questionAnswers));
};

export const handleAnswerChange = (value, questionId) => {
  return {
    type: UPDATE_ANSWER,
    questionId,
    value
  };
};

export const updateOrCreateAnswers = (dataAnswerDetail) => async (dispatch) => {
  try {
    await apiClient({
      method: 'post',
      url: `/credit-details/answer`,
      data: dataAnswerDetail
    });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchSections = (creditId) => async (dispatch) => {
  try {
    const sections = await trackPromise(
      apiClient({
        method: 'get',
        url: `/credit-details/sections?creditId=${creditId}`,
      }),
      tags.FETCH_CREDIT_DETAIL_SECTIONS
    );
    dispatch(fetchSectionsSuccess(sections))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const changeSectionCompletionStatus = (payload) => async (dispatch) => {
  const { creditDetailSectionRefId, isCompleted } = payload;

  try {
    dispatch({
      type: UPDATE_SECTION_COMPLETION_STATUS,
      creditDetailSectionRefId,
      isCompleted
    });
    await apiClient({
      method: 'post',
      url: `/credit-details/change-section-completion-status`,
      data: payload
    });
  } catch (err) {
    dispatch({
      type: UPDATE_SECTION_COMPLETION_STATUS,
      creditDetailSectionRefId,
      isCompleted: !isCompleted
    });
    dispatch(handleError(err));
  }
};

export const fetchSectionsSuccess = (sections) => ({
  type: FETCH_SECTIONS_SUCCESS,
  sections
});

export const fetchQuestionAnswersSuccess = (questionAnswers) => ({
  type: FETCH_QUESTION_ANSWERS_SUCCESS,
  questionAnswers
});
