import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import getValueFromEvent from '../../../../utils/getValueFromEvent';
import {
  createContainer,
  deleteContainer,
  editContainer,
  selectContainerCrud,
  setSelectedContainerField,
} from '../../../../actions/container';
import { CONTAINER_INITIAL_STATE } from '../constants';
import { getSelectedTenant } from '../../../../selectors/tenants';
import { getSelectedContainerCrud } from '../../../../selectors/containers';
import FormBuilder from '../../../../commonComponents/FormBuilder';
import { buildContainerInputFields } from './buildContainerInputFields';

const ContainersCrud = (props) => {
  const dispatch = useDispatch();
  const selectedTenant = useSelector(getSelectedTenant, shallowEqual);
  const container = useSelector(getSelectedContainerCrud, shallowEqual);
  const tenantId = selectedTenant.tenant_id;

  useEffect(() => {
    if (props.crudType !== 'create') {
      dispatch(selectContainerCrud(props));
    } else {
      dispatch(selectContainerCrud(CONTAINER_INITIAL_STATE.selected));
    }
  }, [dispatch, props]);

  const handleChange = (event) => {
    const { name } = event.target;
    const value = getValueFromEvent(event);
    dispatch(setSelectedContainerField(name, value));
  };

  const handleContainerUpdate = () => {
    const { container_id: id } = container;
    const body = makeContainerBody();
    dispatch(editContainer(id, tenantId, body));
    props.closeCrudPanel();
  };

  const handleContainerDelete = () => {
    const { container_id: id } = container;

    dispatch(deleteContainer(id, tenantId));
    props.closeCrudPanel();
  };

  const handleContainerCreate = () => {
    const body = makeContainerBody();
    dispatch(createContainer(tenantId, body));
    props.closeCrudPanel();
  };

  const makeContainerBody = () => {
    const { name, tax_year } = container;
    return {
      name,
      tenantId,
      taxYear: Number(tax_year),
    };
  };

  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleContainerCreate,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleContainerUpdate,
      color: 'grape',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleContainerDelete,
      color: 'rhubarb',
      shouldAskConfirmation: true
    },
  ];
  const inputFields = buildContainerInputFields(container);
  return (
    <div>
      <FormBuilder onChange={handleChange} fields={inputFields} formActions={formActions}/>
    </div>
  );
};

export default ContainersCrud;
