import {
  DELETE_CREDIT,
  GET_CREDITS_BEGIN,
  GET_CREDIT_BY_ID_SUCCESS,
  GET_CREDITS_SUCCESS,
  SELECT_CREDIT,
  SET_SELECTED_CREDIT_FIELD,
  SELECT_CREDIT_FOR_DROPDOWN,
  GET_CREDITS_SUCCESS_FOR_DROPDOWN,
  SET_CALCULATION_MODAL_STATE,
} from '../constants/actionTypes/credit';

export const selectCreditForDropdown = creditId => ({
  type: SELECT_CREDIT_FOR_DROPDOWN,
  creditId,
});

export const fetchCreditsBegin = () => ({
  type: GET_CREDITS_BEGIN,
});

export const fetchCreditByIdSuccess = (id, credit) => ({
  type: GET_CREDIT_BY_ID_SUCCESS,
  id,
  credit,
});

export const fetchCreditsSuccess = credits => ({
  type: GET_CREDITS_SUCCESS,
  credits,
});

export const deleteCreditFromRedux = (creditId) => ({
  type: DELETE_CREDIT,
  id: creditId,
});

export const fetchCreditsForDropdownSuccess = credits => ({
  type: GET_CREDITS_SUCCESS_FOR_DROPDOWN,
  credits,
});

export const selectCredit = selectedCredit => ({
  type: SELECT_CREDIT,
  selectedCredit,
});

export const setCalculationModalState = calculationModalState => ({
  type: SET_CALCULATION_MODAL_STATE,
  calculationModalState,
});

export const setSelectedCreditField = (key, value) => ({
  type: SET_SELECTED_CREDIT_FIELD,
  key,
  value,
});
