import React from 'react';
import GlobalDropdown from '../../commonComponents/GlobalDropdown';
import { getDropdownTenants } from '../../selectors/common';
import { useSelector } from 'react-redux';

const TenantsDropdown = ({ onChange }) => {

  const tenants = useSelector(getDropdownTenants);
  const selectedTenant = useSelector(state => state.common.selectedTenant);

  return (
    <GlobalDropdown
      options={tenants}
      onChange={onChange}
      value={selectedTenant}
      optionAccessors={{ name: 'name', value: 'tenant_id' }}
      label="Company"
    />
  );
};

export default TenantsDropdown;
