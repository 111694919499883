import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getValueFromEvent from '../../../../utils/getValueFromEvent';

// COMPONENTS
import FormBuilder from '../../../../commonComponents/FormBuilder'

// UTILS
import { buildTenantInputFields } from './buildTenantInputFields'

import {
  createTenant,
  deleteTenant,
  editTenant,
  selectTenantCrud,
  setSelectedTenantField,
} from '../../../../actions/tenant';
import { TENANT_INITIAL_STATE, TENANT_STATUSES } from '../constants';

const TenantsCrud = (props) => {
  const dispatch = useDispatch();
  const tenant = useSelector(state => state.tenants.selected);

  useEffect(() => {
    if (props.crudType !== 'create') {
      dispatch(selectTenantCrud(props));
    } else {
      dispatch(selectTenantCrud(TENANT_INITIAL_STATE.selected));
    }
  }, [dispatch, props]);

  const handleChange = (event) => {
    const { name } = event.target;
    const value = getValueFromEvent(event);
    dispatch(setSelectedTenantField(name, value));
  };

  const handleTenantUpdate = () => {
    const { tenant_id } = tenant;
    const body = makeTenantBody();
    dispatch(editTenant(tenant_id, body));
    props.closeCrudPanel();
  };

  const handleTenantDelete = () => {
    dispatch(deleteTenant(tenant.tenant_id));
    props.closeCrudPanel();
  };

  const handleTenantCreate = () => {
    const body = makeTenantBody();
    dispatch(createTenant(body));
    props.closeCrudPanel();
  };

  const makeTenantBody = () => {
    const { name, enabled, status, xb_code } = tenant;
    const body = { name, xb_code };
    body.status = TENANT_STATUSES[status];
    body.enabled = !!enabled;
    return body;
  };
  
  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleTenantCreate,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleTenantUpdate,
      color: 'grape',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleTenantDelete,
      color: 'rhubarb',
      shouldAskConfirmation: true
    },
  ];
  
  const inputFields = buildTenantInputFields(tenant);
  return (
    <div>
      <FormBuilder 
        fields={inputFields}
        onChange={handleChange}
        formActions={formActions}
      />
    </div>
  );
};

export default TenantsCrud;
