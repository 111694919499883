import React from 'react';
import classNames from 'classnames';
import RDPlannerModalShared from './RDPlannerModalShared';
import { useStyles } from './styles';

const TotalCreditAmountRow = ({ totalQrePerExpenseType }) => {
  const styles = useStyles();
  
  const calculateCreditAmountNextYear = () => {
    const totalQreSumForAllExpenseTypesNextYear = RDPlannerModalShared.getTotalQreSumForAllExpenseTypesNextYear(totalQrePerExpenseType);
    let totalQreForAllExpenseTypesNextYear = (totalQreSumForAllExpenseTypesNextYear - 3227611) * .1106;
    
    if (totalQreForAllExpenseTypesNextYear < 0) {
      totalQreForAllExpenseTypesNextYear = 0;
    }
    
    return totalQreForAllExpenseTypesNextYear;
  };
  
  const creditAmountNextYear = calculateCreditAmountNextYear();
  
  const creditAmountNextYearClassNames = classNames({
    [styles.creditAmount]: true,
    [styles.modifiedTotals]: 523198 !== creditAmountNextYear,
  });
  
  return (
    <div className={styles.creditAmountRow}>
      <div>
        <span className={styles.columnTitle}>Current Year Credit Amount</span>
        <span className={styles.creditAmount}>$523,198</span>
      </div>
      
      <div>
        <span className={styles.columnTitle}>Future Year Credit Amount</span>
        <span className={creditAmountNextYearClassNames}>{RDPlannerModalShared.formatCurrency(creditAmountNextYear)}</span>
      </div>
    </div>
  );
};

export default TotalCreditAmountRow;
