import {
  FETCH_CONTAINERS_SUCCESS,
  FETCH_DROPDOWN_CONTAINERS_SUCCESS,
  SELECT_CONTAINER,
  SELECT_CONTAINER_CRUD,
  SET_CAN_CONTAINER_ROLL,
  SET_SELECTED_CONTAINER_FIELD,
  SET_IS_LOADING_ROLL_FORWARD
} from '../constants/actionTypes/container';
import { apiClient } from '../api/apiClient';
import store from '../store';
import { handleError } from './error';
import { showMessage } from './message';

export const fetchContainersForDropdown = (tenantId) => async (dispatch) => {
  try {
    const containers = await apiClient({
      method: 'get',
      url: `user/containers?tenantId=${tenantId}`,
    });

    dispatch(fetchContainersForDropdownSuccess(containers));

    const hasContainerBeenSelected = store.getState().common.selectedContainer;
    if (hasContainerBeenSelected) {
      return;
    }

    const defaultContainer = containers[0];
    if (defaultContainer) {
      dispatch(selectContainer(defaultContainer.container_id));
    } else {
      dispatch(selectContainer(null));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchContainersForAdminDropdown = (tenantId) => async (dispatch) => {
  try {
    const containers = await apiClient({
      method: 'get',
      url: `container?tenantId=${tenantId}`,
    });

    dispatch(fetchContainersForDropdownSuccess(containers));

    const hasContainerBeenSelected = store.getState().common.selectedContainer;
    if (hasContainerBeenSelected) {
      return;
    }

    const defaultContainer = containers[0];
    if (defaultContainer) {
      dispatch(selectContainer(defaultContainer.container_id));
    } else {
      dispatch(selectContainer(null));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const setDefaultSelectedContainer = (containers) => async (dispatch) => {
  try {
    const defaultContainer = containers[0];
    if (defaultContainer) {
     dispatch(selectContainer(defaultContainer.container_id));
    } else {
     dispatch(selectContainer(null));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchContainers = (tenantId) => async (dispatch) => {
  try {
    const containers = await apiClient({
      method: 'get',
      url: `/container?tenantId=${tenantId}`,
    });

    dispatch(fetchContainersSuccess(containers));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchCanContainerRoll = (containerId) => async (dispatch) => {
  const redRollingApiBaseUrl = await store.getState().config.RED_ROLLING_API_BASE_URL;

  dispatch(setIsLoadingRollForward(true));
  
  const { data: canContainerRollResponse } = await apiClient({
    method: 'get',
    baseURL: redRollingApiBaseUrl,
    url: `container/rollable/${containerId}`,
  });
  
   dispatch(setCanContainerRoll({ canRoll: canContainerRollResponse }));
   dispatch(setIsLoadingRollForward(false));
};

export const selectContainer = (selectedContainer) => async (dispatch) => {
  try {
    const validatedContainer = await apiClient({
      method: 'post',
      url: `/auth/setWorkingContainer?containerId=${selectedContainer}`,
    });
   const containerId = validatedContainer.id;

    dispatch({
      selectedContainer: Number(containerId),
      type: SELECT_CONTAINER,
    });
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const selectContainerCrud = (selectedContainer) => ({
  selectedContainer,
  type: SELECT_CONTAINER_CRUD,
});

export const fetchContainersForDropdownSuccess = (containers) => ({
  type: FETCH_DROPDOWN_CONTAINERS_SUCCESS,
  containers,
});

const fetchContainersSuccess = containers => ({
  type: FETCH_CONTAINERS_SUCCESS,
  containers,
});

export const editContainer = (containerId, tenantId, body) => async (dispatch) => {
  try {
    await apiClient({
      method: 'patch',
      url: `/container/${containerId}`,
      data: body,
    });
    dispatch(fetchContainers(tenantId));
    dispatch(showMessage('success', 'Container updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const deleteContainer = (containerId, tenantId) => async (dispatch) => {
  try {
    await apiClient({
      method: 'delete',
      url: `/container/${containerId}`,
    });
    await dispatch(fetchContainers(tenantId));
    dispatch(showMessage('success', 'Container deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const createContainer = (tenantId, body) => async (dispatch) => {
  try {
    await apiClient({
      method: 'post',
      url: `/container`,
      data: body,
    });
    await dispatch(fetchContainers(tenantId));
    dispatch(showMessage('success', 'Container created successfully', 3000));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const setSelectedContainerField = (key, value) => ({
  type: SET_SELECTED_CONTAINER_FIELD,
  value,
  key,
});

export const rollContainerForward = (rollContainerParams) => async (dispatch) => {
  const redRollingApiBaseUrl = await store.getState().config.RED_ROLLING_API_BASE_URL;
  const { containerId, taxYear, rollContainerForward } = rollContainerParams;

  if (containerId && taxYear) {
    try {
      dispatch(setIsLoadingRollForward(true));
    
      await apiClient({
        method: 'post',
        baseURL: redRollingApiBaseUrl,
        url: 'container/roll/',
        data: {
          existingContainerId: containerId,
          newTaxYear: taxYear + 1
        }
      });
  
      dispatch(setCanContainerRoll({ canRoll: { ...rollContainerForward, canRollForward: false } }));
      dispatch(setIsLoadingRollForward(false));
      dispatch(showMessage('success', 'Container rolled successfully', 3000));
  
    } catch (err) {
      dispatch(setCanContainerRoll({ canRoll: rollContainerForward.canRoll}));
      dispatch(setIsLoadingRollForward(false));
      dispatch(showMessage('error', 'Failed to roll the container', 3000))
      dispatch(handleError(err));
    }
  }
};

const setCanContainerRoll = (canContainerRoll) => ({
  type: SET_CAN_CONTAINER_ROLL,
  canContainerRoll
});

const setIsLoadingRollForward = (isLoadingRollForward) => ({
  type: SET_IS_LOADING_ROLL_FORWARD,
  isLoadingRollForward
});

