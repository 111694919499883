const FETCH_CONTAINERS_SUCCESS = 'FETCH_CONTAINERS_SUCCESS';
const FETCH_CONTAINERS_FAIL = 'FETCH_CONTAINERS_FAIL';
const SELECT_CONTAINER = 'SELECT_CONTAINER';
const TOGGLE_CONTAINER_LOADING_STATE = 'TOGGLE_CONTAINER_LOADING_STATE';
const SELECT_CONTAINER_CRUD = 'SELECT_CONTAINER_CRUD';
const SET_SELECTED_CONTAINER_FIELD = 'SET_SELECTED_CONTAINER_FIELD';
const FETCH_DROPDOWN_CONTAINERS_SUCCESS = 'FETCH_DROPDOWN_CONTAINERS_SUCCESS';
const SET_CAN_CONTAINER_ROLL = 'SET_CAN_CONTAINER_ROLL';
const SET_IS_LOADING_ROLL_FORWARD = 'SET_IS_LOADING_ROLL_FORWARD';

export {
  FETCH_CONTAINERS_SUCCESS,
  FETCH_CONTAINERS_FAIL,
  SELECT_CONTAINER,
  SET_SELECTED_CONTAINER_FIELD,
  SELECT_CONTAINER_CRUD,
  TOGGLE_CONTAINER_LOADING_STATE,
  FETCH_DROPDOWN_CONTAINERS_SUCCESS,
  SET_CAN_CONTAINER_ROLL,
  SET_IS_LOADING_ROLL_FORWARD
}
