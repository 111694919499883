import React, {useCallback, useEffect, useState} from "react";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";

const DebounceTextField = ({label = '', type, style, onChange, value = '', indicator, showIndicator = false, ...rest}) => {
  const [valueData, setValueData] = useState(value);
  const [timer, setTimer] = useState();
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (flag && value !== valueData) {
      setValueData(value);
      setFlag(false);
    }// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueData]);

  useEffect(() => {
    setValueData(value);
    setFlag(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onHandleValue = useCallback((event) => {
    const { value } = event.target;
    setValueData(value);

    clearTimeout(timer);

    setTimer(setTimeout(() => {
      setFlag(true);
      if (onChange) {
        onChange(value);
      }
    }, 1000));
  }, [onChange, timer]);

  return (
    <>
    { flag === true && <>Saving...</> }
    { flag === false && <TextField
      placeholder={label}
      value={valueData}
      className={style}
      onChange={onHandleValue}
      type={type}
      InputProps={{
        endAdornment: showIndicator && <InputAdornment position="start">{indicator}</InputAdornment>
      }}
    /> }
    </>
  );
}

DebounceTextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  style: PropTypes.string,
  indicator: PropTypes.string,
  showIndicator: PropTypes.bool
}

export default DebounceTextField;