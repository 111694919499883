import {
  FETCH_TENANTS_SUCCESS,
  SELECT_TENANT,
  SELECT_TENANT_CRUD,
  SET_SELECTED_TENANT_FIELD,
  FETCH_DROPDOWN_TENANTS_SUCCESS,
} from '../constants/actionTypes/tenant';
import { tags } from '../constants/promiseTracker';
import store from '../store';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message'
import { trackPromise } from "react-promise-tracker";

export const fetchTenantsForDropdown = () => async (dispatch) => {
  try {
    const tenants = await apiClient({
      method: 'get',
      url: '/user/tenants',
    });
    dispatch(fetchTenantsForDropdownSuccess(tenants));

    const hasTenantBeenSelected = store.getState().common.selectedTenant;
    if (hasTenantBeenSelected) {
      return;
    }

    const defaultTenant = tenants[0];
    if (defaultTenant) {
      dispatch(selectTenant(defaultTenant.tenant_id));
    } else {
      dispatch(selectTenant(null));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchTenantsForAdminDropdown = () => async (dispatch) => {
  try {
    const tenants = await apiClient({
      method: 'get',
      url: `/tenant`,
    });
    dispatch(fetchTenantsForDropdownSuccess(tenants));

    const hasTenantBeenSelected = store.getState().common.selectedTenant;
    if (hasTenantBeenSelected) {
      return;
    }

    const defaultTenant = tenants[0];
    if (defaultTenant) {
      dispatch(selectTenant(defaultTenant.tenant_id));
    } else {
      dispatch(selectTenant(null));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const setDefaultSelectedTenant = (tenants) => async (dispatch) => {
  try {
    const defaultTenant = tenants[0];

    if (defaultTenant) {
      dispatch(selectTenant(defaultTenant.tenant_id));
    } else {
      dispatch(selectTenant(null));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchTenants = () => async (dispatch) => {
  try {
    const tenants = await trackPromise(
      apiClient({
        method: 'get',
        url: '/tenant',
      }),
      tags.FETCH_TENANTS
    );
    dispatch(fetchTenantsSuccess(tenants));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const editTenant = (tenantId, body) => async (dispatch) => {
  try {
    await apiClient({
      method: 'patch',
      url: `/tenant/${tenantId}`,
      data: body,
    });
    dispatch(fetchTenants());
    dispatch(showMessage('success', 'Tenant updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const deleteTenant = (tenantId) => async (dispatch) => {
  try {
    await apiClient({
      method: 'delete',
      url: `/tenant/${tenantId}`,
    });
    await dispatch(fetchTenants());
    dispatch(showMessage('success', 'Tenant deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const createTenant = (body) => async (dispatch) => {
  try {
    await apiClient({
      method: 'post',
      url: '/tenant',
      data: body,
    });
    await dispatch(fetchTenants());
    dispatch(showMessage('success', 'Tenant created successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const selectTenant = (selectedTenant) => dispatch => {
  dispatch(saveSelectedTenant(selectedTenant));
};

export const saveSelectedTenant = selectedTenant => ({
  selectedTenant,
  type: SELECT_TENANT,
});

export const selectTenantCrud = (selectedTenant) => ({
  selectedTenant,
  type: SELECT_TENANT_CRUD,
});

export const setSelectedTenantField = (key, value) => ({
  type: SET_SELECTED_TENANT_FIELD,
  value,
  key,
});

const fetchTenantsSuccess = (tenants) => ({
  type: FETCH_TENANTS_SUCCESS,
  tenants,
});

export const fetchTenantsForDropdownSuccess = (tenants) => ({
  type: FETCH_DROPDOWN_TENANTS_SUCCESS,
  tenants,
});
