import countChildrenInDepths from './countChildrenInDepths';

export const calculateChartWidth = (nodes, separationBetweenNodes = 700) => {
  /**
   * Calculate the tree width based on the depth with most children.
   */

  const depthCount = countChildrenInDepths(nodes);
  const numberChildrenInDepths = Object.values(depthCount);
  const maxChildrenCount = Math.max(...numberChildrenInDepths);

  return maxChildrenCount * separationBetweenNodes;
};

export const calculateChartHeight = (nodes, verticalSeparation = 400) => {
  const masterParent = nodes[0];

  return masterParent.height * verticalSeparation;
};
