import { GET_REPORT_VERSIONS_SUCCESS, GET_REPORTS_DATA_SUCCESS, GET_PUBLISHED_REPORT_VERSION_SUCCESS } from '../constants/actionTypes/report';

const DEFAULT_STATE = {
  publishedReportVersion: {},
  list: []
}

const reports = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_VERSIONS_SUCCESS: 
      return {
        ...state,
        list: action.reportVersions
    }
    case GET_REPORTS_DATA_SUCCESS: 
      return {
        ...state,
        ...action.payload
    }
    case GET_PUBLISHED_REPORT_VERSION_SUCCESS:
      return {
        ...state,
        publishedReportVersion: action.reportVersion
      }
    default:
        return state
  }
}

export default reports;
