import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  questionWrapper: {
    width: '100%',
    padding: `${theme.spacing(1)}px 0`,
    marginBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[300]}`
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  currency: {
    color: theme.palette.grey[800],
    marginRight: theme.spacing(.5)
  },
  detailFrame: {
    margin: `${theme.spacing(2)}px 0`,
  }
}));
