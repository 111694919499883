import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { InfoOutlined } from '@material-ui/icons';
import { useStyles } from './styles';

const ExpansionPanelHint = ({ children, className }) => {
  const styles = useStyles();

  return (
    <ExpansionPanel className={`${styles.hintWrapper} ${className}`}>
      <ExpansionPanelSummary
        className={styles.hintSummary}
        expandIcon={<InfoOutlined />}
        classes={{ expandIcon: styles.infoIcon }}
      />
      <ExpansionPanelDetails className={styles.hintDetails}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default ExpansionPanelHint;
