export const DEFAULT_STATUSES = {
  creditStatusRefId: 2, // Pending
  publishedStatusRefId: 2 // In Progress
}

export const STUDY_CENTRAL_INITIAL_STATE = {
  list: [],
  selected: {
    jurisdictionId: '',
    jurisdictionName: '',
    entityId: '',
    entityName: '',
    containerId: '',
    taxPayerTypeRefId: '',
    taxPayerType: '',
    publishedStatusRefId: DEFAULT_STATUSES.publishedStatusRefId,
    publishedStatus: '',
    calculationStatusRefId: '',
    calculationStatus: '',
    creditStatusRefId: DEFAULT_STATUSES.creditStatusRefId,
    creditStatus: '',
    totalAmount: '',
    entityCreditAmount: '',
    creditMethodRefId: '',
    creditMethod: '',
    dueDate: null,
    creditUserOptions: [],
    notes: '',
    creditCalculations: [],
  },
  calculationModalState: 'closed',
};

export const CREDIT_STATUSES = {
  Completed: 1,
  Pending: 2
};

export const CREDIT_USER_OPTION_SINGLE_SELECTION = 1;