import { trackPromise } from 'react-promise-tracker';
import { apiClient } from '../../api/apiClient';
import { handleError } from '../error';
import history from '../../history';

import {
  FETCH_SURVEY_BY_ID_SUCCESS,
  FETCH_SURVEY_TABS_SUCCESS,
  RESET_STATE_SURVEY_WIZARD_EXPENSES,
  RESET_STATE_SURVEY_WIZARD_PROJECTS,
  RESET_STATE_SURVEY_WIZARD_SURVEY,
  FETCH_SURVEY_SECTION_INFO_SUCCESS,
  TOGGLE_SURVEY_SECTION_COMPLETE,
  CHANGE_SURVEY_DOCUMENTS_DESCRIPTION
} from '../../constants/actionTypes/surveyWizard';
import { tags } from '../../constants/promiseTracker';
import { SURVEY_INITIAL_STATE } from '../../components/SurveyCentral/SurveyWizard/constants';

export const fetchSurveyByIdSuccess = survey => ({
  type: FETCH_SURVEY_BY_ID_SUCCESS,
  survey
});

export const fetchSurveyById = surveyId => async (dispatch) => {
  try {
    const survey = await trackPromise(
      apiClient({
        method: 'get',
        url: `/survey/${surveyId}`,
      }),
      tags.FETCH_SURVEY_BY_ID
    );
    dispatch(fetchSurveyByIdSuccess(survey));
  } catch (err) {
    if (err.isAxiosError && err.response.status === 404) {
      history.push('/survey-central');
      history.go();
      return;
    }
    dispatch(handleError(err));
  }
};

export const fetchSurveyTabs = (surveyTypeRefId) =>  async (dispatch) => {
  try {
    const surveySectionTypes = await apiClient({
        method: 'get',
        url: `/survey/sections-by-type?surveyTypeRefId=${surveyTypeRefId}`,
      });
    dispatch(fetchSurveyTabsSuccess(surveySectionTypes));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchSurveyTabsSuccess = surveyTabs => ({
  type: FETCH_SURVEY_TABS_SUCCESS,
  surveyTabs
});

export const resetSurveyWizardState = () => (dispatch) => {
  dispatch({ type: RESET_STATE_SURVEY_WIZARD_SURVEY })
  dispatch({ type: RESET_STATE_SURVEY_WIZARD_EXPENSES })
  dispatch({ type: RESET_STATE_SURVEY_WIZARD_PROJECTS })
};

export const toggleSurveySectionComplete = (surveySectionRefId, isCompleted) => ({
  type: TOGGLE_SURVEY_SECTION_COMPLETE,
  surveySectionRefId,
  isCompleted
});

export const fetchSurveySectionInfoSuccess = sections => ({
  type: FETCH_SURVEY_SECTION_INFO_SUCCESS,
  sections
})

export const fetchSurveySectionInfo = surveyId => async dispatch => {
  try {
    let surveySections = await apiClient({
      method: 'get',
      url: `/survey/completion-status-per-section?surveyId=${surveyId}`
    });
    
    if (!surveySections.length) {
      surveySections = SURVEY_INITIAL_STATE.surveySections
    }
    
    dispatch(fetchSurveySectionInfoSuccess(surveySections));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const toggleAndSaveSurveySectionCompletion = (surveyId, surveySectionRefId, isCompleted) => async dispatch => {
  try {
    await apiClient({
      method: 'patch',
      url: '/survey/toggle-section-completion-status',
      data: {
        surveyId,
        surveySectionRefId,
        isCompleted
      }
    });
    dispatch(toggleSurveySectionComplete(surveySectionRefId, isCompleted))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const changeSurveyDocumentsDescription = (documentsDescription) => ({
  type: CHANGE_SURVEY_DOCUMENTS_DESCRIPTION,
  documentsDescription
});
