import InputAdornment from '@material-ui/core/InputAdornment';
import React from 'react';
import { checkIsPercentageValid } from '../../../../../customValidations/checkIsPercentageValid';
import { checkIsIntegerYear } from '../../../../../customValidations/checkIsIntegerYear';

const getEndAdornmentByValueType = (valueType) => {
  const valueTypes = {
    percentage: <InputAdornment position="start">%</InputAdornment>
  }
  
  return valueTypes[valueType];
};

const getInputValidationByValueType = (valueType) => {
  const valueTypes = {
    percentage: checkIsPercentageValid
  };
  
  return valueTypes[valueType] || null;
}

export const buildEntityDetailInputFields = (entityDetail, inputData) => [
  {
    name: 'typeId',
    label: 'Type',
    type: 'AutocompleteSelect',
    onChange: inputData.handleTypeChange,
    options: inputData.entityDetailTypes,
    value: inputData.entityDetailTypes.length ? entityDetail.typeId : '',
    optionKeys: { name: 'name', value: 'id' },
    required: true,
  },
  {
    name: 'taxYear',
    label: 'Tax Year',
    type: 'Year',
    value: entityDetail.taxYear,
    validationOptions: {
      customValidate: checkIsIntegerYear,
    },
    required: true
  },
  {
    name: 'jurisdictionCountry',
    label: 'Country Jurisdiction',
    type: 'AutocompleteSelect',
    options: inputData.countryJurisdictions,
    value: inputData.jurisdictions.length ? inputData.jurisdictionCountry : '',
    optionKeys: { name: 'name', value: 'jurisdiction_id' },
    required: true,
  },
  {
    name: 'jurisdictionLocal',
    label: 'Local Jurisdiction',
    type: 'AutocompleteSelect',
    options: inputData.localJurisdictions,
    value: inputData.jurisdictions.length ? inputData.jurisdictionLocal : '',
    optionKeys: { name: 'name', value: 'jurisdiction_id' },
    shouldDisplay: inputData.localJurisdictions.length !== 0
  },
  {
    name: 'value',
    label: 'Value',
    type: 'Input',
    value: entityDetail.value,
    validationOptions:{
      customValidate: getInputValidationByValueType(inputData.valueType),
    },
    muiProps: {
      type: 'number',
      endAdornment: getEndAdornmentByValueType(inputData.valueType)
    },
    required: true,
  },
];
