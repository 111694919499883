import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AppBar, IconButton, Toolbar, Typography, makeStyles, Paper } from '@material-ui/core';
import { usePromiseTracker } from 'react-promise-tracker';
import LoadingElement from '../../../commonComponents/LoadingElement';
import { fetchSurveyById, resetSurveyWizardState, fetchSurveySectionInfo } from '../../../actions/surveyWizard/surveyWizard';
import { currentWizardSurvey } from '../../../selectors/surveyWizard/surveyWizard';
import { tags } from '../../../constants/promiseTracker';
import parseDateToDisplayable from '../../../utils/dateToDisplayableFormat';
import SurveySectionTabs from './wizardComponents/SurveySectionTabs';

/* IMPORT INDIVIDUAL RESPONSE WIDGETS FOR EACH SURVEY SUB-SECTION */
import ExpenseSection from './ExpenseSection';
import ProjectsSection from './ProjectsSection';
import DocumentsSection from './DocumentsSection';
import SubmitSection from './SubmitSection';

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    position: 'relative',
    border: `0.5px solid ${theme.palette.grey[300]}`,
    display: 'flex',
  },
  title: {
    color: theme.palette.black[800]
  },
  dateRange: {
    color: theme.palette.primary.main,
    marginLeft: '1em',
    marginTop: '0.25em',
    fontWeight: 600
  },
  dueDate: {
    marginLeft: 'auto',
    padding: '0.75em',
    backgroundColor: theme.palette.primary.veryLight,
    color: theme.palette.primary.main,
    fontWeight: 600
  }
}));

const dateFormat = `DD MMM YYYY`;
const getSurveyTitle = ({ entity, taxYear, surveyType }) => `${entity} ${taxYear} ${surveyType} Survey`;
const getDateRange = ({ startDate, endDate }) => `${parseDateToDisplayable(startDate, dateFormat)} to ${parseDateToDisplayable(endDate, dateFormat)}`;
const getDueDate = ({ dueDate }) => `Due ${parseDateToDisplayable(dueDate, dateFormat)}`;

const SurveyWizard = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { surveyId } = useParams();
  const match = useRouteMatch();
  const currentSurvey = useSelector(currentWizardSurvey);
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_SURVEY_BY_ID, delay: 500 });
  const history = useHistory();
  const navigateBack = () => history.push('/survey-central');

  useEffect(() => {
    dispatch(fetchSurveyById(parseInt(surveyId)));
    dispatch(fetchSurveySectionInfo(parseInt(surveyId)));
  }, [surveyId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetSurveyWizardState());
    };
  }, [dispatch])

  if (isDataFetchInProgress || !Object.keys(currentSurvey).length) {
    return <LoadingElement />
  }

  const getSurveyWizardRoutes = () => {
    return (
      <Switch>
        <Route path={`${match.path}/expenses`} component={ExpenseSection} />
        <Route path={`${match.path}/documents`} component={DocumentsSection} />
        <Route path={`${match.path}/projects`} component={ProjectsSection} />
        <Route path={`${match.path}/submit`} component={SubmitSection} />
      </Switch>
    );
  }

  return (
    <>
      <AppBar className={styles.appBar}>
        <Toolbar>
          <IconButton data-testid="wizard-back-button" onClick={navigateBack} edge="start" className={styles.backButton}>
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <Typography data-testid="title" className={styles.title} variant="h6" >
            {getSurveyTitle(currentSurvey)}
          </Typography>
          <Typography data-testid="date-range" className={styles.dateRange} variant="subtitle2">
            {getDateRange(currentSurvey)}
          </Typography>
          <Paper data-testid="due-date" elevation={0} className={styles.dueDate}>
            {getDueDate(currentSurvey)}
          </Paper>
        </Toolbar>
      </AppBar>
      <SurveySectionTabs />
      {getSurveyWizardRoutes()}
    </>
  );
};

export default SurveyWizard;
