import ServiceManager from './ServiceManager';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return ({
    tenants: state.common.tenants,
    selectedTenant: state.common.selectedTenant,
    containers: state.common.containers,
    selectedContainerId: state.common.selectedContainer,
    entities: state.common.entities,
    selectedEntity: state.common.selectedEntity,
  });
};

export default connect(mapStateToProps)(ServiceManager)
