import React, {useCallback, useState} from "react";
import { UseStyles } from "./SearchBar.styles";
import IconButton from "@material-ui/core/IconButton";
import { Search, ArrowDownward, CheckCircleOutline, ArrowUpward } from '@material-ui/icons';
import PropTypes from "prop-types";

const SearchBar = (props) => {
  const {
    placeholder,
    onChange = () => {},
    onSearch = () => {},
    onOrder = () => {},
    onSelect = () => {}
  } = props;
  const styles = UseStyles();

  const [value, setValue] = useState('');
  const [selectedAll, setSelectedAll] = useState(false);
  const [orderKind, setOrderKind] = useState('ASC');

  const onHandleInputChange = useCallback((e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  }, [onChange]);

  const onClickSearch = useCallback(() => {
    onSearch(value);
  }, [value, onSearch]);

  const onHandleOrder = useCallback(() => {
    const order = orderKind === 'ASC' ? 'DESC' : 'ASC';
    setOrderKind(order);
    onOrder();
  }, [onOrder, orderKind]);

  const onHandleSelectAll = useCallback(() => {
    setSelectedAll(!selectedAll);
    onSelect(!selectedAll);
  }, [onSelect, selectedAll]);

  return (
    <div className={styles.SearchBarWrapper}>
      <div className={styles.InputSearchWrapper}>
        <div className={styles.InputSearchBox}>
          <input value={value} placeholder={placeholder} onChange={onHandleInputChange} />
          <IconButton size={'small'} color="inherit" onClick={onClickSearch}>
            <Search />
          </IconButton>
        </div>
        <div className={styles.InputSearchBoxIcon}>
          <IconButton size={'small'} color="inherit" onClick={onHandleOrder}>
            { orderKind === 'ASC' ? <ArrowDownward /> : <ArrowUpward /> }
          </IconButton>
        </div>
      </div>
      <IconButton size={'small'} color="inherit" onClick={onHandleSelectAll}>
        <CheckCircleOutline />
      </IconButton>
    </div>
  );
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onChange: PropTypes.func,
  onOrder: PropTypes.func,
  onSelect: PropTypes.func
};

export default SearchBar;