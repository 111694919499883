import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TableAndCrud from '../TableAndCrud';
import { headConfig, rowsConfig } from './tableConfig';
import { fetchContainers } from '../../../actions/container';
import { getContainers } from '../../../selectors/containers';
import { getSelectedTenant } from '../../../selectors/tenants';
import ContainersCrud from './ContainersCrud';
import { makeStyles } from '@material-ui/core';
import { MAX_TABLE_HEIGHT } from './constants';
import Dials from '../../../commonComponents/Dials';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  }
}));

const Containers = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const selectedTenant = useSelector(getSelectedTenant, shallowEqual);
  const containers = useSelector(getContainers, shallowEqual);
  const tenantId = selectedTenant.tenant_id;

  useEffect(() => {
    dispatch(fetchContainers(tenantId));
  }, [dispatch, tenantId]);

  const totalContainers = containers && containers.length;
  const dialsConfig = [
    { type: 'default', title: 'All Containers', value: totalContainers }
  ];
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <CreateNewButton />
        <Dials dialConfig={dialsConfig} />
      </div>
    )
  };
  return (
    <div className={styles.container}>

      <h1>Containers</h1>
      <TableAndCrud
        tableHead={headConfig}
        tableData={containers}
        searchBy="name"
        searchByPlaceholder="Name"
        tableRows={rowsConfig}
        maxTableHeight={MAX_TABLE_HEIGHT}
        idKey="container_id"
        createNewButtonTitle="Add Container"
        renderComponentBeforeTable={renderComponentBeforeTable}
      >
        <ContainersCrud />
      </TableAndCrud>
    </div>
  );
};

export default Containers;
