import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import getValueFromEvent from '../../../../utils/getValueFromEvent';

// ACTIONS
import {
  deleteExpense,
  updateExpense,
  createExpense,
  getEmployeesForExpense,
  setHoursPerSource,
  clearHoursPerSource
} from '../../../../actions/expense';
import { setHourSources } from '../../../../actions/common';

// SELECTORS
import {
  getExpenseFieldsById,
  getEmployeesForExpense as getEmployees,
  getAllocatedProjects,
  getHoursPerSource,
  getDirectiveClassifications,
} from '../../../../selectors/expense';
import { getSelectedEntityObject } from '../../../../selectors/entities'
import { getJurisdictionsByParentId, getHourSources } from '../../../../selectors/common';

// COMPONENTS
import ProjectsAllocation from '../ProjectsAllocation';

// CONSTANTS
import { CRUD_TYPES, EMPLOYEE_FIELD, MANDATORY_FIELDS, HOUR_SOURCES_BY_NAME, NECESSARY_ROLES_PER_COMPONENT } from '../constants'

// HELPERS
import dateToMysqlFormat from '../../../../utils/dateToMysqlFormat'
import { buildExpenseInputTypes } from './buildExpenseInputTypes';
import FormBuilder from '../../../../commonComponents/FormBuilder';
import useRoleRestriction from "../../../../hooks/useRoleRestriction";

const expenseInitialState = {
  is_asc_730: 0,
  directive_qualified_percent: 0,
  include_in_directive: 0,
  higher_education_contract: 0,
  on_campus: 0,
};

const defaultEmployeeHours = 2048;

const ExpenseCrud = (props) => {
  const dispatch = useDispatch();
  const crudInputs = useSelector(getExpenseFieldsById(props.expense_type_ref_id), shallowEqual);
  const selectedEntity = useSelector(getSelectedEntityObject, shallowEqual);
  const directiveClassifications = useSelector(getDirectiveClassifications, shallowEqual);
  const { jurisdiction_id } = selectedEntity.jurisdiction;
  const employees = useSelector(getEmployees, shallowEqual);
  const allocatedProjects = useSelector(getAllocatedProjects, shallowEqual)
  const jurisdictions = useSelector(getJurisdictionsByParentId(jurisdiction_id), shallowEqual)
  const [expense, setExpense] = React.useState(expenseInitialState);
  const [percentageError, setPercentageError] = React.useState({});
  const isEmployee = crudInputs.some(({ id }) => id.includes(EMPLOYEE_FIELD));
  const isMandatory = (id) => MANDATORY_FIELDS.some(field => field === id)
  const hourSources = useSelector(getHourSources, shallowEqual);
  const hoursPerSource = useSelector(getHoursPerSource, shallowEqual);
  const hasRoleAccessTo = useRoleRestriction(NECESSARY_ROLES_PER_COMPONENT);

  useEffect(() => {
    dispatch(clearHoursPerSource());

    if (props.crudType !== CRUD_TYPES.create) {
      const expenseValues = { ...props };
      delete expenseValues.crudType;
      delete expenseValues.closeCrudPanel;
      delete expenseValues.currencyCode;
      delete expenseValues.resetExpenseTaxYearFilter;
      setExpense(expenseValues);
    } else {
      setExpense(expenseInitialState)
    }
  }, [dispatch, props]);

  useEffect(() => {
    if (isEmployee && selectedEntity) {
      dispatch(getEmployeesForExpense(selectedEntity.entity_id));
    }
  }, [dispatch, isEmployee, selectedEntity]);

  useEffect(() => {
    dispatch(setHourSources());
  }, [dispatch]);

  useEffect(() => {
    if(isEmployee && expense.employee_id) {
      dispatch(setHoursPerSource(expense.employee_id));
    }
  }, [dispatch, isEmployee, expense.employee_id]);

  useEffect(() => {
    const setEmployeeRdHoursBasedOnSource = () => {
      if(hoursPerSource && expense !== expenseInitialState) {
        const source = hoursPerSource.find(source => source.sourceRefId === expense.source_ref_id);
        const hours = (source && source.hours) || null;
        setExpense({ ...expense, 'employee_rd_hours': hours })
      }
    }

    setEmployeeRdHoursBasedOnSource();
  // eslint-disable-next-line
  }, [hoursPerSource]);

  const onChange = (e, name) => {
    const value = e.target.value;

    if (typeof name !== 'string') {
      name = e.target.name;
    }

    if (name === 'employee_id') {
      let values = {};
      Object.keys(expense).forEach(key => {
        const isEmployee = key.includes(EMPLOYEE_FIELD)
        if (!isEmployee) {
          values = { ...values, [key]: expense[key] }
        }
      })
      setExpense({ ...values, [name]: value });
    }
    else {
      const inputValue = getValueFromEvent(e);
      setExpense({ ...expense, [name]: inputValue })
    }
  }

  const resolveDisabledField = (isEmployeeField, crudType, id) => {
    const { employee_id } = expense;
      return (
        (employee_id && isEmployeeField && crudType === CRUD_TYPES.create) ||
        id === 'local_qualified_expense_amount' ||
        id === 'country_qualified_expense_amount' ||
        id === 'directive_qualified_percent' ||
        id === 'directive_country_qualified_expense_amount' ||
        (id === 'qualified_percent' && (expense.source_ref_id === HOUR_SOURCES_BY_NAME.survey.sourceRefId || expense.source_ref_id === HOUR_SOURCES_BY_NAME.resource.sourceRefId)) ||
        (id === 'employee_rd_hours' && expense.source_ref_id !== HOUR_SOURCES_BY_NAME.custom.sourceRefId)
      )
    }


  const resolveIsMandatory = (id) => {
    const { employee_id } = expense;
    const employeeSelected = employee_id;
    const isEmployeeNameField = id === 'employee_name';
    if (!employeeSelected && isEmployeeNameField) {
      return true;
    }

    return isMandatory(id);
  };

  const resolveValue = id => {
    const {
      country_total_expense_amount,
      local_total_expense_amount,
      directive_classification,
      is_asc_730,
      directive_qualified_percent } = expense;

    const expenseValue = expense[id];
    let returnExpenseValue = expenseValue;

    if (id === 'country_qualified_expense_amount' && country_total_expense_amount !== undefined && country_total_expense_amount !== null) {
      returnExpenseValue = country_total_expense_amount / 100 * resolveValue('qualified_percent');
    }

    if (id === 'local_qualified_expense_amount' && local_total_expense_amount !== undefined && local_total_expense_amount !== null) {
      returnExpenseValue = local_total_expense_amount / 100 * resolveValue('qualified_percent')
    }

    const hasDirectiveClassification = directive_classification !== undefined && directive_classification !== null;
    const isAsc730 = is_asc_730;
    if (hasDirectiveClassification || isAsc730) {
      if (id === 'directive_country_qualified_expense_amount' && country_total_expense_amount !== undefined && country_total_expense_amount !== null) {
        returnExpenseValue = country_total_expense_amount / 100 * directive_qualified_percent;
      }
    }

    if (returnExpenseValue === undefined || returnExpenseValue === null) {
      returnExpenseValue = '';
    }

    if (id === 'qualified_percent') {
      returnExpenseValue =  isNaN(expenseValue) ? 0 : expenseValue;
    }

    return returnExpenseValue;
  }

  const formatPayload = () => {
    let formatedExpense = {};
    Object.keys(expense).forEach(key => {
      const isDateType = crudInputs.filter(({ type, id }) => id === key && type === 'date').length
      const isPercentageType = crudInputs.filter(({ type, id }) => id === key && type === 'percentage').length
      if (expense[key] !== undefined && expense[key] !== '-') {
        if (isDateType) {
          formatedExpense = { ...formatedExpense, [key]: dateToMysqlFormat(expense[key]) }
        }
        else if (isPercentageType) {
          formatedExpense = { ...formatedExpense, [key]: expense[key] / 100 }
        }
        else {
          formatedExpense = { ...formatedExpense, [key]: expense[key] }
        }
      }
    })
    return formatedExpense;
  }

  const handleUpdate = () => {
    const expensePayload = formatPayload();
    const allocatedProjectsPayload = allocatedProjects.filter(({ projectId, projectPercent }) => projectId && projectPercent);
    dispatch(updateExpense(expensePayload, allocatedProjectsPayload, expense.expense_id))
    props.resetExpenseTaxYearFilter();
    props.closeCrudPanel();
  }

  const handleCreate = () => {
    const payload = formatPayload()
    const { expense_type_ref_id } = props;
    const { entity_id } = selectedEntity;
    dispatch(createExpense({ ...payload, expense_type_ref_id, entity_id }))
    props.resetExpenseTaxYearFilter();
    props.closeCrudPanel();
  }

  const handleDelete = () => {
    const { expense_id, expense_type_ref_id } = props;
    const { entity_id } = selectedEntity;
    dispatch(deleteExpense(expense_id, expense_type_ref_id, entity_id, allocatedProjects))
    props.resetExpenseTaxYearFilter();
    props.closeCrudPanel();
  }

  const handlePercentageChange = (e) => {
    const { value, name } = e.target;
    setPercentageError({});
    setExpense({ ...expense, [name]: value });

    if (Number(value) > 100) {
      setPercentageError({ ...percentageError, [name]: `Max value allowed 100` });
    } else if (Number(value) < 0) {
      setPercentageError({ ...percentageError, [name]: `Min value allowed 0` });
    }
  };

  const handleBooleanChange = (event) => {
    const { value, name } = event.target;
    setExpense({ ...expense, [name]: value });
  };

  const handleHourSourceChange = (event) => {
    const { value, name } = event.target;

    if (hoursPerSource) {
      const source = hoursPerSource.find(source => source.sourceRefId === value);
      const hours = (source && source.hours) || null;
      const totalHours = (expense.employee_total_hours && Number.isInteger(expense.employee_total_hours)) ? expense.employee_total_hours : defaultEmployeeHours;

      setExpense({ ...expense, 'qualified_percent': ((hours / totalHours) * 100).toFixed(2), 'employee_rd_hours': hours, [name]: value });
    } else {
      setExpense({ ...expense, [name]: value });
    }
  }

  const handleRdHoursInputChange = (event) => {
    let { value, name } = event.target;

    if (expense.source_ref_id === 1) {
      if (value === "") {
        value = null
      }

      setExpense({ ...expense, [name]: value });
    }
  }

  const handleClassificationChange = (event) => {
    const { value, name } = event.target;

    if (props.expense_type_ref_id === 1 && (value === 'QIC' || value === '1LM')) {
      setExpense({ ...expense, [name]: value, directive_qualified_percent: 95 });
    } else if (props.expense_type_ref_id === 1 && value === 'ULM') {
      setExpense({ ...expense, [name]: value, directive_qualified_percent: 100 });
    } else {
      setExpense({ ...expense, [name]: value });
    }
  }

  const handleQualifiedPercentBlur = () => {
    const { qualified_percent, employee_rd_hours, employee_total_hours } = expense;

    // If there is a qualified percent, no calc
    // If qualified_percent is empty and we have both rd and total hours then do calc
    const qualifiedPercent = (qualified_percent === null || qualified_percent === undefined || qualified_percent === "");
    const employeeRdHours = (employee_rd_hours !== null && employee_rd_hours !== undefined && employee_rd_hours !== "");
    const employeeTotalHours = (employee_total_hours !== null && employee_total_hours !== undefined && employee_total_hours !== "");

    if (qualifiedPercent && employeeRdHours && employeeTotalHours) {
      const qualifiedPercent = ((employee_rd_hours / employee_total_hours) * 100).toFixed(2);
      setExpense({ ...expense, 'qualified_percent': qualifiedPercent });
    }
  }

  const handleIsAsc730Change = (event) => {
    const { value, name } = event.target;

    if ((props.expense_type_ref_id === 2 || props.expense_type_ref_id === 4) && value === true) {
      setExpense({ ...expense, [name]: value, directive_qualified_percent: 100 });
    } else {
      setExpense({ ...expense, [name]: value, directive_qualified_percent: 0 });
    }
  };

  const shouldDisplayFormActions = props.crudType === 'edit' && hasRoleAccessTo.expensesTypesFormActionsButton;

  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleCreate,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleUpdate,
      color: 'secondary',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: shouldDisplayFormActions,
      onClick: handleDelete,
      color: 'secondary',
      shouldAskConfirmation: true
    },
  ];

  const inputFields = buildExpenseInputTypes(expense, {
    jurisdictions,
    employees,
    isEmployee,
    resolveDisabledField,
    resolveIsMandatory,
    crudType: props.crudType,
    isCreateCrud: props.crudType === CRUD_TYPES.create,
    isEditCrud: props.crudType === CRUD_TYPES.edit,
    currencyCode: props.currencyCode,
    resolveValue: resolveValue,
    expenseInputs: crudInputs,
    handlePercentageChange,
    handleBooleanChange,
    percentageError: percentageError,
    hourSources,
    handleHourSourceChange,
    handleRdHoursInputChange,
    handleQualifiedPercentBlur,
    directiveClassifications,
    handleClassificationChange,
    handleIsAsc730Change,
  });

  return (
    <FormBuilder
      onChange={onChange}
      fields={inputFields}
      formActions={formActions}
    >
      {props.crudType === CRUD_TYPES.edit && (
        <ProjectsAllocation
          expenseId={expense.expense_id}
          qualifiedPercent={expense.qualified_percent !== '-' ? Number(resolveValue('qualified_percent')) : 0}
        />
      )}
    </FormBuilder>
  )
};

ExpenseCrud.propTypes = {
  crudType: PropTypes.string,
  closeCrudPanel: PropTypes.func,
  expense_type_ref_id: PropTypes.number,
  xpense_id: PropTypes.number,
}

export default ExpenseCrud
