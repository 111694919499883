import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableAndCrud from '../TableAndCrud';
import { headConfig, rowsConfig } from './table.config';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTenants } from '../../../actions/tenant';
import { TENANT_STATUSES, MAX_TABLE_HEIGHT } from './constants';
import TenantsCrud from './TenantsCrud';
import LoadingElement from '../../../commonComponents/LoadingElement';
import { usePromiseTracker } from "react-promise-tracker";
import { tags } from '../../../constants/promiseTracker';
import Dials from '../../../commonComponents/Dials';

const statuses = [
  'Prospect',
  'Setup',
  'Active',
  'Archived',
];

const mapTenantStatuses = (tenants) => {
  if (!tenants) {
    return null;
  }

  return tenants.map(tenant => ({
    ...tenant,
    status: TENANT_STATUSES[tenant.status]
  }));
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  }
}));

const Tenants = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  let tenants = useSelector(state => state.tenants.list);
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_TENANTS, delay: 0 });
  tenants = mapTenantStatuses(tenants);

  useEffect(() => {
    dispatch(fetchTenants());
  }, [dispatch]);

  if (!tenants || isDataFetchInProgress) {
    return <LoadingElement />;
  }

  const totalTenats = tenants && tenants.length;
  const dialsConfig = [
    { type: 'default', title: 'All Clients', value: totalTenats }
  ];
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <CreateNewButton />
        <Dials dialConfig={dialsConfig} />
      </div>
    )
  };
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Clients</h1>
      <TableAndCrud
        tableHead={headConfig}
        tableData={tenants}
        searchBy={'name'}
        tableRows={rowsConfig}
        statusOptions={statuses}
        maxTableHeight={MAX_TABLE_HEIGHT}
        idKey='tenant_id'
        createNewButtonTitle="Add Client"
        renderComponentBeforeTable={renderComponentBeforeTable}
      >
        <TenantsCrud />
      </TableAndCrud>
    </div>
  );
};
export default Tenants;
