const defaultMessage = 'This field has to be a four digit year';
export const checkIsIntegerYear = (value, message = defaultMessage) => {
  
  
  const isInteger = Number.isInteger(Number(value));
  const length = Number(value).toString().length;
  
  if (!isInteger || Number(value) > 0) {
    if (!isInteger || length !== 4) {
      return message;
    }
  }
  
};
