import { APP_SECTIONS } from '../../constants/appSections'
import { roles } from '../../constants/roles';

const { FULL_ACCESS_ADMIN, PS_ASSOCIATE, ACCOUNT_EXECUTIVE, PS_MANAGER, LIMITED_ACCESS_ADMIN, SUPER_ACCESS_ADMIN, DIY_CLIENT } = roles;

const sidenavItems = [
  {
    title: 'ADMIN',
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, SUPER_ACCESS_ADMIN]
  },
  {
    text: 'Clients',
    to: `${APP_SECTIONS.service_manager.baseUrl}/clients`,
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN]
  },
  {
    text: 'Containers',
    to: `${APP_SECTIONS.service_manager.baseUrl}/containers`,
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN]
  },
  {
    text: 'Users',
    to: `${APP_SECTIONS.service_manager.baseUrl}/user-manager`,
    roles: [FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, SUPER_ACCESS_ADMIN]
  },
  {
    title: 'WORKSPACE',
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, PS_ASSOCIATE, DIY_CLIENT]
  },
  {
    text: 'Entities',
    to: `${APP_SECTIONS.service_manager.baseUrl}/entities`,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, PS_ASSOCIATE, DIY_CLIENT]
  },
  {
    text: 'Study Central',
    to: `${APP_SECTIONS.service_manager.baseUrl}/study-central`,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, PS_ASSOCIATE, DIY_CLIENT]
  },
  {
    text: 'Projects',
    to: `${APP_SECTIONS.service_manager.baseUrl}/projects`,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, PS_ASSOCIATE, DIY_CLIENT]
  },
  {
    text: 'Timesheet',
    to: `${APP_SECTIONS.service_manager.baseUrl}/timesheet`,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, PS_ASSOCIATE]
  },
  {
    text: 'Expenses',
    to: `${APP_SECTIONS.service_manager.baseUrl}/expenses`,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, PS_ASSOCIATE, DIY_CLIENT]
  },
  {
    text: 'Surveys',
    to: `${APP_SECTIONS.service_manager.baseUrl}/surveys`,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, PS_MANAGER, PS_ASSOCIATE, ACCOUNT_EXECUTIVE, DIY_CLIENT],
  },
  {
    text: 'Reports',
    to: `${APP_SECTIONS.service_manager.baseUrl}/reports`,
    roles: [PS_ASSOCIATE, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, ACCOUNT_EXECUTIVE, PS_MANAGER, PS_ASSOCIATE]
  }
];

export default sidenavItems
