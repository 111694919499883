import {
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  EDIT_USER_FAIL,
  CREATE_USER_FAIL,
  SET_SELECTED_USER,
  SET_SELECTED_USER_FIELD,
  DELETE_USER_FAIL,
  CLEAN_USER,
  TOGGLE_USER_SURVEY_WELCOME_SUCCESS,
  TOGGLE_USER_SURVEY_WELCOME_FAIL,
  GET_LIST_SURVEY_LEADERS
} from '../constants/actionTypes/users';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message';
import history from '../history';

export const getUsers = () => async dispatch => {
  try {
    const data = await apiClient({
      method: 'get',
      url: '/user',
    });
    return dispatch(getUsersSuccess(data));
  } catch (error) {
    dispatch(getUsersFail(error.message));
    dispatch(handleError(error));
  }
};

export const getUsersSuccess = (data) => ({
  type: GET_USERS_SUCCESS,
  data,
});

export const getUsersFail = (error) => ({
  type: GET_USERS_FAIL,
  error,
});

export const getUserProjects = (user) => async dispatch => {
  try {
    const projects = await apiClient({
      method: 'get',
      url: `/user/assigned-projects?userId=${user.user_id}`,
    });
    return dispatch(setSelectedUser({ ...user, projects, selectedProjects: projects }));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const setSelectedUser = (user) => ({
  type: SET_SELECTED_USER,
  user,
});

export const setSelectedUserField = (key, value) => ({
  type: SET_SELECTED_USER_FIELD,
  value,
  key,
});

export const editUser = (user, tenantId) => async dispatch => {
  try {
    const data = await apiClient({
      method: 'patch',
      url: `/user/${user.user_id}?tenantId=${tenantId}`,
      data: user,
    });
    dispatch(showMessage('success', 'User updated successfully', 3000));
    return dispatch(getUsers(data));
  } catch (error) {
    dispatch(editUserFail(error.message));
    dispatch(handleError(error));
  }
};

export const editUserFail = (error) => ({
  type: EDIT_USER_FAIL,
  error,
});

export const createUser = (user) => async dispatch => {
  try {
    const data = await apiClient({
      method: 'post',
      url: '/user',
      data: user,
    });
    dispatch(showMessage('success', 'User created successfully', 3000));
    return dispatch(getUsers(data));
  } catch (error) {
    dispatch(createUserFail(error.message));
    dispatch(handleError(error));
  }
};

export const createUserFail = (error) => ({
  type: CREATE_USER_FAIL,
  error,
});

export const deleteUser = (userId) => async dispatch => {
  try {
    const data = await apiClient({
      method: 'delete',
      url: `/user/${userId}`,
    });
    dispatch(showMessage('success', 'User deleted successfully', 3000));
    return dispatch(getUsers(data));
  } catch (error) {
    dispatch(deleteUserFail(error.message));
    dispatch(handleError(error));
  }
};

export const deleteUserFail = (error) => ({
  type: DELETE_USER_FAIL,
  error,
});

export const cleanUser = () => ({
  type: CLEAN_USER,
});

export const toggleUserSurveyWelcomeSuccess = () => ({
  type: TOGGLE_USER_SURVEY_WELCOME_SUCCESS
});

export const toggleUserSurveyWelcomeFail = () => ({
  type: TOGGLE_USER_SURVEY_WELCOME_FAIL
});

export const toggleUserSurveyWelcome = () => async dispatch => {
  try {
    await apiClient({
      method: 'put',
      url: `/user/welcome`
    });
    history.push('/survey-central')
    history.go();

    dispatch(toggleUserSurveyWelcomeSuccess());
  } catch (err) {
    return dispatch(toggleUserSurveyWelcomeFail(err.message));
  }
};

export const resetTemporaryPassword = (userId) => async dispatch => {
  try {
    await apiClient({
      method: 'post',
      url: '/user/reset-expired-password',
      data: { userId }
    });
  
    dispatch(showMessage('success', 'Password reset successful', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const fetchLeadersSurvey = (tenantId) => async dispatch => {
  try {
    const data = await apiClient({
      method: 'get',
      url:`/user/survey-leaders?tenantId=${tenantId}`,
    });

    const usersWithLabel = data.map((user) => {
      user.label = `${user.email} (${user.username})`;
      return user;
    });

    return dispatch(getLeadersSurveySuccess(usersWithLabel));
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const getLeadersSurveySuccess = (data) => ({
  type: GET_LIST_SURVEY_LEADERS,
  data,
});
