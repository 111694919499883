import axios from 'axios';
import requestInterceptor from './requestInterceptor';
import store from '../store'
import { logout } from '../actions/auth';
import { EULA_REDIRECT } from '../constants/utils';
import history from '../history';

axios.interceptors.request.use(requestInterceptor);

export const apiClient = (params) => {
  const options = {
    method: params.method.toLowerCase(),
    url: params.url,
    baseURL: params.baseURL,
    data: params.data,
  };

  return axios.request(options)
    .then(res => res.data)
    .catch(err => {
      const { statusText } = (err.response && err.response.data) || {};
      const { message } = (err.response && err.response.data) || {};
      const { status } = err.response || {};
      const { auth } = store.getState().auth;
      const isAuthenticated = auth !== null;
      if (isAuthenticated && status === 403 && statusText === 'Unauthorized Red Trac Api Route') {
        store.dispatch(logout());
      } else if (isAuthenticated && status === 403 && message === 'Invalid Working Container.') {
        store.dispatch(logout());
      } else if (status === 403 && statusText === 'Must accept EULA before accessing this page.') {
        history.push(EULA_REDIRECT);
        history.go();
      }
      throw err;
    });
};
