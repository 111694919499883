import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Bool = ({ name, value, onChange, label, muiProps, isInvalid, singleSelection }) => {
  const handleChange = (event) => {
    const { target } = event;

    onChange({ target: { name, value: target.checked } })
  };

  const checkboxSelect = () => {
      if(singleSelection) {
          return singleSelection === name;
      } else {
          return value
      }
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checkboxSelect()}
          onChange={handleChange}
          value={singleSelection ? value.toString() : value}
          name={name}
          color="primary"
          error={isInvalid}
          {...muiProps}
        />
      }
      label={label}
    />
  );
};

export default Bool;
