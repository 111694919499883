import React, { useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ExpensesDownload from '../../Expenses/ExpenseType/ExpensesDownload';
import { usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../../constants/promiseTracker';
import FileUpload from '../../../../commonComponents/FileUpload';
import downloadBuffer from '../../../../utils/downloadBuffer';
import ExpenseValidationModal from '../../Expenses/ExpenseValidationModal';

// ACTIONS
import { uploadBulkProjectSpreadsheet, clearValidationErrors } from '../../../../actions/project';

// SELECTORS
import { getSelectedEntity } from '../../../../selectors/entities';
import { getSelectedContainer } from '../../../../selectors/containers';
import { getProjectUploadValidationErrors } from '../../../../selectors/projects';

// CONSTANTS
import { handleError } from '../../../../actions/error';
import { showMessage } from '../../../../actions/message';
import { apiClient } from '../../../../api/apiClient';

import { useStyles } from './styles';

const ProjectFileHandler = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [isProjectTemplateDownloading, setIsProjectTemplateDownloading] = useState(false);
  const [isProjectDownloading, setIsProjectDownloading] = useState(false);
  const entityId = useSelector(getSelectedEntity, shallowEqual);
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual);
  const validationErrors = useSelector(getProjectUploadValidationErrors, shallowEqual);
  const { promiseInProgress: isBulkUploadInProgress } = usePromiseTracker({ area: tags.EXPENSES_BULK_UPLOAD, delay: 0 });

  const handleFileUpload = e => {
    const [file] = e.target.files;

    if (!file || file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return dispatch(showMessage('error', 'Please upload an excel (.xlsx) worksheet only'));
    }

    dispatch(uploadBulkProjectSpreadsheet(file, entityId, selectedContainer.tax_year));
  };

  const handleProjectFileDownload = async () => {
    try {
      setIsProjectDownloading(true);

      let queryParams = `?entityId=${entityId}`;
      if (selectedContainer.tax_year) {
        queryParams += `&projectTaxYear=${selectedContainer.tax_year}`;
      }

      const fileDownload = await apiClient({
        method: 'get',
        url: `/project/download${queryParams}`,
      });

      setIsProjectDownloading(false);

      if (fileDownload.Body.data.length) {
        downloadBuffer(fileDownload, `projects_list_${Date.now()}.xlsx`);
        return;
      }

      dispatch(showMessage('error', 'There are no projects to download in this section.'));
    } catch (err) {
      setIsProjectDownloading(false);
      dispatch(handleError(err));
    }
  };

  const handleProjectTemplateDownload = async () => {
    try {
      setIsProjectTemplateDownloading(true);

      const fileDownload = await apiClient({
        method: 'get',
        url: `/project/template?taxYear=${selectedContainer.tax_year}&entityId=${entityId}`,
      });

      setIsProjectTemplateDownloading(false);

      if (fileDownload.Body.data.length) {
        downloadBuffer(fileDownload, `project_template_${Date.now()}.xlsx`);
      }
    } catch (err) {
      setIsProjectTemplateDownloading(false);
      dispatch(handleError(err));
    }
  };

  const closeErrorModal = () => {
    dispatch(clearValidationErrors());
  }

  return (
    <div className={styles.container}>
      {validationErrors.length ? <ExpenseValidationModal errors={validationErrors} handleClose={closeErrorModal}/> : null}
      <div className={styles.ButtonContainer}>
        <div className={styles.ButtonContainerChild}>
          <FileUpload isLoading={isBulkUploadInProgress} onChange={handleFileUpload} buttonText={"Upload Projects"} type="round" />
        </div>
        <div className={styles.ButtonContainerChild}>
          <ExpensesDownload
            onClick={handleProjectFileDownload}
            isLoading={isProjectDownloading}
            title={'Download Projects'}
          />
        </div>
        <div className={styles.ButtonContainerChild}>
          <ExpensesDownload
            onClick={handleProjectTemplateDownload}
            isLoading={isProjectTemplateDownloading}
            title={'Download Project Template'}
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectFileHandler;
