import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listByTaxYear } from '../../../../selectors/employees';
import { Add } from '@material-ui/icons';
import { apiClient } from '../../../../api/apiClient';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../../constants/promiseTracker';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddEmployeeDropdown from './AddEmployeeDropdown';
import useStyles from './styles';
import { showMessage } from '../../../../actions/message';

const AddEmployeeButton = ({ onEmployeeAdd, timesheets, survey }) => {
  const employees = useSelector(listByTaxYear);
  const dispatch = useDispatch()
  const { surveyId } = survey;
  const [shouldDisplayDropdown, setShouldDisplayDropdown] = useState(false);
  const { promiseInProgress: isEmployeeAdditionInProggress } = usePromiseTracker({ area: tags.ADD_EMPLOYEE_TO_SURVEY, delay: 500 });

  const styles = useStyles();

  const handleAddEmployeeClick = () => {
    setShouldDisplayDropdown(true);
  };

  const handleEmployeeOptionClick = async (event, employee) => {
    setShouldDisplayDropdown(false)
    await trackPromise(
      apiClient({
        method: 'patch',
        url: `/survey/${surveyId}/employees/add`,
        data: {
          employeeId: employee.id
        }
      })
      ,tags.ADD_EMPLOYEE_TO_SURVEY
    );
    dispatch(showMessage('success', 'Employee added'))
    onEmployeeAdd();
  };

  const AddEmployeeButton = () => (
    <>
      <span onClick={handleAddEmployeeClick} className={styles.addEmployeeButton} data-testid="addEmployeeButton">
        <Add />
        <span>Add Employee</span>
        {isEmployeeAdditionInProggress && (
          <CircularProgress size={24} />
        )}
      </span>
    </>
  );

  return (
    shouldDisplayDropdown
      ? <AddEmployeeDropdown onClose={() => setShouldDisplayDropdown(false)} employees={employees} timesheets={timesheets} onEmployeeOptionClick={handleEmployeeOptionClick} />
      : <AddEmployeeButton />
  );
};

export default AddEmployeeButton;
