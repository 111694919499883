import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import LoadingModal from './LoadingModal';
import ResultModal from "./ResultModal";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSelectedCredit, getCalculationModalState } from "../../../../../selectors/credits";
import { setCalculationModalState } from '../../../../../actions/credit';

const CreditCalculationModal = () => {
  const credit = useSelector(getSelectedCredit, shallowEqual);
  const dispatch = useDispatch();
  const calculationModalState = useSelector(getCalculationModalState, shallowEqual);
  const [minimumTimeHasPassed, setMinimumTimeHasPassed] = useState(false);

  const onModalClose = () => {
    dispatch(setCalculationModalState('closed'));
  };

  const onFinishAnimation = () => {
    setMinimumTimeHasPassed(true);
  }

  const modalContent = () => {
    if (calculationModalState === 'calculating') {
      return (<div><LoadingModal handleClose={onModalClose} handleComplete={onFinishAnimation} minimumAnimationTime={3500} /></div>);
    }
    if (calculationModalState === 'showResult') {
      if (minimumTimeHasPassed) {
        return (<div><ResultModal handleClose={onModalClose} credit={credit} /></div>);
      }
      return (<div><LoadingModal handleClose={onModalClose} handleComplete={onFinishAnimation} minimumAnimationTime={3500} /></div>);
    }
    return <div></div>;
  }

  return (
    <Modal
      open={(calculationModalState === 'calculating' || calculationModalState === 'showResult')}
      onClose={onModalClose}
    >
      { modalContent() }
    </Modal>
  );
};

export default CreditCalculationModal;
