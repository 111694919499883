import { createStore, applyMiddleware } from 'redux';
import throttle from 'lodash.throttle';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk'
import reducers from './reducers';
import { loadState, saveState } from './utils/localStorage';
const persistedState = loadState();

const composeEnhancers = composeWithDevTools({
    trace: true,
    traceLimit: 25
});

const store = createStore(
  reducers,
  persistedState,
  composeEnhancers(
      applyMiddleware(
        thunk,
      ),
  ),
)

store.subscribe(throttle(() => {
  saveState({
    auth: store.getState().auth,
    config: store.getState().config,
    common: {
      selectedEntity: store.getState().common.selectedEntity,
      selectedContainer: store.getState().common.selectedContainer,
      selectedTenant: store.getState().common.selectedTenant,
      selectedCredit: store.getState().common.selectedCredit,
      canContainerRoll: store.getState().common.canContainerRoll,
      selectedEntityProjects: [],
    }
  });
}, 1000));

export default store;
