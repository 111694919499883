import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dials from '../../../commonComponents/Dials';

// ACTIONS
import { getUsers, cleanUser } from '../../../actions/users';
import { selectContainer } from '../../../actions/container';
import { selectEntity } from '../../../actions/entities';

// SELECTORS
import { getUsersForTable } from '../../../selectors/users';
import { getRoleData } from '../../../selectors/auth';

// COMPONENTS
import TableAndCrud from '../TableAndCrud';
import UserCrud from './UserCrud';
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import EntitiesDropdown from '../../GlobalDropdowns/EntitiesDropdown';

// CONSTANTS
import { headConfig, rowsConfig } from './tableConfig';
import { fetchProjects } from '../../../actions/project';
import { getSelectedEntity } from '../../../selectors/entities';
import { apiClient } from '../../../api/apiClient';
import { MAX_TABLE_HEIGHT } from '../constants'

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  }
}));

const Users = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const tableData = useSelector(getUsersForTable, shallowEqual);
  const [roles, setRoles] = useState([]);
  const userRoles = useSelector(getRoleData)
  const entityId = useSelector(getSelectedEntity, shallowEqual);

  useEffect(() => {
    if (entityId) {
      dispatch(fetchProjects(entityId));
    }
  }, [dispatch, entityId]);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useEffect(() => {
    const fetchUserRoles = async () => {
      let rolesResult = await apiClient({
        method: 'get',
        url: '/user/roles',
      });
      
      setRoles(rolesResult);
    };

    fetchUserRoles();
  }, [userRoles]);

  const handleButtonClickEffect = () => {
    dispatch(cleanUser());
  };

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId));
  };

  const handleEntitySelect = (value) => {
    const entityId = parseInt(value);
    dispatch(selectEntity(entityId));
  };

  const totalUsers = tableData && tableData.length;
  const dialsConfig = [
    { type: 'default', title: 'All Users', value: totalUsers }
  ];
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <CreateNewButton />
        <Dials dialConfig={dialsConfig} />
      </div>
    )
  };
  return (
    <>
      <ContainersDropdown onChange={handleContainerSelect} />
      <EntitiesDropdown onChange={handleEntitySelect} />
      <div className={styles.container}>
        <h1 className={styles.title}>Users</h1>
        <TableAndCrud
          tableHead={headConfig}
          tableData={tableData}
          searchBy={'username'}
          searchByPlaceholder="Username"
          tableRows={rowsConfig}
          handleButtonClickEffect={handleButtonClickEffect}
          maxTableHeight={MAX_TABLE_HEIGHT}
          idKey="user_id"
          createNewButtonTitle="Add User"
          renderComponentBeforeTable={renderComponentBeforeTable}
        >
          <UserCrud
            allRoles={roles}
          />
        </TableAndCrud>
      </div>
    </>
  );
};

export default Users;
