import React from "react";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { useStyles, AntSwitch } from './styles';
import Tooltip from "@material-ui/core/Tooltip";

const ApproveDirectiveSection = ({ title, body, onToggleComplete, isCompleted, disabled }) => {
  const classes = useStyles();

  const toggleChecked = () => {
    if (!disabled) {
      onToggleComplete(!isCompleted);
    }
  };

  return(
    <Card variant="outlined" className={classes.cardMargin}>
      <CardHeader
        title={
          <Typography variant="subtitle2" component="h2">
            {title}
          </Typography>
        }
        action={
          <Tooltip disableHoverListener={!disabled} title={'You must answer all questions first'} arrow>
            <Typography className={classes.switch} component="div">
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>
                  <AntSwitch checked={Boolean(isCompleted)} onChange={toggleChecked} name="checked"/>
                </Grid>
                <Grid item>{isCompleted ? 'Completed' : 'In Progress'}</Grid>
              </Grid>
            </Typography>
          </Tooltip>
        }
      />

      <CardContent className={classes.cardContentHead}>
        <Typography variant="body2" component="p" color="textSecondary">
          { body }
        </Typography>
      </CardContent>
    </Card>
  )
};

export default ApproveDirectiveSection;