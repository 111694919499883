import {
  FETCH_ENTITY_GROUPS_SUCCESS,
  SELECT_ENTITY_GROUP_CRUD,
  SET_SELECTED_ENTITY_GROUP_FIELD,
} from '../constants/actionTypes/entityGroups';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message';

export const fetchEntityGroups = (containerId) => async (dispatch) => {
  try {
    const entityGroups = await apiClient({
      method: 'get',
      url: `/entity-group?containerId=${containerId}`,
    });
    dispatch(fetchEntityGroupsSuccess(entityGroups));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const fetchEntityGroupsSuccess = (entityGroups) => ({
  type: FETCH_ENTITY_GROUPS_SUCCESS,
  entityGroups,
});

export const editEntityGroup = (entityGroupId, containerId, body) => async (dispatch) => {
  try {
    await apiClient({
      method: 'patch',
      url: `/entity-group/${entityGroupId}`,
      data: body,
    });
    await dispatch(fetchEntityGroups(containerId));
    dispatch(showMessage('success', 'Entity group updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const deleteEntityGroup = (entityGroupId, containerId) => async (dispatch) => {
  try {
    await apiClient({
      method: 'delete',
      url: `/entity-group/${entityGroupId}`,
    });
    await dispatch(fetchEntityGroups(containerId));
    dispatch(showMessage('success', 'Entity group deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const createEntityGroup = (body) => async (dispatch) => {
  const { containerId } = body;
  try {
    await apiClient({
      method: 'post',
      url: '/entity-group',
      data: body,
    });
    await dispatch(fetchEntityGroups(containerId));
    dispatch(showMessage('success', 'Entity group created successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const setSelectedEntityGroupField = (key, value) => ({
  type: SET_SELECTED_ENTITY_GROUP_FIELD,
  value,
  key,
});

export const selectEntityGroupCrud = (selectedEntityGroup) => ({
  selectedEntityGroup,
  type: SELECT_ENTITY_GROUP_CRUD,
});
