import React from 'react';

import { useStyles } from './styles';

export const NavCard = ({ cardTitle, isActive, CardIcon, handleSetCurrentTab, isCompleted }) => {
  const styles = useStyles();

  return (
    <div className={styles.navCard} data-is-active={isActive} onClick={handleSetCurrentTab}>
      <CardIcon className={styles.icon} />

      <ul>
        <li className={styles.requirementNote}>Required</li>
        <li>{cardTitle}</li>
      </ul>

      <span className={styles.status}>{isCompleted ? 'Completed' : 'In Progress'}</span>
    </div>
  );
};
