import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ApproveDirectiveSection from '../../../../commonComponents/CreditDetails/ApproveDirectiveSection';
import DetailFrame from '../../../../commonComponents/CreditDetails/DetailFrame'
import Grid from "@material-ui/core/Grid";
import CheckRow from "./CheckRow";
import { PlaylistAddCheck } from '@material-ui/icons';
import { itemLetters } from '../constants';
import {
  updateOrCreateAnswers,
  changeSectionCompletionStatus,
  handleAnswerChange
} from '../../../../actions/creditDetail';
import { getCurrentCreditDetailQuestionAnswers } from '../../../../selectors/creditDetail';

export const DocumentationReview = ({ section, creditId }) => {
  const dispatch = useDispatch();
  const { creditDetailSectionRefId, isCompleted } = section;
  const questions = useSelector(getCurrentCreditDetailQuestionAnswers, shallowEqual);
  const questionEntries = Object.entries(questions);

  const postCreditDetailSectionAnswer = (questionId, answer) => {
    const dataAnswerDetail = {
      creditId: creditId,
      creditDetailQuestionId: questionId,
      answer
    };

    dispatch(updateOrCreateAnswers(dataAnswerDetail));
  };

  const handleCompleteSectionClick = (statusComplete) => {
    const payload = {
      creditId: creditId,
      creditDetailSectionRefId: creditDetailSectionRefId,
      isCompleted: statusComplete
    };
    dispatch(changeSectionCompletionStatus(payload));
  }

  const checkAreAllQuestionsAnswered = () => {
    const checkUnansweredQuestions = (question) => question[1].answer.value === false;
    return !questionEntries.some(checkUnansweredQuestions)
  }

  const handleCheck = (questionId, answer) => {
    dispatch(handleAnswerChange(answer, questionId));
    postCreditDetailSectionAnswer(questionId, answer);
  };

  const ChecklistComponent = () => (
    questionEntries.map((questionEntry, index) => {
      const questionData = questionEntry[1];
      return (
        <CheckRow
          key={index}
          question={questionData}
          onCheck={handleCheck}
          letter={itemLetters[index]}
          isCompleted={isCompleted}
        />
      )
    })
  );

  return (
    <div>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <ApproveDirectiveSection
            title={'Documentation Review'}
            body={'Please confirm all required documentation is present and can be made available upon request.'}
            onToggleComplete={handleCompleteSectionClick}
            isCompleted={isCompleted}
            disabled={!checkAreAllQuestionsAnswered()}
          />
        </Grid>

        <Grid item>
          <DetailFrame
            title={'Documentation Checklist'}
            iconText={<PlaylistAddCheck/>}
          >
            <ChecklistComponent/>
          </DetailFrame>
        </Grid>
      </Grid>
    </div>
  );
};
