import {
  FETCH_ENTITY_GROUPS_SUCCESS,
  SELECT_ENTITY_GROUP_CRUD,
  SET_SELECTED_ENTITY_GROUP_FIELD,
} from '../constants/actionTypes/entityGroups';
import { ENTITY_GROUPS_INITAL_STATE } from '../components/ServiceManager/Entities/EntityGroups/constants';

const entityGroups = (state = ENTITY_GROUPS_INITAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ENTITY_GROUPS_SUCCESS:
      return {
        ...state,
        list: action.entityGroups,
      };
    case SELECT_ENTITY_GROUP_CRUD:
      return {
        ...state,
        selected: action.selectedEntityGroup
      };
    case SET_SELECTED_ENTITY_GROUP_FIELD:
      return {
        ...state,
        selected: { ...state.selected, [action.key]: action.value }
      };
    default:
      return state;
  }
};

export default entityGroups;
