import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import history from '../../history';
import store from '../../store';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from '../../routes';
import theme from '../../theme';
import './style.css'
import axios from 'axios'
import ErrorBoundary from '../ErrorBoundary';
import ToastMessage from '../../commonComponents/ToastMessage';
import Modal from '../../commonComponents/Modal';
import { apiClient } from '../../api/apiClient';
import { setRolesSuccess } from '../../actions/auth';
import { ENVIRONMENT_SHORT_MAP } from '../../constants/utils';

const App = () => {
  
  useEffect(() => {
    const configuration = store.getState().config;
    const hasConfig = Object.keys(configuration).length;
    if(!hasConfig) {
    (async () => {
      const { data : config } = await axios.get('../configuration.json')
      store.dispatch({
        type: 'SET_CONFIG_DATA',
        config
      })
    })()
    }
  });
  
  useEffect(() => {
    const fetchUserRoles = async () => {
      const { auth } = store.getState().auth;

      if (auth && typeof auth.roles === 'undefined') {
        const { roles } = await apiClient({
          url: '/auth/roles',
          method: 'post',
          data: {
            email: auth.userEmail
          }
        });
        store.dispatch(setRolesSuccess(roles))
      }
    }
    
    fetchUserRoles();
  });
  
  useEffect(() => {
    // redirect to exactera domain
    const configuration = store.getState().config;
    const hasConfig = Object.keys(configuration).length;
    const currentEnv = window.location.hostname;

    if (hasConfig) {
      const redirectUrl =
        configuration.XbsEnvironment === 'production'
          ? 'https://rdtax.exactera.com'
          : `https://rdtax.${ENVIRONMENT_SHORT_MAP[configuration.XbsEnvironment]}.exactera.com`;

      if (currentEnv !== 'localhost' && !currentEnv.includes('exactera')) {
        window.location.replace(redirectUrl);
      }
    }
  })

  return(
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router history={history}>
            <ErrorBoundary>
              <Routes/>
              <ToastMessage />
              <Modal />
            </ErrorBoundary>
          </Router>
        </ThemeProvider>
      </Provider>
    </div>
  )};

export default App;
