import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom';
import { generatePath } from 'react-router';
import classNames from 'classnames';

import { SUB_SECTION_ROUTES } from '../constants';

// ACTIONS
import { fetchSurveyTabs } from '../../../../actions/surveyWizard/surveyWizard';

// SELECTORS
import { setSurveyTabs, currentWizardSurvey } from '../../../../selectors/surveyWizard/surveyWizard';

const commonArrowStyles = {
  borderStyle: 'dashed',
  borderColor: 'transparent',
  borderWidth: '0.6em',
  borderLeftWidth: '0.3em',
  borderLeftStyle: 'solid',
  fontSize: '50px',
  display: 'inline-block',
  height: 0,
  lineHeight: 0,
  width: 0,
  verticalAlign: 'middle',
  backgroundColor: 'transparent',
  position: 'absolute',
  top: '50%',
  marginTop: '-31px',
  left: '100%',
  cursor: 'default',
  content: '""'
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '58px',
    marginTop: '-1px',
    backgroundColor: theme.palette.common.white,
    borderBottom: `2px solid ${theme.palette.grey[200]}`
  },
  tab: {
    backgroundColor: 'transparent',
    marginLeft: '15px',
    height: '56px',
    position: 'relative',
    verticalAlign: 'middle',
    width: '140px',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: 3,
    fontWeight: 500,
    color: '#707983',
    '&::before': {
      ...commonArrowStyles,
      borderLeftColor: theme.palette.grey[200],
      zIndex: 1,
      marginLeft: '0px'
    },
    '&::after': {
      ...commonArrowStyles,
      borderLeftColor: theme.palette.common.white,
      zIndex: 2,
      marginLeft: '-1px'
    }
  },
  active: {
    color: theme.palette.primary.main
  }
}));

const SurveySectionTabs = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const currentSurvey = useSelector(currentWizardSurvey, shallowEqual);
  const surveyTabs = useSelector(setSurveyTabs, shallowEqual);
  const history = useHistory();
  const match = useRouteMatch();
  const params = useParams();

  const { surveyTypeRefId } = currentSurvey;

  useEffect(() => {
    if(surveyTypeRefId) {
      dispatch(fetchSurveyTabs(surveyTypeRefId));
    }
  }, [dispatch, surveyTypeRefId]);

  useEffect(() => {
    if (!match.params.section && surveyTabs.length) {
      const path = generatePath(match.path, {
        surveyId: params.surveyId,
        section: SUB_SECTION_ROUTES[surveyTabs[0].name]
      });
      history.push(path)
    }
  }, [currentSurvey.surveyId, history, match.params.section, match.path, params.surveyId, surveyTabs]);

  const getTabs = () => {
    if(surveyTabs) {
      return surveyTabs.map((tab) => {
        const classes = classNames({
          [styles.tab]: true,
          [styles.active]: match.params.section === SUB_SECTION_ROUTES[tab.name]
        });
        return (
          <div
            onClick={() => history.push(generatePath(
              match.path,
              {
                surveyId: params.surveyId,
                section: SUB_SECTION_ROUTES[tab.name]
              }
            ))}
            data-testid="tab-test"
            className={classes}
            key={tab.id}
          >
            {tab.name}
          </div>
        );
      });
    } else {
      return [];
    }
  };

  return (
    <div data-testid="tab-container" className={styles.container}>
      { getTabs() }
    </div>
  );
};

export default SurveySectionTabs;
