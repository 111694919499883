const defaultMessage = '';
export const checkIsPercentageValid = (value, message = defaultMessage) => {
  
  if (Number(value) > 100){
    return message = `Max value allowed 100`;
  } else if (Number(value) < 0){
    return message = `Min value allowed 0`;
  }
 
};
