export const FETCH_SURVEY_BY_ID_SUCCESS = 'FETCH_SURVEY_BY_ID_SUCCESS';
export const FETCH_SURVEY_TABS_SUCCESS = 'FETCH_SURVEY_TABS_SUCCESS';
export const FETCH_EXPENSE_QUESTIONS_SUCCESS = 'FETCH_EXPENSE_QUESTIONS_SUCCESS';
export const FETCH_EXPENSE_TYPES_FOR_WIZARD_SUCCESS = 'FETCH_EXPENSE_TYPES_FOR_WIZARD_SUCCESS';
export const SET_SELECTED_EXPENSE_SUB_SECTION = 'SET_SELECTED_EXPENSE_SUB_SECTION';
export const NEXT_EXPENSE_QUESTION = 'NEXT_EXPENSE_QUESTION';
export const PREVIOUS_EXPENSE_QUESTION = 'PREVIOUS_EXPENSE_QUESTION';
export const FETCH_SURVEY_PROJECTS_SUCCESS = 'FETCH_SURVEY_PROJECTS_SUCCESS';
export const FETCH_SURVEY_PROJECT_QUALIFICATIONS_SUCCESS = 'FETCH_SURVEY_PROJECT_QUALIFICATIONS_SUCCESS';
export const SELECT_SURVEY_PROJECT_QUALIFICATION = 'SELECT_SURVEY_PROJECT_QUALIFICATION';
export const UPDATE_EXPENSE_QUESTION_ANSWER = 'UPDATE_EXPENSE_QUESTION_ANSWER';
export const PREVIOUS_PROJECT_QUESTION = 'PREVIOUS_PROJECT_QUESTION';
export const NEXT_PROJECT_QUESTION = 'NEXT_PROJECT_QUESTION';
export const UPDATE_PROJECT_QUESTION_ANSWER = 'UPDATE_PROJECT_QUESTION_ANSWER';
export const RESET_STATE_SURVEY_WIZARD_EXPENSES = 'RESET_STATE_SURVEY_WIZARD_EXPENSES';
export const RESET_STATE_SURVEY_WIZARD_PROJECTS = 'RESET_STATE_SURVEY_WIZARD_PROJECTS';
export const RESET_STATE_SURVEY_WIZARD_SURVEY = 'RESET_STATE_SURVEY_WIZARD_SURVEY';
export const FETCH_SURVEY_SECTION_INFO_SUCCESS = 'FETCH_SURVEY_SECTION_INFO_SUCCESS';
export const TOGGLE_SURVEY_SECTION_COMPLETE = 'TOGGLE_SURVEY_SECTION_COMPLETE';
export const SET_IS_SAVE_IN_PROGRESS = 'SET_IS_SAVE_IN_PROGRESS';
export const CHANGE_SURVEY_DOCUMENTS_DESCRIPTION = 'CHANGE_SURVEY_DOCUMENTS_DESCRIPTION';

