import { roles } from '../../constants/roles';

const { CREDIT_CENTRAL_FULL_ACCESS, ACCOUNT_EXECUTIVE, REPOSITORY_ONLY, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, PS_MANAGER, PS_ASSOCIATE, DIY_CLIENT } = roles;

const sidenavItems = [
  { icon: 'creditCentral',
    text: 'Credit Central',
    to: '/credit-central',
    roles: [PS_MANAGER, CREDIT_CENTRAL_FULL_ACCESS, ACCOUNT_EXECUTIVE, REPOSITORY_ONLY, FULL_ACCESS_ADMIN, LIMITED_ACCESS_ADMIN, PS_ASSOCIATE, DIY_CLIENT]
  },
];

export default sidenavItems
