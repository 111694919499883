import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider, Card } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '../../../../commonComponents/Button'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(5)
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  iconContainer: {
    backgroundColor: theme.palette.primary.veryLight,
    display: 'inline-block',
    borderRadius: '50%',
    width: '2.5em',
    height: '2.5em',
    position: 'relative'
  },
  icon: {
    fill: theme.palette.secondary.dark,
    '& path': {
      fill: theme.palette.secondary.dark,
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  title: {
    position: 'absolute',
    left: '125%',
    top: '50%',
    width: '16rem',
    transform: 'translateY(-50%)',
    color: theme.palette.secondary.dark
  },
  helpContainer: {
    border: `1px solid ${theme.palette.grey[500]}`,
    position: 'relative',
    display: 'inline-block',
    color: theme.palette.grey[700],
    borderRadius: '5px',
    padding: theme.spacing(0.75),
    fontSize: '0.75rem',
    cursor: 'pointer',
    zIndex: 20,
  },
  division: {
    marginTop: theme.spacing(2)
  },
  questionNumber: {
    color: theme.palette.grey[700]
  },
  divider: {
    marginTop: theme.spacing(1)
  },
  subSectionGraphic: {
    display: 'inline-block',
    marginLeft: theme.spacing(1)
  },
  circle: {
    width: '0.75em',
    height: '0.75em',
    borderRadius: '50%',
    border: `1px solid ${theme.palette.grey[600]}`,
    display: 'inline-block',
    '&$current': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main
    }
  },
  current: {},
  line: {
    display: 'inline-block',
    width: '2em',
    borderTop: `0.1px solid ${theme.palette.grey[600]}`,
    height: 0,
    transform: 'translateY(-0.3em)'
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    '& .Mui-disabled': {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette.grey[500]}`
    }
  },
  wizardContent: {
    minHeight: '55vh'
  },
  questionHelp: {
    position: 'absolute',
    width: '60ch',
    padding: '1rem',
    top: '125%',
    right: 0,
    zIndex: 2,
    '&$hide': {
      display: 'none'
    }
  },
  clearIcon: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
  },
  hide: {}
}));

const SurveyContentPane = ({
  children,
  title = '',
  questionTitle,
  Icon = () => null,
  currentSubsection = 0,
  totalSubSections = 0,
  onNext,
  onPrevious,
  questionHelp = '',
  questionHelpTooltip,
}) => {
  const styles = useStyles();
  const { hideQuestionHelp, setHideHelp } = questionHelpTooltip;
  const questionHelpClassNames = classNames({
    [styles.questionHelp]: true,
    [styles.hide]: hideQuestionHelp,
  })
  const getSectionsGraphic = (current, total) => {
    return (
      <div className={styles.subSectionGraphic}>
        {[...Array(total).keys()].map(i => {
          const classes = classNames({
            [styles.circle]: true,
            [styles.current]: i === current
          });
          return <React.Fragment key={i} >
            <div data-testid="section-bubbles" className={classes} />
            {(i !== totalSubSections -1) && <div className={styles.line} /> }
          </ React.Fragment>
        })}
      </div>
    )
  };
  return (
    <div className={styles.container}>
      <div className={styles.contentHeader}>
        <div data- className={styles.iconContainer}>
          <Icon className={styles.icon} />
          <Typography data-testid="title" className={styles.title}>{ title }</Typography>
        </div>
        <div data-testid="question-help" onClick={() => questionHelp && setHideHelp(false)} className={styles.helpContainer}>
          Question Help
          <Card className={questionHelpClassNames}>
            <ClearIcon fontSize="small" onClick={(e) => {
              e.stopPropagation();
              setHideHelp(true)
            }} className={styles.clearIcon} />
            <Typography variant="h6" >{ questionTitle }</Typography>
            <Typography variant="caption" >{ questionHelp }</Typography>
          </Card>
        </div>
      </div>
      <div className={styles.division}>
        <Typography data-testid="current-question-indicator" className={styles.questionNumber} variant="caption">
          {`Question ${totalSubSections !== 0 ? currentSubsection + 1 : 0} of ${totalSubSections}`}
          { totalSubSections > 1 && getSectionsGraphic(currentSubsection, totalSubSections) }
        </Typography>
        <Divider className={styles.divider} />
      </div>
      <div data-testid="wizard-content" className={styles.wizardContent}>
        {children}
      </div>
      <div className={styles.division}>
        <Divider />
      </div>
      <div className={styles.footer}>
        <Button onClick={() => { onPrevious(); setHideHelp(true)}} disabled={currentSubsection === 0 || totalSubSections === 0} size="small" >Previous Question</Button>
        <Button onClick={() => { onNext(); setHideHelp(true) }} disabled={currentSubsection === totalSubSections - 1 || totalSubSections === 0} size="small" >Next Question</Button>
      </div>
    </div>
  );
};

export default SurveyContentPane;
