import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  nav: {
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    minHeight: 54,
    padding: `0 ${theme.spacing(2)}px`,
    display: 'flex',
  },
  navDropdown: {
    display: 'flex'
  },
  button: {
    marginTop: `${theme.spacing(0.8)}px`,
    marginLeft: `${theme.spacing(2)}px`,
  },
  containerRollForwardButton: {
    width: '100%',
  },
  buttonProgress : {
    marginLeft: `${theme.spacing(0.6)}px`,
  },
  subheaderExternalContent: {
    width: '100%',
    '& > div': {
      width: '100%',
      height: '100%',
    }
  }
}));
