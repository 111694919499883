import { makeStyles } from "@material-ui/core/styles";

const UseStyles = makeStyles((theme) => ({
  FilterWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 20px'
  }
}));

export { UseStyles };
