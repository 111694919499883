import React from 'react';
import PropTypes from 'prop-types';
import { Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    button: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
      borderRadius: 5,
      border: 0,
      height: 38,
      padding: `0 ${theme.spacing(2)}px`,
      boxShadow: 'none',
      textTransform: 'capitalize',
    },
    rhubarbButton: {
      backgroundColor: theme.palette.rhubarb,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.rhubarbDarker,
      }
    },
    grapeButton: {
      backgroundColor: theme.palette.grape,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.grapeDarker,
      }
    },
    blackButton: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.common.black,
      }
    },
    grayButton: {
      backgroundColor: theme.palette.grey[200],
      color: theme.palette.grey[900],
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
      }
    },
    secondary: {
      border: '1px solid',
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
      transitionProperty: 'all',
      '&:hover': {
        color: theme.palette.common.white,
      }
    }
  }
});

export default function Button({ className, color, ...rest }) {
  const styles = useStyles();

  const colors = {
    grape: styles.grapeButton,
    rhubarb: styles.rhubarbButton,
    black: styles.blackButton,
    gray: styles.grayButton,
    secondary: styles.secondary,
  };

  return <MuiButton {...rest} variant="contained" className={`${colors[color]} ${styles.button} ${className}`}/>;
};


Button.propTypes = {
  color: PropTypes.oneOf(['rhubarb', 'grape', 'black', 'gray', 'secondary']),
  onClick: PropTypes.func,
  className: PropTypes.string,
};
