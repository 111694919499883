import {
  GET_EXPENSES_TYPES_SUCCESS,
  GET_EXPENSES_SUCCESS,
  GET_EXPENSES_TAX_YEARS_SUCCESS,
  GET_EMPLOYEES_FOR_EXPENSE_SUCCESS,
  GET_ALLOCATED_PROJECTS,
  EDIT_ALLOCATED_PROJECT,
  ADD_PROJECT_ALLOCATION,
  DELETE_PROJECT_ALLOCATION,
  SET_EXPENSE_VALIDATION_ERRORS,
  CLEAR_EXPENSE_VALIDATION_ERRORS,
  GET_HOURS_BY_SOURCE,
  SET_TOTAL_EXPENSES,
  SET_DIRECTIVE_CLASSIFICATIONS,
} from '../constants/actionTypes/expense';
import { EXPENSE_FILE_MESSAGES } from '../constants/messages';
import moment from 'moment';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message';
import preValidateExpenseXLSX from '../utils/expenseXLSXValidator'
import { trackPromise } from 'react-promise-tracker';
import { tags } from '../constants/promiseTracker';
import { TOTAL_EXPENSE } from '../components/ServiceManager/Expenses/constants';

// HELPERS
const formatCells = values => {
  const cells = values.map(value => {
    Object.keys(value).forEach(key => {
      if (value[key] !== undefined && value[key] !== null) {
        if (key.includes('percent')) {
          value = { ...value, [key]: (value[key] * 100).toFixed(4) };
        } else if (key.includes('date')) {
          value = { ...value, [key]: moment(new Date(value[key])).utc().format('YYYY-MM-DD') };
        }
      } else {
        value = { ...value, [key]: '-' };
      }
    });
    return value;
  });
  return cells;
};

// ACTIONS
export const getExpensesTypes = (jurisdictionId, taxYear) => async dispatch => {
  try {
    const expensesTypes = await apiClient({
      method: 'get',
      url: `/expense-type?jurisdictionId=${jurisdictionId}&taxYear=${taxYear}`,
    });
    dispatch(getExpensesTypesSuccess(expensesTypes));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const getExpensesTypesSuccess = expensesTypes => ({
  type: GET_EXPENSES_TYPES_SUCCESS,
  expensesTypes,
});

export const getExpenses = (expenseTypeRefId, entityId, taxYear) => async dispatch => {
  try {
    let queryParams = `expenseTypeRefId=${expenseTypeRefId}&entityId=${entityId}`;
    if (taxYear) {
      queryParams += `&expenseTaxYear=${taxYear}`;
    }

    let expenses = await apiClient({
      method: 'get',
      url: `/expense?${queryParams}`,
    });
    if (expenses.length) {
      const { countryQualifiedExpenseAmount } = await apiClient({
        method: 'get',
        url: `/total-expense/country-qualified-expense-amount?${queryParams}`,
      });
      expenses = formatCells(expenses);
      dispatch(getExpensesSuccess(expenses, entityId, expenseTypeRefId));
      dispatch(setTotalExpenses(TOTAL_EXPENSE.country, countryQualifiedExpenseAmount));
      dispatch(getExpensesTaxYears(expenseTypeRefId, entityId));
    } else {
      dispatch(setTotalExpenses(TOTAL_EXPENSE.country, 0));
      dispatch(getExpensesSuccess([], entityId, expenseTypeRefId));
      dispatch(getExpensesTaxYearsSuccess([]));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

const getExpensesSuccess = (expenses, entityId, expenseTypeRefId) => ({
  type: GET_EXPENSES_SUCCESS,
  expenseTypeRefId,
  entityId,
  expenses,
});

export const getExpensesTaxYears = (expenseTypeRefId, entityId) => async dispatch => {
  try {
    const taxYears = await apiClient({
      method: 'get',
      url: `/expense/tax-year?expenseTypeRefId=${expenseTypeRefId}&entityId=${entityId}`,
    });
    dispatch(getExpensesTaxYearsSuccess(taxYears));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const getExpensesTaxYearsSuccess = taxYears => ({
  type: GET_EXPENSES_TAX_YEARS_SUCCESS,
  taxYears,
});

export const deleteExpense = (expenseId, expenseTypeRefId, entityId, projectExpenses) => async dispatch => {
  try {
    await apiClient({
      method: 'delete',
      url: `/expense/${expenseId}`,
      data: { projectExpenses },
    });
    dispatch(getExpenses(expenseTypeRefId, entityId));
    dispatch(showMessage('success', 'Expense deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};
export const bulkDeleteExpense = (expenseIds, expenseTypeRefId, entityId) => async dispatch => {
  try {
    await apiClient({
      method: 'delete',
      url: `/expense/bulk-delete`,
      data: {
        expenseIds,
        entityId
      }
    });
    dispatch(getExpenses(expenseTypeRefId, entityId));
    dispatch(showMessage('success', 'Expenses deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const updateExpense = (payload, projectExpenses, expenseId) => async dispatch => {
  try {
    const projectExpensesPayload = { expenseId, projectExpenses };
    await apiClient({
      method: 'patch',
      url: `/expense/${payload.expense_id}`,
      data: payload,
    });
    await apiClient({
      method: 'post',
      url: '/project-expense/bulk',
      data: projectExpensesPayload,
    });
    dispatch(getExpenses(payload.expense_type_ref_id, payload.entity_id));
    dispatch(showMessage('success', 'Expense updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};


export const uploadXLSXFile = (file, expenseId, entityId) => async dispatch => {
  try {
    const preValidation = await preValidateExpenseXLSX(file)
    if (!preValidation.isXLSX) {
      return dispatch(showMessage('error', preValidation.message, preValidation.status))
    }
    const data = new FormData();
    data.append('expenses', file);

    const uploadResponse = await trackPromise(
      apiClient({
        method: 'post',
        url: `/expense/bulk-upload?expenseTypeRefId=${expenseId}&entityId=${entityId}`,
        data,
      }),
      tags.EXPENSES_BULK_UPLOAD
    )

    if (uploadResponse.errors) {
      dispatch(setExpenseUploadValidationErrors(uploadResponse.errors))
    } else {
      dispatch(getExpenses(expenseId, entityId));
      dispatch(showMessage('success', EXPENSE_FILE_MESSAGES.success, 3000))
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const createExpense = payload => async dispatch => {
  try {
    await apiClient({
      method: 'post',
      url: '/expense',
      data: payload,
    });
    dispatch(getExpenses(payload.expense_type_ref_id, payload.entity_id));
    dispatch(showMessage('success', 'Expense created successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const getEmployeesForExpense = entityId => async dispatch => {
  try {
    const employees = await apiClient({
      method: 'get',
      url: `/employee?entityId=${entityId}`,
    });
    dispatch(getEmployeesForExpenseSuccess(employees));

  } catch (err) {
    dispatch(handleError(err));
  }
};

const getEmployeesForExpenseSuccess = employees => ({
  type: GET_EMPLOYEES_FOR_EXPENSE_SUCCESS,
  employees,
});

export const getAllocatedProjects = expenseId => async dispatch => {
  try {
    const projects = await apiClient({
      method: 'get',
      url: `/project-expense?expenseId=${expenseId}`,
    });
    dispatch(getAllocatedProjectsSuccess(projects));
  } catch (err) {
    dispatch(handleError(err));
  }
};

const getAllocatedProjectsSuccess = projects => ({
  type: GET_ALLOCATED_PROJECTS,
  projects,
});

const setExpenseUploadValidationErrors = errors => ({
  type: SET_EXPENSE_VALIDATION_ERRORS,
  errors
})
export const clearValidationErrors = () => ({
  type: CLEAR_EXPENSE_VALIDATION_ERRORS
})

export const editAllocatedProject = (payload, index) => ({
  type: EDIT_ALLOCATED_PROJECT,
  payload,
  index,
});

export const addProjectAllocation = expenseId => ({
  type: ADD_PROJECT_ALLOCATION,
  expenseId,
});

export const deleteProjectAllocation = index => ({
  type: DELETE_PROJECT_ALLOCATION,
  index,
});

export const setHoursPerSource = employeeId => async dispatch => {
  try {
    const hoursBySource = await apiClient({
      method: 'post',
      url: `/expense/hour-sources`,
      data: { employeeId }
    });
    dispatch(setHoursPerSourceSuccess(hoursBySource));
  } catch (err) {
    dispatch(handleError(err));
  }
}

export const setHoursPerSourceSuccess = hoursBySource => ({
  type: GET_HOURS_BY_SOURCE,
  hoursBySource
});

export const clearHoursPerSource = () => ({
  type: GET_HOURS_BY_SOURCE
});

export const setTotalExpenses = (totalExpenseType, totalExpenses) => ({
  type: SET_TOTAL_EXPENSES,
  totalExpenseType,
  totalExpenses
});

export const setDirectiveClassifications = (directiveClassifications) => ({
  type: SET_DIRECTIVE_CLASSIFICATIONS,
  directiveClassifications,
});

export const fetchDirectiveClassifications = (jurisdictionId,taxYear) => async dispatch => {
  try {
    const directiveClassifications = await apiClient({
      method: 'get',
      url: `/directive-classification?jurisdictionId=${jurisdictionId}&taxYear=${taxYear}`,
    });
    dispatch(setDirectiveClassifications(directiveClassifications));
  } catch (err) {
    dispatch(handleError(err));
  }
};
