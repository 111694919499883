export const getSelectedContainerId = state => state.common.selectedContainer || null;
export const getContainers = state => state.containers.list;
export const getSelectedContainerCrud = state => state.containers.selected;
export const getSelectedContainer = state => {
  const { containers } = state.common;
  const selectedContainerId = getSelectedContainerId(state);
  if (!selectedContainerId || !containers) {
    return {};
  }

  const selectedContainer = containers.find(({ container_id }) => container_id === selectedContainerId);
  const dropdownSelectedContainer = typeof selectedContainer === 'undefined' ? {} : selectedContainer;
  return dropdownSelectedContainer;
};

