import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import ProjectFileHandler from './ProjectFileHandler';
import { selectContainer } from '../../../actions/container';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../constants/promiseTracker';
import Dials from '../../../commonComponents/Dials';

// ACTIONS
import { fetchProjects, bulkDeleteProject } from '../../../actions/project';
import { selectEntity } from '../../../actions/entities';
import { toggleCrudPanel } from '../../../actions/common';
import { showModal } from "../../../actions/modal";

// SELECTORS
import { getSelectedEntity, getSelectedEntityObject } from '../../../selectors/entities';
import { getSelectedContainer } from '../../../selectors/containers';

// COMPONENTS
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';
import TableAndCrud from '../TableAndCrud';
import ProjectCrud from './ProjectCrud';
import AutocompleteSelect from '../../../commonComponents/FormBuilder/fieldTypes/AutocompleteSelect';

// CONSTANTS
import { PROJECT_QUALIFICATION_STATUSES, PROJECT_STATUSES, MAX_TABLE_HEIGHT } from './constants';
import { headConfig, rowsConfig } from './table.config';
import { apiClient } from '../../../api/apiClient';

import { useStyles } from './styles';

const mapProjectStatuses = (projects) => {
  if (!projects) {
    return;
  }

  return projects.map(project => ({
    ...project,
    status: PROJECT_STATUSES[project.status],
    project_qualification_status: PROJECT_QUALIFICATION_STATUSES[project.qualification_status_id]
  }));
};

const Projects = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  let projects = useSelector(state => state.common.selectedEntityProjects);
  const [projectTypes, setProjectTypes] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const entityId = useSelector(getSelectedEntity, shallowEqual);
  const { jurisdiction } = useSelector(getSelectedEntityObject, shallowEqual);
  const selectedContainer = useSelector(getSelectedContainer, shallowEqual);
  const selectedEntity = useSelector(getSelectedEntityObject, shallowEqual);
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_EXPENSES_TYPES_FOR_PROJECTS, delay: 500 });
  const entities = useSelector(state => state.common.entities);

  projects = mapProjectStatuses(projects);

  useEffect(() => {
    const fetchExpenseTypesForProjects = async () => {
      const expenseTypesResult = await trackPromise(
        apiClient({
          method: 'get',
          url: `/expense/type/project?entityId=${entityId}&taxYear=${selectedContainer.tax_year}`,
        }),
        tags.FETCH_EXPENSES_TYPES_FOR_PROJECTS,
      );

      setExpenseTypes(expenseTypesResult);
    };

    if (entityId && selectedContainer.tax_year) {
      fetchExpenseTypesForProjects();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  useEffect(() => {
    if (entityId && selectedContainer.tax_year) {
      dispatch(fetchProjects(entityId, selectedContainer.tax_year));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, entityId]);

  useEffect(() => {
    const fetchProjectTypes = async () => {
      const projectTypesResult = await apiClient({
        method: 'get',
        url: `/project/project-type?jurisdictionId=${jurisdiction.jurisdiction_id}&taxYear=${selectedContainer.tax_year}`,
      });
      setProjectTypes(projectTypesResult);
    };
    if (jurisdiction && selectedContainer.tax_year) {
      fetchProjectTypes();
    }

  }, [selectedContainer, jurisdiction]);

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectContainer(containerId));
  };

  const handleEntitySelect = (event, name, value) => {
    const entityId = parseInt(value.entity_id);
    dispatch(selectEntity(entityId));
  };

  const handleConfirm = ({ actionToExecuteOnConfirm, actionName, buttonText }) => {
    dispatch(showModal('confirmation', {
      title: 'We need your approval',
      textBody: `Are you sure you want to ${actionName.toLowerCase()}?`,
      buttonText,
      onConfirm: actionToExecuteOnConfirm
    }));
  };

  const handleBulkDelete = (selectedRowsData, setSelectedRowsData) => {
    function actionToExecuteOnConfirm() {
      const projectIdsToDelete = selectedRowsData.map(row => row.project_id);
      dispatch(bulkDeleteProject(projectIdsToDelete, selectedEntity.entity_id, selectedContainer.tax_year));
      dispatch(toggleCrudPanel(false));
      setSelectedRowsData([]);
    }
    handleConfirm({
      actionToExecuteOnConfirm,
      actionName: `delete ${selectedRowsData.length} ${selectedRowsData.length > 1 ? 'projects' : 'project'}`,
      buttonText: 'Delete',
    });
  };

  const mapExpensesToProjects = () => {
    return projects.map((project) => {
      const { expenses } = project;
      return { ...project, ...expenses };
    });
  };

  const appendExpensesToProjectsTable = () => {
    const appendExpensesToTableHead = () => {
      const headConfigWithExpenses = [...headConfig];
      expenseTypes.forEach((expenseType) => {
        const expenseHead = { name: expenseType, id: expenseType, type: 'currency' };
        headConfigWithExpenses.push(expenseHead)
      });

      return headConfigWithExpenses;
    };

    const appendExpensesToTableBody = () => {
      return rowsConfig.concat(expenseTypes);
    };

    const headConfigWithExpenses = appendExpensesToTableHead();
    const rowsConfigWithExpenses = appendExpensesToTableBody();
    return { rowsConfigWithExpenses, headConfigWithExpenses };
  };

  const { rowsConfigWithExpenses, headConfigWithExpenses } = appendExpensesToProjectsTable();
  const projectsData = mapExpensesToProjects();
  const totalProjects = projectsData && projectsData.length;
  const dialsConfig = [
    { type: 'default', title: 'All Projects', value: totalProjects },
  ];
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <div className={styles.entityDropdown}>
          <AutocompleteSelect
            name="entity_id"
            label="Entities"
            options={entities}
            muiProps={{
              forcePopupIcon: true
            }}
            sortOptions={{
              propertyToSortBy: 'name',
              direction: 'ascending'
            }}
            optionKeys={{
              name: 'name',
              value: 'entity_id'
            }}
            value={selectedEntity.entity_id}
            onChange={handleEntitySelect}
          />
        </div>
        <CreateNewButton />
        <Dials dialConfig={dialsConfig} />
      </div>
    )
  };

  return (
    <div className={styles.container}>
      <ContainersDropdown onChange={handleContainerSelect} />
      <h1 className={styles.title}>Projects</h1>

      <ProjectFileHandler />

      <TableAndCrud
        tableHead={headConfigWithExpenses}
        tableData={projectsData}
        searchBy={'name'}
        searchByPlaceholder="Name"
        tableRows={rowsConfigWithExpenses}
        shouldShowCheckBoxesOnClick={true}
        formatConfig={{
          locale: 'en-US',
          currency: (selectedEntity.currency && selectedEntity.currency.iso_code) || 'USD',
          shouldIncludeCents: false
        }}
        maxTableHeight={MAX_TABLE_HEIGHT}
        idKey="project_id"
        createNewButtonTitle="Add Project"
        renderComponentBeforeTable={renderComponentBeforeTable}
        isDataFetchInProgress={isDataFetchInProgress}
        bulkActions={{
          delete: handleBulkDelete,
        }}
      >
        <ProjectCrud
          allProjectTypes={projectTypes}
        />
      </TableAndCrud>
    </div>
  )
};

export default Projects
