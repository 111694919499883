import { checkIsIntegerYear } from '../../../../customValidations/checkIsIntegerYear';

export const buildContainerInputFields = container => [
  {
    name: 'name',
    label: 'Name',
    type: 'Input',
    value: container.name,
    required: true,
  },
  {
    name: 'tax_year',
    label: 'Tax Year',
    type: 'Year',
    value:  container.tax_year,
    validationOptions: {
      customValidate: checkIsIntegerYear,
    },
    required: true
  },
];
