import React, {useCallback} from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '../../Button';
import PropTypes from 'prop-types';
import { UseStyles, DialogContent, DialogActions } from './ModalConfirmation.styles';
import { useDispatch } from "react-redux";
import { hideModal } from "../../../actions/modal";

const ModalConfirmation = ({isOpen,cancelText,title,textBody,buttonText,onConfirm}) => {
  const classes = UseStyles();
  const dispatch = useDispatch();
  
  const handleClose = useCallback(() => {
    dispatch(hideModal())
  }, [dispatch]);

  const handleConfirmation = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }
    handleClose();
  }, [onConfirm, handleClose]);

  return (
    <div>
      <Dialog onClose={handleClose} open={isOpen || false}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <Typography gutterBottom>
            {textBody}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button data-testid={'confirmation-modal-cancel-button-test-id'} color='secondary' onClick={handleClose}>
            {cancelText || 'Cancel'}
          </Button>
          <Button data-testid={'confirmation-modal-button-test-id'} onClick={handleConfirmation}>
            {buttonText || ''}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ModalConfirmation.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  textBody: PropTypes.string,
  buttonText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func
};

export default ModalConfirmation;
