import React, { useEffect, useState } from 'react';
import { apiClient } from '../../../../api/apiClient';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import getValueFromEvent from '../../../../utils/getValueFromEvent';

// ACTIONS
import {
  editUser,
  createUser,
  getUserProjects,
  setSelectedUserField,
  deleteUser,
  resetTemporaryPassword,
} from '../../../../actions/users';

// SELECTORS
import { getSelectedUser, getUserValidProjects, getProjectsForEntity } from '../../../../selectors/users';
import { getSelectedEntity } from '../../../../selectors/entities';
import { getSelectedTenant } from '../../../../selectors/tenants';

// CONSTANTS
import { CRUD_TYPES } from './constants';
import FormBuilder from '../../../../commonComponents/FormBuilder';
import { buildUserInputFields } from './buildUserInputFields';
import { rolesByName } from '../../../../constants/roles';
import Modal from '../../../../commonComponents/Modal';

const UserCrud = (props) => {
  const dispatch = useDispatch();
  const user = useSelector(getSelectedUser, shallowEqual);
  const entityProjects = useSelector(getProjectsForEntity, shallowEqual);
  const validProjects = useSelector(getUserValidProjects, shallowEqual);
  const selectedEntity = useSelector(getSelectedEntity, shallowEqual);
  const selectedTenant = useSelector(getSelectedTenant, shallowEqual);
  const [userContainers, setUserContainers] = useState([]);
  const [confirmationModalProps, setConfirmationModalProps] = useState({});
  const [hasTemporaryPasswordExpired, setHasTemporaryPasswordExpired] = useState(false);
  const { allRoles } = props;

  useEffect(() => {
    if (props.crudType !== CRUD_TYPES.create) dispatch(getUserProjects(props))
  }, [dispatch, props]);

  useEffect(() => {
    const fetchUserContainers = async () => {
      const userContainersResult = await apiClient({
        method: 'get',
        url: `container?tenantId=${selectedTenant.tenant_id}`,
      });
      setUserContainers(userContainersResult);
    };

    fetchUserContainers();
  }, [user.user_id, selectedTenant.tenant_id]);
  
  useEffect(() => {
    const checkHasTemporaryPasswordExpired = async () => {
      const { hasTemporaryPasswordExpired: hasTemporaryPasswordExpiredResult } = await apiClient({
        method: 'get',
        url: `/user/check-has-temporary-password-expired?userId=${user.user_id}`
      });
  
      setHasTemporaryPasswordExpired(hasTemporaryPasswordExpiredResult)
    };
    
    if (user.user_id) {
      checkHasTemporaryPasswordExpired();
    }
  }, [user]);

  const handleChange = (e) => {
    const { name } = e.target;
    const value = getValueFromEvent(e);
    dispatch(setSelectedUserField(name, value))
  }

  const handleUpdateUser = () => {
    const { user_id, username, email, enabled, activeProjects, selectedProjects, roles, projects: originalProjects, containers } = user;
    const containerId = entityProjects.length ? entityProjects[0].container_id : null
    let add = [];
    let remove = [];
    if (containerId) {
      add = selectedProjects.filter(p => !originalProjects.some(pr => pr.project_id === p.project_id)).map(({ project_id }) => project_id)
      const validProjects = originalProjects.filter(proj => proj.container_id === containerId)
      remove = validProjects.filter(p => !selectedProjects.some(pr => pr.project_id === p.project_id)).map(({ project_id }) => project_id)
    }

    const validUser = {
      user_id,
      username,
      email,
      enabled: Boolean(enabled),
      activeProjects,
      roles,
      containers,
      projects: {
        add,
        remove,
      }
    }
    dispatch(editUser(validUser, selectedTenant.tenant_id))
    props.closeCrudPanel()
  }

  const handleDeleteUser = () => {
    dispatch(deleteUser(user.user_id))
    props.closeCrudPanel()
  }
  
  const handleResetTemporaryPassword = async () => {
    dispatch(resetTemporaryPassword(user.user_id));
  }

  const handleCreateUser = () => {
    const { username, email, enabled, selectedProjects, roles, containers } = user;
    const projectIds = selectedProjects ? selectedProjects.map(project => project.project_id) : []
    const validUser = {
      username,
      email,
      enabled: Boolean(enabled),
      roles,
      containers,
      projectIds
    }
    dispatch(createUser(validUser))
    props.closeCrudPanel()
  }

  const handleProjectChange = (selectedProjects) => {
    dispatch(setSelectedUserField('selectedProjects', selectedProjects))
  };

  const handleRoleChange = (selectedRoles) => {
    const selectedRoleIds = selectedRoles.map(role => role.id);
    
    const closeModal = () => {
      setConfirmationModalProps({ ...confirmationModalProps, open: false });
    };
    
    const wasAdminRoleAlreadySelected = user.roles.find((rolId) => rolId === rolesByName.FULL_ACCESS_ADMIN)
    const isAdminRoleSelected = selectedRoleIds.find((rolId) => rolId === rolesByName.FULL_ACCESS_ADMIN)
    
    if (!wasAdminRoleAlreadySelected && isAdminRoleSelected) {
      setConfirmationModalProps({
        buttonText: 'Yes, give access',
        setOpenModal: closeModal,
        textBody: 'Are you sure you want to give this user Service Manager Full Access (Admin)?',
        title: 'We need your approval',
        isOpen: true,
        onConfirm: ()  => {
          dispatch(setSelectedUserField('roles', selectedRoleIds))
        },
      });
      
      return;
    }
  
    dispatch(setSelectedUserField('roles', selectedRoleIds));
  };

  const handleUserContainerChange = (selectedContainers) => {
    const selectedContainerIds = selectedContainers.map(container => container.container_id);
    dispatch(setSelectedUserField('containers', selectedContainerIds))
  };

  const getSelectedRoles = () => {
    return allRoles.filter((role) => {
      return (
      user.roles.find((userRoleId) => role.id === userRoleId)
    )});
  };

  const getSelectedUserContainers = () => {
    return userContainers.filter((container) => (
      user.containers.find((userContainerId) => container.container_id === userContainerId)
    ));
  };
  
  const formActions = [
    {
      name: 'Create',
      shouldDisplay: props.crudType === 'create',
      onClick: handleCreateUser,
      shouldCheckInputsValidity: true
    },
    {
      name: 'Update',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleUpdateUser,
      color: 'grape',
      shouldCheckInputsValidity: true
    },
    {
      name: 'Delete',
      shouldDisplay: props.crudType === 'edit',
      onClick: handleDeleteUser,
      color: 'rhubarb',
      shouldAskConfirmation: true
    },
    {
      name: 'Reset Password',
      shouldDisplay: hasTemporaryPasswordExpired && props.crudType === 'edit',
      onClick: handleResetTemporaryPassword,
      color: 'rhubarb',
      shouldAskConfirmation: false
    },
  ];
  const inputFields = buildUserInputFields(user, {
    allRoles,
    handleRoleChange,
    selectedRoles: getSelectedRoles(),
    userContainers,
    selectedUserContainers: getSelectedUserContainers(),
    handleUserContainerChange,
    entityProjects,
    validProjects,
    handleProjectChange,
    selectedEntity
  });
  return ((
    <div>
      <FormBuilder
        onChange={handleChange}
        fields={inputFields}
        formActions={formActions}
      />
  
      <Modal
        name={'confirmation'}
        buttonText={confirmationModalProps.buttonText}
        setOpenModal={confirmationModalProps.setOpenModal}
        textBody={confirmationModalProps.textBody}
        title={confirmationModalProps.title}
        isOpen={confirmationModalProps.isOpen}
        onConfirm={confirmationModalProps.onConfirm}
      />
    </div>
  ))
}

UserCrud.propTypes = {
  crudType: PropTypes.string,
  closeCrudPanel: PropTypes.func,
}

export default UserCrud
