import React, { useEffect, useState } from 'react';
import DemoModal from '../../../../../commonComponents/DemoModal/DemoModal';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import 'rc-slider/assets/index.css';
import { useStyles } from './styles';
import TotalQreExpenseTypeRows from './TotalQreExpenseTypeRows';
import TotalQualifiedExpensesRow from './TotalQualifiedExpensesRow';
import TotalCreditAmountRow from './TotalCreditAmountRow';

const totalQrePerExpenseTypeDemoData = [
  {
    expenseTypeRefId: 1,
    expenseTypeName: 'Wages',
    totalQre: 5842940,
    totalQreNextYear: 5842940,
    percentageIncrease: 1,
  },
  {
    expenseTypeRefId: 2,
    expenseTypeName: 'Supplies',
    totalQre: 329607,
    totalQreNextYear: 329607,
    percentageIncrease: 1,
  },
  {
    expenseTypeRefId: 3,
    expenseTypeName: 'Cloud Hosting',
    totalQre: 0,
    totalQreNextYear: 0,
    percentageIncrease: 1,
  },
  {
    expenseTypeRefId: 4,
    expenseTypeName: 'Contract Research',
    totalQre: 1218928,
    totalQreNextYear: 1218928,
    percentageIncrease: 1,
  },
]

const RdPlannerModal = ({ isOpen, onClose }) => {
  const styles = useStyles();
  const [totalQrePerExpenseType, setTotalQrePerExpenseType] = useState([]);
  
  // For now this useEffect is just a placeholder in case we want this data to come from an api request.
  useEffect(() => {
    setTotalQrePerExpenseType(totalQrePerExpenseTypeDemoData);
  }, []);
  
  const transitionProps = {
    onExited: () => {
      setTotalQrePerExpenseType(totalQrePerExpenseTypeDemoData);
    }
  }
  
  const modalHead = (
    <div className={styles.modalHead}>
      <span className={styles.modalTitle}>R&D Planner</span> <br/>
      <span className={styles.modalSubtitle}>USA - Federal Alternative Simplified Credit</span>
    </div>
  );
  return (
    <DemoModal
      head={modalHead}
      buttonCaption="Done"
      onButtonClick={onClose}
      isOpen={isOpen}
      onClose={onClose}
      transitionProps={transitionProps}>
      <div className={styles.mainWrapper}>
        <TableContainer>
          <Table size="small" className={styles.table}>
            <TableHead>
              <TableRow className={styles.headRow}>
                <TableCell>Expenses</TableCell>
                <TableCell align="right">Current Year</TableCell>
                <TableCell colSpan={2} align="right">Future Year</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TotalQreExpenseTypeRows 
                totalQrePerExpenseType={totalQrePerExpenseType} 
                totalQrePerExpenseTypeState={{ setTotalQrePerExpenseType, totalQrePerExpenseType }} 
              />
  
              <TotalQualifiedExpensesRow totalQrePerExpenseType={totalQrePerExpenseType} />
            </TableBody>
          </Table>
          
          <TotalCreditAmountRow totalQrePerExpenseType={totalQrePerExpenseType} />
        </TableContainer>
      </div>
    </DemoModal>
  );
};

export default RdPlannerModal;
