export const ENTITY_DETAIL_INITIAL_STATE = {
  list: [],
  listDetailTypes: [],
  selected: {
    id: '',
    typeName: '',
    taxYear: '',
    jurisdictionName: '',
    value: '',
    typeId: '',
    jurisdictionId: '',
    jurisdictionCountry: '',
    jurisdictionLocal: '',
    entityId: '',
    entityName: '',
  },
};
