import { apiClient } from "../../../api/apiClient"

export const updateOrCreateAnswers = (answers) => {
  return apiClient({
    method: 'post',
    url: '/localized-entity/answer',
    data: { answers },
  });
};

export const getOverviewQuestions = (payload) => {
  const { jurisdictionId, taxYear, entityId } = payload;
  return apiClient({
    method: 'get',
    url: `/localized-entity?jurisdictionId=${jurisdictionId}&taxYear=${taxYear}&entityId=${entityId}`,
  });
};
