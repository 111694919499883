import React, {useEffect, useRef} from 'react';
import { InputLabel, Input as InputMui } from '@material-ui/core';

const Input = ({ name, value, onChange, label, muiProps, isInvalid, onBlur }) => {
  const inputFieldRef = useRef(null);
  const handleWheel = event => event.preventDefault();

  useEffect(() => {
    const currentRef = inputFieldRef.current;
    currentRef.addEventListener("wheel", handleWheel);
    return () => {
      currentRef.removeEventListener("wheel", handleWheel);
    };
  }, [inputFieldRef]);

  return (
    <>
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <InputMui
        id={name}
        value={value}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        error={isInvalid}
        ref={inputFieldRef}
        {...muiProps}
      />
    </>
  );
};

export default Input;
