import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'table',
    width: '100%',
    height: theme.spacing(4.5),
    borderTop: `1px solid ${theme.palette.grey[300]}`
  },
  letter: {
    width: theme.spacing(1.5),
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  titleText: {
    width: theme.spacing(55),
    paddingLeft: theme.spacing(1.2),
    display: 'table-cell',
    verticalAlign: 'middle'
  },
  checkbox: {
    display: 'table-cell',
    float: 'right',
    verticalAlign: 'middle',
    paddingTop: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
  },

}));

const CheckRow = ({ question, onCheck, letter, isCompleted }) => {
  const classes = useStyles();

  const handleChange = () => {
    onCheck(question.id, !question.answer.value);
  };

  return (
    <>
      <div className={classes.main}>

        <div className={classes.letter}>
          <Typography variant="body2" component="span" color="textSecondary">
            {letter}
          </Typography>
        </div>

        <div className={classes.titleText}>
          <Typography variant="caption" component="p" color="textSecondary">
            {question.question}
          </Typography>
        </div>

        <div className={classes.checkbox}>
          <FormControlLabel
            disabled={Boolean(isCompleted)}
            control={<Checkbox color="primary" checked={Boolean(question.answer.value)} onChange={handleChange}/>}
            label={<Typography variant="caption"
                               color="textSecondary">{question.answer.value ? 'Available' : null}</Typography>}
            labelPlacement="start"
          />
        </div>

      </div>
    </>
  )

}

export default CheckRow;