import React, { useEffect } from 'react';
import { compose } from 'redux';
import { Switch,Route, Redirect } from 'react-router-dom';
import { WithHeader, WithAuth } from '../hocs';

// components
import ServiceManager from '../components/ServiceManager';
import LaunchPad from '../components/LaunchPad';
import CreditCentral from '../components/CreditCentral';
import SurveyCentral from '../components/SurveyCentral';
import LoginRedirect from '../components/LoginRedirect';
import Eula from '../components/Eula';


// constants
import  { APP_SECTIONS } from '../constants/appSections';
import SurveyWelcomePage from '../components/SurveyCentral/WelcomePage';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/auth';

import { setupWalkme } from '../services/walkme';

import { getConfig } from '../selectors/config';
import { getAuthData } from '../selectors/auth';
import { getSelectedTenantId } from '../selectors/tenants';

const Routes = () => {
  const minutesInactiveToLogout = 60;
  const dispatch = useDispatch();
  const config = useSelector(getConfig);
  const { auth } = useSelector(getAuthData);
  const selectedTenantId = useSelector(getSelectedTenantId);

  useEffect(runWalkmeSetup, [config, auth, selectedTenantId]);

  const handleIdleTimeout = () => {
    dispatch(logout());
  };

  useIdleTimer({
    timeout: 1000 * 60 * minutesInactiveToLogout,
    onIdle: handleIdleTimeout,
    debounce: 500
  });

  function runWalkmeSetup() {
    if (config && auth && selectedTenantId) {
      setupWalkme({ config, auth, selectedTenantId });
    };
  }

  return (
    <Switch>
      <Route exact path='/login-redirect' component={LoginRedirect}/>
      <Route path='/eula' component={WithAuth(Eula)}/>
      <Route exact path={`${APP_SECTIONS.survey_central.baseUrl}/welcome`} component={WithAuth(SurveyWelcomePage)} />

      <Route
        path={APP_SECTIONS.service_manager.baseUrl}
        component={
          compose(
            WithHeader(),
            WithAuth)(ServiceManager)}
      />
      <Route
        path={APP_SECTIONS.credit_central.baseUrl}
        component={
          compose(
            WithHeader(),
            WithAuth)(CreditCentral)}
      />
      <Route
        path={APP_SECTIONS.survey_central.baseUrl}
        component={
          compose(
            WithHeader({ toolbarTitle: 'R&D Surveys', shouldShowSubheader: true, shouldFetchEmployeeEntity: true }),
            WithAuth)(SurveyCentral)}
      />

      <Route path='/' component={WithAuth(LaunchPad)} exact/>
      <Redirect to="/" />
    </Switch>
  );
}

export default Routes;
