import React from 'react';
import { useSelector } from "react-redux";
import { getModal } from "../../selectors/modal";

import ModalRegistry from "./Modal.registry";

const Modal = () => {
  const { name, attributes } = useSelector(getModal);
  const ModalComponent = name && ModalRegistry[name];

  if (!ModalComponent) {
    return (<></>);
  }

  return (
    <ModalComponent isOpen={true} {...attributes} />
  );
};

export default Modal;
