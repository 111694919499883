import {
  SELECT_PROJECT_CRUD,
  SET_SELECTED_PROJECT_FIELD,
  SET_SELECTED_PROJECT_TYPE,
  SET_PROJECT_VALIDATION_ERRORS,
  CLEAR_PROJECT_VALIDATION_ERRORS,
  CLEAR_PROJECTS,
} from '../constants/actionTypes/project';

import { PROJECT_BULK_UPLOAD_MESSAGES } from '../constants/messages';
import { tags } from '../constants/promiseTracker';
import { saveEntityProjects } from './entities';
import { apiClient } from '../api/apiClient';
import { handleError } from './error';
import { showMessage } from './message';
import { trackPromise } from "react-promise-tracker";

export const fetchProjects = (entityId, taxYear) => async dispatch => {
  try {
    const projects = await apiClient({
      method: 'get',
      url: `/project?entityId=${entityId}&taxYear=${taxYear}`,
    });
    dispatch(saveEntityProjects(projects));
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const editProject = (projectId, body, taxYear) => async (dispatch) => {
  try {
    await apiClient({
      method: 'patch',
      url: `/project/${projectId}`,
      data: body,
    });
    const { entityId } = body;
    await dispatch(fetchProjects(entityId, taxYear));
    dispatch(showMessage('success', 'Project updated successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const deleteProject = (projectId, entityId, taxYear) => async (dispatch) => {
  try {
    await apiClient({
      method: 'delete',
      url: `/project/${projectId}`,
    });
    await dispatch(fetchProjects(entityId, taxYear));
    dispatch(showMessage('success', 'Project deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const bulkDeleteProject = (projectIds, entityId, taxYear) => async dispatch => {
    try {
    await apiClient({
      method: 'delete',
      url: `/project/bulk-delete`,
      data: {
        projectIds,
        entityId
      }
    });

    await dispatch(fetchProjects(entityId, taxYear));
    dispatch(showMessage('success', 'Projects deleted successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const createProject = (body,taxYear) => async (dispatch) => {
  try {
    await apiClient({
      method: 'post',
      url: '/project',
      data: body,
    });
    const { entityId } = body;
    await dispatch(fetchProjects(entityId, taxYear));
    dispatch(showMessage('success', 'Project created successfully', 3000))
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const uploadBulkProjectSpreadsheet = (file, entityId, taxYear) => async dispatch => {
  try {
    const data = new FormData();
    data.append('projects', file);

    const uploadResponse = await trackPromise(
      apiClient({
        method: 'post',
        url: `/project/upload?entityId=${entityId}`,
        data,
      }),
      tags.PROJECTS_BULK_UPLOAD
    )

    if (uploadResponse.errors) {
      dispatch(setProjectUploadValidationErrors(uploadResponse.errors));
    } else {
      dispatch(showMessage('success', PROJECT_BULK_UPLOAD_MESSAGES.success, 3000));
      dispatch(fetchProjects(entityId, taxYear));
    }
  } catch (err) {
    dispatch(handleError(err));
  }
};

export const selectProjectCrud = (selectedProject) => ({
  selectedProject,
  type: SELECT_PROJECT_CRUD,
});

export const setSelectedProjectField = (key, value) => ({
  type: SET_SELECTED_PROJECT_FIELD,
  value,
  key,
});

export const setSelectedProjectType = (key, value) => ({
  type: SET_SELECTED_PROJECT_TYPE,
  value,
  key,
});

const setProjectUploadValidationErrors = errors => ({
  type: SET_PROJECT_VALIDATION_ERRORS,
  errors
});

export const clearValidationErrors = () => ({
  type: CLEAR_PROJECT_VALIDATION_ERRORS
});

export const clearProjects = () => ({
  type: CLEAR_PROJECTS,
});
