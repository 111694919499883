import React from 'react';
import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Search } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minWidth: 300
  },
  input: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[100],
    border: 'none',
    fontSize: '1rem',
    width: '100%',
    fontFamily: 'Basis Grotesque Pro',
    color: theme.palette.grey[900],
    '&:focus': {
      outline: 'none'
    }
  },
  button: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[900],
    cursor: 'auto',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    }
  }
}));

const Input = ({ label, onChange, icon, value, className, inputClassName }) => {
  const styles = useStyles();
  const selectIcon = (icon) => {
    const icons={
      search: <Search />
    }
    return icons[icon]
  }
  return (
    <div className={`${styles.root} ${className}`}>
      <input className={`${styles.input} ${inputClassName}`} value={value} type="text" placeholder={label} onChange={onChange}/>
      {icon && <Button className={styles.button}>
        {selectIcon(icon)}
      </Button>}
    </div>
  );
};

export default Input;
