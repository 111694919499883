export class LocationProxy {

  static getOrigin() {
    return window.location.origin;
  }

  static replace(url) {
    window.location.replace(url);
  }

}
