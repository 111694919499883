import moment from 'moment';

const parseDateToDisplayable = (date, format = 'MM-DD-YYYY') => {
  if (!date) {
    return ''
  }
  return moment(new Date(date)).utc().format(format)
};

export default parseDateToDisplayable;
