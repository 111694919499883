import React from 'react';
import { useStyles } from '../styles';

const ReadOnly = ({ value, label }) => {
  const styles = useStyles();
  return (
    <>
      <span className={styles.readOnlyTitle}>{label}</span>
      <span>{value}</span>
    </>
  );
};

export default ReadOnly;
