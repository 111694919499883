import React from 'react';

const ExacteraLogoWhiteSvg = ({ className }) => {
    return (
        <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 339.69 331.79"
            className={className}
        >
            <defs>
                <style>{".cls-1{fill:#06ab71;}.cls-2{fill:#fff;}"}</style>
            </defs>
            <polygon
                className="cls-1"
                points="92.61 25.75 1.9 25.75 99.41 162.87 141.86 162.89 166 128.95 92.61 25.75"
            />
            <polygon
                className="cls-1"
                points="230.86 220.16 188.44 220.16 164.29 254.11 217.9 329.49 308.62 329.49 230.86 220.16"
            />
            <polygon
                className="cls-2"
                points="318.28 2.3 209.85 25.96 175.62 80.28 226.98 68.61 149.46 177.62 109.9 177.62 1.9 329.49 92.62 329.49 180.84 205.43 220.39 205.43 291.12 105.98 303.56 160.9 337.79 106.58 318.28 2.3"
            />
        </svg>
    );
};

export default ExacteraLogoWhiteSvg;

