import React, { useEffect } from 'react';
import { headConfig, rowsConfig } from './tableConfig';
import EntityDetailsCrud from './EntityDetailCrud';
import TableAndCrud from '../../TableAndCrud';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { fetchEntityDetails } from '../../../../actions/entityInfo';
import { getEntityDetails } from '../../../../selectors/entityInfo';
import { MAX_TABLE_HEIGHT } from '../constants'
import { usePromiseTracker } from 'react-promise-tracker';
import { tags } from '../../../../constants/promiseTracker';
import LoadingElement from '../../../../commonComponents/LoadingElement';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  beforeTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  }
}));

const EntityDetail = ({ entity }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const entityDetails = useSelector(getEntityDetails, shallowEqual);
  const { entityId, currencyIsoCode } = entity;
  const { promiseInProgress: isDataFetchInProgress } = usePromiseTracker({ area: tags.FETCH_ENTITY_DETAILS, delay: 0 });

  useEffect(() => {
    if (entityId) {
      dispatch(fetchEntityDetails(entityId))
    }
  }, [dispatch, entityId]);
  
  const renderComponentBeforeTable = (CreateNewButton) => {
    return (
      <div className={styles.beforeTable}>
        <CreateNewButton />
      </div>
    )
  };
  
  if (isDataFetchInProgress) {
    return <LoadingElement />
  }
  
  return (
    <TableAndCrud
      tableHead={headConfig}
      tableData={entityDetails}
      searchBy="typeName"
      searchByPlaceholder="Name"
      tableRows={rowsConfig}
      maxTableHeight={MAX_TABLE_HEIGHT}
      formatConfig={{
        currency: currencyIsoCode,
        shouldIncludeCents: true
      }}
      idKey="id"
      createNewButtonTitle="Add Entity Detail"
      renderComponentBeforeTable={renderComponentBeforeTable}
    >
      <EntityDetailsCrud entity={entity} />
    </TableAndCrud>
  );

};

export default EntityDetail;
