import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

// ACTIONS
import { selectContainer } from '../../../actions/container';
import { getReportsData } from '../../../actions/report';
import { fetchCreditsForDropdownSuccess, selectCreditForDropdown } from '../../../actions/credit';

// SELECTORS
import { getSelectedEntity } from '../../../selectors/entities';
import { getSelectedCreditForDropdown } from '../../../selectors/common';

// COMPONENTS
import TabNavigation from '../../../commonComponents/TabNavigation';
import ReportStage from './ReportStage';
import ContainersDropdown from '../../GlobalDropdowns/ContainersDropdown';

// CONSTANTS
import { TABS, SECTION_TITLE} from './constants';
import { LocalStorageProxy } from '../../../proxies/localStorageProxy';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
}));

const Reports = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const entityId = useSelector(getSelectedEntity);
  const creditId = useSelector(getSelectedCreditForDropdown);

  useEffect(() => {
    dispatch(getReportsData(entityId, creditId));
  }, [dispatch, entityId, creditId])

  const handleContainerSelect = (value) => {
    const containerId = parseInt(value);
    dispatch(selectCreditForDropdown(null));
    LocalStorageProxy.removeItem('study-id-report-section');
    dispatch(fetchCreditsForDropdownSuccess([]));
    dispatch(selectCreditForDropdown(null));
    dispatch(selectContainer(containerId));
  };

  return (
    <div className={styles.container}>
      <ContainersDropdown onChange={handleContainerSelect} />

      <h1>{SECTION_TITLE}</h1>

      <TabNavigation tabs={TABS.map(tab => tab.name)}>
        {
          TABS.map((tab, i) =>
            <ReportStage key={`${tab.name}_${i}`} stage={tab.id} />
            )
        }
      </TabNavigation>
    </div>
  );
};

export default Reports;
