class RDPlannerModalShared {
  static formatCurrency(value) {
    return `$${Math.floor(value).toLocaleString()}`;
  }
  
  static getTotalQreSumForAllExpenseTypes = (totalQrePerExpenseType) => {
    const allExpenseTypeTotalQres = totalQrePerExpenseType.map((expenseTypeTotalQre) => expenseTypeTotalQre.totalQre)
    
    const totalForAllExpenseTypes = allExpenseTypeTotalQres.reduce((total, expenseTypeTotalQre) => {
      return total + expenseTypeTotalQre;
    }, 0);
    
    return totalForAllExpenseTypes;
  };
  
  static getTotalQreSumForAllExpenseTypesNextYear = (totalQrePerExpenseType) => {
    const allExpenseTypeTotalQres = totalQrePerExpenseType.map((expenseTypeTotalQre) => (
      expenseTypeTotalQre.totalQreNextYear
    ));
    
    const totalForAllExpenseTypes = allExpenseTypeTotalQres.reduce((total, expenseTypeTotalQre) => {
      return total + expenseTypeTotalQre;
    }, 0);
    
    return totalForAllExpenseTypes;
  };
}

export default RDPlannerModalShared;
